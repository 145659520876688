import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'recipientIdFilter', pure: false})

export class RecipientIdFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === '') { return value; }

    let query = args.toLowerCase();
    return value.filter(obj =>
      obj.recipientOrganization.id.toLowerCase().indexOf(query) > -1
    );
  }
}

@Pipe({name: 'senderIdFilter', pure: false})

export class SenderIdFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === '') { return value; }

    let query = args.toLowerCase();
    return value.filter(obj =>
      obj.senderOrganization.id.toLowerCase().indexOf(query) > -1
    );
  }
}
