import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'column-toggle',
  templateUrl: './column-toggle.component.html',
  styleUrls: ['./column-toggle.component.scss']
})
export class ColumnToggleComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() availableColumns = [];
  @Input() displayedColumns = [];
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  icon = 'settings';
  lastColumnDragged;
  lastColumnDropped;
  lastNodeDropped;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      allSelected: this.availableColumns.length === this.displayedColumns.length,
      columns: this.buildColumnControls(true)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['displayedColumns']) {
      if (this.form && this.form.controls) {
        this.form.controls['columns'] = this.buildColumnControls(true);
        if (this.availableColumns.length === this.displayedColumns.length) {
          this.form.controls['allSelected'].setValue(true, { emitEvent: false });
        } else if (this.form.controls['allSelected'].value === true) {
          this.form.controls['allSelected'].setValue(false, { emitEvent: false });
        }
      }
    }
  }

  get columns(): FormArray {
    return this.form.get('columns') as FormArray;
  }

  get allSelected() {
    return this.form.get('allSelected');
  }

  buildColumnControls(init = false) {
    if (init) {
      this.sortAvailableColumns();
    }

    const arr = this.availableColumns.map((column) => {
      return this.fb.control(
        this.displayedColumns.includes(column.key)
      );
    });

    return this.fb.array(arr);
  }

  onSelectAllChange(): void {
    const formValue = this.form.controls['allSelected'].value;
    this.displayedColumns = formValue
      ? this.availableColumns.map((column) => column.key)
      : ['select', 'expand', 'actions'];

    this.form.controls['columns'] = this.buildColumnControls();

    const selectIdx = this.availableColumns.findIndex(c => c.key === 'select');
    const expandIdx = this.availableColumns.findIndex(c => c.key === 'expand');
    const actionsIdx = this.availableColumns.findIndex(c => c.key === 'actions');
    if (selectIdx > -1 && selectIdx !== 0) { this.moveFormControl('select', 0); }
    if (expandIdx > -1 && expandIdx !== 1) { this.moveFormControl('expand', 1); }
    if (actionsIdx > -1 && actionsIdx !== (this.displayedColumns.length - 1)) {
      this.moveFormControl('actions', this.displayedColumns.length - 1);
    }

    const formValues = this.form.controls['columns'].value;
    this.displayedColumns = this.availableColumns.map((column, index) => {
      if (formValues[index]) { return column.key; }
    }).filter(Boolean);

    this.displayedColumnsChange.emit(this.displayedColumns);
  }

  onColumnChange(node: any, column: any): void {
    const formControls = this.form.controls['columns'] as FormArray;

    if (!node.value) {
      this.removeFormControl(column.key);
    }

    if (node.value) {
      let newPosition = this.displayedColumns.length;
      if (column.key === 'select') { newPosition = 0; }
      if (column.key === 'expand') { newPosition = 1; }
      if (column.key !== 'actions' && column.key !== 'select' && column.key !== 'expand' && this.displayedColumns.includes('actions')) {
        newPosition = this.displayedColumns.length - 1;
      }
      this.moveFormControl(column.key, newPosition);
    }

    const formValues = this.form.controls['columns'].value;
    this.displayedColumns = this.availableColumns.map((col, index) => {
      if (formValues[index]) { return col.key; }
    }).filter(Boolean);

    this.displayedColumnsChange.emit(this.displayedColumns);
  }

  onDragEnd(event: any, column: any): void {
    event.target.draggable = false;
    this.lastColumnDragged = column;

    if (this.lastNodeDropped && this.lastNodeDropped.dragOver) {
      this.lastNodeDropped.dragOver = false;
    }

    if (this.lastColumnDragged && this.lastColumnDropped) {
      const draggedIdx = this.availableColumns.findIndex(c => c.key === this.lastColumnDragged.key);
      const droppedIdx = this.availableColumns.findIndex(c => c.key === this.lastColumnDropped.key);
      if (draggedIdx > -1 && droppedIdx > -1) {
        this.availableColumns.splice(draggedIdx, 1);
        this.availableColumns.splice(droppedIdx, 0, this.lastColumnDragged);
      }

      this.displayedColumns = this.availableColumns.map(col => {
        if (this.displayedColumns.includes(col.key)) { return col.key; }
      }).filter(Boolean);
      this.displayedColumnsChange.emit(this.displayedColumns);
    }

    this.resetDragAndDrop();
  }

  onDrop(node: any, column: any): void {
    node.dragOver = false;

    // If column is not checked, column cannot be a drop zone
    if (!node.value || column.key === 'select' || column.key === 'expand' || column.key === 'actions') {
      this.resetDragAndDrop();
    } else {
      this.lastColumnDropped = column;
      this.lastNodeDropped = node;
    }
  }

  onHandleMouseDown(event: any): void {
    event.target.parentNode.draggable = true;
  }

  removeFormControl(key: string): void {
    if (!key) { return; }
    const formControls = this.form.controls['columns'] as FormArray;
    const currentIdx = this.availableColumns.findIndex(c => c.key === key);
    const column = this.availableColumns.find(c => c.key === key);
    const node = formControls.controls[currentIdx];
    this.availableColumns.splice(currentIdx, 1);
    this.availableColumns.push(column);
    formControls.removeAt(currentIdx);
    formControls.push(node);
  }

  moveFormControl(key: string, newIdx: number): void {
    if (!key || newIdx < 0) { return; }
    const formControls = this.form.controls['columns'] as FormArray;
    const currentIdx = this.availableColumns.findIndex(c => c.key === key);
    const column = this.availableColumns.find(c => c.key === key);
    const node = formControls.controls[currentIdx];
    if (currentIdx && column && node) {
      this.availableColumns.splice(currentIdx, 1);
      this.availableColumns.splice(newIdx, 0, column);
      formControls.removeAt(currentIdx);
      formControls.insert(newIdx, node);
    }
  }

  resetDragAndDrop(): void {
    this.lastColumnDragged = '';
    this.lastColumnDropped = '';
  }

  sortAvailableColumns(): void {
    this.availableColumns.sort((a, b) => {
      const aInDisplay = this.displayedColumns.indexOf(a.key);
      const bInDisplay = this.displayedColumns.indexOf(b.key);
      if (aInDisplay === -1 && bInDisplay === -1) { return 1; }
      if (aInDisplay === -1) { return 1; }
      if (bInDisplay === -1) {  return -1; }
      return aInDisplay > bInDisplay ? 1 : -1;
    });
  }
}
