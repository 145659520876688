import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const fancyTableAnimation = trigger('expandRow', [
  state(
    'collapsed',
    style({ height: '0px', minHeight: '0', visibility: 'hidden', padding: '0' })
  ),
  state('expanded', style({ height: '*', visibility: 'visible' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  ),
]);
