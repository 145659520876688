import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dispatch-by-date',
  templateUrl: './dispatch-by-date.component.html',
  styleUrls: ['./dispatch-by-date.component.scss']
})

export class DispatchByDateComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
