import { Resource } from '../../shared/resource';

export class ProductItem extends Resource {
  deleted: boolean;
  createdAt: string;
  lastModified: string;
  description: string;
  materialCode: string;
  itemType: string;
  itemSubType: string;
  itemCategory: string;
  createdBy: string;
  lastModifiedBy: string;
  organization: string;

  constructor() {
    super();
  }
}
