import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'kindFilter', pure: false})

export class KindFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === '') { return value; }
    return value.filter(obj =>
      obj.kind.toLowerCase() === args.toLowerCase()
    );
  }
}
