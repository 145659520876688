import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventAlert } from './event-alert';

@Injectable()
export class EventAlertService {
    private alert = new Subject<any>();
    private clicked = new Subject<boolean>();

    showAlert(descriptionText: string, actionText: string): Observable<boolean> {
      this.alert.next({ 'description': descriptionText, 'action': actionText });
      return this.clicked.asObservable();
    }

    getAlert(): Observable<EventAlert> {
      return this.alert.asObservable();
    }

    click(): void {
      this.clicked.next(true);
    }
}
