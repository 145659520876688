import { OnInit, Component, EventEmitter, Output, Input, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { KeyCodes } from '../enums/key-codes.enum';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput;

  @Input() searchTerm;
  @Input() searchBarType: string;
  @Input() autoSearchDelay = 1000;
  @Output() searchTermChange: EventEmitter<any> = new EventEmitter<any>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  searchOpen = false;
  placeholder: string;

  constructor() { }

  ngOnInit() {
    this.updatePlaceholder();
  }

  ngAfterViewInit() {
    this.allSubscriptionsToUnsubscribe.push(
      this.searchInput.valueChanges
        .pipe(debounceTime(this.autoSearchDelay), distinctUntilChanged())
        .subscribe((value) => {
          if (value === '') {
            this.searchTermChange.emit(value.trim());
          } else {
            this.changeTerm(value);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.code === KeyCodes.Enter) {
      this.changeTerm(this.searchTerm);
      event.preventDefault();
    }
  }

  openSearchBar(bool: boolean): void {
    this.searchOpen = bool;
    if (bool) {
      this.placeholder = 'Search';
      this.searchInput.nativeElement.focus();
    } else {
      this.placeholder = null;
    }
  }

  onBlur(): void {
    this.changeTerm(this.searchTerm);
  }

  updatePlaceholder(): void {
    this.placeholder = (this.searchBarType === 'map') ? null : 'Search';
  }

  changeTerm(value): void {
    if (value && value.trim()) {
      this.searchTermChange.emit(value.trim());
    }
  }

  clearTerm(): void {
    const previousSearchTerm = this.searchTerm;
    this.searchTerm = '';
    this.updatePlaceholder();
    if (previousSearchTerm && previousSearchTerm.trim() !== this.searchTerm) {
      this.searchTermChange.emit(this.searchTerm);
    }
  }
}
