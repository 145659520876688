<form #newCustomer="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>New Customer</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <p translate>
        Create a new customer so you can use this as project and job filter criteria and for invoicing purposes.
      </p>

      <div class="field-row">
        <div class="field-group company-name">
          <label class="required" translate>Company Name</label>
          <input type="text" name="company-name" placeholder="{{ 'Company Name' | translate }}" required [(ngModel)]="model.name" #name="ngModel" />
          <span [ngClass]="{'ng-valid': name.valid, 'ng-invalid': name.invalid, 'ng-pristine': name.pristine}"></span>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group email">
          <label class="required" translate>Email Address</label>
          <input type="text" name="email" placeholder="{{ 'Email Address' | translate }}" required [(ngModel)]="model.email" #email="ngModel" />
          <span [ngClass]="{'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group phone">
          <label class="required" translate>Phone Number</label>
          <input type="text" name="phone" placeholder="{{ 'Phone Number' | translate }}" required [(ngModel)]="model.phone" #phone="ngModel" />
          <span [ngClass]="{'ng-valid': phone.valid, 'ng-invalid': phone.invalid, 'ng-pristine': phone.pristine}"></span>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button id="save-customer-button" class="btn btn-primary" (click)="newCustomer.form.valid && submit(); false"
            [disabled]=" ! newCustomer.form.valid || loading"
            [ngClass]="{loading:loading}"
            translate>
      Save Customer
    </button>
  </div>
</form>
