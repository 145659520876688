import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomMarker } from '@ngui/map';
import { find as _find } from 'lodash';
import { LocationUpdate } from '../locationUpdate';
import { TMapMarkerStatus, TMapMarkerType } from '../map-marker';
import { JobEvent } from '../../job-events/job-event';
import { CondensedJobEvent } from '../../job-events/condensed-job-event';
import { Job } from '../job';

declare let google: any;

@Component({
  selector: 'map-markers-location-updates',
  templateUrl: './map-markers-location-updates.component.html',
  styleUrls: ['./map-markers-location-updates.component.scss']
})
export class MapMarkersLocationUpdatesComponent {
  @Input() locationUpdates: LocationUpdate[];
  @Input() markerType: TMapMarkerType;
  @Input() markerStatus: TMapMarkerStatus;
  @Input() map: any;
  @Input() activeJobEvents: JobEvent[] | CondensedJobEvent[] = [];
  @Input() activeJobs: Job[] = [];
  @Input() customUpdateMarkers = [];
  @Input() errors = [];
  @Input() assignments = {
    items: [],
    errors: [],
    loading: false,
  };

  @Output() customUpdateMarkersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() errorsChange: EventEmitter<any[]> = new EventEmitter();
  @Output() assignmentTitle: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenDriverInfoWindow: EventEmitter<{index: number, update: LocationUpdate}>  = new EventEmitter<any>();

  public assignmentDetails: any = {};
  public selectedJobEvent: any;
  public selectedJob: Job;

  constructor() { }

  /**
  * If the window is less than 900px wide, the assignment details are assigned
  * to component properties and displayed in the template.
  *
  * If the window is greater than or equal to 900px wide, the Driver Info Window
  * is opened on the map.
  *
  * @param {any} update The location update
  * @param {any} index The marker index in the array
  */
  openAssignmentDetails(update, index): void {
    if (window.innerWidth < 900) {
      let latLng;
      this.assignmentDetails = {};
      if (update && update.location && update.driver && update.driver.id) {
        this.assignmentDetails = _find(this.assignments.items, { driver: { id: update.driver.id } });
        latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
      } else if (update && update.shift && update.driver && update.driver.id) {
        this.assignmentDetails = update;
        let locationUpdate = _find(this.locationUpdates, { driver: { id: update.driver.id } });
        if (locationUpdate && locationUpdate['location']) {
          latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
        }
      }

      if (this.map && typeof this.map.setCenter === 'function' && latLng) {
        this.map.setCenter(latLng);
        this.map.setZoom(17);
      }
      if (this.assignmentDetails && this.assignmentDetails.jobevent) {
        this.selectedJobEvent = _find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
        this.selectedJob = _find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
        this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
      } else {
        this.errors = ['There was an issue getting the assignment details.'];
        this.errorsChange.emit(this.errors);
      }
    } else {
      this.onOpenDriverInfoWindow.emit({ index, update });
    }
  }

  onCustomUpdateMarkerInit(customMarker: CustomMarker): void {
    this.customUpdateMarkers.push(customMarker);
    this.customUpdateMarkersChange.emit(this.customUpdateMarkers);
  }

}
