export const JOBWEIGHTOPTIONS = [
  { value: 'ton', label: 'Tons', name: 'Ton', selected: true  },
  { value: 'metric-tons', label: 'Metric Tons', name: 'Metric Ton' },
  { value: 'pound', label: 'Pounds', name: 'Pound' },
  { value: 'cuyds', label: 'Cu. Yds.', name: 'Cubic Yard' },
  { value: 'bushel', label: 'Bushels', name: 'Bushel' },
  { value: 'bag', label: 'Bags', name: 'Bag' },
];

export enum JobHaulType {
  load = 'load',
  hour = 'hour',
  weight = 'weight'
}

export const TRIPTICKETIMAGETYPE = 'ticket_image';
export const TRIPSIGNATUREIMAGETYPE = 'signature_image';

export const LOADINGTRIPTICKETIMAGETYPE = 'loading_ticket_image';
export const LOADINGTRIPSIGNATUREIMAGETYPE = 'loading_signature_image';
export const UNLOADINGTRIPTICKETIMAGETYPE = 'unloading_ticket_image';
export const UNLOADINGTRIPSIGNATUREIMAGETYPE = 'unloading_signature_image';

// organization attachments
export const ORGANIZATIONIMAGETYPE = 'organization_image';
export const ORGANIZATIONINSURANCE = 'insurance_document';
export const ORGANIZATIONBUSINESSLICENSE = 'business_license';
export const ORGANIZATIONOVERWEIGHTPERMIT = 'overweight_permit';

// dialogs
export const DEFAULT_DIALOG_SIZE = {
  width: '430px',
  height: '250px',
};

export const DEFAULT_MAP_REFRESH_TIME = 120000; // 2min expressed in milliseconds - 2 * 60 * 1000
