import {
  Component, Output, OnInit, EventEmitter, Input, TemplateRef,
  ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { difference } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TrackableService } from './trackable.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { AuthenticationService } from '../shared';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { Trackable } from './trackable';
import { FiltersDialogComponent } from '../shared/filters-dialog/filters-dialog.component';
import { TrackableTypeService } from '../trackable-types/trackable-type.service';
import { DropdownConfig } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { EditTrackableComponent } from './edit-trackable.component';
import { NewTrackableDialogComponent } from './new-trackable-dialog.component';

// animations
import { editTrackableAnimation } from '../shared/animations/edit-trackable.animation';

@Component({
  selector: 'trackables',
  templateUrl: './trackables.component.html',
  styleUrls: ['./trackables.component.scss'],
  animations: [editTrackableAnimation]
})
export class TrackablesComponent implements OnInit {
  trackableList: Trackable[];
  loadingProgress = 0;
  displayKeys = ['name', 'displayName'];

  @Input() availableColumns = [
    { key: 'select' },
    { key: 'trackable-type', title: this.translationService.instant('Trackable Type'), sortable: true, sortBy: 'trackable_type__name' },
    { key: 'device-id', title: this.translationService.instant('Device ID'), sortable: true, sortBy: 'device_id' },
    { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
    { key: 'active', title: this.translationService.instant('Active'), sortable: true, sortBy: 'active' },
    { key: 'device-state', title: this.translationService.instant('Device State'), sortable: true, sortBy: 'device_state' },
    { key: 'position-source', title: this.translationService.instant('Position Source'), sortable: true, sortBy: 'position_source' },
    { key: 'hdop', title: this.translationService.instant('HDOP'), sortable: true, sortBy: 'hdop' },
    { key: 'rssi', title: this.translationService.instant('RSSI'), sortable: true, sortBy: 'rssi' },
    { key: 'voltage', title: this.translationService.instant('Voltage'), sortable: true, sortBy: 'voltage' },
    { key: 'heading', title: this.translationService.instant('Heading'), sortable: true, sortBy: 'heading' },
    { key: 'speed', title: this.translationService.instant('Speed'), sortable: true, sortBy: 'speed' },
    { key: 'accuracy', title: this.translationService.instant('Accuracy'), sortable: true, sortBy: 'accuracy' },
    { key: 'temperature', title: this.translationService.instant('Temperature'), sortable: true, sortBy: 'temperature' },
    { key: 'last-status-event', title: this.translationService.instant('Last Status Event'), sortable: true, sortBy: 'last_status_event' },
    { key: 'location', title: this.translationService.instant('Location'), sortable: false },
    { key: 'action', title: this.translationService.instant('Action') },
  ];
  @Input() displayedColumns = [
    'select', 'trackable-type', 'device-id', 'truck', 'active', 'location',
    'last-status-event', 'action'
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() query = {};
  @Input() carrierId;
  @Input() customHeight;
  @Input() customClasses = 'trackables';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  trackable: Trackable;
  tableConfig = {
    hasHeader: true,
    service: TrackableService,
    preferenceKey: 'TrackablesComponent-TrackableService',
    filterQuery: false,
    query: {},
    collectionTitle: this.translationService.instant('Trackable'),
    noResultsText: this.translationService.instant('a trackable'),
    newRecordModal: () => { this.addTrackable(); },
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ]
  };
  filters = [];
  loading = true;
  type = 'all';
  confirmDialog: MatDialogRef<any>;
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  @ViewChild(EditTrackableComponent) editDrawer;
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('trackablesTable') trackablesTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle') columnToggle;
  filtersDialog: FiltersDialogComponent;
  saveTrackableCallback = () => {
    this.trackablesTable.getRecords();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public trackableService: TrackableService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslateService
  ) { }

  /**
   * Subs to any updates in the listAllProgress subject in the trackable service
   */
  ngOnInit() {
    this.allSubscriptionsToUnsubscribe.push(
      this.trackableService.listAllProgress.subscribe(progress => {
        this.loadingProgress = Math.ceil(progress * 100);
      })
    );
  }

  /**
   * Parses the url params and queries to set values used by the table and grid
   */
  ngAfterViewInit() {
    this.tableConfig['customHeight'] = this.customHeight;
    if (this.route && this.route.queryParams) {
      this.route.queryParams.forEach((params: Params) => {
        this.loading = true;
        this.search = params['search'] || '';
      });
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.trackablesTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
      this.trackablesTable.query = this.query;
      this.refreshTable();
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * @param  {} event
   * This function would get called on the click of row of the table
   * On click of row it will open the edit drawer for the selected trackable.
   */
  clickAction(event) {
    if (event) {
      if (this.editDrawer && this.editDrawer.trackableForm && !this.editDrawer.trackableForm.form.pristine) {
        this.editDrawer.confirmBeforeClosing().subscribe((dialogResult) => {
          if (dialogResult) {
            this.editDrawer.submit();
          } else {
            this.editDrawer.close();
            this.selectTrackable(event, event[1]);
          }
        });
      } else {
        this.editDrawer.close();
        this.selectTrackable(event, event[1]);
      }
    }
  }

  /**
   * @param  {} action
   * @param  {} trackable
   * Check the action to be performed and call the corresponding function.
   * other functions like export can be added here in future.
   */
  menuAction(action: string, trackable) {
    switch (action) {
      case 'edit':
        this.selectTrackable(null, trackable);
        break;
      case 'remove':
        this.removeTrackable(trackable);
        break;
    }
  }

  /**
   * @param  {} e
   * @param  {} trackable
   * This function would open a edit window for updating or removing
   * the trackable
   */
  selectTrackable(e, trackable) {
    let target = e && (e.target || e.srcElement || e.currentTarget);
    if (
      target && target.className && target.className.includes('action-menu-icon') ||
      target && target.type === 'checkbox'
    ) {
      // Do nothing
    } else {
      // if click action is performed on the opened trackable then editor will be closed.
      if (trackable && this.trackable && this.trackable.id === trackable.id) {
        this.trackable = null;
      } else {
        setTimeout(() => {
          this.trackable = trackable;
          this.editDrawer.setOpen();
          this.editDrawer.afterEdit = this.saveTrackableCallback;
        }, 100);
      }
    }
  }

  /**
   * @param  {} trackable
   * Removes the selected trackable and refresh the table with new data
   * Opens the remove confirm dialog
   */
  removeTrackable(trackable) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Trackable?'),
      body: this.translationService.instant('This trackable will be deleted and will NOT be recoverable.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.trackableService.remove(trackable).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  /**
   * opens a page for adding new trackable
   */
  addTrackable() {
    const dialog = this.dialog.open(NewTrackableDialogComponent, {
      width: '430px',
      height: '800px',
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.callback = this.saveTrackableCallback;
    }
  }

  /**
   * refresh/reload the trackables table on any action performed like
   * add, edit, remove
   */
  refreshTable() {
    if (this.trackablesTable) {
      let query = {};
      this.trackablesTable.getRecords({ ...this.tableConfig['query'], ...this.query, ...query });
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.filters = [
      {
        type: 'dropdown',
        field: 'trackableType',
        label: 'Trackable Type',
        dropdownConfig: <DropdownConfig>{
          service: TrackableTypeService
        }
      },
      {
        type: 'text',
        field: 'deviceId',
        label: 'Device ID Search'
      }
    ];
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      trackableType: 'trackable_type',
      deviceId: 'device_id'
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (filterRes[key]) {
        if (key === 'tags') {
          if (values && values.length > 0) {
            values = values.map(tag => tag.name).join(',');
            query[queryKeys[key]] = values;
          }
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
        }
      }
      let filter = new FilterOption({
        filterType: 'text',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (!filter.values) { falseyFilters.push(filter); }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

   /**
   * This function is called when edit is successfully completed
   * This would update the table with the changed fields
   *
   * @returns {void}
   */
  onEditComplete(modifiedTrackable): void {
    this.trackablesTable.updateTable(modifiedTrackable);
    if (modifiedTrackable && modifiedTrackable.removed) { this.refreshTable(); }
  }

  onDataLoaded(event: any) {
    const query = {...this.tableConfig.query, ...this.query};
  }

  onClose() {
    this.trackable = null;
  }
}
