import { TranslateService } from '@ngx-translate/core';
import { StatsJobEventFilterService } from '../../job-events/stats-job-event.filter.service';
import { FilterOption } from '../../shared/filters-panel/filter-option';

export const DISPLAYED_COLUMNS = [
  'select',
  'expand',
  'order-number',
  'name',
  'customer',
  'num-trucks',
  'dispatched-drivers-count',
  'daily-delivery-target',
  'ticketed-weight',
  'weight-in-transit',
  'total-tons',
  'total-ordered',
  'total-loads',
  'shares-count',
  'loading-location-name',
  'unloading-location-name',
  'material',
  'quantities-detail',
  'start-time',
  'avg-trip-time',
  'job-number',
  'delivery-interval',
  'apex-customer-job',
  'action',
];

export const AVAILABLE_COLUMNS = (translateService: TranslateService) => [
  { key: 'select' },
  { key: 'expand' },
  {
    key: 'order-number',
    title: translateService.instant('Order #'),
    sortable: true,
    sortBy: 'job__order_number_sort',
  },
  {
    key: 'name',
    title: translateService.instant('Job'),
    sortable: true,
    sortBy: 'job__name',
  },
  {
    key: 'customer',
    title: translateService.instant('Customer'),
    sortable: true,
    sortBy: 'customer_organization__name',
  },
  {
    key: 'apex-customer-job',
    title: translateService.instant('Customer Job'),
    sortable: false,
  },
  { key: 'num-trucks', title: translateService.instant('Requested Trucks') },
  {
    key: 'dispatched-drivers-count',
    title: translateService.instant('Dispatched Trucks'),
  },
  {
    key: 'daily-delivery-target',
    title: translateService.instant('Daily Delivery Target'),
  },
  {
    key: 'ticketed-weight',
    title: translateService.instant('Ticketed Weight'),
  },
  {
    key: 'weight-in-transit',
    title: translateService.instant('Weight in Transit'),
  },
  { key: 'total-tons', title: translateService.instant('Delivered Tons') },
  { key: 'total-ordered', title: translateService.instant('Total Ordered') },
  { key: 'total-loads', title: translateService.instant('Delivered Loads') },
  { key: 'shares-count', title: translateService.instant('Shares Count') },
  {
    key: 'loading-location-name',
    title: translateService.instant('Loading Location'),
    sortable: true,
    sortBy: 'job__start_location__name',
  },
  {
    key: 'unloading-location-name',
    title: translateService.instant('Unloading Location'),
    sortable: true,
    sortBy: 'job__end_location__name',
  },
  { key: 'material', title: translateService.instant('Material') },
  { key: 'quantities-detail', title: translateService.instant('Quantities Detail') },
  { key: 'phase-code', title: translateService.instant('Phase Code') },
  { key: 'start-time', title: translateService.instant('Start Time') },
  { key: 'avg-trip-time', title: translateService.instant('Avg Trip Time') },
  { key: 'total-hours', title: translateService.instant('Total Hours') },
  { key: 'total-amount', title: translateService.instant('Invoice Amount') },
  { key: 'invoice-rate', title: translateService.instant('Invoice Rate') },
  {
    key: 'effective-ton-rate',
    title: translateService.instant('Effective Ton Rate'),
  },
  { key: 'truck-type', title: translateService.instant('Truck Type') },
  { key: 'haul-amount', title: translateService.instant('Haul Total') },
  { key: 'haul-rate', title: translateService.instant('Haul Rate') },
  { key: 'haul-type', title: translateService.instant('Haul Unit Type') },
  {
    key: 'confirmed-trucks',
    title: translateService.instant('Confirmed Trucks'),
  },
  {
    key: 'assignment-status',
    title: translateService.instant('Assignment Status'),
  },
  { key: 'status', title: translateService.instant('Status') },
  { key: 'job-number', title: translateService.instant('Job #') },
  {
    key: 'delivery-interval',
    title: translateService.instant('Delivery Interval'),
  },
  {
    key: 'connex-actual-loads',
    title: translateService.instant('Connex Actual Loads'),
  },
  {
    key: 'connex-actual-quantity',
    title: translateService.instant('Connex Actual Quantity'),
  },
  {
    key: 'connex-carrier-restriction-id',
    title: translateService.instant('Carrier ID'),
    sortable: true,
    sortBy: 'connex_carrier_restriction_id',
  },
  {
    key: 'dsorder-id',
    title: translateService.instant('DS Order ID'),
    sortable: true,
    sortBy: 'dsorder_id',
  },
  {
    key: 'slorder-id',
    title: translateService.instant('SL Order ID'),
    sortable: true,
    sortBy: 'slorder_id',
  },
  {
    key: 'pin',
    title: translateService.instant('PIN'),
    sortable: true,
    sortBy: 'pin',
  },
  { key: 'action', title: translateService.instant('Action'), sortable: false },
];

export const AVAILABLE_FILTERS = (translateService: TranslateService) => [
  new FilterOption({
    key: 'job__start_location',
    slug: '-start-locations',
    title: translateService.instant('Loading Location'),
    filterType: 'select',
    service: StatsJobEventFilterService,
    searchable: true,
    removeFromFiltersQuery: true,
  }),
  new FilterOption({
    key: 'job__end_location',
    slug: '-end-locations',
    title: translateService.instant('Unloading Location'),
    filterType: 'select',
    service: StatsJobEventFilterService,
    searchable: true,
    removeFromFiltersQuery: true,
  }),
  new FilterOption({
    key: 'customer_organization',
    slug: '-organizations',
    title: translateService.instant('Customer'),
    filterType: 'select',
    service: StatsJobEventFilterService,
    searchable: true,
    removeFromFiltersQuery: true,
  }),
  new FilterOption({
    key: 'truck_types',
    slug: '-truck-types',
    title: translateService.instant('Truck Type'),
    filterType: 'select',
    service: StatsJobEventFilterService,
  }),
  new FilterOption({
    key: 'job__material__icontains',
    withNullOption: false,
    title: translateService.instant('Material'),
    filterType: 'text',
    service: null,
  }),
  new FilterOption({
    key: 'apex_customer_job__icontains',
    withNullOption: false,
    title: translateService.instant('Customer Job'),
    filterType: 'text',
    service: null,
  }),
  // new FilterOption({
  //   key: 'job__material', title: this.translateService.instant('Material'),
  //   filterType: 'select', service: ''
  // }),
];

export const MENU_OPTIONS = (translateService: TranslateService) => [
  { name: translateService.instant('Edit Job'), action: 'edit', link: true },
  { name: translateService.instant('Clone Job'), action: 'clone', link: true },
  {
    name: translateService.instant('Edit Job Days'),
    action: 'edit-days',
    link: false,
  },
  {
    name: translateService.instant('Edit Day Details'),
    action: 'edit-day-details',
    link: false,
  },
  {
    name: translateService.instant('Cancel Job Day'),
    action: 'cancel-day',
    link: false,
    external: false,
  },
  {
    name: translateService.instant('Message Drivers'),
    action: 'message-drivers',
    link: false,
    external: false,
  },
  {
    name: translateService.instant('Add Collaborators'),
    action: 'add-collaborators',
    link: true,
  },
  {
    name: translateService.instant('Job Dispatch'),
    action: 'dispatch-drivers',
    link: true,
  },
  {
    name: translateService.instant('Invite Customer'),
    action: 'invite',
    link: false,
    external: false,
  },
  {
    name: translateService.instant('View Tickets'),
    action: 'view-tickets',
    link: true,
  },
];
