import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { clone, find as _find, reject } from 'lodash';

import { InvoiceFilterService } from  './invoice.filter.service';
import { ConnectionService } from  '../connections/connection.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'invoice-filter-dialog',
  templateUrl: './invoice-filters-dialog.component.html',
  styleUrls: ['./invoice-filters-dialog.component.scss'],
  providers: [
    InvoiceFilterService, ConnectionService
  ]
})
export class InvoiceFiltersDialogComponent implements OnInit {
  loading = false;
  errors = [];
  jobs = [];
  jobsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  customers = [];
  customersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  @ViewChild('jobsDropdown', { static: true }) jobsDropdown;
  @ViewChild('customersDropdown', { static: true }) customersDropdown;
  datePipe = new DatePipe('en-US');
  callback;
  model = {
    job: null,
    customer: null,
    draft: false,
    sent: false,
    startDate: null,
    endDate: null,
    jobDay: null
  };
  jobsReq;
  customersReq;

  constructor(
    private invoiceFilterService: InvoiceFilterService,
    private connectionService: ConnectionService,
    public dialogRef: MatDialogRef<InvoiceFiltersDialogComponent>
  ) { }

  ngOnInit() {
    this.getJobs();
    this.getCustomers();
  }

  onSelect(filterType, e) {
    this.model[filterType] = e;
  }

  submit() {
    if (this.model.startDate) {
      let startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name });
    }

    if (this.model.endDate) {
      let endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name });
    }

    if (this.model.startDate && this.model.endDate) {
      this.model.jobDay = { name: [this.model.startDate['name'], this.model.endDate['name']].join(' - ') };
    } else if (this.model.startDate) {
      this.model.jobDay = { name: [this.model.startDate['name'], 'or after'].join(' ') };
    } else if (this.model.endDate) {
      this.model.jobDay = { name: ['on or before', this.model.endDate['name']].join(' ') };
    }
    this.callback(this.model);
    this.dialogRef.close();
  }

  getJobs(search = null) {
    this.jobs = [];
    this.jobsConfig.loadingOptions = true;
    this.jobsConfig = clone(this.jobsConfig);
    if (this.jobsReq) { this.jobsReq.unsubscribe(); }

    this.jobsReq = this.invoiceFilterService.getFilters('jobs', search).subscribe(
      jobs => {
        this.jobs = jobs;
        if (this.model.job) {
          let selectedOption = _find(this.jobs, {id: this.model.job.id});
          this.jobs = reject(this.jobs, selectedOption);
          this.jobs.unshift(this.model.job);
          this.jobsDropdown.selectedOption = selectedOption;
        }
        this.jobsDropdown.config.loadingOptions = false;
        this.jobsConfig.loadingOptions = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
        this.jobsDropdown.config.loadingOptions = false;
        this.jobsConfig.loadingOptions = false;
        this.jobsConfig = clone(this.jobsConfig);
      }
    );
  }

  getCustomers(search = null) {
    if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
      this.customersReq.unsubscribe();
    }

    this.customersReq = this.invoiceFilterService.getFilters('customers', search).subscribe(
      customers => {
        this.customers = customers;
        if (this.model.customer) {
          let selectedOption = _find(this.customers, {id: this.model.customer.id});
          this.customers = reject(this.customers, selectedOption);
          this.customers.unshift(this.model.customer);
          this.customersDropdown.selectedOption = selectedOption;
        }
        this.customersDropdown.config.loadingOptions = false;
        this.customersConfig.loadingOptions = false;
      },
      err => this.errors = err,
      () => {
        this.customersConfig.loadingOptions = false;
      }
    );
  }

  dropdownNextPage(e, type) {
    let config;
    let filterType;
    let options;

    switch (type) {
      case 'customer':
        filterType = 'customers';
        config = this.customersConfig;
        options = this.customers;
        break;
      case 'job':
        filterType = 'jobs';
        config = this.jobsConfig;
        options = this.jobs;
        break;
    }

    if (!config.loadingOptions) {
      let o = this.invoiceFilterService.getNext(filterType);
      if (o) {
        config.loadingOptions = true;
        o.subscribe(
          results => {
            switch (type) {
              case 'customer':
                this.customers = this.customers.concat(results);
                break;
              case 'job':
                this.jobs = this.jobs.concat(results);
                break;
            }
            config.loadingOptions = false;
          }, (err) => {
            this.errors = parseErrors(err);
            config.loadingOptions = false;
          }
        );
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'customer':
        this.getCustomers(term);
        break;
      case 'job':
        this.getJobs(term);
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  onDateChanged(values: Date[], type = '') {
    if (values && values.length) {
      this.model[type] = values[0];
    }
  }
}
