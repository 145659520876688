import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../app/app.constants';
import { environment } from '../../environments/environment';
import { Attachment } from './attachment.interface';

const decamelizeKeysDeep = require('decamelize-keys-deep');
const camelizeKeysDeep = require('camelcase-keys-deep');

@Injectable()
export class AttachmentService {
  baseUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return {
      Authorization: `Token ${currentUser.token}`,
    };
  }

  updateTicketImage(data: Attachment, file: File): Observable<any> {
    const postData = decamelizeKeysDeep(data);
    const formData = new FormData();
    formData.append('json', JSON.stringify(postData));
    formData.append('checkin_attachment_file', file, file.name);

    return this.http
      .put<any>(`${this.baseUrl}attachments/`, formData, {
        headers: this.getHeaders(),
      })
      .pipe(map((res) => camelizeKeysDeep(res)));
  }

  createAttachment(attachment: any, checkinId: string) {
    const attachmentData = {
      file_extension: attachment.type.split('/')[1], // changing image/png to png
      file_format: 'image',
      file_type: TRIPTICKETIMAGETYPE,
      name: attachment.name,
      file: attachment.file,
      checkin: checkinId,
    };
    return this.http.post<any>(`${this.baseUrl}attachments/`, attachmentData, {
      headers: this.getHeaders(),
    });
  }

  createMultipleAttachments(attachments: any[]) {
    return this.http.post<any>(`${this.baseUrl}attachments/`, attachments, {
      headers: this.getHeaders(),
    });
  }

  updateAttachment(attachment: any) {
    return this.http.patch<any>(
      `${this.baseUrl}attachments/${attachment.id}/`,
      attachment,
      {
        headers: this.getHeaders(),
      }
    );
  }

  deleteAttachment(attachmentId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}attachments/${attachmentId}/`, {
      headers: this.getHeaders(),
    });
  }

  parseAttachment(attachment: any, file_type: string, checkin?: string) {
    const file_extension =
      attachment && attachment.type ? attachment.type.split('/')[1] : 'jpg'; // changing image/png to png; if no type, default to jpg
    return {
      ...(attachment.id && { id: attachment.id }),
      file_extension,
      file_format: 'image',
      file_type,
      name: `${UUID.UUID()}.${file_extension}`,
      file: attachment.file,
      ...(checkin && { checkin }),
    };
  }

  // should be refactored soon
  combineTicketImagesAndAttachments(
    imagesToDelete: any[],
    loadingTicketImages: any[],
    loadingSignatures: any[],
    unloadingTicketImages: any[],
    unloadingSignatures: any[],
    loadingCheckinId: string,
    unloadingCheckinId: string
  ) {
    let deleteImagesApiCalls = [];
    let imagesToUpdateApiCalls = [];
    let imagesToCreate = [];

    imagesToDelete.forEach((img) => {
      if (img.id) {
        deleteImagesApiCalls.push(this.deleteAttachment(img.id));
      }
    });
    loadingTicketImages.forEach((img) => {
      if (img.isDeleted) { return; }
      if (img.legacy) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPTICKETIMAGETYPE, loadingCheckinId)
        );
        return;
      }
      if (img.isNew) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPTICKETIMAGETYPE, loadingCheckinId)
        );
        return;
      }
      if (img.isEdited && img.isNew !== true) {
        imagesToUpdateApiCalls.push(
          this.updateAttachment(
            this.parseAttachment(img, TRIPTICKETIMAGETYPE, loadingCheckinId)
          )
        );
        return;
      }
    });
    loadingSignatures.forEach((img) => {
      if (img.isDeleted) { return; }
      if (img.legacy) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPSIGNATUREIMAGETYPE, loadingCheckinId)
        );
        return;
      }
      if (img.isNew) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPSIGNATUREIMAGETYPE, loadingCheckinId)
        );
        return;
      }
      if (img.isEdited && img.isNew !== true) {
        imagesToUpdateApiCalls.push(
          this.updateAttachment(
            this.parseAttachment(img, TRIPSIGNATUREIMAGETYPE, loadingCheckinId)
          )
        );
        return;
      }
    });

    unloadingTicketImages.forEach((img) => {
      if (img.isDeleted) { return; }
      if (img.legacy) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPTICKETIMAGETYPE, unloadingCheckinId)
        );
        return;
      }
      if (img.isNew) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPTICKETIMAGETYPE, unloadingCheckinId)
        );
        return;
      }
      if (img.isEdited && img.isNew !== true) {
        imagesToUpdateApiCalls.push(
          this.updateAttachment(
            this.parseAttachment(img, TRIPTICKETIMAGETYPE, unloadingCheckinId)
          )
        );
        return;
      }
    });
    unloadingSignatures.forEach((img) => {
      if (img.isDeleted) { return; }
      if (img.legacy) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPSIGNATUREIMAGETYPE, unloadingCheckinId)
        );
        return;
      }
      if (img.isNew) {
        imagesToCreate.push(
          this.parseAttachment(img, TRIPSIGNATUREIMAGETYPE, unloadingCheckinId)
        );
        return;
      }
      if (img.isEdited && img.isNew !== true) {
        imagesToUpdateApiCalls.push(
          this.updateAttachment(
            this.parseAttachment(
              img,
              TRIPSIGNATUREIMAGETYPE,
              unloadingCheckinId
            )
          )
        );
        return;
      }
    });

    return [
      ...deleteImagesApiCalls,
      ...imagesToUpdateApiCalls,
      ...(imagesToCreate.length
        ? [this.createMultipleAttachments(imagesToCreate)]
        : []),
    ];
  }

  saveOrganizationAttachment(id: string, attachment: any, file_type: string) {
    const payload = {
      // file_extension: attachment.type.split('/')[1],
      file_extension: attachment.name.split('.').pop(),
      file_format: this.getFileFormatFromFilename(attachment.name),
      file_type,
      name: attachment.name,
      file: attachment.file,
      organization: id,
    };

    return attachment.id
      ? this.http.put(`${this.baseUrl}attachments/${attachment.id}/`, payload, {
          headers: this.getHeaders(),
        })
      : this.http.post(`${this.baseUrl}attachments/`, payload, {
          headers: this.getHeaders(),
        });
  }

  getFileFormatFromFilename(filename: string): string {
    const fileExtension = filename.split('.').pop();
    if (fileExtension === 'pdf') {
      return 'pdf';
    }
    if (['png', 'jpg', 'jpeg', 'img'].includes(fileExtension)) {
      return 'image';
    }

    if (['doc', '.docx'].includes(fileExtension)) {
      return 'document';
    }
    return 'image';
  }

  getFirstAttachmentIdFromAttachments(
    attachments: any[],
    fileType: string
  ): string {
    const attachment = attachments.find((a) => a.fileType === fileType);
    return attachment ? attachment.id : null;
  }
}

// paylod example
// {
//   "file":"base64",
//   "file_type":"", #usually a ticket_image or a signature_image
//   "file_format":"", #pdf/imasge
//   "file_extension":"", #pdf/png/jpg/jpeg/img/gif
//   "extra_data":"", {} (youu can omit this one if you do not want to add it in, that's just for some random stuff)
//   "checkin": uuid, #not needed in patch
//   "ticket": uuid, #not needed in patch
//   }
