import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

// libraries
import { remove, find as _find } from 'lodash';

// services
import { TripService } from './../trip.service';
import { JobService } from '../../jobs/job.service';
import { DriverService } from '../../drivers/driver.service';
import { TruckService } from '../../trucks/truck.service';

// components
import { ViewSignaturesDialogComponent } from '../../checkins/view-signatures-dialog.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/index';

// models
import { CondensedTrip } from './../condensed-trip';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';
import { Driver } from '../../drivers/driver';

// constants
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../app.constants';

@Component({
  selector: 'completed-trips',
  templateUrl: './completed-trips.component.html',
  styleUrls: ['./completed-trips.component.scss'],
  providers: [TripService, JobService, DriverService, TruckService],
})
export class CompletedTripsComponent implements OnInit {
  @Input() jobEvent: any;
  @Input() scroll;
  trips: CondensedTrip[] = [];
  expandedTrips = [];
  loading = true;
  errors = [];
  signatureImageType = TRIPSIGNATUREIMAGETYPE;
  ticketImageType = TRIPTICKETIMAGETYPE;
  menuOptions = [
    { name: 'Details', action: '', link: true },
    { name: 'Edit', action: 'edit', link: true },
    { name: 'Unvoid', action: 'unvoid', link: false },
    { name: 'Void', action: 'void', link: false },
  ];
  tripReq;
  confirmDialog: MatDialogRef<any>;
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor(
    private router: Router,
    private tripService: TripService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getTrips();
  }

  onScroll(e) {
    if (
      !this.loading &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3
    ) {
      let o = this.tripService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(
          (trips) => {
            this.trips = this.trips.concat(trips);
            this.loading = false;
          },
          (err) => (this.errors = err),
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getTrips() {
    this.loading = true;

    if (this.tripReq) {
      this.tripReq.unsubscribe();
    }

    this.tripReq = this.tripService
      .getCondensedTrips({
        jobevent: this.jobEvent && this.jobEvent.id,
        completed: 'True',
      })
      .subscribe(
        (trips) => {
          trips = trips.filter((trip) => trip.completed === true);
          this.trips = trips;
          this.loading = false;
        },
        (err) => (this.errors = err),
        () => {
          this.loading = false;
        }
      );
  }

  openSignatures(trip) {
    this.tripService.getTrip(trip.id).subscribe(
      (_trip) => {
        this.dialog.open(ViewSignaturesDialogComponent, {
          width: '722px',
          data: {
            trip: _trip,
            callback: () => this.getTrips(),
          },
        });
      },
      (err) => console.error(err)
    );
  }

  openAddTrip() {
    this.router.navigate(['/trips/new'], {
      queryParams: { returnTo: '/trips' },
    });
  }

  menuAction(name, trip) {
    switch (name) {
      case 'void':
        this.voidTrip(trip);
        break;
      case 'unvoid':
        this.unvoidTrip(trip);
        break;
    }
  }

  voidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Trip?',
      body: `This trip will be marked as 'Void' and will not be visible for the Job.`,
      close: 'Cancel',
      accept: 'Void',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: true }).subscribe(
          (result) => {
            trip.void = true;
            trip.loading = false;
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  unvoidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unvoid Trip?',
      body: 'This trip will be unmarked as \'Void\' and will be visible for the Job.',
      close: 'Cancel',
      accept: 'Unvoid',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: false }).subscribe(
          () => {
            trip.void = false;
            trip.loading = false;
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  expandTrip(trip) {
    if (_find(this.expandedTrips, { id: trip.id })) {
      remove(this.expandedTrips, { id: trip.id });
    } else {
      this.expandedTrips.push(trip);
    }
  }

  expandedTrip(trip): boolean {
    if (_find(this.expandedTrips, { id: trip.id })) {
      return true;
    }
    return false;
  }

  openContextMenu(event: any, driver: Driver) {
    this.contextMenuEventSubject.next({
      event,
      driverId: driver.id,
    });
  }
}
