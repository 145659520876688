import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownComponent } from '../../../shared';

export type PayDurationOption = {
  value: 'scheduled-start' | 'shift-start' | 'geofence-start' | 'first-load' | 'shift-end' | 'geofence-end' | 'last-load',
  name: string
};

@Component({
  selector: 'ruckit-bulk-edit-dialog',
  templateUrl: './bulk-edit-dialog.component.html',
  styleUrls: ['./bulk-edit-dialog.component.scss']
})

export class BulkEditDialogComponent {
  loading = false;
  errors = [];
  callback: Function;
  changes = {
    duration: 0,
    startTime: '',
    endTime: '',
    payStart: '',
    payEnd: ''
  };

  payDropdownConfig = { nameProperty: 'name' };
  payStartOptions: PayDurationOption[] = [
    { value: 'scheduled-start', name: 'Scheduled Start' },
    { value: 'shift-start', name: 'Shift Start' },
    { value: 'geofence-start', name: 'Geofence Start' },
    { value: 'first-load', name: 'First Load' }
  ];
  payEndOptions: PayDurationOption[] = [
    { value: 'shift-end', name: 'Shift End' },
    { value: 'geofence-end', name: 'Geofence End' },
    { value: 'last-load', name: 'Last Load' }
  ];

  @ViewChild('payStartSelect') payStartSelect: DropdownComponent;
  @ViewChild('payEndSelect') payEndSelect: DropdownComponent;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BulkEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  submit() {
    this.callback(this.changes);
    this.dialogRef.close();
  }
}
