import { debounceTime } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { InvoiceService } from './invoice.service';
import { ConnectionService } from '../connections/connection.service';
import { JobService } from '../jobs/job.service';
import { parseErrors } from '../shared/api.service';
import { InvoiceTripsDialogComponent } from './invoice-trips-dialog.component';
import { InvoicePunchCardsDialogComponent } from './invoice-punch-cards-dialog.component';

@Component({
  selector: 'ruckit-new-invoice-dialog',
  templateUrl: './new-invoice-dialog.component.html',
  styleUrls: ['./new-invoice-dialog.component.scss'],
  providers: [InvoiceService, JobService, ConnectionService]
})
export class NewInvoiceDialogComponent implements OnInit {
  loading = false;
  invoiceTypes = [
    'trips',
    'punchCards'
  ];
  model: any = {
    invoiceType: 'trips'
  };
  errors = [];
  callback;

  jobSearch = '';
  searchTimeout;
  jobOptions = [];
  jobLoading = false;
  jobsReq;
  jobDropdownConfig: any = {
    nameProperty: 'name'
  };
  customerSearch = '';
  customerOptions = [];
  customerLoading = false;
  customersReq;
  customerDropdownConfig: any = {
    nameProperty: 'name'
  };
  invoiceCounts = {
    'trips': 0,
    'punchCards': 0
  };
  invoiceStatsReq;

  constructor(
    private invoiceService: InvoiceService,
    private connectionService: ConnectionService,
    private jobService: JobService,
    private router: Router,
    public dialogRef: MatDialogRef<NewInvoiceDialogComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.jobDropdownConfig = {searchable: true, loadingOptions: false};
    this.customerDropdownConfig = { searchable: true, loadingOptions: false };
    this.getCustomers();
    this.getJobs();
  }

  submit() {
    let dialog;
    this.dialogRef.close();

    if (this.model.invoiceType === 'trips') {
      dialog = this.dialog.open(InvoiceTripsDialogComponent, {
        width: '100%',
        height: '100%',
        disableClose: true
      });
    } else {
      dialog = this.dialog.open(InvoicePunchCardsDialogComponent, {
        width: '100%',
        height: '100%',
        disableClose: true
      });
    }
    dialog.componentInstance.jobId = this.model && this.model.job && this.model.job.id;
    dialog.componentInstance.customerId = this.model && this.model.customer && this.model.customer.id;
    dialog.componentInstance.routeToInvoice = true;
  }

  selectJob(job) {
    this.model.job = job;
    this.invoiceCounts['punchCards'] = job.invoiceablePunchcardCount;
    this.invoiceCounts['trips'] = job.invoiceableTripCount;
    this.model.invoiceType = job.invoiceType === 'hour' ? 'punchCards' : 'trips';
  }

  getJobs(search = null, setLoading = false) {
    if (setLoading) { this.jobLoading = true; }
    if (this.jobsReq) {
      this.jobsReq.unsubscribe();
    }

    let debounceAmt = search ? 500 : 0;

    this.jobsReq = this.jobService.list({
      ordering: 'name',
      has_billable_items: 'True',
      search: search,
      customer_organization: this.model && this.model.customer && this.model.customer.id
    }).pipe(debounceTime(debounceAmt)).subscribe(jobs => {
      this.jobOptions = [];
      this.jobOptions = jobs.map(job => {
        return {
          name: job.name, id: job.id, invoiceType: job.invoiceType,
          invoiceablePunchcardCount: job.invoiceablePunchcardCount,
          invoiceableTripCount: job.invoiceableTripCount
        };
      });
      this.jobLoading = false;
      this.jobDropdownConfig.loadingOptions = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.jobLoading = false;
      this.jobDropdownConfig.loadingOptions = false;
    });
  }

  jobDropdownSearch(term) {
    this.jobDropdownConfig.loadingOptions = true;
    this.getJobs(term);
    this.jobDropdownConfig.loadingOptions = false;
  }

  jobDropdownNextPage() {
    if (!this.jobDropdownConfig.loadingOptions) {
      let o = this.jobService.listNext();
      if (o) {
        this.jobDropdownConfig.loadingOptions = true;
        o.subscribe(jobs => {
          this.jobOptions = this.jobOptions.concat(jobs.map(job => {
            return {
              name: job.name, id: job.id, invoiceType: job.invoiceType,
              invoiceablePunchcardCount: job.invoiceablePunchcardCount,
              invoiceableTripCount: job.invoiceableTripCount
            };
          }));
          this.jobDropdownConfig.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          this.jobDropdownConfig.loadingOptions = false;
        });
      }
    }
  }

  selectCustomer(customer) {
    this.model.customer = customer;
    this.model.job = null;
    this.invoiceStatsReq = this.connectionService.getInvoiceStats(customer.customerId).subscribe(stats => {
      this.invoiceCounts['punchCards'] = stats.invoiceablePunchcardCount;
      this.invoiceCounts['trips'] = stats.invoiceableTripCount;
    }, (err) => {
      this.errors = parseErrors(err);
    });
    this.getJobs(null, true);
  }

  getCustomers(query = {}, setLoading = true) {
    if (setLoading) { this.customerLoading = true; }
    if (this.customersReq) {
      this.customersReq.unsubscribe();
    }

    this.customersReq = this.connectionService.list({
      ordering: 'name',
      has_billable_items: 'True',
      search: this.customerSearch
    }).subscribe(customers => {
      if (this.customerSearch) { this.customerOptions = []; }
      this.customerOptions = this.customerOptions.concat(customers.map(customer => {
        return {
          name: customer.organization.name, id: customer.organization.id, customerId: customer.id,
          invoiceablePunchcardCount: customer.invoiceablePunchcardCount,
          invoiceableTripCount: customer.invoiceableTripCount
        };
      }));
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    });
  }

  customerDropdownSearch(term) {
    this.customerDropdownConfig.loadingOptions = true;
    this.customerSearch = term;
    this.getCustomers({}, false);
    this.customerDropdownConfig.loadingOptions = false;
  }

  customerDropdownNextPage() {
    if (!this.customerDropdownConfig.loadingOptions) {
      let o = this.connectionService.listNext();
      if (o) {
        this.customerDropdownConfig.loadingOptions = true;
        o.subscribe(customers => {
          this.customerOptions = this.customerOptions.concat(customers.map(customer => {
            return {
              name: customer.organization.name, id: customer.organization.id, customerId: customer.id,
              invoiceablePunchcardCount: customer.invoiceablePunchcardCount,
              invoiceableTripCount: customer.invoiceableTripCount
            };
          }));
          this.customerDropdownConfig.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          this.customerDropdownConfig.loadingOptions = false;
        });
      }
    }
  }

  selectInvoiceType(item) {
    this.model.invoiceType = item;
  }
}
