import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownComponent } from '../../../shared';

export type PayBasisOption = {
  value: 'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip',
  name: string
};

@Component({
  selector: 'ruckit-add-employee-dialog',
  templateUrl: './generate-report-dialog.component.html',
  styleUrls: ['./generate-report-dialog.component.scss']
})

export class GenerateReportDialogComponent implements OnInit {
  loading = false;
  errors = [];
  callback: Function;
  durationDropdownConfig = { nameProperty: 'name' };
  durationOptions: PayBasisOption[] = [
    {
      value: 'all-driver-items',
      name: 'All Driver Reported Items'
    },
    {
      value: 'each-driver-item',
      name: 'Each Driver Reported Item'
    },
    {
      value: 'all-geo-trips',
      name: 'All GPS Reported Items'
    },
    {
      value: 'each-geo-trip',
      name: 'Each GPS Reported Item'
    }
  ];
  selectedOption: PayBasisOption;

  @ViewChild('durationSelect') durationSelect: DropdownComponent;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.selectedOption = this.durationOptions.find(option => (data.selectedOption === option.value)); }

  ngOnInit() {
    if (this.selectedOption && this.durationSelect) { this.durationSelect.selectedOption = this.selectedOption; }
  }

  submit() {
    this.callback(this.selectedOption);
    this.dialogRef.close();
  }
}
