'use strict';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventAlert } from './event-alert';
import { EventAlertService } from './event-alert.service';

@Component({
  selector: 'event-alert',
  templateUrl: './event-alert.component.html',
  styleUrls: ['./event-alert.component.scss']
})
export class EventAlertComponent implements OnInit, OnDestroy {
  eventAlert: EventAlert;
  showAlert = false;
  eventAlertSub: Subscription;

  constructor(
    public eventAlertService: EventAlertService
  ) {}

  ngOnInit(): void {
    this.eventAlertSub = this.eventAlertService.getAlert().subscribe(alert => {
      this.eventAlert = alert;
      this.showAlert = true;
    });
  }

  ngOnDestroy(): void {
    if (this.eventAlertSub && typeof this.eventAlertSub.unsubscribe === 'function') {
      this.eventAlertSub.unsubscribe();
    }
  }

  onClick(): void {
    this.eventAlertService.click();
    this.showAlert = false;
  }
}
