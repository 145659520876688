import { clone } from 'lodash';

import { TrackableType } from '../trackable-types/trackable-type';
import { Resource } from '../shared/resource';
import { Truck } from '../trucks/truck';
import { Organization } from '../organizations/organization';

export class Trackable extends Resource {
  id: string;
  truck: Truck;
  trackableType: TrackableType;
  organization: Organization;
  deviceId: string;
  active: boolean;
  deviceState: string;
  positionSource: string;
  hdop: number;
  rssi: number;
  voltage: number;
  heading: number;
  speed: number;
  accuracy: number;
  temperature: number;
  location: any;
  lastStatusEvent: string;
  createdAt: string;
  lastModified: string;
  removed: boolean;

  url(action): string {
    return '/trackables/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
