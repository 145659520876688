import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType } from 'chart.js';
// import { Label, SingleDataSet } from 'ng2-charts';

import { FleetHealthStats } from './../fleet-health-stats';
import { FleetHealthStatsService } from './../fleet-health-stats.service';

@Component({
  selector: 'fleet-health-stats',
  templateUrl: './fleet-health-stats.component.html',
  styleUrls: ['./fleet-health-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetHealthStatsComponent implements OnChanges {
  @Input() appliedFilters;
  @Input() searchQuery: string;
  @Input() query;
  @Output() filterChanges: EventEmitter<any> = new EventEmitter();

  fleetHealthStats$: Observable<FleetHealthStats>;
  versionsCount = 0;
  moreThanTwoVersions = false;
  showOtherVersions = false;

  public pieChartType: ChartType = 'pie';
  public pieChartOptions: ChartOptions = {
    responsive: false,
    legend: {
      position: 'right',
      labels: {
        fontColor: ['#d6273c', '#1986d8'],
      },
    },
  };

  public driversPieChartLabels: any[];
  public driversPieChartData: any;

  public versionsPieChartLabels: any[];
  public versionsPieChartData: any;

  public driversChartColors: any[] = [
    {
      backgroundColor: ['#c0dcf8', '#f8d9dd'],
    },
  ];
  public driversTextColors = ['#d6273c', '#4990e2'];

  public versionsChartColors: any[] = [
    {
      backgroundColor: ['#d4f2e0', '#f8d9dd', '#c0dcf8', '#7A4AF9', '#FCE53F'],
    },
  ];
  public versionsTextColors = [
    '#33aa64',
    '#d6273c',
    '#4990e2',
    '#6B36F9',
    '#FCE229',
  ];
  // text color - red (#d6273c), green - (#33aa64), blue (#4990e2), purple (#7A4AF9), yellow (#FCE53F)

  constructor(
    private fleetHealthService: FleetHealthStatsService,
    private translate: TranslateService
  ) {}

  ngOnChanges() {
    const filters = this.appliedFiltersToQuery(this.appliedFilters);
    const query = {
      ...this.query,
      filters: filters && filters.length ? filters : null,
      search: this.searchQuery ? this.searchQuery : null,
    };
    this.getFleetHealthStats(query);
  }

  getFleetHealthStats(query = null) {
    this.fleetHealthStats$ = this.fleetHealthService
      .get(null, query, false)
      .pipe(
        map((stats) => {
          this.driversPreparePieCharts(stats);
          return {
            ...stats,
            applicationVersions: this.groupApplicationVersions(
              stats.applicationVersions
            ),
          };
        })
      );
  }

  groupApplicationVersions(applicationVersions) {
    const versions = applicationVersions.reduce(
      (arrayAcc, arrayCurr) =>
        Object.keys(arrayCurr).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: arrayCurr[curr] + (acc[curr] || 0),
          }),
          arrayAcc
        ),
      {}
    );
    const appVersions: any = Object.keys(versions)
      .map((k) => ({
        key: k,
        count: versions[k],
      }))
      .sort((a, b) => b.count - a.count);
    this.versionsPreparePieCharts(appVersions);
    return appVersions;
  }

  driversPreparePieCharts(stats: FleetHealthStats) {
    const translatedNoIssues = this.translate.instant('No issues');
    const translatedIssues = this.translate.instant('Has issues');
    this.driversPieChartLabels = [translatedNoIssues, translatedIssues];
    this.driversPieChartData = [
      stats.driverCount - stats.driversWithIssues,
      stats.driversWithIssues,
    ];
  }

  versionsPreparePieCharts(versions) {
    const labels = versions.map((v) => v.key);
    const data = versions.map((v) => v.count);
    const versionsCount = data.reduce((sum, x) => sum + x, 0);

    this.versionsPieChartLabels = labels;
    this.versionsPieChartData = data;
    this.versionsCount = versionsCount;
  }

  appliedFiltersToQuery(appliedFilters) {
    return appliedFilters.map((filter) => {
      if (filter.query) {
        const queryFilter = filter.query;
        const key = Object.keys(queryFilter)[0];
        return {
          key,
          values: queryFilter[key],
        };
      } else {
        return null;
      }
    });
  }

  filterByOnShift() {
    this.filterChanges.emit('on_shift');
  }

  filterByIssues() {
    this.filterChanges.emit('issues');
  }

  filterExists(key: string): boolean {
    const appliedFilter = this.appliedFilters.find((af) => af.key === key);
    return appliedFilter && appliedFilter.values === 'True' ? true : false;
  }
}
