<form #filtersForm="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filters</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group company-name">
          <label translate>Project</label>
          <ruckit-dropdown #projectsDropdown
            [config]="projectsConfig"
            [selectedOption]="model.project"
            (onSelect)="onSelect('project', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group street">
          <label translate>Customer</label>
          <ruckit-dropdown #customersDropdown
            [config]="customersConfig"
            [selectedOption]="model.customer"
            (onSelect)="onSelect('customer', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group tags">
          <label translate>Customer Job</label>
          <input type="text" name="apex-customer-job" [(ngModel)]="model.apexCustomerJob" />
        </div>
      </div>

      <div class="field-row">
        <div class="field-group street">
          <label translate>Start Location</label>
          <ruckit-dropdown #startLocationsDropdown
            [config]="locationsConfig"
            [selectedOption]="model.startLocation"
            (onSelect)="onSelect('startLocation', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group street">
          <label translate>End Location</label>
          <ruckit-dropdown #endLocationsDropdown
            [config]="locationsConfig"
            [selectedOption]="model.endLocation"
            (onSelect)="onSelect('endLocation', $event)">
          </ruckit-dropdown>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="submit(); false"
            translate>
      Filter Results
    </button>
  </div>
</form>
