import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { LocationService } from '../../locations/location.service';
import { AuthenticationService } from '../../shared';


@Component({
  selector: 'location-status',
  templateUrl: './location-status.component.html',
  styleUrls: ['./location-status.component.scss'],
  providers: [LocationService]
})
export class LocationStatusComponent implements OnInit, OnDestroy {
  locations: any = [];
  loading = true;
  errors = [];
  sortBy: string;
  sortAsc = true;
  sortParameter;
  averageTimeSpentReq: Subscription;

  constructor(
    private locationService: LocationService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (window.innerWidth > 900) { this.getAverageTimeSpent(); }
  }

  ngOnDestroy() {
    if (this.averageTimeSpentReq && typeof this.averageTimeSpentReq.unsubscribe === 'function') {
      this.averageTimeSpentReq.unsubscribe();
    }
  }

  onResize(event): void {
    if (this.locations.length === 0) { this.ngOnInit(); }
  }

  getAverageTimeSpent(query = {}): void {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.loading = true;
    this.locations = [];
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    if (this.averageTimeSpentReq && typeof this.averageTimeSpentReq.unsubscribe === 'function') {
      this.averageTimeSpentReq.unsubscribe();
    }

    this.averageTimeSpentReq = this.locationService.averageTimeSpent({
      ordering: order,
      ...query
    }).subscribe(locations => {
      this.locations = locations;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey: string): void {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getAverageTimeSpent({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }
}
