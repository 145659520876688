<div class="edit-drawer" #editTrackable [ngClass]="{
  'mobile': device && device.mobile,
  'tablet': device && device.tablet,
  'desktop': device && device.desktop || !device,
  'open': open
}">
  <div *ngIf="errors && errors.length" class="errors">
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <form #trackableForm="ngForm" *ngIf="modifiedTrackable && trackable && (trackable.id === modifiedTrackable.id)" (keydown.enter)="$event.preventDefault()">
    <div class="form-content">
      <div class="field-group full-width">
        <h2>Edit Trackable</h2>
      </div>
      <div class="field-group trackable-type full-width">
        <label class="required" translate>Trackable Type</label>
        <input type="hidden" name="trackableType" required [(ngModel)]="modifiedTrackable.trackableType" #trackableType="ngModel" />
        <ruckit-dropdown #trackableTypesDropdown
          [config]="trackableTypesConfig"
          [selectedOption]="modifiedTrackable.trackableType"
          (onSelect)="optionSelected($event, 'trackableType')">
        </ruckit-dropdown>
      </div>

      <div class="field-row">
        <div class="field-group device-id">
          <label class="required" translate>Device ID</label>
          <input type="text" name="device-id" placeholder="{{ 'Device ID' | translate }}" required [(ngModel)]="modifiedTrackable.deviceId" #deviceId="ngModel" />
          <span [ngClass]="{'ng-valid': deviceId.valid, 'ng-invalid': deviceId.invalid, 'ng-pristine': deviceId.pristine}"></span>
        </div>
      </div>

      <div class="field-group truck full-width">
        <label translate>Truck</label>
        <input type="hidden" name="truck" [(ngModel)]="modifiedTrackable.truck" />
        <ruckit-dropdown #trucksDropdown
          [config]="trucksConfig"
          [selectedOption]="modifiedTrackable.truck"
          (onSelect)="optionSelected($event, 'truck')">
        </ruckit-dropdown>
      </div>

      <div class="field-group active full-width">
        <label translate>Trackable State</label>
        <label class="checkbox" translate>
          <input name="active" [checked]="modifiedTrackable.active" [(ngModel)]="modifiedTrackable.active" type="checkbox">
          Active
        </label>
      </div>
    </div>

    <div class="sidenav-footer">
      <div class="actions">
        <button class="btn btn-inverted-delete" (click)="remove(); false" [ngClass]="{loading: loading && modifiedTrackable.active !== 'false'}">
          <i class="icomoon icon-delete"></i><ng-container translate>Delete</ng-container>
        </button>
        <button class="btn btn-primary" (click)="trackableForm.form.valid && submit(); false" [disabled]="!trackableForm.form.valid || trackableForm.form.pristine || loading" [ngClass]="{loading: loading}" translate>
          Save Changes
        </button>
      </div>
    </div>
  </form>
</div>
