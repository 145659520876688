import {
  Component, ElementRef, OnInit, Input, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { pick } from 'lodash';
import { Subscription } from 'rxjs';

import { SurchargeSerializer } from './surcharge.serializer';
import { SurchargeService } from './surcharge.service';
import { SurchargeTemplateService } from '../surcharge-templates/surcharge-template.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'surcharge-dialog',
  templateUrl: './surcharge-dialog.component.html',
  styleUrls: ['./surcharge-dialog.component.scss'],
  providers: [SurchargeService, SurchargeTemplateService]
})
export class SurchargeDialogComponent implements OnInit, OnDestroy {
  invoice;
  ticket;
  surchargeDate: Date;
  positiveAmount = true;
  @Input() model = {
    id: null,
    title: null,
    description: null,
    time: null,
    amount: null,
    positive: false,
    invoice: null
  };
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  callback;
  surchargeTemplate;
  surchargesReq: Subscription;
  surchargeDropdownOptions = [];
  surchargeDropdownConfig = {
    nameProperty: 'title',
    searchable: true,
    loadingOptions: false
  };

  constructor(
    public dialogRef: MatDialogRef<SurchargeDialogComponent>,
    private surchargeService: SurchargeService,
    private surchargeTemplateService: SurchargeTemplateService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.getSurchargeTemplates();
    this.model.invoice = this.invoice && this.invoice.id;
    if (this.model && this.model.amount >= 0) {
      this.positiveAmount = true;
    } else {
      this.positiveAmount = false;
    }
    if (this.model && this.model.time !== null) {
      let _time;
      if (typeof this.model.time === 'string') {
        _time = new Date(this.model.time);
      } else {
        _time = this.model.time;
      }
      this.surchargeDate = _time;
    }
  }

  ngOnDestroy() {
    if (this.surchargesReq) { this.surchargesReq.unsubscribe(); }
  }

  submitSurcharge() {
    this.loading = true;

    let date;
    if (this.surchargeDate) {
      date = new Date(this.surchargeDate);
    }
    this.model.time = date && date.toISOString();

    this.surchargeService.save(this.model).subscribe(res => {
      this.dialogRef.close();
      this.callback();
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  onDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.surchargeDate = values[0];
    }
  }

  setAmountPositive(positive = true): void {
    if (this.model.amount === null) {
      this.model.amount = '-0.00';
    } else {
      this.model.amount = positive
        ? Math.abs(this.model.amount)
        : -Math.abs(this.model.amount);
    }
    this.positiveAmount = positive;
  }

  amountChange() {
    if (!this.positiveAmount) {
      this.model.amount = -Math.abs(this.model.amount);
    }
  }

  getSurchargeTemplates(query = {}): void {
    this.surchargeDropdownOptions = [{id: null, title: 'Custom Surcharge', button: true}];
    if (this.surchargesReq) { this.surchargesReq.unsubscribe(); }
    let kind = this.invoice ? 'invoice' : 'scaleticket';
    this.surchargesReq = this.surchargeTemplateService.list({
      ordering: 'title',
      kind: kind,
      ...query
    }).subscribe(surchargeTemplates => {
      this.surchargeDropdownOptions = this.surchargeDropdownOptions.concat(surchargeTemplates);
    }, err => {
      this.errors = err;
    });
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'surcharge':
        this.surchargeTemplate = e;
        if (this.surchargeTemplate && this.surchargeTemplate.id) {
          let _surcharge = (new SurchargeSerializer).fromJson(pick(
            this.surchargeTemplate,
            ['title', 'description', 'amount', 'positive']
          ));
          this.model = Object.assign(this.model, _surcharge);
        }
        break;
    }
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'surcharge':
        this.getSurchargeTemplates({ search: term });
        break;
    }
  }

  dropdownNextPage(e, type: string): void {
    let config;
    let service;
    let options;

    switch (type) {
      case 'surcharge':
        config = this.surchargeDropdownConfig;
        service = this.surchargeService;
        options = this.surchargeDropdownOptions;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'surcharge':
              this.surchargeDropdownOptions = this.surchargeDropdownOptions.concat(
                results
              );
              break;
          }
          config.loadingOptions = false;
        }, err => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }
}
