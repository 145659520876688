import {
  Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef, SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';

// libraries
import { cloneDeep, difference, find as _find } from 'lodash';

// angualar material
import { MatDialog } from '@angular/material/dialog';

// services
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from './driver.service';
import { AuthenticationService } from '../shared/authentication.service';
import { TagService } from '../tags/tag.service';

// components
import { NewDriverDialogComponent } from './new-driver-dialog/new-driver-dialog.component';
import { InviteDriverDialogComponent } from './invite-driver-dialog/invite-driver-dialog.component';
import { EditDriverComponent } from './driver-edit/edit-driver.component';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { FiltersDialogComponent } from '../shared/filters-dialog/filters-dialog.component';
import { DropdownConfig } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { ItemGroup, ItemList } from '../shared/item-grid/item-grid.component';
import { TimelineLabel } from '../shared';

// models
import { Driver } from './driver';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { DriverContextEvent } from './driver-context-menu/interfaces/driver-context-event';

// animations
import { editDriverAnimation } from '../shared/animations/edit-driver.animation';

// constants
import { ViewDriverProfileAction } from './driver-context-menu/data/options';

@Component({
  selector: 'drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  animations: [editDriverAnimation]
})
export class DriversComponent implements OnInit {
  view: 'list' | 'grid' = 'list';
  archive: boolean;
  @Output() viewArchive: EventEmitter<boolean> = new EventEmitter<boolean>();

  driverList: Driver[];
  driverGroups: ItemList;
  loadingProgress = 0;
  displayKeys = ['name'];
  groupByOptions = ['Carrier', 'Market', 'Assigned', 'Duty Status'];
  activeGroupBy: 'Carrier' | 'Market' | 'Assigned' | 'Duty Status' = 'Carrier';
  gridLabels: TimelineLabel[] = [
    {
      name: 'Truck Assigned',
      color: '#015BC5'
    },
    {
      name: 'No Truck Assigned',
      color: '#ffffff'
    },
    {
      name: 'Off Duty',
      color: 'rgba(208, 2, 27, 0.15)'
    }
  ];

  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'profile__first_name' },
    { key: 'cdl', title: this.translationService.instant('CDL'), sortable: true, sortBy: 'cdl' },
    { key: 'phone-number', title: this.translationService.instant('Phone Number'), sortable: true, sortBy: 'profile__phone_number' },
    { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'carrier__name'  },
    { key: 'billing-id', title: this.translationService.instant('Billing ID'), sortable: false },
    { key: 'truck', title: this.translationService.instant('Assigned Truck'), sortable: false },
    { key: 'markets', title: this.translationService.instant('Markets'), sortable: false },
    { key: 'duty-status', title: this.translationService.instant('Duty Status'), sortable: false },
    { key: 'deleted', title: this.translationService.instant('Deleted'), sortable: false },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];

  @Input() displayedColumns = [
    'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets',
    'duty-status', 'action'
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() customClasses = 'drivers';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  // config for fancy table
  tableConfig = {
    hasHeader: true,
    service: DriverService,
    preferenceKey: 'DriversComponent-DriverService',
    query: {},
    collectionTitle: this.translationService.instant('Drivers'),
    noResultsText: this.translationService.instant('a driver'),
    newRecordModal: () => { this.openAddDriver(); },
    sortBy: 'profile__first_name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
      { name: this.translationService.instant('View Driver Profile'), action: 'details', link: false, external: false }
    ]
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('driverTable') driverTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle') columnToggle;
  filtersDialog: FiltersDialogComponent;

  drivers: any = [];
  driver: any = {};
  driversForEdit = [];
  stateOptionsForModal = [];
  @Input() customerOnly = null;
  @Input() carrierId = '';
  @Input() query = {};
  @Input() customHeight;
  sortBy;
  sortAsc = true;
  drawerOpen = false;
  enabledFeatures: string[] = [];
  inviteDriverEnabled = false;
  @ViewChild(EditDriverComponent) editDrawer;
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();

  // context menu
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  driverIdToOpenAssignments: string;
  viewDriverProfileAction = ViewDriverProfileAction;

  saveDriverCallback = () => {
    this.refreshTable();
  }

  inviteDriverCallback = () => {
    this.refreshTable();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private driverService: DriverService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('inviteDriver')) {
      this.inviteDriverEnabled = true;
    }
    this.tableConfig['query'] = {
      ...this.tableConfig['query'], ...this.query, ...query
    };
    this.driverService.listAllProgress.subscribe(progress => {
      this.loadingProgress = Math.ceil(progress * 100);
    });
  }

  ngAfterViewInit() {
    this.tableConfig['customHeight'] = this.customHeight;
    this.route.queryParams.forEach((params: Params) => {
      this.search = params['search'] || '';
      this.tableConfig['sortBy'] = 'profile__first_name';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.driverTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
      this.driverTable.query = this.query;
      this.tableConfig['query'] = {
        ...this.tableConfig['query'], ...this.query
      };

      this.refreshTable();
      if (this.query['status']) {
        this.archive = !!(this.query['status'] === 'deleted');
      } else {
        this.archive = false;
      }
    }
  }

  clickAction(event) {
    if (event) { this.selectDriver(event, event[1]); }
  }

  /**
   * @param  {} e
   * @param  {} driver
   * This function would open a edit window for updating or removing
   * the driver
   */
  selectDriver(e, driver) {
    let target = e.target || e.srcElement || e.currentTarget;
    if (target && target.className && target.className.includes('action-menu-icon') ||
      target && target.type === 'checkbox') {
      // Do nothing
    } else {
      setTimeout(() => {
        if (this.drivers) {
          this.driversForEdit = this.drivers.map((type) => {
            const selected = type.name.trim() === driver.name.trim();
            return { ...type, selected };
          });
        }
        this.driver = cloneDeep(driver);
        if (this.editDrawer) {
          this.editDrawer.setOpen();
        }
      }, 100);
    }
  }

  handleGridSelection(driverIds: string[]) {
    if (driverIds[0]) {
      const matchedDriver = this.driverList.find(t => (t.id === driverIds[0]));
      this.selectDriver({}, matchedDriver);
    } else {
      this.editDrawer.close();
    }
  }

  unarchive(driver: Driver) {
    this.driverService.save({ id: driver.id, status: 'active' }).subscribe(() => {
      this.refreshTable();
    });
  }

  menuAction(name, driver) {
    switch (name) {
      case 'edit':
        setTimeout(() => {
          this.selectDriver(name, driver);
        }, 100);
        break;
      case 'details':
        if (driver && driver.id) {
          this.router.navigate(
            ['drivers', driver.id, 'details', 'details'],
            {
              queryParams: { returnTo: this.router.url },
            }
          );
        }
        break;
      case 'unarchive':
        this.unarchive(driver);
        break;
    }
  }

  isSelected(driver) {
    return this.driver && driver.id === this.driver.id;
  }

  openAddDriver() {
    const dialog = this.dialog.open(NewDriverDialogComponent, {
      width: '444px'
    });
    dialog.componentInstance.leasedMode = this.displayedColumns.includes('carrier');
    dialog.componentInstance.carrierId = this.carrierId;
    dialog.componentInstance.stateOptions = this.stateOptionsForModal;
    dialog.componentInstance.callback = this.saveDriverCallback;
  }

  openInviteDriver() {
    const dialog = this.dialog.open(InviteDriverDialogComponent, {
      width: '444px'
    });
    dialog.componentInstance.stateOptions = this.stateOptionsForModal;
    dialog.componentInstance.callback = this.inviteDriverCallback;
  }

  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.filters = [
      {
        type: 'dropdown',
        field: 'tags',
        label: 'Markets',
        dropdownConfig: <DropdownConfig>{
          service: TagService,
          multiselect: true
        }
      },
      {
        type: 'text',
        field: 'truckName',
        label: 'Current Truck Search'
      },
      {
        type: 'text',
        field: 'cdl',
        label: 'CDL Search'
      }
    ];
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      tags: 'tags',
      truckName: 'truck__name',
      cdl: 'cdl'
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (filterRes[key]) {
        if (key === 'tags') {
          if (values && values.length > 0) {
            values = values.map(tag => tag.name).join(',');
            query[queryKeys[key]] = values;
          }
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
        }
      }
      let filter = new FilterOption({
        filterType: 'text',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (!filter.values) { falseyFilters.push(filter); }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  refreshTable() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.driverTable.getRecords({ ...this.tableConfig['query'], ...this.query, ...query });
  }

  /**
   * Gets called on when fancy table is finished with loading data
   * Opens the side edit driver drawer if driverId in query params
   *
   * @param data List of drivers
   */
  dataLoaded({data}) {
    this.driverList = data;
    const driverId = this.route.snapshot.queryParams.driverId;
    if (driverId) {
      const driver = (<Driver[]> data).find(r => r.id === driverId);
      if (driver) {
        this.selectDriver({}, driver);
      }
      this.router.navigate([], {
        queryParams: {
          'driverId': null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  /**
 * @returns void
 * This function is called when edit is successfully completed
 * This would update the table with the changed fields
 */
  onEditComplete(modifiedDriver): void {
    if (this.view === 'list') {
      this.refreshTable();
    } else {
      this.driverList = this.driverList.map(d => (
        d.id === modifiedDriver.id ?
        Object.assign(d, modifiedDriver) :
        d
      ));
      this.setupItemList(this.driverList, this.activeGroupBy);
    }
  }

  /**
   * Switched the UI view and then triggers the  driver list setup if no list is currently set up
   *
   * @param {'list' | 'grid'} view The selected view
   */
   switchView(view: 'list' | 'grid') {
    this.view = view;
    if (!this.driverGroups) {
      this.getFullDriverList(this.query);
    }
  }

  /**
   * Fetches the full truck list based on the same query as the truck table
   *
   * @param {any} query The query to be appended to the full list request
   */
  getFullDriverList(query: any) {
    this.driverService.listAll(25, query).subscribe(drivers => {
      this.driverList = drivers;
      this.setupItemList(this.driverList, this.activeGroupBy);
    });
  }

  /**
   * Sets up the driver groups based on the specified groupBy param
   *
   * @param {Driver[]} drivers The driver list to be grouped
   * @param {'Carrier' | 'Market' | 'Assigned' | 'Duty Status'} groupBy The selected groupBy option
   */
   setupItemList(drivers: Driver[], groupBy: 'Carrier' | 'Market' | 'Assigned' | 'Duty Status') {
    this.activeGroupBy = groupBy;
    switch (groupBy) {
      case 'Carrier':
        this.driverGroups = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                id: d.carrier.id,
                name: d.carrierOrganizationName,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (driver.carrier.id === group.id))
        })));
        break;
      case 'Market':
        let marketGroups = [];
        drivers.forEach(d => {
          if (d.tags && d.tags.length) {
            d.tags.forEach(tag => {
              marketGroups.push(<ItemGroup>{
                id: tag.id,
                name: tag.name,
                groupBy: groupBy,
                items: []
              });
            });
          } else {
            marketGroups.push(<ItemGroup>{
              id: '',
              groupBy: groupBy,
              items: []
            });
          }
        });
        this.driverGroups = marketGroups.filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (
            group.id === '' ? (!driver.tags || driver.tags.length === 0) :
            driver.tags.map(t => (t.id)).join(' ').includes(group.id)
          ))
        })));
        break;
      case 'Assigned':
        this.driverGroups = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                name: d.truck && d.truck.id ? 'Truck Assigned' : 'No Truck Assigned',
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => {
            if (group.name === 'Truck Assigned') {
              return driver.truck && driver.truck.id;
            } else {
              return !(driver.truck && driver.truck.id);
            }
          })
        })));
        break;
      case 'Duty Status':
        this.driverGroups = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                id: d.dutyStatus,
                name: d.displayDutyStatus,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (driver.dutyStatus === group.id))
        })));
        break;
    }
    this.driverGroups = this.driverGroups.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
  }

  /**
   * Selects a new groupBy option and re-triggers the driver grid setup based on that option
   *
   * @param {'Carrier' | 'Market' | 'Assigned' | 'Duty Status'} groupBy The selected groupBy option
   */
  selectGroupBy(groupBy: 'Carrier' | 'Market' | 'Assigned' | 'Duty Status') {
    this.setupItemList(this.driverList, groupBy);
  }

  /**
   * Returns a set of class names in a single string to be appended to item elements in the item grid
   *
   * @param {Driver} driver The driver object
   * @returns {string} The class names to be appended to item elements
   */
  generateItemClassNames(driver: Driver): string {
    let classNames = '';
    if (driver.dutyStatus === 'off-duty') {
      classNames += 'red ';
    } else if (driver.truck && driver.truck.id) {
      classNames += 'blue ';
    }
    return classNames;
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
