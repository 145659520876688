import { Directive, forwardRef, ElementRef, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[numberInRange]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberInRangeDirective), multi: true }
  ]
})

export class NumberInRangeDirective implements Validator {
  @Input() minValue: number;
  @Input() maxValue: number;
  @Output() ngModelChange = new EventEmitter();

  restrict(e) {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
    if (e.keyCode === 38) {
      e.target.value++;
    } else if (e.keyCode === 40) {
      e.target.value--;
    }
    if (e.key && e.key.length !== 1) {
      return;
    }
    if (e.key && !e.key.match(/(\d|\.)/) && !e.metaKey) {
      e.preventDefault();
    }
    setTimeout(() => {
      let value = parseInt(e.target.value, 10);
      if (this.maxValue != null && value > this.maxValue) {
        e.target.value = this.maxValue;
      }
      if (this.minValue != null && value < this.minValue) {
        e.target.value = this.minValue;
      }
      this.ngModelChange.emit(e.target.value);
    }, 10);
  }


  constructor(el: ElementRef, renderer: Renderer2) {
    renderer.listen(el.nativeElement, 'keydown', $event => {
      this.restrict($event);
    });
  }

  validate(c: AbstractControl): { [key: string]: any } {
    let v = c.value;

    if (v && v.match) {
      let numbers = v.match(/(\d|\.)/g);
      if (numbers && numbers.length !== 0) {
      } else {
        return { value: false };
      }
    }
    return null;
  }
}
