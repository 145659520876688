import { JobEventStat, JobEventStatsSummary } from './job-event-stat';
import { JobEvent } from '../job-events/job-event';
import { TruckSerializer } from '../trucks/truck.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class JobEventStatSerializer {
  fromJson (json: any): JobEventStat {
    json = camelcaseKeysDeep(json);
    const jobEventStat = new JobEventStat();
    if (!json) { return jobEventStat; }

    jobEventStat.id = json.id;
    jobEventStat.jobId = json.jobId;
    jobEventStat.jobName = json.jobName;
    jobEventStat.externalIdentifier = json.externalIdentifier;
    jobEventStat.jobDisplayName = [jobEventStat.externalIdentifier, jobEventStat.jobName].filter(Boolean).join(': ');
    jobEventStat.dispatchedEstimatedNumberOfLoads = json.dispatchedEstimatedNumberOfLoads;
    jobEventStat.dispatchedEstimatedNumberOfTons = json.dispatchedEstimatedNumberOfTons;
    jobEventStat.dispatchedDriversCount = json.dispatchedDriversCount || 0;
    jobEventStat.totalAmount = json.totalAmount;
    jobEventStat.totalLoads = json.totalLoads;
    jobEventStat.totalTons = json.totalTons;
    jobEventStat.totalHours = json.totalHours;
    jobEventStat.totalTripHours = json.totalTripHours;
    jobEventStat.totalCompletedTripHours = json.totalCompletedTripHours;
    jobEventStat.totalOrdered = json.totalOrdered;
    jobEventStat.totalOrderedType = json.totalOrderedType || 'ton';
    jobEventStat.sharesCount = json.sharesCount;
    jobEventStat.invoiceRate = json.invoiceRate;
    jobEventStat.numTrucks = json.numTrucks || 0;
    jobEventStat.confirmedTrucks = json.confirmedTrucks || 0;
    jobEventStat.invoiceType = json.invoiceType;
    jobEventStat.invoiceWeightUnit = json.invoiceWeightUnit;
    jobEventStat.invoiceRateUnit = json.invoiceRateUnit;
    jobEventStat.weightInTransit = json.weightInTransit;
    jobEventStat.ticketedWeight = json.ticketedWeight;
    if (json.truckTypes && json.truckTypes.length) {
      jobEventStat.truckTypes = json.truckTypes.map(truckType => {
        if (truckType && typeof truckType === 'object') {
          return new TruckSerializer().fromJson(truckType);
        } else if (json.truckTypes) {
          return new TruckSerializer().fromJson({ id: truckType });
        }
      });
    } else {
      jobEventStat.truckTypes = [];
    }
    jobEventStat.shift1Status = json.shift1Status;
    jobEventStat.shift2Status = json.shift2Status;
    jobEventStat.dailyDeliveryTarget = json.dailyDeliveryTarget;
    jobEventStat.expanded = json.expanded || false;

    if (json.jobEvent) {
      jobEventStat.jobEvent = new JobEvent(json.jobEvent);
    } else {
      jobEventStat.jobEvent = new JobEvent({ id: json.jobEvent });
    }
    if ((
      json.shift1Status === 'cancelled' && json.shift2Status === 'cancelled'
    ) || json.status === 'cancelled') {
      jobEventStat.status = 'Day Cancelled';
    } else if (json.shift1Status === 'cancelled') {
      jobEventStat.status = 'Shift 1 Cancelled';
    } else if (json.shift2Status === 'cancelled') {
      jobEventStat.status = 'Shift 2 Cancelled';
    } else {
      jobEventStat.status = json.status;
    }

    if (json.measurementUnit) {
      if (json.invoiceWeightUnit === 'cuyds') {
        jobEventStat.pluralMeasurementUnit = 'cu. yds.';
        jobEventStat.measurementUnit = 'cu. yd.';
      } else {
        jobEventStat.pluralMeasurementUnit = jobEventStat.invoiceWeightUnit;
        jobEventStat.measurementUnit = jobEventStat.invoiceWeightUnit + 's';
      }
    }

    jobEventStat.startLocationId = json.startLocationId;
    jobEventStat.startLocationName = json.startLocationName;
    jobEventStat.endLocationId = json.endLocationId;
    jobEventStat.endLocationName = json.endLocationName;
    jobEventStat.orderNumber = json.orderNumber;
    jobEventStat.customer = json.customer;
    jobEventStat.truckTypes = json.truckTypes;
    jobEventStat.material = json.material;
    jobEventStat.startTime = json.startTime;
    jobEventStat.avgTripTime = json.avgTripTime;
    if (jobEventStat.avgTripTime) {
      const avgTripTimeMins = Math.floor(jobEventStat.avgTripTime / 60);
      jobEventStat.avgTripTimeFormatted = (Math.floor(avgTripTimeMins / 60)) + 'h ' + (avgTripTimeMins % 60) + 'm';
    }
    jobEventStat.haulAmount = json.haulAmount;
    jobEventStat.haulType = json.haulType;
    jobEventStat.haulRate = json.haulRate;
    jobEventStat.effectiveTonRate = json.effectiveTonRate;
    jobEventStat.assignedDriversCount = json.assignedDriversCount;
    jobEventStat.confirmedAssignmentsCount = json.confirmedAssignmentsCount;
    jobEventStat.tonsPerHour = json.tonsPerHour;
    jobEventStat.connexActualLoads = json.connexActualLoads;
    jobEventStat.connexActualQuantity = json.connexActualQuantity;
    jobEventStat.connexCarrierRestrictionId = json.connexCarrierRestrictionId;
    jobEventStat.requestedAmount = json.requestedAmount;
    jobEventStat.requestedUnit = json.requestedUnit;
    jobEventStat.confirmedAmount = json.confirmedAmount;

    // Quantities detail
    if (json.quantitiesList && json.quantitiesList.length && json.quantitiesList.length > 1 &&
        json.quantitiesList.every((item: string) => item !== null)) {
      jobEventStat.quantitiesDetail = json.quantitiesList.map((item: string, idx: number) => {
        return `${item} ${json.uomList[idx]}`;
      }).join(' / ');
    }

    return jobEventStat;
  }

  toJson (jobEventStat: JobEventStat): any {
    let json = {
      dispatchedEstimatedNumberOfLoads: jobEventStat.dispatchedEstimatedNumberOfLoads,
      dispatchedEstimatedNumberOfTons: jobEventStat.dispatchedEstimatedNumberOfTons,
      dispatchedDriversCount: jobEventStat.dispatchedDriversCount,
      totalAmount: jobEventStat.totalAmount,
      totalLoads: jobEventStat.totalLoads,
      totalTons: jobEventStat.totalTons,
      totalHours: jobEventStat.totalHours,
      totalOrdered: jobEventStat.totalOrdered,
      totalOrderedType: jobEventStat.totalOrderedType,
      sharesCount: jobEventStat.sharesCount,
      invoiceRate: jobEventStat.invoiceRate,
      numTrucks: jobEventStat.numTrucks,
      confirmedTrucks: jobEventStat.confirmedTrucks,
      invoiceType: jobEventStat.invoiceType,
      invoiceWeightUnit: jobEventStat.invoiceWeightUnit,
      invoiceRateUnit: jobEventStat.invoiceRateUnit,
      measurementUnit: jobEventStat.measurementUnit,
      weightInTransit: jobEventStat.weightInTransit,
      ticketedWeight: jobEventStat.ticketedWeight,
      id: jobEventStat.id,
      jobId: jobEventStat.jobId,
      jobName: jobEventStat.jobName,
      jobEvent: jobEventStat.jobEvent && jobEventStat.jobEvent.id,
      startLocationId: jobEventStat.startLocationId,
      startLocationName: jobEventStat.startLocationName,
      endLocationId: jobEventStat.endLocationId,
      endLocationName: jobEventStat.endLocationName,
      orderNumber: jobEventStat.orderNumber,
      customer:  jobEventStat.customer ,
      truckTypes: jobEventStat.truckTypes,
      material: jobEventStat.material,
      startTime: jobEventStat.startTime,
      avgTripTime: jobEventStat.avgTripTime,
      haulAmount: jobEventStat.haulAmount,
      haulType: jobEventStat.haulType,
      haulWeight: jobEventStat.haulRate,
      effectiveTonRate: jobEventStat.effectiveTonRate,
      requestedAmount: jobEventStat.requestedAmount,
      requestedUnit: jobEventStat.requestedUnit,
      confirmedAmount: jobEventStat.confirmedAmount,
    };

    return decamelizeKeysDeep(json);
  }
}

export class JobEventStatsSummarySerializer {
  fromJson(json: any): JobEventStatsSummary {
    json = camelcaseKeysDeep(json);
    const jobEventStats = new JobEventStatsSummary();
    if (!json) { return jobEventStats; }

    jobEventStats.requestedTrucksSum = json.requestedTrucksSum;
    jobEventStats.confirmedTrucksSum = json.confirmedTrucksSum;
    jobEventStats.dispatchedTrucksSum = json.dispatchedTrucksSum;
    jobEventStats.orderedLoadsSum = json.orderedLoadsSum;
    jobEventStats.deliveredLoadsSum = json.deliveredLoadsSum;
    jobEventStats.orderedTonsSum = json.orderedTonsSum;
    jobEventStats.deliveredTonsSum = json.deliveredTonsSum;
    jobEventStats.driversOnShiftCount = json.driversOnShiftCount;
    jobEventStats.connexActualQuantitySum = json.connexActualQuantitySum;

    return jobEventStats;
  }
}
