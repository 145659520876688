<form #editJobDay="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>
      <span [ngPlural]="drivers.length">
        <ng-template ngPluralCase="=1" translate>Driver Is Busy</ng-template>
        <ng-template ngPluralCase="other" translate>Drivers Are Busy</ng-template>
      </span>
    </h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content" *ngIf="drivers.length == 1">
      <div class="field-row heavy separator">
        {{ drivers[0].profile.firstName }} {{ drivers[0].profile.lastName }}
        <ng-container translate>is assigned to another job at this time.</ng-container>
      </div>
      <div class="field-row" *ngFor="let conflict of drivers[0].conflicts">
        {{ conflict.jobeventName }}: {{ conflict.time }}
      </div>
    </div>
    <div class="body form-content" *ngIf="drivers.length > 1">
      <div class="field-row separator">
        <div class="heavy">{{ drivers.length }}
          <ng-container translate>drivers are assigned to another job at this time:</ng-container>
        </div>
      </div>
      <div class="separator" *ngFor="let driver of drivers">
        <div class="heavy">{{ driver.profile.firstName }} {{ driver.profile.lastName }}</div>
        <div *ngFor="let conflict of driver.conflicts">{{ conflict.jobeventName }}: {{ conflict.time }}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel"
            (click)="dialogRef.close(); false"
            translate>
      Cancel
    </button>
    <button class="btn btn-primary"
            (click)="submit(); false"
            translate>
      Assign Job
    </button>
  </div>
</form>
