<form #quickDispatch="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Quick Dispatch</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group job-name">
          <label translate>Job</label>
          <ruckit-dropdown *ngIf="!isCrh"
                           #jobsDropdown [config]="jobsDropdownConfig"
                           [selectedOption]="selectedJob && selectedJob.id"
                           (onSelect)="onSelect('jobs', $event)">
          </ruckit-dropdown>
          <ruckit-dropdown *ngIf="isCrh"
                           #jobEventsDropdown [config]="jobEventsDropdownConfig"
                           [selectedOption]="selectedJobEvent && selectedJobEvent.id"
                           (onSelect)="onSelect('jobEvents', $event)">
          </ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group carrier-name">
          <label translate>Carrier</label>
          <ruckit-dropdown #carriersDropdown [config]="carriersDropdownConfig"
                           [disabled]="!selectedJobEvent"
                           [selectedOption]="selectedCarrier && selectedCarrier.id"
                           (onSelect)="onSelect('carriers', $event)">
          </ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group markets">
          <label translate>Markets</label>
          <ruckit-dropdown #marketsDropdown
                           [disabled]="!selectedJobEvent"
                           [config]="marketsConfig"
                           [selectedItems]="selectedTags"
                           (onSelect)="onSelect('tags', $event)">
          </ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group driver-name">
          <label translate>Driver</label>
          <ruckit-dropdown #driversDropdown [config]="driversDropdownConfig" [disabled]="!selectedCarrier"
                           [selectedOption]="selectedDriver && selectedDriver.id"
                           (onSelect)="onSelect('drivers', $event)">
          </ruckit-dropdown>
          <ng-container *ngIf="selectedDriver && selectedDriver.conflicts && selectedDriver.conflicts.length > 0">
            <div *ngFor="let conflict of selectedDriver.conflicts"
                class="label-warn">Assigned to {{ conflict.jobeventName }}: {{ conflict.time }}</div>
          </ng-container>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group truck-name">
          <label translate>Truck</label>
          <ruckit-dropdown #trucksDropdown [config]="trucksDropdownConfig" [disabled]="!selectedCarrier || !selectedDriver"
                           [selectedOption]="selectedTruck && selectedTruck.id"
                           (onSelect)="onSelect('trucks', $event)">
          </ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group date-picker">
          <label translate>Date</label>
          <date-range-picker [selectedDates]="selectedDates"
                             [highlightedDates]="existingDates"
                             [disabled]="loading || jobDaysLoading || !job"
                             (dateChanged)="dateChanged($event)"></date-range-picker>
        </div>
        <div class="field-group unique-start">
          <label translate>Start Time</label>
          <input type="text" name="uniqueStartTime" class="timepicker"
                 [disabled]="!selectedJobEvent" placeholder="&ndash;"
                 [(ngModel)]="uniqueStart"
                 [options]="{
                   disableTextInput: false,
                   'timeFormat': 'g:i A',
                   showDuration: false,
                   step: 5,
                   minTime: dispatchFirstTime | date: 'h:mm a',
                   maxTime: selectedJobEventEndTime | date: 'h:mm a'
                 }" />
          <span class="dropdown-arrow"></span>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group load-single">
          <input type="radio" name="max-loads" required [(ngModel)]="numberOfLoadsType" [value]="'numbered'"/>
          <label for="max-loads" [ngClass]="{'active': numberOfLoadsType === 'numbered'}" translate>Loads:</label>
          <input type="number" name="load-count" [disabled]="numberOfLoadsType !== 'numbered'" [(ngModel)]="maxLoads" min="1"/>
          <input type="radio" name="max-loads" required [(ngModel)]="numberOfLoadsType" [value]="'allDay'"/>
          <label for="max-loads" [ngClass]="{'active': numberOfLoadsType === 'allDay'}" translate>All Day</label>
        </div>
      </div>
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button translate class="btn btn-primary"
            [ngClass]="{ 'loading': dispatching }"
            [disabled]="!isValidAssignment()"
            (click)="dispatch(); false">
      Dispatch
    </button>
  </div>
</form>
