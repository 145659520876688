<form #export="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Export {{ (data.title ? data.title : data.type) | titlecase }}</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content success-message" *ngIf="exportSubmitted">
      <h2 translate>Your files are on the way and should arrive in your email in a few minutes.</h2>
    </div>

    <div class="body form-content" *ngIf="!exportSubmitted && !fieldsLocked">

      <div *ngIf="TYPELIST_OPTIONS.FORMAT_OPTIONS | isInArray: data.type" class="field-row">
        <div class="field-group export-type">
          <label class="required" translate>What data would you like to Export?</label>
          <div *ngFor="let option of data.dataFormats" class="radio-buttons">
            <label attr.data-label="{{ option | titlecase | translate }}">
              <input type="radio" name="export_type_{{ option }}" [(ngModel)]="selectedDataFormat" [value]="option" />
            </label>
          </div>
        </div>
      </div>

      <ng-container *ngIf="TYPELIST_OPTIONS.FIELD_OPTIONS | isInArray: data.type">
        <p class="subheader" translate>What do you want to export?</p>
        <div class="field-row">
          <div class="field-group export-type">
            <div class="radio-buttons">
              <label
                class="bold"
                attr.data-label="{{ 'Export All Data' | translate }}"
              >
                <input
                  type="radio"
                  name="export_fields_all"
                  [(ngModel)]="exportAllFields"
                  [value]="'export_fields_all'"
                />
              </label>
              <label
                class="bold"
                attr.data-label="{{ 'Custom Export' | translate }}"
              >
                <input
                  type="radio"
                  name="export_fields_custom"
                  [(ngModel)]="exportAllFields"
                  [value]="'export_fields_custom'"
                />
              </label>
              <p
                *ngIf="exportAllFields === 'export_fields_custom'"
                class="smaller expand-toggle"
                [ngClass]="{ expanded: showFieldOptions }"
                (click)="showFieldOptions = !showFieldOptions"
              >
                {{
                  (showFieldOptions ? 'Hide ' : 'Show ') + 'Fields' | translate
                }}<mat-icon>expand_more</mat-icon>
              </p>
              <ng-container *ngIf="showExportDisplayedColumns">
                <label
                  class="bold"
                  attr.data-label="{{ 'Export Displayed' | translate }}"
                >
                  <input
                    type="radio"
                    name="export_fields_displayed"
                    [(ngModel)]="exportAllFields"
                    [value]="'export_fields_displayed'"
                  />
                </label>
                <p
                  *ngIf="exportAllFields === 'export_fields_displayed'"
                  class="smaller expand-toggle"
                  [ngClass]="{ expanded: showFieldOptions }"
                  (click)="showFieldOptions = !showFieldOptions"
                >
                  {{
                    (showFieldOptions ? 'Hide ' : 'Show ') + 'Fields' | translate
                  }}<mat-icon>expand_more</mat-icon>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="exportAllFields === 'export_fields_custom' && showFieldOptions">
          <p class="subheader" translate>Select {{ (data.title ? data.title : data.type) | titlecase }} Fields to Include</p>
          <div class="field-row column-fields">
            <div *ngFor="let column of columns" class="field-group column">
              <label *ngFor="let field of column" class="field-option" attr.data-label="{{ field.label | translate }}">
                <input type="checkbox" name="field_{{ field.key }}" [(ngModel)]="field.selected" />
              </label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="exportAllFields === 'export_fields_displayed' && showFieldOptions">
          <p class="subheader" translate>Select {{ (data.title ? data.title : data.type) | titlecase }} Fields to Include</p>
          <div class="field-row column-fields">
            <div *ngFor="let column of displayedColumns" class="field-group column">
              <label *ngFor="let field of column" class="field-option" attr.data-label="{{ field.label | translate }}">
                <input type="checkbox" name="field_{{ field.key }}" [(ngModel)]="field.selected" />
              </label>
            </div>
            <!-- <div *ngFor="let col of data.displayedColumns" class="">
              <label class="field-option" attr.data-label="{{ col.label | translate }}">
                <input type="checkbox" name="field_{{ col.key }}" [(ngModel)]="col.selected" />
              </label>
            </div> -->
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="TYPELIST_OPTIONS.IMAGE_OPTIONS | isInArray: data.type" class="field-row">
        <div class="field-group ticket-images">
          <label class="bold" attr.data-label="{{ 'Include Images in Export' | translate }}">
            <input type="checkbox" name="ticket_images" [(ngModel)]="data.scope.includeImages" />
          </label>
        </div>
      </div>

      <ng-container *ngIf="TYPELIST_OPTIONS.TYPE_OPTIONS | isInArray: data.type">
        <div class="field-row">
          <div class="field-group ticket-images">
            <label class="bold" attr.data-label="{{ 'Payroll PR Export' | translate }}">
              <input type="checkbox" name="payroll" [(ngModel)]="data.scope.driverPayFormats && data.scope.driverPayFormats.payroll"/>
            </label>
          </div>
        </div>
        <div class="field-row">
          <div class="field-group ticket-images">
            <label class="bold" attr.data-label="{{ 'Equipment EM Export' | translate }}">
              <input type="checkbox" name="equipment" [(ngModel)]="data.scope.driverPayFormats && data.scope.driverPayFormats.equipment"/>
            </label>
          </div>
        </div>
        <div class="field-row">
          <div class="field-group ticket-images">
            <label class="bold" attr.data-label="{{ 'Hired Hauler MS Export' | translate }}">
              <input type="checkbox" name="hiredHauler" [(ngModel)]="data.scope.driverPayFormats && data.scope.driverPayFormats.hiredHauler"/>
            </label>
          </div>
        </div>
        <div class="field-row">
          <div class="field-group ticket-images">
            <label class="bold" attr.data-label="{{ 'Hourly Ticket Export' | translate }}">
              <input type="checkbox" name="hourlyTicket" [(ngModel)]="data.scope.driverPayFormats && data.scope.driverPayFormats.hourlyTicket"/>
            </label>
          </div>
        </div>
        <div class="field-row">
          <div class="field-group ticket-images">
            <label class="bold" attr.data-label="{{ 'Raw Export' | translate }}">
              <input type="checkbox" name="raw" [(ngModel)]="data.scope.driverPayFormats && data.scope.driverPayFormats.raw"/>
            </label>
          </div>
        </div>
      </ng-container>

      <div *ngIf="TYPELIST_OPTIONS.FIELD_OPTIONS | isInArray: data.type" class="field-row">
        <div class="field-group ticket-images" *ngIf="!(TYPELIST_OPTIONS.LEGACY_OPTIONS | isInArray: data.type)">
          <label class="bold" attr.data-label="{{ 'Include Legacy Export' | translate }}">
            <input type="checkbox" name="ticket_images" [(ngModel)]="data.scope.includeLegacy" />
          </label>
        </div>
      </div>

      <div *ngIf="TYPELIST_OPTIONS.DATE_OPTIONS | isInArray: data.type" class="field-row">
        <div class="field-group date-filters">
          <label class="bold" attr.data-label="{{ 'Date Range' | translate }}"></label>
          <date-range-picker [config]="{rangeMode: true, decreaseControl: false, increaseControl: false}"
                             (dateChanged)="onDateChanged($event)"></date-range-picker>
        </div>
      </div>
    </div>

    <div class="body form-content" *ngIf="!exportSubmitted && fieldsLocked && !data.type.includes('driver-shifts')">
      <div class="field-row">
        <h2 translate>Click below to export your data.</h2>
      </div>
    </div>

    <div class="body form-content" *ngIf="!exportSubmitted && fieldsLocked && data.type.includes('driver-shifts')">
      <div class="field-row">
        <h2 translate>Select a date range below to export your data.</h2>
      </div>

      <div *ngIf="data.excludeLeasedFleetOption !== true && (TYPELIST_OPTIONS.FORMAT_OPTIONS | isInArray: data.type)" class="field-row">
        <div class="field-group leased-fleet">
          <label class="bold" attr.data-label="{{ 'Exclude Leased Fleet Drivers' | translate }}">
            <input type="checkbox" name="leased_fleet" [(ngModel)]="data.scope.excludeLeasedFleetDrivers" />
          </label>
        </div>
      </div>

      <div *ngIf="TYPELIST_OPTIONS.DATE_OPTIONS | isInArray: data.type" class="field-row">
        <div class="field-group date-filters">
          <label class="bold" attr.data-label="{{ 'Date Range' | translate }}"></label>
          <date-range-picker [config]="{rangeMode: true, decreaseControl: false, increaseControl: false}"
            (dateChanged)="onDateChanged($event)"></date-range-picker>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer" *ngIf="!exportSubmitted">
    <button class="btn btn-primary"
            (click)="export.form.valid && submit(); false"
            [disabled]="!export.form.valid || loading"
            [ngClass]="{ loading: loading }">
      {{ data.buttonText | translate }}
    </button>
  </div>

</form>
