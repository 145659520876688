<div class="app-content">
  <my-toolbar title="{{ 'Invoices' | translate }}"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/invoices', 'filter', 'draft']" routerLinkActive="active" translate>Draft</a>
        <a [routerLink]="['/invoices', 'filter', 'sent']" routerLinkActive="active" translate>Sent</a>
      </div>
    </div>

    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}">
        <i class="icon-filter"></i>{{ 'Filters' | translate }}
      </button>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <dropdown title="Actions" [options]="dropdownOptions[state]" [changeTitle]="false" (onSelect)="setSelectedAction($event, state)"
        class="actions-dropdown" [disabled]="loading || (!allSelected[state] && !selectedInvoices[state].length)"></dropdown>
      <button id="new-invoice-button" class="btn btn-primary" (click)="openNewInvoice()"><span class="icon-plus"></span>
        New Invoice</button>
    </div>
  </action-bar>

  <div class="filters" *ngIf="filters.length">
    <ng-container *ngIf="!loading">{{ count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters | propertyFilter: 'hidden': 'false'">
      {{ filter.key | titleCase }}: {{ filter.value && (filter.value.selectName || filter.value.name || filter.value)
      }}
      <i class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="notice" *ngIf="displayLastExpensed()">
    <notification level="info" translate>
      {{ expenseLabel }} have been created from Invoice #{{ lastExpensed }}.
    </notification>
  </div>

  <div class="invoices">
    <div class="list-table">
      <div class="table-body">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event, state)" [(ngModel)]="allSelected[state]" />
            </label>
          </div>
          <div class="head date sort" (click)="sort('start_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'start_time'}"
            translate>
            Date
          </div>
          <div class="head invoice-number sort" (click)="sort('number')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'number'}"
            translate>
            Number
          </div>
          <div class="head amount sort" (click)="sort('amount')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'amount'}"
            translate>
            Amount
          </div>
          <div class="head customer sort" (click)="sort('customer_organization__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'customer_organization__name'}"
            translate>
            Customer
          </div>
          <div class="head job sort" (click)="sort('job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'job__name'}"
            translate>
            Job
          </div>
          <div class="head trip-dates" translate>
            Trip Dates
          </div>
          <div class="head created sort" (click)="sort('created_at')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'created_at'}"
            translate>
            Created
          </div>
          <div class="head expensed" translate>
            Expensed
          </div>
          <div class="head actions" translate>
            Actions
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && invoices.length === 0">
          <div class="icon"><i class="icon-invoice"></i></div>
          <h2 translate>No Invoices</h2>
          <p translate>When you create invoices you’ll see them here.</p>
          <div class="actions">
            <button class="btn btn-primary" (click)="openNewInvoice()"><span class="icon-plus"></span> {{ 'New Invoice'
              | translate }}</button>
          </div>
        </div>

        <div *ngFor="let invoice of invoices | propertyFilter: 'administrativeStatus': state; let i = index">
          <div class="row" [ngClass]="{selected: invoice === selectedInvoice || invoice.selected}">
            <div class="selector">
              <label>
                <input type="checkbox" (change)="selector($event, state, invoice)" [checked]="allSelected[state] || invoice.selected" />
              </label>
            </div>
            <div class="date" [ngClass]="{'spacer': !invoice.displayableStatus}">
              <div class="value">{{ invoice.invoicePeriod || '&nbsp;' }}</div>
              <div class="invoice-status">
                <div class="label-{{invoice.administrativeStatus}}" *ngIf="invoice.displayableStatus">{{invoice.administrativeStatus}}</div>
                <div class="label" translate>Invoice Period</div>
              </div>
            </div>
            <div class="invoice-number">
              <div class="value">#{{ invoice.number || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="amount">
              <div class="value currency">{{ (invoice.amount || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="customer">
              <div class="value">{{ invoice.customerOrganization?.name || '&nbsp;' }}</div>
              <div class="label" translate>Customer</div>
            </div>
            <div class="job">
              <div class="value" translate>{{ invoice.job?.name || 'Multiple Jobs' }}</div>
              <div class="label" translate>Job</div>
            </div>
            <div class="trip-dates">
              <div class="value">{{ invoice.tripDates || '&nbsp;' }}</div>
              <div class="label" translate>Trip Dates</div>
            </div>
            <div class="created">
              <div class="value">{{ (invoice.sentTime || invoice.createdAt) | date:'mediumDate' || '&nbsp;' }}</div>
              <div class="label">{{ invoice.sentTime ? 'Sent' : 'Created' }}</div>
            </div>
            <div class="expensed">
              <div class="label-expensed" *ngIf="invoice.expensed" translate>Expensed</div>
            </div>
            <div class="actions">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of invoice.filterOptions(menuOptions)">
                  <a *ngIf="option.link && !option.external" md-menu-item [routerLink]="invoice.url(option.action)" [queryParams]="{ returnTo: '/invoices/filter/' + state }">{{ option.name }}</a>
                  <a *ngIf="option.link && option.external" md-menu-item [href]="invoice.url(option.action)" target="_blank">{{ option.name }}</a>
                  <a *ngIf="!option.link" md-menu-item (click)="menuAction(option.action, invoice)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
          </div>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': invoices.length === 0}" class="loader"></my-loader>
      </div>
    </div>

    <ruckit-pagination *ngIf="(!loading && count) || loading" [perPage]="pageSize" [hideSinglePage]="false"
      [totalCount]="count" [currentPage]="1*page" (page)="pageChange($event)"></ruckit-pagination>
  </div>
</div>
