import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DriverService } from './../driver.service';

@Component({
  selector: 'invite-driver-dialog',
  templateUrl: './invite-driver-dialog.component.html',
  styleUrls: ['./invite-driver-dialog.component.scss'],
  providers: [DriverService]
})
export class InviteDriverDialogComponent {
  stateOptions = {};
  loading = false;
  model: any = {profile: {}};
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<InviteDriverDialogComponent>,
    private driverService: DriverService
  ) { }

  selectState(state) {
    this.model.state = state;
  }

  submit() {
    this.loading = true;
    this.driverService.invite(this.model).subscribe(() => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = err;
      this.loading = false;
    });
  }
}
