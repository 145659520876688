import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnChanges {
  @Output() timeChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('value') value;
  hours = '';
  minutes = '';
  meridian = '';

  minutesInput: any;

  constructor(public renderer: Renderer2, public elementRef: ElementRef) {
  }

  hoursKeydown(e) {
    if (e.key && e.key.length !== 1) {
      return;
    }
    if ( ! e.key.match(/\d/)) {
      e.preventDefault();
    }
    setTimeout(() => {
      if (parseInt(e.target.value, 10) > 1) {
        if (parseInt(e.target.value, 10) > 12) {
          this.hours = '12';
        }

        this.renderer.selectRootElement('[name="minutes"]').focus();
      }
      this.emitChange();
    }, 0);
  }
  minutesKeydown(e) {
    if (e.key && e.key.length !== 1) {
      return;
    }
    if ( ! e.key.match(/\d/)) {
      e.preventDefault();
    }
    setTimeout(() => {
      if (parseInt(e.target.value, 10) > 9) {
        if (parseInt(e.target.value, 10) > 59) {
          this.minutes = '59';
        }

        this.renderer.selectRootElement('[name="minutes"]').focus();
      }
      this.emitChange();
    }, 0);
  }
  meridianKeydown(e) {
    if (e.key && e.key.length !== 1) {
      return;
    }
    if (e.key.match(/a/i)) {
      this.meridian = 'AM';
    } else if (e.key.match(/p/i)) {
      this.meridian = 'PM';
    } else {
      // do nothing.
    }
    e.preventDefault();
    this.emitChange();
  }

  emitChange() {
    this.timeChange.emit(
      ( parseInt(this.hours, 10) +  ( (this.meridian === 'PM') ? 12 : 0 ) ).toString()
      + ':' + this.minutes
    );
  }

  select(e) {
    e.target.select();
  }

  ngOnChanges(changes: SimpleChanges)  {
    let currentValue = changes['value']['currentValue'];

    if (currentValue) {
      let parts = currentValue.split(':');
      let pm = parts[1].match(/pm/i);
      this.hours = (parts[0] % 12).toString();
      this.minutes = parts[1].match(/\d+/)[0];
      this.meridian = (parseInt(parts[0], 10) > 12 || pm)  ? 'PM' : 'AM';
    }
  }
}
