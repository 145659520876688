<div class="daily-board-list-container">
  <div class="actions-wrapper" [ngClass]="{'with-job-stats': withJobStats}">

    <fancy-table #dailyBoardTable *ngIf="!loading"
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(detailColumns)]="detailColumns"
                 [(availableFilters)]="availableFilters"
                 [(filters)]="appliedFilters"
                 [(search)]="search"
                 (selectionChanged)="selector($event)"
                 (columnChange)="columnsChanged($event)"
                 (onDataLoaded)="captureStats($event)"
                 [config]="dailyBoardTableConfig">

      <ng-template #detailTemplates let-row="row" let-column="column" let-value="value">
        <div class="data">
          <all-collaborators *ngIf="row.expanded" [jobEventId]="row.id" [filters]="appliedFilters">
          </all-collaborators>
        </div>
      </ng-template>

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'name'">
            <div class="data">
              <a [routerLink]="['/jobs/', row.jobId, row.id]">
                {{ row.jobDisplayName ? row.jobDisplayName : '-' }}
              </a>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'customer'">
            <div class="data">
              {{ row.stats && row.stats.customer ? row.stats.customer : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'apex-customer-job'">
            {{ row.apexCustomerJob || '&nbsp;' }}
          </div>
          <div class="value numeric" *ngSwitchCase="'total-ordered'">
            <div class="data">
              {{ row.stats && row.stats.totalOrdered ? (row.stats.totalOrdered | number) : '-' }} {{ row.stats && row.stats.totalOrderedType ? row.stats.totalOrderedType : ' ' }}
            </div>
          </div>
          <div class="value numeric" *ngSwitchCase="'total-loads'">
            <div class="data">{{ (row.stats && row.stats.totalLoads ? row.stats.totalLoads : 0) | number }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'total-tons'">
            <div class="data">{{ (row.stats && row.stats.totalTons ? row.stats.totalTons : 0) | number }}</div>
          </div>
          <div class="value currency" *ngSwitchCase="'total-amount'" [ngClass]="{'zero': !(row.stats && row.stats.totalAmount)}">
            <div class="data">{{ row.stats && row.stats.totalAmount ? (row.stats.totalAmount | currency:'USD':'symbol') : '-' }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'dispatched-drivers-count'">
            <div class="data">
              <a [routerLink]="['/dispatch/', row.jobId, row.id]">
                {{ row.stats && row.stats.dispatchedDriversCount ? (row.stats.dispatchedDriversCount | number) : 0 }}
              </a>
            </div>
          </div>
          <div class="value numeric" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">
                {{ row.stats && row.stats.confirmedTrucks ? (row.stats.confirmedTrucks | number) : 0 }}
            </div>
          </div>
          <div class="value numeric" *ngSwitchCase="'assignment-status'">
            <div class="data" translate>
              {{ row.stats && row.stats.confirmedAssignmentsCount ? (row.stats.confirmedAssignmentsCount | number) : 0 }} accepted / 
              {{ row.stats && row.stats.assignedDriversCount ? (row.stats.assignedDriversCount | number) : 0 }} assigned
            </div>
          </div>
          <div class="value numeric" *ngSwitchCase="'num-trucks'">
            <div class="data">
              <a [routerLink]="['/dispatch/', row.jobId, row.id]">
                {{ row.stats && row.stats.numTrucks ? (row.stats.numTrucks | number) : 0 }}
              </a>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'status'">
            <div class="data titleize">{{ row.statusText ? row.statusText : '-' }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'weight-in-transit'">
            <div class="data">{{ row.stats && row.stats.weightInTransit ? (row.stats.weightInTransit | number) : 0 }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'ticketed-weight'">
            <div class="data">{{ row.stats && row.stats.ticketedWeight ? (row.stats.ticketedWeight | number) : 0 }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'total-hours'">
            <div class="data">{{ row.stats && row.stats[totalHoursField] ? (row.stats[totalHoursField] | number) : 0 }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'daily-delivery-target'">
            <div class="data">{{ row.stats && row.stats.dailyDeliveryTarget ? (row.stats.dailyDeliveryTarget | number) : 0 }}</div>
          </div>
          <div class="value currency" *ngSwitchCase="'invoice-rate'">
            <div class="data">{{ row.stats && row.stats.invoiceRate ? (row.stats.invoiceRate | currency:'USD':'symbol':'1.2-4') : '-' }}</div>
          </div>
          <div class="value currency" *ngSwitchCase="'effective-ton-rate'">
            <div class="data">{{ row.stats && row.stats.effectiveTonRate ? (row.stats.effectiveTonRate | currency:'USD':'symbol':'1.2-4') : '-' }}</div>
          </div>
          <div class="value numeric" *ngSwitchCase="'shares-count'">
            <div class="data">
              <a [routerLink]="['/jobs/', row.jobId, row.id, 'collaborators']"
                 [queryParams]="{returnTo: '/jobs/daily', returnToTitle: 'Daily Board'}">
                {{ row.stats && row.stats.sharesCount ? (row.stats.sharesCount | number) : 0 }}
              </a>
            </div>
          </div>
          <div class="value loading-location" *ngSwitchCase="'loading-location-name'">
            <div class="data">
              <ng-container *ngIf="row.startLocation; else newStartLocation">
                <a [routerLink]="['/locations', row.startLocation, 'edit']"
                   [queryParams]="{ returnTo: '/jobs/daily?date='+ urlDate +'&search=' + search }">
                  {{ row.startLocationName ? row.startLocationName : '-' }}
                  <mat-icon title="Geofence set for start location" *ngIf="row.startLocationGeofence">layers</mat-icon>
                  <mat-icon color="warn" title="Geofence missing for start location" *ngIf="!row.startLocationGeofence">layers_clear</mat-icon>
                </a>
              </ng-container>
              <ng-template #newStartLocation>
                <a [routerLink]="['/locations', 'new']"
                   [queryParams]="{
                     returnTo: '/jobs/daily',
                     job: row.jobId,
                     type: 'start'
                   }" translate>
                  Add a New Location
                </a>
              </ng-template>

              <i [ngClass]="{'icon-pencil': loadingDropdownOpen !== row.id, 'icon-close': loadingDropdownOpen === row.id}" (click)="toggleLocationEdit('loading', row.id)"></i>
              <ruckit-dropdown class="locations-dropdown"
                *ngIf="loadingDropdownOpen === row.id"
                [open]="true" [toggle]="true"
                [config]="locationsConfig" [selectedOption]="row.startLocation"
                (onSelect)="selectLocation('start', row, $event)">
              </ruckit-dropdown>
            </div>
          </div>
          <div class="value unloading-location" *ngSwitchCase="'unloading-location-name'">
            <div class="data">
              <ng-container *ngIf="row.endLocation; else newEndLocation">
                <a [routerLink]="['/locations', row.endLocation, 'edit']"                  
                  [queryParams]="{ returnTo: '/jobs/daily?date='+ urlDate +'&search=' + search }">
                  {{ row.endLocationName ? row.endLocationName : '-' }}
                  <mat-icon title="Geofence set for end location" *ngIf="row.endLocationGeofence">layers</mat-icon>
                  <mat-icon color="warn" title="Geofence missing for end location" *ngIf="!row.endLocationGeofence">layers_clear</mat-icon>
                </a>
              </ng-container>
              <ng-template #newEndLocation>
                <a [routerLink]="['/locations', 'new']"
                   [queryParams]="{
                     returnTo: '/jobs/daily',
                     job: row.jobId,
                     type: 'end'
                   }" translate>
                  Add a New Location
                </a>
              </ng-template>
              <i [ngClass]="{'icon-pencil': unloadingDropdownOpen !== row.id, 'icon-close': unloadingDropdownOpen === row.id}" (click)="toggleLocationEdit('unloading', row.id)"></i>
              <ruckit-dropdown class="locations-dropdown"
                *ngIf="unloadingDropdownOpen === row.id"
                [open]="true" [toggle]="true"
                [config]="locationsConfig" [selectedOption]="row.endLocation"
                (onSelect)="selectLocation('end', row, $event)">
              </ruckit-dropdown>
            </div>
          </div>
          <div class="value order-number" [matTooltip]="row?.stats?.orderNumber" *ngSwitchCase="'order-number'">
            <div class="data">{{ row.stats && row.stats.orderNumber ? row.stats.orderNumber : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'truck-type'">
            <div class="data" *ngIf="!row.allowAnyTruckType && row.stats && row.stats.truckTypes && row.stats.truckTypes.length === 1">
              <div *ngFor="let truckType of row.stats.truckTypes">{{truckType.name}}</div>
            </div>
            <div class="data" *ngIf="!row.allowAnyTruckType && row.stats && row.stats.truckTypes && row.stats.truckTypes.length > 1">
              <truck-type-names [truckTypes]="row.stats.truckTypes"></truck-type-names>
            </div>
            <div class="data" *ngIf="row.allowAnyTruckType" translate>
              Any Type
            </div>
          </div>
          <div class="value material" *ngSwitchCase="'material'">
            <div class="data">{{ row.material ? row.material : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'quantities-detail'" >
            <ng-container *ngIf="row.stats && row.stats.quantitiesDetail; else totalOrderedTemplate">
              <div class="data">{{ row.stats.quantitiesDetail }}</div>
            </ng-container>
            <ng-template #totalOrderedTemplate>
              <div class="data">
                {{ row.stats && row.stats.totalOrdered ? (row.stats.totalOrdered | number) : '-' }} {{ row.stats && row.stats.totalOrderedType ? row.stats.totalOrderedType : ' ' }}
              </div>
            </ng-template>
          </div>
          <div class="value" *ngSwitchCase="'phase-code'">
            <div class="data">{{ row.phaseCode ? row.phaseCode : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'start-time'">
            <div class="data">{{ row.stats && row.stats.startTime ? row.stats.startTime : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'avg-trip-time'">
            <div class="data">{{ row.stats && row.stats.avgTripTime ? ((row.stats.avgTripTime/60) |  number : '1.2-2') + ' mins' : '-'}}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-amount'">
            <div class="data">{{ row.stats && row.stats.haulAmount ? (row.stats.haulAmount | currency : 'USD' : 'symbol' ) : 0}}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-rate'">
            <div class="data">{{ row.stats && row.stats.haulRate ? (row.stats.haulRate | currency : 'USD' : 'symbol' : '1.2-4') : 0}}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-type'">
            <div class="data">{{ row.stats && row.stats.haulType ? row.stats.haulType : '-'}}</div>
          </div>
          <div class="value" *ngSwitchCase="'job-number'">
            <div class="data">{{ row.jobNumber ? row.jobNumber : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'connex-actual-loads'">
            <div class="data">{{ row.stats && row.stats.connexActualLoads ? row.stats.connexActualLoads : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'connex-actual-quantity'">
            <div class="data">{{ row.stats && row.stats.connexActualQuantity ? (row.stats.connexActualQuantity | number : '1.2-2') : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'connex-carrier-restriction-id'">
            <div class="data">{{ row.stats && row.stats.connexCarrierRestrictionId ? row.stats.connexCarrierRestrictionId : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'delivery-interval'">
            <div class="data">
              {{ row.deliveryInterval ? row.deliveryInterval : '-' }}
              <span *ngIf="row.deliveryInterval">{{ row.deliveryIntervalUnit }}</span>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'dsorder-id'">
            <div class="data">{{ row.dsorderId ? row.dsorderId : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'slorder-id'">
            <div class="data">{{ row.slorderId ? row.slorderId : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'pin'">
            <div class="data">{{ row.pin ? row.pin : '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'action'">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of row.filterOptions(dailyBoardTableConfig.menuOptions)">
                <a *ngIf="option.link" mat-menu-item [routerLink]="row.url(option.action)" [fragment]="row.routeFragment" [queryParams]="{ returnTo: '/jobs/daily?date='+ urlDate +'&search=' + search }">
                  {{ option.name }}
                </a>
                <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
          <div class="value" *ngSwitchDefault>{{ value || '-' }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div translate class="title" *ngIf="!enabledFeatures.includes('hasCollaboratorStats')">
          Job Stats
        </div>
        <div class="title" translate *ngIf="enabledFeatures.includes('hasCollaboratorStats')">
          <button mat-button [matMenuTriggerFor]="menu" translate>
            Job Stats <mat-icon class="dropdown-arrow">arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item translate [routerLink]="['/jobs/daily', 'collaborator']"
                    [queryParams]="{date: jobEventDate | date:'yyyyMMdd'}">
              Collaborator Stats
            </button>
          </mat-menu>
        </div>
      </div>
      <div applied-filter-content>
        <applied-filters 
          [(filters)]="appliedFilters" 
          [search]="search" 
          (searchChange)="onSearchClear()" 
          (filtersChange)="filtersModified($event)">
        </applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle 
        #columnToggle
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns">
      </column-toggle>
      <filters-panel 
        [(availableFilters)]="availableFilters" 
        [(appliedFilters)]="appliedFilters" 
        [(search)]="search">
      </filters-panel>
      <action-menu icon="icon-more">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{ option.name }}</a>
          <a *ngIf="!option.link" mat-menu-item (click)="setSelectedBulkAction(option)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
    </div>
  </div>
</div>
