import { ProductItem } from './product-items';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ProductItemSerializer {
  fromJson(json: any): ProductItem {
    json = camelcaseKeysDeep(json);
    const productItem = new ProductItem();
    if (!json) { return productItem; }

    productItem.deleted = json.deleted;
    productItem.createdAt = json.createdAt;
    productItem.lastModified = json.lastModified;
    productItem.description = json.description;
    productItem.materialCode = json.materialCode;
    productItem.itemType = json.itemType;
    productItem.itemSubType = json.itemSubType;
    productItem.itemCategory = json.itemCategory;
    productItem.createdBy = json.createdBy;
    productItem.lastModifiedBy = json.lastModifiedBy;
    productItem.organization = json.organization;

    return productItem;
  }

  toJson(productItem: ProductItem) {
    const json = {
      id: productItem.id,
      deleted:  productItem.deleted,
      createdAt:  productItem.createdAt,
      lastModified: productItem.lastModified,
      description:  productItem.description,
      materialCode: productItem.materialCode,
      itemType: productItem.itemType,
      itemSubType:  productItem.itemSubType,
      itemCategory: productItem.itemCategory,
      createdBy:  productItem.createdBy,
      lastModifiedBy: productItem.lastModifiedBy,
      organization: productItem.organization
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
