import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { map, startWith, mergeMap, debounceTime } from 'rxjs/operators';
import { filter, keys, clone, cloneDeep, each, get, reject, find as _find } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

import { TruckTypeService } from '../trucks/truck-type.service';
import { JobService } from './job.service';
import { TagInputComponent } from '../shared/tag-input/tag-input.component';
import { LocationSerializer } from '../locations/location.serializer';
import { Project } from '../projects/project';
import { ProjectService } from '../projects/project.service';
import { ConnectionService } from '../connections/connection.service';
import { LocationService } from '../locations/location.service';
import { NewCustomerDialogComponent } from '../customers/new-customer-dialog.component';
import { NewLocationDialogComponent } from '../locations/new-location-dialog.component';
import { parseErrors } from '../shared/api.service';
import { AuthenticationService, DropdownComponent } from '../shared';
import { CustomFieldService } from '../custom-fields/custom-field.service';
import { CustomField, CustomFieldKind } from '../custom-fields/custom-field';
import { WorkOrderTemplate } from '../work-orders/work-order-templates/work-order-template';
import { WorkOrderTemplateService } from '../work-orders/work-order-templates/work-order-template.service';
import { User } from '../users/user';
import { Preference } from '../preferences/preference';
import { PreferenceService } from '../preferences/preference.service';
import { Job } from './job';
import { JobSerializer } from './job.serializer';
import { TruckType } from '../trucks/truck-type';
import { Organization } from '../organizations/organization';
import { ProjectSerializer } from '../projects/project.serializer';
import { EditLocationDialogComponent } from '../locations/edit-location-dialog.component';
import { Tag } from '../tags/tag';
import { JOBWEIGHTOPTIONS } from '../app.constants';
import { ProductItemsService } from './product-items-service/product-items.service';
import { ProductItem } from './product-items-service/product-items';

@Component({
  selector: 'new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss'],
  providers: [MatDialog, FormBuilder]
})

export class NewJobComponent implements OnInit, OnDestroy {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  loading = false;
  user: User;
  preference: Preference;
  hasWorkOrder = false;
  days = [];
  cloneHaulRate;
  jobTypeOptions = {
    loading: [],
    unloading: []
  };
  job: Job = new JobSerializer().fromJson({
    project: new ProjectSerializer().fromJson({ id: '', name: '' }),
    customerOrganization: new Organization({ id: '', name: '' }),
    qrJobType: 'loading_import',
    haulType: 'weight',
    haulWeightUnit: 'ton',
    invoiceType: 'weight',
    invoiceWeightUnit: 'ton',
    dailyDeliveryTargetType: 'tons',
    totalAmountType: 'tons',
    deliveryIntervalUnit: 'minutes',
    dates: [],
    allowAnyTruckType: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  truckTypes: TruckType[] = [];
  weightOptions = [...JOBWEIGHTOPTIONS];
  orderTypeOptions = [
    { id: 'tons', name: 'Tons' },
    { id: 'metric-tons', name: 'Metric Tons' },
    { id: 'tonnes', name: 'Tonnes' },
    { id: 'loads', name: 'Loads' },
    { id: 'lbs', name: 'Pounds' },
    { id: 'kgs', name: 'Kilograms' },
    { id: 'cuyds', name: 'Cubic Yards' },
    { id: 'bushels', name: 'Bushels' },
    { id: 'bags', name: 'Bags' }
  ];
  deliveryIntervalUnits = [{
    id: 'hours', name: 'Hours'
  }, {
    id: 'minutes', name: 'Minutes', selected: true
  }];
  truckTypeMap = {};
  loadingLocations = [{
    displayName: 'Add a New Address',
    id: 'new-loading-location',
    button: true
  }];
  unloadingLocations = [{
    displayName: 'Add a New Address',
    id: 'new-unloading-location',
    button: true
  }];
  jobWeightOptions = clone(this.weightOptions);
  haulWeightOptions = clone(this.weightOptions);
  project: any;
  returnTo: string;
  defaultLocation;
  locationsDropdownConfig = {
    start: { searchable: true, nameProperty: 'displayName' },
    end: { searchable: true, nameProperty: 'displayName' }
  };
  materialDropdownConfig = {
    searchable: false,
    autocomplete: true,
    nameProperty: 'description',
    loadingOptions: false
  };
  materialDropdownOptions: ProductItem[] = [];
  errors = [];
  newProject = true;
  projectOptions = [];
  projectValueSet = false;
  customerDialogRef: MatDialogRef<NewCustomerDialogComponent>;
  customerSearch = '';
  customerOptions = [];
  customerLoading = false;
  customerDropdownConfig: any = {
    nameProperty: 'name'
  };
  projectControl = new FormControl();
  truckTypesExpanded = false;
  customFields: CustomField[] = [];
  workOrderTemplateOptions: WorkOrderTemplate[] = [];
  workOrderTemplateDropdownConfig: any = {
    nameProperty: 'name'
  };

  searchReq: Subscription;
  locationByIPReq: Subscription;
  locationsReq: Subscription;
  connectionsReq: Subscription;
  projectsReq: Subscription;
  customFieldsReq: Subscription;
  truckTypesReq: Subscription;
  workOrderTemplatesReq: Subscription;
  jobDaysReq: Subscription;
  private paramsSub: Subscription;
  private queryParamSub: Subscription;
  allSubscriptionsToUnsubscribe: Subscription[] = [];


  configOptions = [
    { name: 'Required', id: 'required' },
    { name: 'Optional', id: 'optional' },
    { name: 'Hidden', id: 'hidden' },
  ];

  @ViewChild(TagInputComponent, { static: true }) tagInput: TagInputComponent;
  @ViewChild('projectAutocomplete', { static: true }) projectAutocomplete: MatAutocomplete;
  @ViewChild('customerDropdown') customerDropdown: DropdownComponent;
  @ViewChild('startLocationDropdown') startLocationDropdown: DropdownComponent;
  @ViewChild('endLocationDropdown') endLocationDropdown: DropdownComponent;

  saveCustomerCallback = (customer: Organization) => {
    this.reloadCustomerOptions();
    this.job.customerOrganization = customer;
  }

  constructor(
    private route: ActivatedRoute,
    private truckTypeService: TruckTypeService,
    private jobService: JobService,
    private projectService: ProjectService,
    private connectionService: ConnectionService,
    private locationService: LocationService,
    private workOrderTemplateService: WorkOrderTemplateService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    private customFieldService: CustomFieldService,
    private deviceDetectorService: DeviceDetectorService,
    private productItemsService: ProductItemsService
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.user = this.authenticationService.user();
    this.loadProjectOptions();
    this.allSubscriptionsToUnsubscribe.push(
      this.projectAutocomplete.opened.subscribe(() => {
        Promise.resolve(null).then(() => this.projectScrollEvent());
      })
    );
    this.customerDropdownConfig = { searchable: true, loadingOptions: false };
    this.reloadCustomerOptions();

    this.truckTypesReq = this.truckTypeService.list({ page_size: 100 }).subscribe(res => {
      this.truckTypes = res;
      this.truckTypesExpanded = _find(this.truckTypes, { isFavorite: true }) ? false : true;
    });
    this.paramsSub = this.route.params.subscribe((params) => {
      if (params['jobId']) {
        this.jobService.get(params['jobId']).subscribe(job => {
          this.copyToJob(job);
        });
      }
      if (params['id']) {
        this.projectService.get(params['id']).subscribe(project => {
          this.project = project;
        });
      }
    });
    this.queryParamSub = this.route.queryParams.subscribe((params: Params) => {
      if (params['returnTo'] && params['returnTo'].length) {
        this.returnTo = params['returnTo'];
      }
    });
    this.locationsReq = this.locationService.list({
      archived: 'False'
    }).subscribe(res => {
      res = res.map((loc) => {
        loc['displayName'] = loc.displayName + ' - ' + loc.street;
        return loc;
      });

      if (this.device.mobile) {
        this.loadingLocations = [];
        this.unloadingLocations = [];
        this.loadingLocations = this.loadingLocations.concat(res);
        this.unloadingLocations = this.unloadingLocations.concat(clone(res));
      } else {
        this.loadingLocations = this.loadingLocations.concat(res);
        this.unloadingLocations = this.unloadingLocations.concat(clone(res));
      }

      this.setStartLocation();
      this.setEndLocation();
    });
    this.locationByIPReq = this.locationService.getLocationByIP().subscribe(res => {
      this.defaultLocation = res;
    });
    this.hasWorkOrder = this.authenticationService.hasWorkOrder();
    if (this.hasWorkOrder) { this.getWorkOrderTemplates(); }
    if (this.job && this.user && this.user.organization && !this.user.organization.qrEnabled) {
      this.job.qrJobType = 'other';
    }
    this.loadCustomFields();
    this.getProductItems();
    this.getPreferences();
  }

  loadCustomFields(): void {
    this.customFieldsReq = this.customFieldService.getFieldsForKind(
      CustomFieldKind.Job
    ).subscribe(fields => {
      this.customFields = fields;
    });
  }

  ngOnDestroy() {
    if (this.searchReq && typeof this.searchReq.unsubscribe === 'function') {
      this.searchReq.unsubscribe();
    }
    if (this.paramsSub && typeof this.paramsSub.unsubscribe === 'function') {
      this.paramsSub.unsubscribe();
    }
    if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
      this.customFieldsReq.unsubscribe();
    }
    if (this.locationByIPReq && typeof this.locationByIPReq.unsubscribe === 'function') {
      this.locationByIPReq.unsubscribe();
    }
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }
    if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
      this.projectsReq.unsubscribe();
    }
    if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
      this.truckTypesReq.unsubscribe();
    }
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
    if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
      this.workOrderTemplatesReq.unsubscribe();
    }
    if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
      this.jobDaysReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  timeChange(prop: string, e): void {
    this.job[prop] = e;
  }

  changeTotalAmountType(type) {
    this.job.totalAmountType = type.id;
  }

  changeDailyDeliveryTargetType(type) {
    this.job.dailyDeliveryTargetType = type.id;
  }

  changeDeliveryIntervalUnits(interval) {
    this.job.deliveryIntervalUnit = interval.id;
  }

  weightUnitChange(tracking: string, val) {
    this.job[tracking + 'WeightUnit'] = val.value;
    if (tracking === 'invoice') {
      this.changeCloneHaulRate();
    }
  }
  getCoord(which: string, index: number) {
    let defaultCoords = [
      get(this.defaultLocation, 'longitude', -97.6023238),
      get(this.defaultLocation, 'latitude', 30.2178214)
    ];
    return get(this.job, which + 'Location.location.coordinates[' + index + ']', defaultCoords[index]);
  }

  getPaths(which: string): any[] {
    let paths = [];
    let location = (new LocationSerializer).fromJson(get(this.job, which + 'Location'));

    if (location && location.geofence) {
      paths = location.geofence.coordinates[0].map((path) => {
        return { lat: path[1], lng: path[0] };
      });
    }

    return [paths];
  }

  getProductItems() {
    this.productItemsService.list({}).subscribe(
      (products) => this.materialDropdownOptions = products.filter(product => product.description)
    );
  }

  materialDropdownNextPage() {
    this.materialDropdownConfig.loadingOptions = true;
    const next = this.productItemsService.listNext();
    if (next) {
      next.subscribe(
        (products) => {
          this.materialDropdownOptions.concat(products.filter(product => product.description));
          this.materialDropdownConfig.loadingOptions = false;
        }
      );
    } else {
      this.materialDropdownConfig.loadingOptions = false;
    }
  }

  tagChange(tags: Tag[]): void {
    this.job['tags'] = tags.map(t => (t.name));
  }

  getWorkOrderTemplates(query = {}, next = false): void {
    if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
      this.workOrderTemplatesReq.unsubscribe();
    }

    let request = this.workOrderTemplateService.list(query);
    if (next) {
      request = this.workOrderTemplateService.listNext();
    } else {
      this.workOrderTemplateOptions = [];
    }
    if (request) {
      this.workOrderTemplatesReq = request.subscribe(res => {
        this.workOrderTemplateOptions = this.workOrderTemplateOptions.concat(res);
      }, err => {
        this.errors = err;
      });
    }
  }

  truckTypeChange(e): void {
    if (e.target && e.target.name === 'anyTruckType') {
      each(this.truckTypeMap, (val, key) => {
        this.truckTypeMap[key] = false;
      });
      this.job.truckTypes = this.truckTypes;
      this.job.allowAnyTruckType = true;
    } else {
      this.job.allowAnyTruckType = false;
      this.truckTypeMap[e.target.value] = e.target.checked;
      const truckTypeIds = filter(keys(this.truckTypeMap), key => this.truckTypeMap[key]);
      this.job.truckTypes = filter(this.truckTypes, truckType => truckTypeIds.includes(truckType.id));
    }
    if (!this.job.truckTypes || !this.job.truckTypes.length) {
      this.job.allowAnyTruckType = true;
      this.job.truckTypes = this.truckTypes;
    }
  }

  dateToDatepickerFormat(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  onDateChanged(values: Date[]): void {
    this.job.dates = values;
  }

  changeLoadingLocation(location, place = 'start') {
    if (location['id'] === 'new-loading-location' || location['id'] === 'new-unloading-location') {
      // deselect
      location.selected = false;
      const dialog = this.dialog.open(NewLocationDialogComponent, {
        width: '100%',
        height: '100%',
        disableClose: true
      });
      this.tagUrlWith('new-location');
      dialog.afterClosed().subscribe(() => {
        this.tagUrlWith(null);
      });
      dialog.componentInstance.callback = (newLocation) => {
        newLocation.displayName = newLocation.street ? (newLocation.displayName + ' - ' + newLocation.street) : newLocation.displayName;

        if (place === 'start') {
          this.loadingLocations.forEach((_location) => {
            _location['selected'] = false;
          });
          newLocation.selected = true;
          this.loadingLocations.push(newLocation);
          if (this.startLocationDropdown) {
            this.startLocationDropdown.toggleOption(this.loadingLocations.find(l => (l.id === newLocation.id)), false);
          }

          let _newLocation = clone(newLocation);
          _newLocation.selected = false;

          this.unloadingLocations.push(_newLocation);
        } else {
          this.unloadingLocations.forEach((_location) => {
            _location['selected'] = false;
          });
          newLocation.selected = true;
          this.unloadingLocations.push(newLocation);
          if (this.endLocationDropdown) {
            this.endLocationDropdown.toggleOption(this.unloadingLocations.find(l => (l.id === newLocation.id)), false);
          }

          let _newLocation = clone(newLocation);
          _newLocation.selected = false;

          this.loadingLocations.push(_newLocation);
        }

        this.job[place + 'Location'] = newLocation;
      };
    } else {
      this.job[place + 'Location'] = location;
    }
  }

  changeUnloadingLocation(location) {
    this.changeLoadingLocation(location, 'end');
  }

  editLocation(location, place = 'start'): void {
    location.selected = false;
    const dialog = this.dialog.open(EditLocationDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true,
      data: { locationId: location && location.id }
    });
    this.tagUrlWith('edit-location');
    dialog.afterClosed().subscribe(() => {
      this.tagUrlWith(null);
    });
    dialog.componentInstance.callback = (editLocation) => {
      editLocation.displayName = editLocation.street ? (editLocation.displayName + ' - ' + editLocation.street) : editLocation.displayName;

      if (place === 'start') {
        this.loadingLocations.forEach((_location) => {
          _location['selected'] = false;
        });
        editLocation.selected = true;
        this.loadingLocations.push(editLocation);
        if (this.startLocationDropdown) {
          this.startLocationDropdown.toggleOption(this.loadingLocations.find(l => (l.id === editLocation.id)), false);
        }

        let _editLocation = clone(editLocation);
        _editLocation.selected = false;

        this.unloadingLocations.push(_editLocation);
      } else {
        this.unloadingLocations.forEach((_location) => {
          _location['selected'] = false;
        });
        editLocation.selected = true;
        this.unloadingLocations.push(editLocation);
        if (this.endLocationDropdown) {
          this.endLocationDropdown.toggleOption(this.unloadingLocations.find(l => (l.id === editLocation.id)), false);
        }

        let _editLocation = clone(editLocation);
        _editLocation.selected = false;

        this.loadingLocations.push(_editLocation);
      }

      this.job[place + 'Location'] = editLocation;
    };
  }

  tagUrlWith(fragment: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams
      },
      fragment: fragment
    });
  }

  invoiceTypeChanged(): void {
    this.changeCloneHaulRate();
    this.jobTypeChanged();
  }

  haulTypeChanged(): void {
    this.jobTypeChanged();
  }

  changeCloneHaulRate(): void {
    if (this['cloneHaulRate']) {
      this.job['haulType'] = this.job.invoiceType;
      this.job['haulWeightUnit'] = this.job['invoiceWeightUnit'];
      this.job['haulRate'] = this.job['rate'];
    }
  }

  locationDropdownSearch(place, term: string): void {
    if (this.searchReq) { this.searchReq.unsubscribe(); }
    let optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
    this.locationsDropdownConfig[place].loadingOptions = true;
    this.searchReq = this.locationService.list({
      search: term,
      archived: 'False'
    }).subscribe((res) => {
      this[optionsKey].splice(0, this[optionsKey].length);
      if (optionsKey === 'loadingLocations') {
        this[optionsKey] = [{
          displayName: 'Add a New Address',
          id: 'new-loading-location',
          button: true
        }];
      } else if (optionsKey === 'unloadingLocations') {
        this[optionsKey] = [{
          displayName: 'Add a New Address',
          id: 'new-unloading-location',
          button: true
        }];
      }
      this[optionsKey] = this[optionsKey].concat(res);
      this.locationsDropdownConfig[place].loadingOptions = false;
    });
  }

  locationDropdownNextPage(place): void {
    let optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
    this.locationsDropdownConfig[place].loadingOptions = true;
    let next = this.locationService.listNext();
    if (next) {
      next.subscribe(res => {
        this[optionsKey] = this[optionsKey].concat(res);
        this.locationsDropdownConfig[place].loadingOptions = false;
      });
    } else {
      this.locationsDropdownConfig[place].loadingOptions = false;
    }
  }

  updateOptionsFromOrgFeatureFlag(organization) {
    if (organization.features && organization.features.checkinOptions) {
      this.job['checkinOptions']['activeTrackingConfig'] = organization.features.checkinOptions['activeTrackingConfig'];
      this.job['checkinOptions']['qrConfig'] = organization.features.checkinOptions['qrConfig'];
      this.job['checkinOptions']['weightConfig'] = organization.features.checkinOptions['weightConfig'];
      this.job['checkinOptions']['loadImageConfig'] = organization.features.checkinOptions['loadImageConfig'];
      this.job['checkinOptions']['ticketImageConfig'] = organization.features.checkinOptions['ticketImageConfig'];
      this.job['checkinOptions']['ticketNumberConfig'] = organization.features.checkinOptions['ticketNumberConfig'];
      this.job['checkinOptions']['signatureImageConfig'] = organization.features.checkinOptions['signatureImageConfig'];
    }

    if (organization.features && organization.features.checkoutOptions) {
      this.job['checkoutOptions']['activeTrackingConfig'] = organization.features.checkoutOptions['activeTrackingConfig'];
      this.job['checkoutOptions']['qrConfig'] = organization.features.checkoutOptions['qrConfig'];
      this.job['checkoutOptions']['weightConfig'] = organization.features.checkoutOptions['weightConfig'];
      this.job['checkoutOptions']['loadImageConfig'] = organization.features.checkoutOptions['loadImageConfig'];
      this.job['checkoutOptions']['ticketImageConfig'] = organization.features.checkoutOptions['ticketImageConfig'];
      this.job['checkoutOptions']['ticketNumberConfig'] = organization.features.checkoutOptions['ticketNumberConfig'];
      this.job['checkoutOptions']['signatureImageConfig'] = organization.features.checkoutOptions['signatureImageConfig'];
    }
  }


  jobTypeChanged() {
    if (this.job && this.job.qrJobType === 'loading_import') {
      if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
        this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
      } else {
        this.job.checkinOptions.ticketNumberConfig = 'required';
        this.job.checkinOptions.ticketImageConfig = 'required';
        this.job.checkinOptions.qrConfig = 'optional';
      }
      if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
        this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
      } else {
        this.job.checkoutOptions.ticketNumberConfig = 'optional';
        this.job.checkoutOptions.ticketImageConfig = 'optional';
        this.job.checkoutOptions.qrConfig = 'required';
      }
      this.job.checkinOptions.states['ticket_number'] = true;
      this.job.checkinOptions.states['ticket_image'] = true;
      this.job.checkinOptions.states['signature_image'] = false;
      this.job.checkinOptions.states['active_tracking'] = false;
      this.job.checkinOptions.states['log_weight'] = false;
      this.job.checkinOptions.states['qr_code'] = true;
      this.job.checkoutOptions.states['ticket_number'] = false;
      this.job.checkoutOptions.states['ticket_image'] = true;
      this.job.checkoutOptions.states['signature_image'] = false;
      this.job.checkoutOptions.states['active_tracking'] = false;
      this.job.checkoutOptions.states['log_weight'] = true;
      this.job.checkoutOptions.states['qr_code'] = true;
    } else if (this.job && this.job.qrJobType === 'unloading_import') {
      if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
        this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
      } else {
        this.job.checkinOptions.ticketNumberConfig = 'optional';
        this.job.checkinOptions.ticketImageConfig = 'optional';
        this.job.checkinOptions.weightConfig = 'optional';
        this.job.checkinOptions.qrConfig = 'optional';
      }
      if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
        this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
      } else {
        this.job.checkoutOptions.ticketNumberConfig = 'optional';
        this.job.checkoutOptions.ticketImageConfig = 'optional';
        this.job.checkoutOptions.weightConfig = 'optional';
        this.job.checkoutOptions.qrConfig = 'required';
      }
      this.job.checkinOptions.states['ticket_number'] = true;
      this.job.checkinOptions.states['ticket_image'] = true;
      this.job.checkinOptions.states['signature_image'] = false;
      this.job.checkinOptions.states['active_tracking'] = false;
      this.job.checkinOptions.states['log_weight'] = true;
      this.job.checkinOptions.states['qr_code'] = true;
      this.job.checkoutOptions.states['ticket_number'] = true;
      this.job.checkoutOptions.states['ticket_image'] = true;
      this.job.checkoutOptions.states['signature_image'] = false;
      this.job.checkoutOptions.states['active_tracking'] = false;
      this.job.checkoutOptions.states['log_weight'] = true;
      this.job.checkoutOptions.states['qr_code'] = true;
    } else if (this.job && this.job.qrJobType === 'export') {
      if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
        this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
      } else {
        this.job.checkinOptions.ticketNumberConfig = 'optional';
        this.job.checkinOptions.ticketImageConfig = 'optional';
        this.job.checkinOptions.weightConfig = 'optional';
        this.job.checkinOptions.qrConfig = 'required';
      }
      if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
        this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
      } else {
        this.job.checkoutOptions.qrConfig = 'optional';
      }
      this.job.checkinOptions.states['ticket_number'] = true;
      this.job.checkinOptions.states['ticket_image'] = true;
      this.job.checkinOptions.states['signature_image'] = false;
      this.job.checkinOptions.states['active_tracking'] = false;
      this.job.checkinOptions.states['log_weight'] = true;
      this.job.checkinOptions.states['qr_code'] = true;
      this.job.checkoutOptions.states['ticket_number'] = false;
      this.job.checkoutOptions.states['ticket_image'] = false;
      this.job.checkoutOptions.states['signature_image'] = false;
      this.job.checkoutOptions.states['active_tracking'] = false;
      this.job.checkoutOptions.states['log_weight'] = false;
      this.job.checkoutOptions.states['qr_code'] = true;
    } else {
      if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
        this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
      } else {
        this.job.checkinOptions.qrConfig = 'optional';
      }
      if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
        this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
      } else {
        this.job.checkoutOptions.qrConfig = 'optional';
      }
      this.job.checkinOptions.states['ticket_number'] = false;
      this.job.checkinOptions.states['ticket_image'] = false;
      this.job.checkinOptions.states['signature_image'] = false;
      this.job.checkinOptions.states['active_tracking'] = false;
      this.job.checkinOptions.states['log_weight'] = false;
      this.job.checkinOptions.states['qr_code'] = true;
      this.job.checkoutOptions.states['ticket_number'] = false;
      this.job.checkoutOptions.states['ticket_image'] = false;
      this.job.checkoutOptions.states['signature_image'] = false;
      this.job.checkoutOptions.states['active_tracking'] = false;
      this.job.checkoutOptions.states['log_weight'] = false;
      this.job.checkoutOptions.states['qr_code'] = true;
    }
  }

  isValid(): boolean {
    let timeValid = true;
    if (this.job.dates && this.job.dates.length > 0) {
      timeValid = this.job.shift1StartTime ? true : false;
    }
    let amountNeededValue = true;
    if (this.job['amountNeeded']) {
      this.job['amountNeeded'] = this.job['amountNeeded'] && this.job['amountNeeded'].replace(/[^\d.]/g, '');
      amountNeededValue = (Number(this.job['amountNeeded']) >= 0) ? true : false;
    }
    return !!this.job['name'] && this.job['material'] &&
      this.job.rate !== null && this.job.rate !== undefined && parseFloat(this.job.rate) >= 0 &&
      (this.cloneHaulRate || (this.job.haulRate !== null && this.job.haulRate !== undefined && parseFloat(this.job.haulRate) >= 0)) &&
      timeValid && amountNeededValue &&
      get(this, 'job.startLocation.id');
  }

  submit(dispatch = false): void {
    if (this.loading || !this.isValid()) { return; }
    this.loading = true;
    // Convert shift times to 24-hour format
    if (this.job['shift1StartTime']) {
      this.job['shift1StartTime'] = moment(this.job['shift1StartTime'], ['h:mm A']).format('HH:mm');
      this.job['shift1EndTime'] = moment(this.job['shift1EndTime'], ['h:mm A']).format('HH:mm');
    }

    let _job = cloneDeep(this.job);

    if (
      this.newProject || !this.projectControl || !this.projectControl.value ||
      (this.projectControl.value.name !== _job.project.name)
    ) {
      delete _job.project.id;
      if (this.projectControl && this.projectControl.value && this.projectControl.value.name) {
        _job.project.name = this.projectControl.value.name;
      } else {
        _job.project.name = this.projectControl.value;
      }
      // TODO(jlee): Remove after migration to new processes
      if (_job.project && _job.customerOrganization && _job.customerOrganization.id) {
        _job.project.customerOrganization = _job.customerOrganization;
      }
      this.projectService.save(_job.project).pipe(
        mergeMap(project => {
          _job.project = project;
          this.newProject = false;
          return this.jobService.save(_job);
        })
      ).subscribe((res) => {
        this.loading = false;
        this.routeAfterSave(res, dispatch);
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    } else {
      // TODO(jlee): Remove after migration to new processes
      if (_job.project && _job.customerOrganization && _job.customerOrganization.id) {
        _job.project.customerOrganization = _job.customerOrganization;
      }
      this.jobService.save(_job).subscribe(res => {
        this.loading = false;
        this.routeAfterSave(res, dispatch);
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
      });
    }
  }

  handleMaterial(material: string, materialCode?: string) {
    this.job.material = material;
    this.job.materialCode = materialCode ? materialCode : null;
  }

  routeAfterSave(job: Job, dispatch: boolean): void {
    if (dispatch) {
      this.router.navigate(['/dispatch', job.id]);
    } else if (this.returnTo && this.returnTo.length) {
      if (this.returnTo.includes('?')) {
        let route = this.returnTo.split('?')[0];
        let params = {};
        this.returnTo.split('?')[1].split('&').forEach(param => {
          let paramData = param.split('=');
          params[paramData[0]] = paramData[1];
        });
        this.router.navigate([route], { queryParams: params });
      } else { this.router.navigate([this.returnTo]); }
    } else if (this.device.mobile || this.device.tablet) {
      this.router.navigate(['/dispatch']);
    } else {
      this.router.navigate(['/daily']);
    }
  }

  selectProject(project: Project): void {
    this.job.project = project;
    this.projectControl.setValue(this.job.project);
    this.projectValueSet = true;
  }

  loadProjectOptions(): void {
    this.allSubscriptionsToUnsubscribe.push(
      this.projectControl.valueChanges.pipe(
        startWith<string | Project>(''),
        debounceTime(300),
        map(value => typeof value === 'string' ? value : value.name)
      ).subscribe(term => {
        this.projectsReq = this.projectService.list({
          ordering: 'name',
          search: term
        }).subscribe(projects => {
          this.projectOptions = projects.map(project => {
            return { name: project.name, id: project.id };
          });

          if (this.job.project && !this.projectValueSet) {
            let project = _find(this.projectOptions, { id: this.job.project.id });
            if (project) {
              this.selectProject(project);
              this.projectOptions = reject(this.projectOptions, project);
              this.projectOptions.unshift(project);
            }
          }
        }, err => {
          this.errors = parseErrors(err);
        });
      })
    );
  }

  loadProjectsOnScroll(event): void {
    if (event.target.scrollTop > 300) {
      let o = this.projectService.listNext();
      if (o) {
        o.subscribe(projects => {
          this.projectOptions = this.projectOptions.concat(projects.map(project => {
            return { name: project.name, id: project.id };
          }));
          this.customerDropdownConfig.loadingOptions = false;
        }, err => {
          this.errors = parseErrors(err);
        });
      }
    }
  }

  projectScrollEvent(): void {
    if (this.projectAutocomplete && this.projectAutocomplete.panel) {
      const dropdown = this.projectAutocomplete.panel.nativeElement;
      dropdown.addEventListener('scroll', event => this.loadProjectsOnScroll(event));
    }
  }

  projectDisplayFn(project?: Project): string | undefined {
    return project ? project.name : undefined;
  }

  selectCustomer(customer: Organization): void {
    if (customer.name === 'New Customer') {
      this.openNewCustomer();
    } else {
      this.job.customerOrganization = customer;
    }
  }

  reloadCustomerOptions(query = {}, append = false): void {
    if (!append) {
      if (this.device.mobile || this.device.tablet) {
        this.customerOptions = [];
      } else {
        this.customerOptions = [{ name: 'New Customer', id: 'new-customer', button: true }];
      }
    }
    this.customerLoading = true;
    if (this.connectionsReq) {
      this.connectionsReq.unsubscribe();
    }
    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      search: this.customerSearch
    }).subscribe(connections => {
      if (append) {
        this.customerOptions = this.customerOptions.concat(connections.map(connection => {
          return new Organization({ name: connection.organization.name, id: connection.organization.id });
        }));
      } else {
        this.customerOptions = this.customerOptions.concat(connections.map(connection => {
          return new Organization({ name: connection.organization.name, id: connection.organization.id });
        }));
      }
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    });
  }

  customerDropdownSearch(term: string): void {
    this.customerOptions = [];
    this.customerDropdownConfig.loadingOptions = true;
    this.customerSearch = term;
    this.reloadCustomerOptions();
    this.customerDropdownConfig.loadingOptions = false;
  }

  customerDropdownNextPage(): void {
    if (!this.customerDropdownConfig.loadingOptions) {
      let o = this.connectionService.listNext();
      if (o) {
        this.customerDropdownConfig.loadingOptions = true;
        o.subscribe(connections => {
          this.customerOptions = this.customerOptions.concat(connections.map(connection => {
            return { name: connection.organization.name, id: connection.organization.id };
          }));
          this.customerDropdownConfig.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          this.customerDropdownConfig.loadingOptions = false;
        });
      }
    }
  }

  openNewCustomer(): void {
    this.customerDialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: '444px'
    });
    this.customerDialogRef.componentInstance.callback = this.saveCustomerCallback;
    this.customerDialogRef.afterClosed().subscribe((result: string) => {
      if (this.job.customerOrganization) {
        this.selectCustomer(
          new Organization({
            name: this.job.customerOrganization.name,
            id: this.job.customerOrganization.id
          })
        );
      } else {
        this.customerDropdown.deselectAll();
      }
    });
  }

  onSelect(dropdownType: string, e): void {
    switch (dropdownType) {
      case 'workOrderTemplate':
        this.job.workOrderTemplate = e;
        break;
    }
    this.job[dropdownType] = e;
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'workOrderTemplate':
        this.getWorkOrderTemplates({ search: term });
        break;
    }
  }

  dropdownNextPage(type: string, e): void {
    let config, service, options, o;

    switch (type) {
      case 'workOrderTemplate':
        config = this.workOrderTemplateDropdownConfig;
        service = this.workOrderTemplateService;
        options = this.workOrderTemplateOptions;
        o = service.listNext();
        break;
    }

    if (!config.loadingOptions) {
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'workOrderTemplate':
              this.workOrderTemplateOptions = this.workOrderTemplateOptions.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  getJobDays(futureDatesOnly = true): void {
    if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
      this.jobDaysReq.unsubscribe();
    }

    this.loading = true;
    this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(days => {
      if (futureDatesOnly) {
        days = days.filter(day => moment(day).toDate() >= moment().startOf('day').toDate());
      }
      this.job.dates = days.map(day => moment(day).toDate());
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  copyToJob(job: Job): void {
    this.job = cloneDeep(job);
    this.getJobDays();
    this.job.id = null;
    this.tagInput.forceSelectedTags(this.job.tags);
    if (this.job.haulRate === this.job.rate &&
      this.job.haulType === this.job.invoiceType &&
      this.job.haulWeightUnit === this.job.invoiceWeightUnit) {
      this['cloneHaulRate'] = true;
    }
    this.setStartLocation();
    this.setEndLocation();
    if (job.project && job.project.id) { this.selectProject(job.project); }
    if (!job.allowAnyTruckType) {
      each(this.truckTypes, (val) => { this.truckTypeMap[val.id] = false; });
      each(job.truckTypes, (val) => { this.truckTypeMap[val.id] = true; });
    }
    this.jobTypeChanged();
  }

  setStartLocation(): void {
    if (this.job && this.job.startLocation && this.job.startLocation.id) {
      let _location = _find(this.loadingLocations, { id: this.job.startLocation.id });
      if (_location) {
        this.loadingLocations = reject(this.loadingLocations, _location);
      } else {
        _location = { id: this.job.startLocation.id, displayName: this.job.startLocation.displayName, button: false };
      }

      this.loadingLocations.unshift(_location);
      this.startLocationDropdown.selectedOption = _location;
    }
  }

  setEndLocation(): void {
    if (this.job && this.job.endLocation && this.job.endLocation.id) {
      let _location = _find(this.unloadingLocations, { id: this.job.endLocation.id });
      if (_location) {
        this.unloadingLocations = reject(this.unloadingLocations, _location);
      } else {
        _location = { id: this.job.endLocation.id, displayName: this.job.endLocation.displayName, button: false };
      }

      this.unloadingLocations.unshift(_location);
      this.endLocationDropdown.selectedOption = _location;
    }
  }

  checkIfOvernight(shift: string): void {
    if (shift === 'shift1' && this.job.shift1StartTime && this.job.shift1EndTime) {
      const startTime = moment(this.job.shift1StartTime, 'h:mm a');
      const endTime = moment(this.job.shift1EndTime, 'h:mm a');
      this.job.shift1Overnight = endTime.isBefore(startTime);
    }
  }

  getPreferences(): void {
    this.preferenceService.list({
      name: 'GeneralOrganizationPreferences',
      type: 'organization',
      organization: this.user && this.user.organization && this.user.organization.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        const preference = preferences[0];
        this.preference = preference;
        if ((this.user.organization.features && this.user.organization.features.checkinOptions === undefined) && preference.blob) {
          this.user.organization.features.checkinOptions = preference.blob.checkinOptions;
        }
        if ((this.user.organization.features && this.user.organization.features.checkoutOptions === undefined) && preference.blob) {
          this.user.organization.features.checkoutOptions = preference.blob.checkoutOptions;
        }
      }
      this.jobTypeChanged();
    },
    // error
    () => {},
    // finally
    () => {
      this.loadOrganizationDefaults();
    });
  }

  loadOrganizationDefaults(): void {
    if (this.user && this.user.organization) {
      this.job.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
      this.job.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
      this.updateOptionsFromOrgFeatureFlag(this.user.organization);
    }
  }
}
