<form #filterJobs="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Jobs</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group start-date">
          <label translate>Start Date</label>
          <date-range-picker [selectedDates]="[model.startDate]"
                             (dateChanged)="onDateChanged($event, 'startDate')">
          </date-range-picker>
        </div>
        <div class="field-group end-date">
          <label translate>End Date</label>
          <date-range-picker [selectedDates]="[model.endDate]"
                             (dateChanged)="onDateChanged($event, 'endDate')">
          </date-range-picker>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group project-name">
          <label translate>Project</label>
          <ruckit-dropdown #projectsDropdown
            [config]="projectsConfig"
            [selectedOption]="model.project && model.project.id"
            (onSelect)="onSelect('project', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group customer-name">
          <label translate>Customer</label>
          <ruckit-dropdown #customersDropdown
            [config]="customersConfig"
            [selectedOption]="model.customer && model.customer.id"
            (onSelect)="onSelect('customer', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group order-number">
          <label translate>Order Number</label>
          <input type="text" name="order-number" [(ngModel)]="model.orderNumber" #orderNumber="ngModel" />
        </div>
      </div>

      <div class="field-row">
        <div class="field-group job-number">
          <label translate>Job #</label>
          <input type="text" name="job-number" [(ngModel)]="model.jobNumber" />
        </div>
      </div>

      <div class="field-row">
        <div class="field-group tags">
          <label translate>Markets</label>
          <ruckit-dropdown #marketsDropdown
            [config]="marketsConfig"
            [selectedItems]="model.tags"
            (onSelect)="onSelect('tags', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div *ngFor="let field of customFields" class="field-row">
        <div class="field-group tags">
          <label translate>{{ field.displayName }}</label>
          <div class="checkbox-input-group">
            <input type="text" name="{{ field.key }}" (change)="onSelect(field.key, $event.target.value)"/>
            <mat-checkbox [checked]="this.model[field.key] && this.model[field.key] === 'None'" (change)="onSelect(field.key, 'None')">None</mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
