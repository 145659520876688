<div class="app-content">
  <my-toolbar title="New Project"></my-toolbar>

  <div class="field-column">
    <div class="new-project-form" *ngIf="!loading">
      <form #newProject="ngForm">
        <h1 i18n='Add project header'>Project Details</h1>
        <p i18n='Add project description'>
          Set up the information for your new project. You'll create jobs under your projects to keep all of your work organized.
        </p>
        <div class="body form-content">
          <div class="field-row">
            <div class="field-group project-title">
              <label class="required">Project Title</label>
              <input type="text" name="project-title" placeholder="Project Title" required [(ngModel)]="model.name" #title="ngModel" />
              <span [ngClass]="{'ng-valid': title.valid, 'ng-invalid': title.invalid, 'ng-pristine': title.pristine}"></span>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group customer">
              <label class="required">Customer</label>
              <input type="hidden" name="customer" required [(ngModel)]="model.customer" #customer="ngModel" />
              <dropdown #customerDropdown required [options]="customerOptions" [selectedOption]="model.customer"
                [config]="customerDropdownConfig"
                (onSelect)="selectCustomer($event)"
                (onSearch)="customerDropdownSearch($event)"
                (nextPage)="customerDropdownNextPage($event)"
                title="Select Customer"></dropdown>
            </div>
          </div>

          <notification *ngFor="let error of errors">{{error}}</notification>

          <div class="field-row actions">
            <button class="btn btn-light create" (click)="newProject.form.valid && submit(); false" [disabled]="!newProject.form.valid || loading" [ngClass]="{loading: loading}">Create and Skip Job</button>
            <button class="btn btn-primary create-continue" (click)="newProject.form.valid && submitAndContinue(); false" [disabled]="!newProject.form.valid || loading" [ngClass]="{loading: loading}">Continue to Job</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
