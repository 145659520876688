import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DriversComponent } from '../drivers/drivers.component';
import { TrucksComponent } from '../trucks/trucks.component';
import { View } from '../shared/view/view';

/**
 *  Fleet Component: Requests and displays navigation links and,
 * through the {@link DriversComponent} or {@link TrucksComponent}, the fleet associated with the
 * selected .
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <fleet></fleet>
 */
@Component({
  selector: 'fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
  providers: []
})
export class FleetComponent implements OnInit {
  view: View = { stub: 'drivers', title: 'Drivers' };
  loading = true;
  errors = [];
  search = '';
  query = {};
  displayedColumns: string[] = [
    'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets',
    'action'
  ];
  @ViewChild('driverTableWrapper') driverTableWrapper: DriversComponent;
  @ViewChild('truckTableWrapper') truckTableWrapper: TrucksComponent;

  /**
   * An anonymous callback function to call the FancyTable's `refreshTable()`
   * function that is called when drivers are created.
   */
  saveDriverCallback = () => {
    if (this.driverTableWrapper) { this.driverTableWrapper.refreshTable(); }
  }

  saveTruckCallback = () => {
    if (this.truckTableWrapper) { this.truckTableWrapper.refreshTable(); }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  /**
  * Examines the route and query parameters to determine the  Type and
  * persisted search value.
  *
  * If the route includes `accounts` the `customer_only: True` filter is added
  * to the query.
  *
  * If the `account` has an {@link Organization} with a {@link Carrier} the
  * `carrier: CARRIER_ID` filter is added to the query.
  */
  ngOnInit() {
    if (this.route && this.route.snapshot && this.route.snapshot.url.length) {
      this.view = <View>this.route.snapshot.data['view'];
      this.displayedColumns = this.view.stub === 'drivers' ? [
        'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets', 'status', 'action'
      ] : [
        'select', 'truck-type', 'name', 'license-plate', 'markets', 'status', 'action'
      ];
    }
  }

  ngAfterViewInit(): void {
    if (this.view.type && this.view.type === 'archive') {
      this.query = {...this.query, ...{status: 'deleted'}};
      this.updateRowActions('archive');
    } else {
      this.query = {...this.query, ...{status: 'active'}};
      this.updateRowActions('main');
    }
  }

  /**
   * Handles changing to and from the archive view, navigating to the correct url
   *
   * @param {boolean} view Should the view be changed to archive or not
   */
  viewArchive(view: boolean) {
    const routeList = view ? ['/', this.view.stub, 'archive'] : ['/', this.view.stub];
    this.router.navigate(routeList, {relativeTo: this.route});
  }

  updatedMarkets() {
    if (this.driverTableWrapper) { this.driverTableWrapper.refreshTable(); }
    if (this.truckTableWrapper) { this.truckTableWrapper.refreshTable(); }
  }

  updateRowActions(type: 'main' | 'archive') {
    if (type === 'main') {
      if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
        this.driverTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Edit', action: 'edit', link: false, external: false },
          { name: 'View Driver Profile', action: 'details', link: false, external: false }
        ];
      }
      if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
        this.truckTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Edit', action: 'edit', link: false },
          { name: 'Remove', action: 'remove', link: false, external: false }
        ];
      }
    } else {
      if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
        this.driverTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Reactivate', action: 'unarchive', link: false, external: false },
          { name: 'View Driver Profile', action: 'details', link: false, external: false }
        ];
      }
      if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
        this.truckTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Reactivate', action: 'unarchive', link: false, external: false }
        ];
      }
    }
  }
}
