import {
  Component, ElementRef, OnInit, Input, Output, EventEmitter, ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { find as _find } from 'lodash';
import { Router } from '@angular/router';

import { CollaboratorService } from '../collaborators/collaborator.service';
import { parseErrors } from '../shared/api.service';
import { User } from '../users/user';
import { AuthenticationService } from '../shared';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Device } from '../shared/device';

@Component({
  selector: 'accept-job-dialog',
  templateUrl: './accept-job-dialog.component.html',
  styleUrls: ['./accept-job-dialog.component.scss']
})
export class AcceptJobDialogComponent implements OnInit {
  device: Device = new Device();
  @Input() invitation: any = {};
  @Output() completed = new EventEmitter();
  user: User;
  hasSignature = false;
  loading = false;
  confirmDeny = false;
  errors = [];
  weightUnits = [
    {name: 'Tons', id: 'ton'},
    {name: 'Metric Tons', id: 'metric-tons'},
    {name: 'Yards', id: 'yard'},
  ];
  weightUnitsConfig = {
    nameProperty: 'name'
  };
  haulTypeOption;
  callback;
  projectsReq;

  model = {
    id: null,
    haulWeightUnit: null,
    haulRate: null,
    haulType: 'weight',
    status: 'pending',
    allDays: null,
    confirmedTrucks: null
  };

  @ViewChild('weightUnitDropdown', { static: true }) weightUnitDropdown;

  constructor(
    public dialogRef: MatDialogRef<AcceptJobDialogComponent>,
    private collaboratorService: CollaboratorService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private elementRef: ElementRef,
    private deviceDetectorService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.model.id = this.invitation.id;
    this.model.haulType = this.invitation.invoiceType;
    this.setWeightUnitOptions(this.model.haulType);
    this.model.haulWeightUnit = this.invitation.invoiceWeightUnit;
    this.user = this.authenticationService.user();
    this.hasSignature = this.authenticationService.hasSignature();
  }

  submit(allDays = false) {
    this.loading = true;
    if (allDays) {
      this.model['allDays'] = 'True';
    } else {
      delete this.model['allDays'];
    }
    this.collaboratorService.accept(this.model).subscribe(result => {
      this.loading = false;
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  deny() {
    this.loading = true;
    if (this.confirmDeny) {
      this.collaboratorService.reject(this.invitation.id).subscribe(result => {
        this.loading = false;
        this.confirmDeny = false;
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
      });
    } else {
      this.loading = false;
      this.confirmDeny = true;
    }
  }

  selectWeightUnit(e, form: NgForm) {
    this.model.haulWeightUnit = e.id;
    form.controls['weight_unit'].markAsDirty();
  }

  changeHaulRateType(e, form: NgForm) {
    this.setWeightUnitOptions(this.model.haulType);
    form.controls['weight_unit'].markAsDirty();
  }

  setWeightUnitOptions(haulType) {
    if (!this.confirmDeny) {
      let values = [];
      switch (haulType) {
        case 'weight':
          values = [
            {name: 'Tons', id: 'ton'},
            {name: 'Metric Tons', id: 'metric-tons'},
            {name: 'Yards', id: 'yard'},
          ];
          break;
        case 'load':
          values = [{name: 'Load', id: 'load'}];
          break;
        case 'hour':
          values = [{name: 'Hour', id: 'hour'}];
          break;
      }

      this.weightUnits = values;
      this.haulTypeOption = _find(this.weightUnits, {
        id: this.invitation.invoiceRateUnit
      });
      if (this.weightUnitDropdown) {
        this.weightUnitDropdown.options = values;
        this.weightUnitDropdown.selectedOption = values[0];
        this.haulTypeOption = _find(this.weightUnits, {
          id: haulType === 'weight' ? 'ton' : haulType
        });
      }
    }
  }

  routeToSettings() {
    this.dialogRef.close();
    this.router.navigate(['/settings', 'user', this.user && this.user.id, 'work-orders']);
  }
}
