import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const editTrackableAnimation = trigger('statusAnimation', [
  state(
    'updated',
    style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })
  ),
  state(
    'removed',
    style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })
  ),
  transition('* => removed', [
    animate('300ms ease-out', style({ background: '#fbd0d1', opacity: 0 })),
  ]),
  transition('* => updated', [
    animate('300ms', style({ background: '#d4e6f7' })),
    animate('300ms ease-out', style({ background: '#FFFFFF' })),
  ]),
]);
