import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '../../shared/resource.service';
import { ProductItem } from './product-items';
import { ProductItemSerializer } from './product-items.serializer';

@Injectable({
  providedIn: 'root'
})
export class ProductItemsService extends ResourceService<ProductItem> {
  constructor(http: HttpClient) {
    super(http, 'productitems/', new ProductItemSerializer());
  }
}
