import {
  Component, ElementRef, OnInit, Inject, OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { JobEventService } from '../job-events/job-event.service';
import { AuthenticationService } from '../shared/authentication.service';
import { parseErrors } from '../shared/api.service';
import { JobEvent } from '../job-events/job-event';

@Component({
  selector: 'edit-slorder-id-dialog',
  templateUrl: './edit-slorder-id-dialog.component.html',
  styleUrls: ['./edit-slorder-id-dialog.component.scss']
})
export class EditSLOrderIDDialogComponent implements OnInit, OnDestroy {
  jobEvent: JobEvent;
  jobEventId: string;
  jobEventReq: Subscription;
  errors = [];
  loading = false;
  callback;

  constructor(
    public dialogRef: MatDialogRef<EditSLOrderIDDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobEventService: JobEventService
  ) {
    this.jobEvent = this.data && this.data.jobEvent;
    this.jobEventId = this.data && this.data.jobEventId;
  }

  ngOnInit() {
    if (this.jobEventId) {
      this.getJobEvent();
    }
  }

  ngOnDestroy() {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
  }

  getJobEvent(): void {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }

    this.loading = true;

    this.jobEventReq = this.jobEventService.get(this.jobEventId).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  submit(): void {
    this.jobEventService.save({
      id: this.jobEvent.id, slorder_id: this.jobEvent.slorderId
    }).subscribe(result => {
      this.loading = false;
      this.dialogRef.close();
    }, (err) => {
      this.errors = ['SLOrder ID could not be updated.'];
      this.errors.push(parseErrors(err));
      this.loading = false;
    });
  }
}
