import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { InvoiceTripsDialogComponent } from './invoice-trips-dialog.component';

@Component({
  selector: 'ruckit-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss'],
  providers: []
})
export class CreateInvoiceComponent implements OnInit {
  jobId;
  search = '';
  errors = [];
  addTripsCallback = (e) => {
    // Update trip data
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.jobId = params['jobId'];
    });
    this.route.queryParams.forEach((params: Params) => {
      this.search = params['search'] || '';
    });
    this.openAddTrips();
  }

  openAddTrips() {
    const dialog = this.dialog.open(InvoiceTripsDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true
    });
    // dialog.disableClose = true;
    dialog.componentInstance.jobId = this.jobId;
    dialog.componentInstance.search = this.search;
    dialog.componentInstance.callback = this.addTripsCallback;
  }
}
