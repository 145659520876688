<form #editLocation='ngForm'>
  <h2 translate>Enter Details and Draw Geofence</h2>
  <div class="instructions" *ngIf="includeInstructions">
    <p translate>To improve location accuracy and trip status times, draw a geofence around the location on the map to
      the right.</p>
    <img src="assets/img/location-geofence.gif" alt="{{ 'Geofencing steps' | translate }}" />
  </div>

  <div class="field-row">
    <div class="field-group street">
      <label translate>Address</label>
      <input places-auto-complete (place_changed)="placeChanged($event)" type="text" id="street" name="street"
        placeholder="{{ 'Address' | translate }}" autocomplete="off" autocorrect="off" autocapitalize="off"
        spellcheck="off" required (blur)="geolocate()" [(ngModel)]="location.street" #street="ngModel"
        [disabled]="loading" />
      <address-select [addresses]="addresses" [selectedAddress]="selectedAddress" [showingAddresses]="showingAddresses"
        (addressChanged)="updateAddress($event)"></address-select>
      <div (click)="copyUpdatedAddress()" class="update-address" translate *ngIf="address && address.street">
        <mat-icon>gps_fixed</mat-icon>Marker placed at new address, click here to update
      </div>
    </div>
  </div>

  <div class="field-row">
    <div class="field-group country">
      <label translate>Country</label>
      <select name="country" [disabled]="loading" required (change)="selectCountry($event.target.value)">
        <option value="" selected disabled hidden>{{ 'Select Country' | translate }}</option>
        <option *ngFor="let countryOption of countryOptions" [value]="countryOption.id" [selected]="countryOption.id === location.country">{{ countryOption.name }}</option>
      </select>
    </div>
    <div class="field-group state">
      <ng-container *ngIf="location.country !== 'CA'">
        <label translate>State</label>
        <select name="state" [disabled]="loading" required (change)="selectState($event.target.value)">
          <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
          <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === location.state">{{ stateOption.name }}</option>
        </select>
      </ng-container>
      <ng-container *ngIf="location.country === 'CA'">
        <label translate>Province</label>
        <select name="state" [disabled]="loading" required (change)="selectState($event.target.value)">
          <option value="" selected disabled hidden>{{ 'Select Province' | translate }}</option>
          <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === location.state">{{ stateOption.name }}</option>
        </select>
      </ng-container>
    </div>
  </div>

  <div class="field-row">
    <div class="field-group city">
      <ng-container *ngIf="location.country !== 'CA'">
        <label translate>City</label>
        <input type="text" [disabled]="loading" name="city" placeholder="{{ 'City' | translate }}" required [(ngModel)]="location.city" #city="ngModel" (blur)="geolocate()" />
        <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
      </ng-container>
      <ng-container *ngIf="location.country === 'CA'">
        <label translate>Municipality</label>
        <input type="text" [disabled]="loading" name="city" placeholder="{{ 'Municipality' | translate }}" required [(ngModel)]="location.city" #city="ngModel" (blur)="geolocate()" />
        <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
      </ng-container>
    </div>
    <div class="field-group zipcode">
      <ng-container *ngIf="location.country !== 'CA'">
        <label translate>Zip</label>
        <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Zip' | translate }}" required (blur)="geolocate()" [(ngModel)]="location.zipcode" #zipcode="ngModel" />
        <span [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
      </ng-container>
      <ng-container *ngIf="location.country === 'CA'">
        <label translate>Postal Code</label>
        <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Postal Code' | translate }}" required (blur)="geolocate()" [(ngModel)]="location.zipcode" #zipcode="ngModel" />
        <span [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
      </ng-container>
    </div>
  </div>


  <div class="field-row">
    <div class="field-group location-name">
      <label translate>Location Name</label>
      <input type="text" name="location-name" [disabled]="loading" placeholder="{{ 'Location Name' | translate }}"
        required [(ngModel)]="location.name" #name="ngModel" />
    </div>
  </div>

  <div class="field-row">
    <div class="field-group latitude">
      <label translate>Latitude</label>
      <input type="text" id="latitude" name="latitude" [(ngModel)]="location.latitude" #latitude="ngModel" />
    </div>
    <div class="field-group longitude">
      <label translate>Longitude</label>
      <input type="text" id="longitude" name="longitude" [(ngModel)]="location.longitude" #longitude="ngModel" />
    </div>
  </div>

  <div *ngIf="false" class="field-row">
    <div class="field-group">
      <label translate>Markets</label>
      <tag-input *ngIf="location && location.id"
                 name="tags" [selectedOptions]="location.tags"
                 (onSelect)="tagChange($event)"></tag-input>
    </div>
  </div>

  <div class="field-row">
    <div class="field-group location-type">
      <label translate>Select Location Type</label>
      <ruckit-dropdown [config]="locationTypeDropdownConfig"
        [selectedOption]="location.locationType && location.locationType.id"
        (onSelect)="onSelect('locationType', $event)">
      </ruckit-dropdown>
    </div>
  </div>

  <div class="field-row" *ngIf="qrEnabled || laserFicheEnabled">
      <div class="field-group location-owner" *ngIf="!showLocationOwnerTextField">
        <label translate>Select Owner</label>
        <dropdown title="{{ 'Location Owner' | translate }}" #ownersDropdown
                  [selectedOption]="location.locationOwner"
                  [options]="ownerOptions" (onSelect)="selectOwner($event)"
                  (onSearch)="dropdownSearch($event, 'owner')"
                  (nextPage)="dropdownNextPage($event, 'owner')"
                  [config]="ownersConfig"></dropdown>
      </div>
      <div class="field-group location-owner" *ngIf="showLocationOwnerTextField">
        <label translate>New Owner</label>
        <input type="text" id="location-owner" [disabled]="loading"
               name="location_owner" autocomplete="off" autocorrect="off"
               autocapitalize="off" spellcheck="off"
               [(ngModel)]="location.locationOwner.name"
               #locationOwnerName="ngModel" />
      </div>
    </div>

  <div class="field-row">
    <div class="field-group loading-target">
      <label translate>Loading Target Time</label>
      <input type="text" id="loading-target" [disabled]="loading" name="loading_target" autocomplete="off"
        autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="location.averageLoadingTime"
        #averageLoadingTime="ngModel" />
    </div>
    <div class="field-group unloading-target">
      <label translate>Unloading Target Time</label>
      <input type="text" id="unloading-target" [disabled]="loading" name="unloading_target" autocomplete="off"
        autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="location.averageUnloadingTime"
        #averageunLoadingTime="ngModel" />
    </div>
  </div>

  <div class="field-row">
    <div class="field-group action cancel">
      <button class="btn btn-default" (click)="cancel(); false" translate>
        Cancel
      </button>
    </div>
    <div class="field-group action clear">
      <button id="clear-geofence-button" class="btn btn-primary clear-geofence-btn" (click)="onClearGeoFence()" 
        [ngClass]="{loading:loading}" [disabled]="!isGeoFenceAvailable" translate>
        Clear Geofence
      </button>
    </div>
    <div class="field-group action save">
      <button id="new-location-button" class="btn btn-primary" (click)="editLocation.form.valid && submit(); false" [disabled]="!editLocation.form.valid || loading"
        [ngClass]="{loading:loading}" translate>
        Save Location
      </button>
    </div>
  </div>
</form>
