import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { SatDatepickerInputEvent } from 'saturn-datepicker';

import { Moment } from 'moment';
import * as moment from 'moment';

import { DateTimePickerInlineConfig, DEFAULT_DATETIMEPICKERINLINE_CONFIG } from './date-time-picker-inline-config';

const INPUT_TIME_FORMAT = 'HH:mm';

@Component({
  selector: 'date-time-picker-inline',
  templateUrl: './date-time-picker-inline.component.html',
  styleUrls: [ './date-time-picker-inline.component.scss' ]
})
export class DateTimePickerInlineComponent implements OnInit {
  @ViewChild(MatDatepicker) datePicker: MatDatepicker<Moment>;
  @Input() config: DateTimePickerInlineConfig;
  @Input() disabled: boolean;
  @Output() dateTimeChange: EventEmitter<string> = new EventEmitter<string>();

  dateTime: Moment | undefined;
  limitH: Moment | undefined;
  limitL: Moment | undefined;
  displayDate: string;
  displayTime: string;
  editMode = false;
  editDateTime: Moment;
  editTime: string;
  editDateDisplay: string;

  @Input() set value(value: string) { this.setValue(value); }

  ngOnInit(): void {
    if (!this.config) { this.config = DEFAULT_DATETIMEPICKERINLINE_CONFIG; }
    this.limitL = this.config.AbsoluteMin ? moment(this.config.AbsoluteMin) : undefined;
    this.limitH = this.config.AbsoluteMax ? moment(this.config.AbsoluteMax) : undefined;
  }

  onEditClick(): void {
    const editDateTime = this.dateTime ? this.dateTime.clone() : moment(new Date());
    this.setEditValue(editDateTime, true);
    this.editMode = true;
  }

  onSaveClick(): void {
    const value = this.editDateTime.toDate();
    value.setHours(+this.editTime.substr(0, 2), +this.editTime.substr(3, 2), 0, 0);

    // Emit updated value and rely on Input() value to be set externally for updating display
    this.dateTimeChange.emit(value.toISOString());
    this.closeForm();
  }

  onCancelClick(): void {
    this.closeForm();
  }

  adjustDate(numOfDays: number): void {
    this.setEditValue(this.editDateTime.add(numOfDays, 'day'));
  }

  onDateChange(event: SatDatepickerInputEvent<Moment>): void {
    this.setEditValue(event.value as Moment);
  }

  private setEditValue(value: Moment, initTime = false): void {
    if (initTime) { this.editTime = value.format(INPUT_TIME_FORMAT); }

    this.editDateTime = this.applyDateLimits(value);
    this.editDateDisplay = this.editDateTime.format(this.getDateFormat());
  }

  private setValue(value?: string | Date): void {
    this.dateTime = value ? moment(value) : undefined;
    if (this.dateTime && this.editMode) {
      // If the value is changed while being edited, update the edit time as well
      this.setEditValue(this.dateTime.clone(), true);
    }
    this.refreshDisplay();
  }

  private applyDateLimits(value: Moment): Moment {
    if (!value) { return undefined; }

    if (this.limitL && value.isBefore(this.limitL)) {
      return this.limitL.clone();
    }
    if (this.limitH && value.isAfter(this.limitH)) {
      return this.limitH.clone();
    }

    return value;
  }

  private refreshDisplay(): void {
    if (this.dateTime) {
      this.displayDate = this.dateTime.format(this.getDateFormat());
      this.displayTime = this.dateTime.format(this.getTimeFormat());
    }
  }

  private getDateFormat(): string {
    return this.config && this.config.DateFormat ? this.config.DateFormat : DEFAULT_DATETIMEPICKERINLINE_CONFIG.DateFormat;
  }

  private getTimeFormat(): string {
    return this.config && this.config.TimeFormat ? this.config.TimeFormat : DEFAULT_DATETIMEPICKERINLINE_CONFIG.TimeFormat;
  }

  private closeForm(): void {
    this.editMode = false;

    // Cleanup
    this.editTime = undefined;
    this.editDateTime = undefined;
    this.editDateDisplay = undefined;
  }
}
