<form #acceptJob="ngForm" [ngClass]="{'mobile': device.mobile}">
  <div mat-dialog-title class="modal-title">
    <h1>{{ invitation.job }}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div class="mat-dialog-content" *ngIf="!confirmDeny">
    <div *ngIf="errors && errors.length" class="errors">
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>

    <div class="content">
      <div class="job-parameters">
        <div class="form-content">
          <h2 translate>You are allowed {{ invitation.numTrucks || 0 }} trucks</h2>
          <p translate>Set a haul rate and approve before your team can share this job or dispatch it.</p>

          <div class="field-row">
            <div class="field-group invoice-type">
              <label class="required" translate>How is the haul rate tracked?</label>
              <div class="radio-buttons">
                <label *ngIf="invitation.allowWeight" attr.data-label="{{ 'Weight' | translate }}">
                  <input type="radio" name="haul-type" [(ngModel)]="model.haulType" value="weight"
                         (change)="changeHaulRateType($event, acceptJob)" />
                </label>
                <label attr.data-label="{{ 'Load' | translate }}">
                  <input type="radio" name="haul-type" [(ngModel)]="model.haulType" value="load"
                         (change)="changeHaulRateType($event, acceptJob)" />
                </label>
                <label attr.data-label="{{ 'Hour' | translate }}">
                  <input type="radio" name="haul-type" [(ngModel)]="model.haulType" value="hour"
                         (change)="changeHaulRateType($event, acceptJob)" />
                </label>
              </div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group haul-rate">
              <label class="required" translate>What is the haul rate?</label>
              <div class="input-group">
                <input type="text" name="haul-rate" required [(ngModel)]="model.haulRate"/>
                <div class="add-on">
                  <input type="hidden" name="weight_unit" [(ngModel)]="model.haulWeightUnit" />
                  <dropdown #weightUnitDropdown name="weight_unit" [options]="weightUnits" [config]="weightUnitsConfig"
                            [selectedOption]="haulTypeOption"
                            (onSelect)="selectWeightUnit($event, acceptJob)" title="-"></dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group confirmed-trucks">
              <label class="required" translate>How many trucks you can provide?</label>
              <div class="input-group">
                <input type="number" name="confirmed-trucks" required min="1" [(ngModel)]="model.confirmedTrucks" />
              </div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group notes">
              <label class="important" translate>Notes</label>
              <div class="value">{{ invitation.notes || '&mdash;' }}</div>
            </div>
          </div>

          <div class="field-row" *ngIf="invitation.workOrderTemplate">
            <div class="field-group work-order">
              <label class="required" translate>Work Order</label>
              <div class="template-preview">
                <a *ngIf="invitation.workOrderRenderedHtml" [href]="invitation.workOrderRenderedHtml" target="_blank">
                  <mat-icon>picture_as_pdf</mat-icon> View Work Order
                </a>
                <a *ngIf="!hasSignature" class="error" (click)="routeToSettings()">
                  <mat-icon>error</mat-icon> Signature Missing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div *ngIf="invitation.job" class="job-detail">
        <div class="form-content">
          <h2>{{ invitation.sharedBy }}</h2>
          <div class="field-row">
            <div class="field-group sent-on">
              {{ 'Sent on' | translate }} {{ invitation.createdAt | date:'MMM d, yyyy' }}
            </div>
          </div>

          <div class="field-row">
            <div class="field-group invoice-rate">
              <div class="label" translate>Invoice Rate</div>
              <div class="value">
                <span class="currency">{{ (invitation.invoiceRate || 0) | currency:'USD':'symbol':'1.2-4' }}</span> {{ 'per' | translate }} {{ invitation.invoiceRateUnit }}
              </div>
            </div>

            <div class="field-group sender">
              <div class="label">{{ invitation.senderFirstName }} {{ invitation.senderLastName }}</div>
              <div class="value">{{ (invitation.senderOrganizationPhoneNumber | telephone) || '&nbsp;' }}</div>
            </div>
          </div>

          <div class="horizontal-divider"></div>

          <div class="job-locations">
            <div class="field-row">
              <div class="field-group start-location">
                <div class="value">{{ invitation.startLocation && invitation.startLocation.name }}</div>
              </div>
            </div>
            <div class="field-row">
              <div class="field-group end-location">
                <div class="value">{{ invitation.endLocation && invitation.endLocation.name }}</div>
              </div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group date-range">
              <div class="label" translate>Start and End Day</div>
              <div class="value">{{ invitation.shift1Start | date: 'shortDate' }}</div>
              <div class="value" *ngIf="invitation.shifts > 1">&nbsp;</div>
            </div>

            <div class="field-group daily-schedule">
              <div class="label" translate>Daily Schedule</div>
              <div class="value">{{ invitation.shift1Start | date: 'shortTime' }} - {{ invitation.shift1End | date: 'shortTime' }}</div>
              <div class="value" *ngIf="invitation.job.shifts > 1">{{ invitation.shift2Start | date: 'shortTime' }} - {{ invitation.shift2End | date: 'shortTime' }}</div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group material">
              <div class="label" translate>Material</div>
              <div class="value">{{ invitation.material }}</div>
            </div>

            <div class="field-group allowed-trucks">
              <div class="label" translate>Allowed Trucks</div>
              <div class="value">{{ invitation.numTrucks || '&nbsp;' }}</div>
            </div>
          </div>

          <div class="field-row">
            <div class="field-group external-identifier">
              <div class="label" translate>External Identifier</div>
              <div class="value">{{ invitation.externalIdentifier || '&mdash;' }}</div>
            </div>
          
            <div class="field-group truck-types">
              <div class="label" translate>Allowed Truck Types</div>
              <div class="value">
                <ng-container *ngIf="invitation.allowAnyTruckType">
                  Any Truck Type
                </ng-container>
                <ng-container *ngIf="!invitation.allowAnyTruckType">
                  <ng-container *ngFor="let truckType of invitation.truckTypes; let i = index; last as isLast">
                    <ng-container *ngIf="i <= 2">
                      <ng-container *ngIf="i !== 2">{{ truckType.name }}<span *ngIf="!isLast">, </span></ng-container>
                      <ng-container *ngIf="i === 2 && invitation.truckTypes.length > 2">+{{ invitation.truckTypes.length - 2 }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!invitation.allowAnyTruckType && !invitation.truckTypes">&mdash;</ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-content class="mat-dialog-content deny-confirmation" *ngIf="confirmDeny">
    <div *ngIf="errors && errors.length" class="errors">
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>

    <div class="content">
      <h2>Are you sure you want to deny this job?</h2>

      <p>This action cannot be undone. If you deny, the job will need to be sent to you again for acceptance.</p>
    </div>
  </div>

  <div class="modal-footer" *ngIf="!confirmDeny">
    <button class="btn btn-delete"
            (click)="deny(); false"
            [disabled]="loading"
            [ngClass]="{loading: loading}"
            translate>
      Deny Job
    </button>
    <button *ngIf="!invitation.workOrderTemplate"
            class="btn btn-primary"
            (click)="acceptJob.form.valid && submit(); false"
            [disabled]="loading || !acceptJob.form.valid"
            [ngClass]="{loading: loading}"
            translate>
      Approve This Day
    </button>
    <button *ngIf="!invitation.workOrderTemplate"
            class="btn btn-primary"
            (click)="acceptJob.form.valid && submit(true); false"
            [disabled]="loading || !acceptJob.form.valid"
            [ngClass]="{loading: loading}"
            translate>
      Approve All Days
    </button>
    <button *ngIf="invitation.workOrderTemplate"
            class="btn btn-primary"
            (click)="acceptJob.form.valid && submit(); false"
            [disabled]="loading || !acceptJob.form.valid || !hasSignature"
            [ngClass]="{loading: loading}"
            translate>
      Sign Work Order & Approve Job
    </button>
  </div>

  <div class="modal-footer" *ngIf="confirmDeny">
    <button class="btn btn-default"
            (click)="confirmDeny = false"
            [disabled]="loading"
            [ngClass]="{loading: loading}"
            translate>
      Cancel
    </button>
    <button class="btn btn-delete"
            (click)="deny(); false"
            [disabled]="loading"
            [ngClass]="{loading: loading}"
            translate>
      Deny Job
    </button>
  </div>
</form>
