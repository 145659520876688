<form #filterPurchaseOrders="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Orders</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group customer-name">
          <label>Customer</label>
          <dropdown #customersDropdown [options]="customers" (onSelect)="onSelect('customer_organization', $event)" [config]="customersConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'customer')" (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group product">
          <label>Product</label>
          <dropdown #productsDropdown [options]="products" (onSelect)="onSelect('product', $event)" [config]="productsConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'product')" (nextPage)="dropdownNextPage($event, 'product')"></dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group location">
          <label>Delivery Location</label>
          <dropdown #locationsDropdown [options]="locations" (onSelect)="onSelect('delivery_location', $event)" [config]="locationsConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'location')" (nextPage)="dropdownNextPage($event, 'location')"></dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group fulfilled">
          <label attr.data-label="{{ 'Fulfilled' | translate }}" class="toggle-label">
            <input type="checkbox" name="fulfilled" checked="{model.fulfilled}" [(ngModel)]="model.fulfilled" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group full-stop">
          <label attr.data-label="{{ 'Full Stop' | translate }}" class="toggle-label">
            <input type="checkbox" name="full-stop" checked="{model.full_stop}" [(ngModel)]="model.full_stop" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group void">
          <label attr.data-label="{{ 'Void' | translate }}" class="toggle-label">
          <input type="checkbox" name="void" checked="{model.void}" [(ngModel)]="model.void" class="toggle-input">
          <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false">
      Filter Results
    </button>
  </div>
</form>
