<div class="ruckit-input-wrapper" [class.small]="config.small">
  <input
    id="ruckit-input" [class.editing]="editMode"
    type="{{ config.type }}" [(ngModel)]="updatedValue"
    (click)="enableEditing(true)"
  />
  <div class="edit-actions" *ngIf="editMode">
    <i class="icon icon-close" (click)="enableEditing(false)"></i>
    <i class="icon icon-checkmark" (click)="submitEdit(updatedValue)"></i>
  </div>
</div>
