import {
  Component, ElementRef, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { pull, find as _find } from 'lodash';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { CollaboratorService } from './collaborator.service';
import { ConnectionService } from '../connections/connection.service';
import { parseErrors } from '../shared/api.service';
import { JOBWEIGHTOPTIONS } from '../app.constants';

@Component({
  selector: 'new-days-collaboration',
  templateUrl: './new-days-collaboration-dialog.component.html',
  styleUrls: ['./new-days-collaboration-dialog.component.scss'],
  providers: [CollaboratorService, ConnectionService]
})
export class NewDaysCollaborationDialogComponent implements OnInit {
  @Input() jobEvent: any = null;
  @Input() daysAdded = [];
  @Output() completed = new EventEmitter();
  selectedCollaborators = [];
  selectedCount = 0;
  weightOption;
  invoiceTypeOption;
  weightOptions = [...JOBWEIGHTOPTIONS];
  invoiceTypeOptions = [
    { value: 'weight', label: 'Weight', name: 'Weight' },
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  collaborators: any = [];
  collaboratorsReq;
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<NewDaysCollaborationDialogComponent>,
    private collaboratorService: CollaboratorService,
    private connectionService: ConnectionService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.getCollaborators();
  }

  getCollaborators(query = {}) {
    if (this.collaboratorsReq) {
      this.collaboratorsReq.unsubscribe();
    }
    this.loading = true;
    this.collaboratorsReq = this.collaboratorService.getCollaborators(this.jobEvent.id).subscribe(
      collaborators => {
        this.collaborators = collaborators.map((collaborator) => {
          collaborator.invoiceTypeOption = _find(this.invoiceTypeOptions, {
            value: collaborator.invoiceType
          });
          collaborator.weightOption = _find(this.weightOptions, {
            value: collaborator.invoiceRateUnit
          });
          return collaborator;
        });
        this.loading = false;
      },
      err => this.errors = parseErrors(err)
    );
  }

  submit() {
    this.loading = true;
    let rates = this.collaborators.map((collaborator) => {
      if (collaborator.selected) {
        return {
          organization: collaborator.organizationId,
          haulRate: collaborator.invoiceRate,
          haulType: collaborator.invoiceType,
          haulWeightUnit: collaborator.invoiceWeightUnit,
          numTrucks: collaborator.numTrucks
        };
      }
    });

    let model = {
      dates: this.daysAdded && this.daysAdded.map(date => moment(date).format('YYYY-MM-DD')),
      rates: rates.filter(rate => rate !== undefined)
    };

    this.collaboratorService.multiShare(this.jobEvent.job.id, model).subscribe(result => {
      this.loading = false;
      this.dialogRef.close();
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  setSelectedType(collaborator, e, form: NgForm) {
    form.controls['rate_tracking[' + collaborator.id + ']'].markAsDirty();
    collaborator.invoiceType = e.value;
  }

  setSelectedWeight(collaborator, e, form: NgForm) {
    form.controls['invoice_rate[' + collaborator.id + ']'].markAsDirty();
    collaborator.invoiceWeightUnit = e.value;
  }

  selector(event, collaborator) {
    if (!event.target.checked) {
      collaborator.selected = false;
      pull(this.selectedCollaborators, collaborator.id);
      this.selectedCount = this.selectedCollaborators.length;
    } else {
      collaborator.selected = true;
      this.selectedCollaborators.push(collaborator.id);
      this.selectedCount = this.selectedCollaborators.length;
    }
  }
}
