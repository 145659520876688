import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { TripFilterService } from  './trip.filter.service';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'trip-filter-dialog',
  templateUrl: './trip-filters-dialog.component.html',
  styleUrls: ['./trip-filters-dialog.component.scss']
})
export class TripFiltersDialogComponent implements OnInit {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];
  firstApiCall = true;

  @ViewChild('jobsDropdown', { static: true }) jobsDropdown: RuckitDropdownComponent;
  @ViewChild('projectsDropdown', { static: true }) projectsDropdown: RuckitDropdownComponent;
  @ViewChild('customersDropdown', { static: true }) customersDropdown: RuckitDropdownComponent;
  @ViewChild('driversDropdown', { static: true }) driversDropdown: RuckitDropdownComponent;
  @ViewChild('trucksDropdown', { static: true }) trucksDropdown: RuckitDropdownComponent;
  @ViewChild('payablesDropdown', { static: true }) payablesDropdown: RuckitDropdownComponent;
  @ViewChild('carriersDropdown', { static: true }) carriersDropdown: RuckitDropdownComponent;

  @Output() onDateChange: EventEmitter<{startDate: string, endDate: string}> = new EventEmitter();

  defaultDropdownConfig = {
    service: TripFilterService,
    serviceFunction: 'listFilters',
    searchKey: 'filter_search',
    pageSize: 30,
  };

  driverDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    serviceFunctionScope: 'drivers',
    getFilter: 'driver',
    query: { serializer: 'DriverFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  customerDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Customer',
    loadingText: 'Loading Customers...',
    noResultsText: 'No Customers',
    serviceFunctionScope: 'customers',
    getFilter: 'jobevent__customer_organization',
    query: { serializer: 'OrganizationFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  truckDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    serviceFunctionScope: 'trucks',
    getFilter: 'truck',
    query: { serializer: 'TruckFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  carrierDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Carrier',
    loadingText: 'Loading Carriers...',
    noResultsText: 'No Carriers',
    serviceFunctionScope: 'carriers',
    getFilter: 'driver__carrier',
    query: { serializer: 'CarrierFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  payableToDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Payable',
    loadingText: 'Loading Payables...',
    noResultsText: 'No Payables',
    serviceFunctionScope: 'payables',
    getFilter: 'jobevent__owner_organization',
    query: { serializer: 'OrganizationFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  projectDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Project',
    loadingText: 'Loading Projects...',
    noResultsText: 'No Projects',
    serviceFunctionScope: 'projects',
    getFilter: 'jobevent__job__project',
    query: { serializer: 'ProjectFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };
  jobDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    serviceFunctionScope: 'jobs',
    getFilter: 'jobevent__job',
    query: { serializer: 'JobFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null }
  };

  callback: Function;
  model = {
    startDate: null,
    endDate: null,
    job: null,
    jobNumber: null,
    project: null,
    customer: null,
    driver: null,
    truck: null,
    payableTo: null,
    carrier: null,
    unvoid: null,
    edited: false,
    incomplete: false,
    retake: false,
    uninvoiced: false,
    missedAssignedLocations: false
  };

  constructor(
    public dialogRef: MatDialogRef<TripFiltersDialogComponent>
  ) { }

  ngOnInit() {
    this.filterDropdowns();
    this.onDateChange.pipe(
      debounceTime(1000), distinctUntilChanged()
    ).subscribe(() => {
      if (!this.firstApiCall) {
        this.filterDropdowns(true);
      } else {
        this.firstApiCall = false;
      }
    });
  }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.convertDates();
    this.callback(this.model);
    this.dialogRef.close();
  }

  convertDates(assign = true) {
    let startDate: Date, endDate: Date;
    if (this.model.startDate) {
      startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      if (assign) { Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name }); }
    }

    if (this.model.endDate) {
      endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      if (assign) { Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name }); }
    }

    return {
      startDate: startDate && startDate.toISOString(),
      endDate: endDate && endDate.toISOString()
    };
  }

  onDateChanged(dates: Date[], type = ''): void {
    this.model[type] = dates[0];
    let { startDate, endDate } = this.convertDates(true);
    this.onDateChange.next({ startDate, endDate });
  }

  filterDropdowns(fetch = false): void {
    let { startDate, endDate } = this.convertDates(false);
    this.filterJobs(startDate, endDate, fetch);
    this.filterProjects(startDate, endDate, fetch);
    this.filterCustomers(startDate, endDate, fetch);
    this.filterDrivers(startDate, endDate, fetch);
    this.filterTrucks(startDate, endDate, fetch);
    this.filterPayables(startDate, endDate, fetch);
    this.filterCarriers(startDate, endDate, fetch);
  }

  filterJobs(startDate, endDate, fetch) {
    this.jobDropdownConfig.query = {
      ...this.jobDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.jobDropdownConfig = { ...this.jobDropdownConfig };
    if (fetch) {
      this.jobsDropdown.getRecords(this.jobDropdownConfig.query);
    }
  }

  filterProjects(startDate, endDate, fetch) {
    this.projectDropdownConfig.query = {
      ...this.projectDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.projectDropdownConfig = { ...this.projectDropdownConfig };
    if (fetch) {
      this.projectsDropdown.getRecords(this.projectDropdownConfig.query);
    }
  }

  filterCustomers(startDate, endDate, fetch) {
    this.customerDropdownConfig.query = {
      ...this.customerDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.customerDropdownConfig = { ...this.customerDropdownConfig };
    if (fetch) {
      this.customersDropdown.getRecords(this.customerDropdownConfig.query);
    }
  }

  filterDrivers(startDate, endDate, fetch) {
    this.driverDropdownConfig.query = {
      ...this.driverDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.driverDropdownConfig = { ...this.driverDropdownConfig };
    if (fetch) {
      this.driversDropdown.getRecords(this.driverDropdownConfig.query);
    }
  }

  filterTrucks(startDate, endDate, fetch) {
    this.truckDropdownConfig.query = {
      ...this.truckDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.truckDropdownConfig = { ...this.truckDropdownConfig };
    if (fetch) {
      this.trucksDropdown.getRecords(this.truckDropdownConfig.query);
    }
  }

  filterPayables(startDate, endDate, fetch) {
    this.payableToDropdownConfig.query = {
      ...this.payableToDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.payableToDropdownConfig = { ...this.payableToDropdownConfig };
    if (fetch) {
      this.payablesDropdown.getRecords(this.payableToDropdownConfig.query);
    }
  }

  filterCarriers(startDate, endDate, fetch) {
    this.carrierDropdownConfig.query = {
      ...this.carrierDropdownConfig.query,
      ...{ jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate }
    };
    this.carrierDropdownConfig = { ...this.carrierDropdownConfig };
    if (fetch) {
      this.carriersDropdown.getRecords(this.carrierDropdownConfig.query);
    }
  }
}
