import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';

import { PunchCardFilterService } from  './punch-card.filter.service';

@Component({
  selector: 'punch-card-filter-dialog',
  templateUrl: './punch-card-filters-dialog.component.html',
  styleUrls: ['./punch-card-filters-dialog.component.scss']
})
export class PunchCardFiltersDialogComponent implements OnInit {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];

  defaultDropdownConfig = {
    service: PunchCardFilterService,
    serviceFunction: 'listFilters',
    searchKey: 'filter_search'
  };

  driverDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    serviceFunctionScope: 'drivers',
    getFilter: 'driver'
  };
  customerDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Customer',
    loadingText: 'Loading Customers...',
    noResultsText: 'No Customers',
    serviceFunctionScope: 'customers',
    getFilter: 'jobevent__customer_organization'
  };
  truckDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    serviceFunctionScope: 'trucks',
    getFilter: 'truck'
  };
  carrierDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Carrier',
    loadingText: 'Loading Carriers...',
    noResultsText: 'No Carriers',
    serviceFunctionScope: 'carriers',
    getFilter: 'driver__carrier'
  };
  projectDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Project',
    loadingText: 'Loading Projects...',
    noResultsText: 'No Projects',
    serviceFunctionScope: 'projects',
    getFilter: 'jobevent__job__project'
  };
  jobDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    serviceFunctionScope: 'jobs',
    getFilter: 'jobevent__job',
    query: {}
  };

  callback: Function;
  model = {
    startDate: null,
    endDate: null,
    job: null,
    project: null,
    customer: null,
    driver: null,
    truck: null,
    carrier: null,
    uninvoiced: false
  };

  constructor(
    public dialogRef: MatDialogRef<PunchCardFiltersDialogComponent>
  ) { }

  ngOnInit() {
    this.filterJobs();
  }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.convertDates();
    this.callback(this.model);
    this.dialogRef.close();
  }

  convertDates(assign = true) {
    let startDate: Date, endDate: Date;
    if (this.model.startDate) {
      startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      if (assign) { Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name }); }
    }

    if (this.model.endDate) {
      endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      if (assign) { Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name }); }
    }

    if (!assign) {
      return {
        startDate: startDate && startDate.toISOString(),
        endDate: endDate && endDate.toISOString()
      };
    }
  }

  onDateChanged(dates: Date[], type = ''): void {
    this.model[type] = dates[0];
    this.filterJobs();
  }

  filterJobs(): void {
    let { startDate, endDate } = this.convertDates(false);
    this.jobDropdownConfig.query = { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate };
    this.jobDropdownConfig = { ...this.jobDropdownConfig };
  }
}
