import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { AuthenticationService } from '../../shared/index';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { AssignmentService } from '../../assignments/assignment.service';
import { ExportDialogComponent, ExportDialogData } from '../../shared/export-dialog/export-dialog.component';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'needs-dispatched',
  templateUrl: './needs-dispatched.component.html',
  styleUrls: ['./needs-dispatched.component.scss'],
  providers: [CollaboratorService, AssignmentService]
})
export class NeedsDispatchedComponent implements OnInit, OnDestroy {
  collaborators: any = [];
  loading = true;
  errors = [];
  range = 'today';
  startDate = new Date();
  endDate = new Date();
  organization;
  sortBy: string;
  sortAsc = true;
  sortParameter;
  sharesReq: Subscription;

  constructor(
    private collaboratorService: CollaboratorService,
    private authenticationService: AuthenticationService,
    private assignmentService: AssignmentService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (window.innerWidth > 900) {
      this.organization = this.authenticationService.getOrganization();
      this.startDate.setHours(0, 0, 0, 0);
      this.endDate.setHours(23, 59, 59, 999);
      this.getShares();
    }
  }

  ngOnDestroy() {
    if (this.sharesReq && typeof this.sharesReq.unsubscribe === 'function') {
      this.sharesReq.unsubscribe();
    }
  }

  onResize(event) {
    if (window.innerWidth <= 900) { this.ngOnInit(); }
  }

  setRange(range) {
    this.range = range;
    this.startDate = new Date();
    this.endDate = new Date();

    switch (this.range) {
      case 'today':
        this.startDate.setHours(0, 0, 0, 0);
        this.endDate.setHours(23, 59, 59, 999);
        break;
      case 'tomorrow':
        this.startDate.setDate(this.startDate.getDate() + 1);
        this.endDate.setDate(this.endDate.getDate() + 1);
        this.startDate.setHours(0, 0, 0, 0);
        this.endDate.setHours(23, 59, 59, 999);
        break;
    }
    this.getShares();
  }

  exportAssignments () {
    let filters = {
      'jobevent__shift1_start__gte': this.startDate && this.startDate.toISOString(),
      'jobevent__shift1_start__lte': this.endDate && this.endDate.toISOString()
    };
    this.assignmentService.export({}, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          params: params,
          service: this.assignmentService,
          buttonText: 'Try to Export Again'
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  getShares(query = {}) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.loading = true;
    this.collaborators = [];
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    if (this.sharesReq && typeof this.sharesReq.unsubscribe === 'function') {
      this.sharesReq.unsubscribe();
    }

    this.sharesReq = this.collaboratorService.getShares({
      end__gte: this.startDate.toISOString(),
      end__lte: this.endDate.toISOString(),
      organization: this.organization && this.organization.id,
      dispatch_percent: 'True',
      ordering: order,
      ...query
    }).subscribe(collaborators => {
      this.collaborators = collaborators;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getShares({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }
}
