<form #confirmAutoAssign="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Auto-Assign</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="heading heavy" *ngIf="successes.length === 0 && failures.length === 0 && drivers.length === 0" translate>
        There were no dispatched assignments for the previous day.
      </div>
      <div class="heading heavy" *ngIf="(failures.length > 0 || drivers.length > 0) && successes.length === 0" translate>
        No Trucks & Drivers could be auto-assigned.
      </div>
      <div class="heading heavy" *ngIf="(failures.length > 0 || drivers.length > 0) && successes.length > 0" translate>
        Some Trucks & Drivers could not be auto-assigned.
      </div>
    
      <div class="field-row separator" *ngIf="drivers.length === 1">
        {{ drivers.length }} <ng-container translate>driver is assigned to another job at this time:</ng-container>
      </div>
      <div class="field-row separator" *ngIf="drivers.length > 1">
        {{ drivers.length }} <ng-container translate>drivers are assigned to another job at this time:</ng-container>
      </div>

      <div class="field-column separator" *ngFor="let driver of drivers">
        <div class="heavy">{{ driver.profile.firstName }} {{ driver.profile.lastName }}</div>
        <div *ngFor="let conflict of driver.conflicts">{{ conflict.jobeventName }}: {{ conflict.time }}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default confirm-button"
            (click)="close(); false"
            [disabled]="loading"
            [ngClass]="{loading: loading}"
            translate>
      Okay
    </button>

    <button class="btn btn-primary confirm-button"
            (click)="forceAssign(); false"
            *ngIf="canForce && (failures.length > 0 || drivers.length > 0)"
            [disabled]="loading"
            [ngClass]="{loading: loading}"
            translate>
      Assign Anyway
    </button>
  </div>
</form>
