<form #newDaysCollaboration="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Share your new days</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors">
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>

    <div class="content">
      <h2 translate>Who is working the new days?</h2>
      <p translate>You've added {{ daysAdded.length }} new days. Which Collaborators are you sharing this job with?</p>
      <p translate *ngIf="jobEvent" class="job-specs">
        <span class="label">Invoice:</span> {{ (jobEvent.rate || 0) | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ jobEvent.invoiceRateUnit }}
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <span class="label">Trucks Allowed:</span> {{ jobEvent.numTrucks }}
      </p>

      <div *ngFor="let collaborator of collaborators" class="collaborator">
        <div class="form-content">
          <div class="field-row">
            <div class="collaborator-selection">
              <div class="selector">
                <label>
                  <input type="checkbox" (change)="selector($event, collaborator)" [checked]="collaborator.selected" />
                </label>
              </div>
              <div class="collaborator-name">{{ collaborator.organization }}</div>
            </div>
            <div class="rate-tracking">
              <div class="label" translate>How is the rate tracked?</div>
              <input required type="hidden" name="rate_tracking[{{collaborator.id}}]" [(ngModel)]="collaborator.invoiceType" />
              <dropdown [options]="invoiceTypeOptions"
                        [config]="rateTrackingConfig"
                        [selectedOption]="collaborator.invoiceTypeOption"
                        (onSelect)="setSelectedType(collaborator, $event, newDaysCollaboration)"
                        class="rate-tracking-dropdown">
              </dropdown>
            </div>

            <div class="rate">
              <div class="label" translate>What is the rate?</div>
              <div class="input-group" data-prefix="$">
                <input type="number" name="invoice_rate[{{collaborator.id}}]" [(ngModel)]="collaborator.invoiceRate" min="0" />
                <dropdown [options]="weightOptions"
                          [selectedOption]="collaborator.weightOption"
                          (onSelect)="setSelectedWeight(collaborator, $event, newDaysCollaboration)"
                          *ngIf="collaborator.invoiceType === 'weight'"
                          title="-">
                </dropdown>
                <div class="add-on" *ngIf="collaborator.invoiceType !== 'weight'">{{collaborator.invoiceType}}</div>
              </div>
            </div>
            <div class="trucks">
              <div class="label" translate>Allowed Trucks</div>
              <div class="input-group">
                <input type="number" name="num_trucks[{{collaborator.id}}]" *ngIf="collaborator.selected" required [(ngModel)]="collaborator.numTrucks" min="0" />
                <input type="number" name="num_trucks[{{collaborator.id}}]" *ngIf="!collaborator.selected" [(ngModel)]="collaborator.numTrucks" min="0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="newDaysCollaboration.form.valid && submit(); false"
            [disabled]="!newDaysCollaboration.form.valid || selectedCount === 0 || loading"
            [ngClass]="{loading:loading}"
            translate>
      Share New Days
    </button>
  </div>
</form>
