import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { ApiService, parseErrors } from '../shared/api.service';
import { AuthenticationService } from '../shared/authentication.service';
import { DataService } from '../shared/data.service';
import { UploadService, S3PolicyData } from '../shared/upload.service';

import { Invitation } from './invitation';
import { InvitationService } from './invitation.service';
import { UserService } from '../users/user.service';
import { OrganizationService } from '../organizations/organization.service';
import { AB5QuestionsDialogComponent } from './ab5-questions/ab5-questions-dialog.component';
import { Organization } from '../organizations/organization';

// const camelcaseKeysDeep = require('camelcase-keys-deep');

type InviteData = {
  organization: Organization,
  carrier: any,
  profile: any,
  data: any
};

@Component({
  selector: 'organization-invitation',
  templateUrl: './organization-invitation.component.html',
  styleUrls: ['./organization-invitation.component.scss']
})
export class OrganizationInvitationComponent implements OnInit {
  loading = false;
  documentLoading = false;
  initializing = false;
  inviteCode: string;
  invitation: Invitation;
  onboardingType = 'dependent';
  ab5 = false;
  errors = [];
  orgCreateStep = 0;
  inviteData: InviteData = <InviteData>{
    organization: {},
    carrier: {},
    profile: {},
    data: {}
  };
  customOrgInfo: any = {};
  organization: any = {};
  profile: any = {};
  inviteList: any[] = [];
  states: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private invitationService: InvitationService,
    private uploadService: UploadService,
    private apiService: ApiService,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.states = this.apiService.getStates();
    this.route.params.forEach((params: Params) => {
      this.inviteCode = params['invite_code'];
      if (params['step'] && params['step'].length) {
        this.orgCreateStep = +params['step'];
      } else {
        this.orgCreateStep = 0;
      }
      this.inviteList.length = 2;
      this.getInvitation();
    });
  }

  getInvitation() {
    this.invitationService.getInvitation(this.inviteCode).subscribe(invitation => {
      // invitation = camelcaseKeysDeep(invitation);
      this.invitation = <Invitation> invitation;
      this.inviteData.profile.email = this.invitation.recipientEmail;
      if (this.invitation.status === 'pending') { this.authenticationService.logout(); }
      if (this.invitation.data && !isEmpty(this.invitation.data.organization)) {
        this.inviteData.organization = this.invitation.data.organization;
        if (!isEmpty(this.invitation.data.carrier) || !isEmpty(this.invitation.data.data) || this.orgCreateStep > 2) {
          this.inviteData.data = this.invitation.data;
          if (this.invitation.data.profile && this.invitation.data.profile.title) {
            this.inviteData.profile = this.invitation.data.profile;
            this.orgCreateStep = 4;
          } else { this.orgCreateStep = 3; }
        } else { this.orgCreateStep = 2; }
      }
      this.router.navigate(['/create-organization', this.invitation.id, this.orgCreateStep]);
      this.loading = false;
    }, (err) => {
      if (err.indexOf('Not found.', 0) > -1) { this.orgCreateStep = 1; }
      this.loading = false;
      if (!this.authenticationService.isLoggedIn()) {
        this.orgCreateStep = 0;
        this.errors = parseErrors(err);
      }
    });
  }

  nextStep() {
    if (this.orgCreateStep !== 4) {
      this.orgCreateStep += 1;
      this.router.navigate(['/create-organization', this.invitation.id, this.orgCreateStep]);
    } else {
      this.router.navigate(['/daily']);
    }
  }

  saveOrgDetails() {
    if (this.invitation) {
      this.invitationService.updateInvitation(
        this.invitation.id, { data: this.inviteDataMapped(this.inviteData) }
      ).subscribe((result) => {
        this.nextStep();
      }, (err) => {
        this.errors = parseErrors(err);
      });
    }
  }

  inviteDataMapped(data: InviteData): InviteData {
    if (data.organization) {
      this.inviteData.data.motorCarrierNumber = data.organization.mcNumber;
      this.inviteData.carrier.dotNumber = data.organization.dotNumber;
    }
    return this.inviteData;
  }

  saveOrgProfile() {
    if (this.validatePassword(this.inviteData.profile.password, this.inviteData.profile.passwordConfirmation)) {
      this.saveOrgDetails();
    }
  }

  validatePassword(password, passwordRepeat) {
    // password requirements: min 6 characters
    if (password.length < 6) {
      this.errors.push('Passwords must be at least 6 characters.');
      return false;
    } else if (password !== passwordRepeat) {
      this.errors.push('The passwords you entered do not match.');
      return false;
    } else {
      return true;
    }
  }

  submitOrg(inviteList = null) {
    this.initializing = true;
    this.invitationService.registerFromInvitation(this.invitation.id, this.invitation.kind).subscribe((res) => {
      let user = this.authenticationService.user();
      if (user) {
        this.dataService.changeData({ authenticated: true, sidebar: true });
      }
      let orgId = user && user.organization && user.organization.id;
      if (inviteList) {
        inviteList.forEach(email => {
          if (email.length > 0) {
            this.invitationService.createInvitation({
              kind: 'new_user',
              recipient_organization: orgId,
              recipient_email: email
            }).subscribe(result => {
                if (result !== true) {
                  this.errors = ['Invite for ' + email + ' failed to send!'];
                }
              }, (err) => {
              this.loading = false;
              this.errors = parseErrors(err);
            });
          }
        });
      }
      setTimeout(() => {
        this.initializing = false;
        this.ngZone.run(() => this.router.navigate(['/daily']));
      }, 5000);
    }, (err) => {
      this.errors = parseErrors(err);
    });
  }

  addInviteField() {
    this.inviteList.push('');
  }

  inviteTrackBy(index: any, item: any) {
    return index;
  }

  openAB5Questions() {
    const dialog = this.dialog.open(AB5QuestionsDialogComponent, { width: '660px' });
    dialog.componentInstance.callback = () => {
      this.inviteData.organization.isAb5Compliant = true;
    };
  }

  setExpirationDate(e: Date[], type: string) {
    this.inviteData.organization[type + 'ExpirationDate'] = e[0] && typeof e[0].toISOString === 'function' ? e[0].toISOString() : e[0];
  }

  uploadAB5Document(type = 'insuranceDocument', e: Event) {
    this.documentLoading = true;
    if (e.target['files'] && e.target['files'].length > 0) {
      const documentFile: File = e.target['files'][0];
      this.uploadService.getS3Policy('s3_documents').subscribe((policy: S3PolicyData) => {
        const organization = this.authenticationService.getOrganization();
        const dateString = moment().format('YYYYMMDD');
        policy['fields']['key'] = `documents/${organization.id}/${type}-${dateString}.${documentFile.name.split('.').pop()}`;
        this.uploadService.uploadToS3(policy, documentFile, documentFile.type).subscribe(() => {
          this.inviteData.organization[type] = environment.s3ServerUrl + policy['fields']['key'];
        }, err => this.errors.push(err), () => this.documentLoading = false);
      }, err => { this.documentLoading = false; this.errors.push(err); });
    }
  }

  complianceEntered(): boolean {
    return this.inviteData.organization &&
           !!this.inviteData.organization.dotNumber &&
           !!this.inviteData.organization.mcNumber &&
           !!this.inviteData.organization.dirNumber &&
           !!this.inviteData.organization.einNumber &&
           !!this.inviteData.organization.isAb5Compliant &&
           !!this.inviteData.organization.insuranceDocument &&
           !!this.inviteData.organization.insuranceExpirationDate &&
           !!this.inviteData.organization.overweightPermit &&
           !!this.inviteData.organization.overweightPermitExpirationDate &&
           !!this.inviteData.organization.businessLicense;
  }
}
