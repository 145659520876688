import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'statusFilter', pure: false})

export class StatusFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === '') { return value; }

    let query = args.toLowerCase();
    return value.filter(obj =>
      obj.status.toLowerCase().indexOf(query) > -1
    );
  }
}
