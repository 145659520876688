import { Resource } from '../shared/resource';
import { clone } from 'lodash';
import { JobEvent } from '../job-events/job-event';
import { TruckType } from './../trucks/truck-type';

export class JobEventStat extends Resource {
  dispatchedEstimatedNumberOfLoads: number;
  dispatchedEstimatedNumberOfTons: number;
  dispatchedDriversCount: number;
  totalAmount: number;
  totalLoads: number;
  totalTons: number;
  totalHours: number;
  totalTripHours: number;
  totalCompletedTripHours: number;
  totalOrdered: number;
  totalOrderedType: string;
  sharesCount: number;
  invoiceRate: number;
  numTrucks: number;
  confirmedTrucks: number;
  invoiceType: string;
  invoiceWeightUnit: string;
  invoiceRateUnit: string;
  measurementUnit: string;
  pluralMeasurementUnit: string;
  weightInTransit: number;
  ticketedWeight: number;
  jobId: string;
  jobName: string;
  jobEvent: JobEvent;
  externalIdentifier: string;
  jobDisplayName: string;
  status: string;
  shift1Status: string;
  shift2Status: string;
  dailyDeliveryTarget: number;
  startLocationId: string;
  startLocationName: string;
  endLocationId: string;
  endLocationName: string;
  orderNumber: string;
  customer: string;
  truckTypes: TruckType[];
  material: string;
  startTime: string;
  expanded = false;
  avgTripTime: number;
  avgTripTimeFormatted: string;
  routeFragment: string;
  haulType: string;
  haulAmount: number;
  haulRate: number;
  effectiveTonRate: number;
  firstLoad: string;
  assignedDriversCount: number;
  confirmedAssignmentsCount: number;
  tonsPerHour: number;
  connexActualLoads: string;
  connexActualQuantity: string;
  connexCarrierRestrictionId: string;
  confirmedAmount: number;
  requestedAmount: number;
  requestedUnit: {key: string, name: string, active: boolean};
  quantitiesDetail: string;

  url(action): string {
    let route = '/jobevents/' + this.id + '/' + action;
    switch (action) {
      case 'edit':
        route = '/jobs/' + this.jobId + '/' + action;
        break;
      case 'add-collaborators':
        this.routeFragment = 'addCollaborator';
        route = '/jobs/' + this.jobId + '/' + this.id + '/collaborators';
        break;
      case 'dispatch-drivers':
        route = '/dispatch/' + this.jobId + '/' + this.id;
        break;
    }
    return route;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}

export class JobEventStatsSummary {
  requestedTrucksSum: number | string;
  confirmedTrucksSum: number | string;
  dispatchedTrucksSum: number | string;
  orderedLoadsSum: number | string;
  deliveredLoadsSum: number | string;
  orderedTonsSum: number | string;
  deliveredTonsSum: number | string;
  driversOnShiftCount: number | string;
  connexActualQuantitySum: number | string;
}
