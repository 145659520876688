import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../shared';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {
  loading = true;
  errors: any[] = [];
  toolbarTitle = 'Dashboard';
  assignmentToggle = true;
  enabledFeatures: string[] = [];
  @ViewChild('activeTrucks') activeTrucks;
  @ViewChild('dailyShifts') dailyShifts;
  @ViewChild('needsDispatched') needsDispatched;
  @ViewChild('locationStatus') locationStatus;
  @ViewChild('dailyProduction') dailyProduction;
  @ViewChild('assignmentAcceptance') assignmentAcceptance;
  @ViewChild('esbUploader') esbUploader;

  constructor(
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.enabledFeatures = this.authenticationService.enabledFeatures();
  }

  ngAfterViewInit() {
    this.toolbarTitle = (window.innerWidth > 900) ? 'Dashboard' : 'Live Map';
    this.cdr.detectChanges();
  }

  onResize(event) {
    this.toolbarTitle = (event.target.innerWidth > 900) ? 'Dashboard' : 'Live Map';
    this.cdr.detectChanges();
  }

  toolbarState(assignmentTitle?) {
    this.toolbarTitle = assignmentTitle ? assignmentTitle : 'Live Map';
    this.assignmentToggle = true;
    this.cdr.detectChanges();
  }

  updatedMarkets(): void {
    if (this.activeTrucks) { this.activeTrucks.ngOnInit(); }
    if (this.needsDispatched) { this.needsDispatched.ngOnInit(); }
    if (this.locationStatus) { this.locationStatus.ngOnInit(); }
    if (this.dailyProduction) { this.dailyProduction.ngOnInit(); }
    if (this.assignmentAcceptance) { this.assignmentAcceptance.ngOnInit(); }
  }
}
