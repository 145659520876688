<div class="app-content">
  <my-toolbar title="Projects"></my-toolbar>

  <action-bar>
    <div class="left-actions">

    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <button class="btn btn-primary" (click)="openAddProject()"><span class="icon-plus"></span> Add Project</button>
    </div>
  </action-bar>

  <div class="list-table">
    <div class="header">
      <div class="head name sort" (click)="sort('name')" >
        Name
      </div>
      <div class="head customer-name sort" (click)="sort('customer_organization__name')" >
        Customer
      </div>
    </div>
    <div class="table-body"  (scroll)="onScroll($event)">
      <div class="no-results" *ngIf="!loading && projects.length === 0">
        <div *ngIf="!search">
          <div class="icon"><i class="icon-projects"></i></div>
          <h2>No Projects</h2>
          <p>When you add a project you’ll see it here.</p>
          <div class="actions">
            <button class="btn btn-primary" (click)="openAddProject()"><span class="icon-plus"></span> Add Project</button>
          </div>
        </div>
        <div *ngIf="search">
          <h2>No Results Found</h2>
          <div class="actions">
            <button class="btn btn-primary" [ngClass]="{loading: loading}" (click)="expandSearch()">Clear Filters</button>
          </div>
        </div>
      </div>

      <div *ngFor="let project of projects" class="row">
        <div class="name">
          <div class="value">{{ project.name }}</div>

          <button class="btn btn-primary" (click)="openAddJob(project)"><span class="icon-plus"></span> Add Job</button>

        </div>
        <div class="customer-name">
          {{project.customerOrganization.name}}
        </div>
      </div>
      <my-loader *ngIf="loading" [ngClass]="{'full-height': trucks && trucks.length === 0}"></my-loader>
    </div>
  </div>
</div>
