<div class="dropdown" role="menu" (window:keydown)="onKeyPress($event)"
  [ngClass]="{
    open: open,
    disabled: disabled,
    inline: config.inline,
    empty: !selectedOption && !config.multiselect,
    action: config.style === 'action',
    user: config.user,
    'bottom-right': config.pin === 'bottom-right',
    'custom-icon': icon,
    searchable: config.searchable,
    'loading-options': config.loadingOptions,
    'right-align': config.rightAlign
  }">
  <div class="dropdown-button" aria-haspopup="true" tabindex="0" (click)="toggleMenu()"
       [ngClass]="{'custom-icon': icon}"
       [ngStyle]="{'background-color': backgroundColor}"
       (keypress)="buttonKeyPress($event)" [attr.aria-disabled]="(disabled ? 'true':'false')"
       *ngIf="!config.autocomplete && !toggle">
    <div *ngIf="config.user" class="user-pic">
      <img *ngIf="config.user && config.user.image" [src]="user.image" />
      <span *ngIf="!config.user || !config.user.image">
        {{ config.user.name && config.user.name[0] }}
      </span>
    </div>
    {{ buttonText }}
    <i *ngIf="icon" class="{{icon}}"></i>
  </div>

  <div class="dropdown-input" *ngIf="config.autocomplete">
    <input name="autocomplete" type="text" class="autocomplete" [(ngModel)]="autocompleteTerm" [required]="required"
           autocomplete="off" [placeholder]="buttonText" (ngModelChange)="changeAutocomplete()" (focus)="toggleMenu()" [disabled]="disabled" />
  </div>

  <div class="dropdown-menu" *ngIf="(groupKeys && groupKeys.length > 0) || options || searching" [style.height]="menuHeight" [style.width]="'auto'">
    <div class="dropdown-options" #optionsEl [attr.aria-hidden]="(open ? 'false':'true') " aria-label="submenu">

      <form id="dropdown-search" class="option search-option" *ngIf="config.searchable && !config.autocomplete">
        <input name="search" type="text" class="search" [(ngModel)]="searchTerm" placeholder="Search"
               autocomplete="off" (ngModelChange)="changeSearch()"/>
        <span class="icomoon icon-search"></span>
      </form>

      <div class="no-results-dropdown" *ngIf="options.length === 0 && !config.deselectOption">
        {{config.noResultsText}}
      </div>

      <div class="scrollable" infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]="false"
           [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <div *ngIf="config.group">
          <div *ngFor="let groupKey of groupKeys" class="group" [ngClass]="{empty: groupKey.length === 0}">
            <div class="group-header" *ngIf="groupKey && groupKey.length > 0">
              {{ (groupKey === 'undefined' ? '&mdash;' : groupKey) || '&mdash;' }}
            </div>
            <div class="option" attr.data-id="{{ option && option.id }}" role="menuitem" *ngFor="let option of groups[groupKey]" (click)="toggleOption(option)"
                 tabindex="-1" (keypress)="optionKeyPress($event, option)"
                 [ngClass]="{
                   selected: isSelected(option),
                   button: option && option.button,
                   'has-image': config.image,
                   disabled: option && option.disabled,
                   'has-icon': config.optionIcon
                  }">
              <div class="option-left">
                <div *ngIf="config.image">
                  <img class="image" src="{{optionImageDisplay(option)}}" *ngIf="optionImageDisplay(option)" />
                  <div class="default-image" *ngIf="!optionImageDisplay(option)"><i class="icon-fleet"></i></div>
                </div>
                <div *ngIf="config.optionIcon">
                  <div class="option-icon" *ngIf="optionIconDisplay(option)">
                    <mat-icon>{{ optionIconDisplay(option) }}</mat-icon>
                  </div>
                </div>
              </div>
              <div class="option-right" [ngClass]="{'no-subtitle': !config.subtitle}">
                <span>{{optionDisplay(option)}}</span>
                <span class='subtitle' *ngIf="config.subtitle">{{optionSubtitleDisplay(option)}}</span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!config.group">
          <div *ngIf="config.deselectOption"
               class="option"
               role="menuitem"
               (click)="deselectAll()"
               tabindex="-1">
            <div class="option-right no-subtitle">
                <span>{{ config.deselectOption }}</span>
            </div>
          </div>
          <div class="option"
               attr.data-id="{{ option && option.id }}"
               role="menuitem"
               *ngFor="let option of options; let i = index"
               (click)="toggleOption(option)"
               tabindex="-1"
               (keypress)="optionKeyPress($event, option)"
               [ngClass]="{
                 selected: isSelected(option),
                 button: option && option.button,
                 'has-image': config.image,
                 disabled: option && option.disabled,
                 'has-icon': config.optionIcon
               }">
            <div class="option-left">
              <div *ngIf="config.image">
                <img class="image" src="{{optionImageDisplay(option)}}" *ngIf="optionImageDisplay(option)" />
                <div class="default-image" *ngIf="!optionImageDisplay(option)"><i class="icon-fleet"></i></div>
              </div>
              <div *ngIf="config.optionIcon">
                <div class="option-icon" *ngIf="optionIconDisplay(option)">
                  <mat-icon>{{ optionIconDisplay(option) }}</mat-icon>
                </div>
              </div>
              <div *ngIf="config.numbered">
                <div class="option-number">
                  {{ i + 1 }}
                </div>
              </div>
            </div>
            <div class="option-right" [ngClass]="{'no-subtitle': !config.subtitle}">
              <span>{{optionDisplay(option)}}</span>
              <span class='subtitle' *ngIf="config.subtitle">{{optionSubtitleDisplay(option)}}</span>
            </div>
          </div>
        </div>

        <div class="option loading-option" *ngIf="loading && (config.showLoading || config.loadingOptions)">
          <my-loader></my-loader>
        </div>
      </div>
    </div>
  </div>
</div>
