import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { CdAutocomplete, CdAutocompleteResponse } from '../directives/cd-autocomplete/index';
import { ConnectionService } from './connection.service';
import { OrganizationService } from '../organizations/organization.service';
import { InvitationService } from '../invitation/invitation.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'connection-dialog',
  templateUrl: './connection-dialog.component.html',
  styleUrls: ['./connection-dialog.component.scss'],
  providers: [ConnectionService, OrganizationService, InvitationService]
})

export class ConnectionDialogComponent implements OnInit {
  model: any = {};
  loading = false;
  organizations: any = [];
  invitationSent = false;
  inputValue = '';
  inputAutocomplete: CdAutocomplete = {
    changeTrigger: false,
    list: []
  };
  senderUserId: string;
  senderOrganizationId: string;
  recipientOrganizationId: string;
  errors = [];
  activeView = 'invite';
  searching = false;
  submitted = false;
  invite = {
    'contact': '',
    'email': '',
    'business': ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService,
    private organizationService: OrganizationService,
    private invitationService: InvitationService,
    public dialogRef: MatDialogRef<ConnectionDialogComponent>
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = false;
    });
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.senderUserId = currentUser.id;
      this.senderOrganizationId = currentUser.organization.id;
    }
  }

  openNewInvite() {
    this.activeView = 'invite';
  }

  openSearch() {
    this.activeView = 'search';
  }

  resetSearch() {
    this.model.search = '';
    this.searching = false;
    this.inputAutocomplete = {
      changeTrigger: false,
      list: []
    };
    this.getOrganizations('');
  }

  filterList(q: string, list: any[]) {
    if (list) {
      return list.filter(function(item) {
        return item.name.toLowerCase().indexOf(q.toLowerCase()) >= 0;
      });
    }
  }

  getOrganizations(q: string) {
    this.loading = true;
    this.searching = q.length > 0 ? true : false;

    this.organizationService.getOrganizations(q).subscribe(organizations => {
      if (organizations.length > 0) {
        this.organizations = organizations;
        this.updateInputAutocompleteData(this.filterList(q, this.organizations));
      } else {
        this.organizations = [{name: 'No Companies Found!', detail: 'Please try another search term.', noResults: true}];
        this.updateInputAutocompleteData(this.organizations);
      }
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  updateSelectedValue(selectedValue: string): void {
    this.recipientOrganizationId = selectedValue;
  }

  updateInputAutocomplete(response: CdAutocompleteResponse): void {
    this.getOrganizations(response.q);
  }

  updateInputAutocompleteData(list: string[]): void {
    if (this.model.search) {
      this.inputAutocomplete.list = list;
      this.inputAutocomplete.changeTrigger = !this.inputAutocomplete.changeTrigger;
    }
  }

  onSubmit(type) {
    this.loading = true;
    this.invitationSent = true;
    if (type === 'search') {
      this.invitationService.createInvitation({
        sender_user: this.senderUserId,
        sender_organization: this.senderOrganizationId,
        kind: 'connection',
        recipient_organization: this.recipientOrganizationId
      }).subscribe(result => {
        this.loading = false;
        this.router.navigate(['/connections/sent']);
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
      });
    } else {
      this.invitationService.createInvitation({
        sender_user: this.senderUserId,
        sender_organization: this.senderOrganizationId,
        kind: 'new_org',
        recipient_email: this.invite.email,
        data: {
          contact: this.invite.contact,
          business: this.invite.business
        }
      }).subscribe(result => {
          this.loading = false;
        }, (err) => {
          this.loading = false;
          this.errors = parseErrors(err);
      });
    }

    // Simulate server activity...
    setTimeout(() => {
      this.submitted = true;
      this.loading = false;
      setTimeout(() => {
        this.dialogRef.close();
      }, 20000);
    }, 1000);
  }
}
