import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { CondensedTrip } from '../../trips/condensed-trip';
import { OrderService } from '../order.service';
import { parseErrors } from '../../shared';

@Component({
  selector: 'ticket-review-dialog',
  templateUrl: './ticket-review-dialog.component.html',
  styleUrls: ['../order.scss']
})

export class TicketReviewDialogComponent {
  loading = false;
  errors = [];
  callback: (ticket: CondensedTrip) => void;
  saveModel: {
    id: string,
    plantQc?: number,
    plantVt?: number,
    roadwayQc?: number,
    roadwayVt?: number
  };

  constructor(
    public orderService: OrderService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TicketReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ticket: CondensedTrip }
  ) {
    this.saveModel = { id : this.data.ticket.id };
  }

  saveDisabled(): boolean {
    return !!!this.saveModel.plantQc &&
      !!!this.saveModel.plantVt &&
      !!!this.saveModel.roadwayQc &&
      !!!this.saveModel.roadwayVt;
  }

  reviewField(field: string, event: Event) {
    this.saveModel[field] = Number(event.target['value']);
  }

  save() {
    this.saveModel['id'] = this.data.ticket.id;
    let reviewReqs: Observable<any>[] = [];
    Object.keys(this.saveModel).forEach((key: 'id' | 'plantQc' | 'plantVt' | 'roadwayQc' | 'roadwayVt') => {
      const field = key === 'plantQc' ? 'plant_qc' : key === 'plantVt' ? 'plant_vt' :
        key === 'roadwayQc' ? 'roadway_qc' : key === 'roadwayVt' ? 'roadway_vt' : 'id';
      if (field !== 'id') {
        reviewReqs.push(
          this.orderService.tripService.reviewTicket(
            this.saveModel['id'], field, { [field]: this.saveModel[key] }
          ).pipe(first())
        );
      }
    });
    forkJoin(reviewReqs).subscribe((tickets: CondensedTrip[]) => {
      let updatedTicket = this.data.ticket;
      tickets.forEach(ticket => {
        if (ticket.plantQc) {
          updatedTicket.plantQc = ticket.plantQc;
          updatedTicket.plantQcReviewer = ticket.plantQcReviewer;
        } else if (ticket.plantVt) {
          updatedTicket.plantVt = ticket.plantVt;
          updatedTicket.plantVtReviewer = ticket.plantVtReviewer;
        } else if (ticket.roadwayQc) {
          updatedTicket.roadwayQc = ticket.roadwayQc;
          updatedTicket.roadwayQcReviewer = ticket.roadwayQcReviewer;
        } else if (ticket.roadwayVt) {
          updatedTicket.roadwayVt = ticket.roadwayVt;
          updatedTicket.roadwayVtReviewer = ticket.roadwayVtReviewer;
        }
      });
      this.callback(updatedTicket);
      this.dialogRef.close();
    }, err => this.errors = parseErrors(err));
  }
}
