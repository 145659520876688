import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import * as Sentry from '@sentry/browser';
import { FilterField } from './filter-field';


@Component({
  selector: 'filter-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialogComponent implements OnInit {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];
  callback: Function;
  @Input() model: {
    startDate?: Date,
    endDate?: Date,
    [fieldName: string]: any
  } = {};
  @Input() filters: FilterField[];

  constructor(
    public dialogRef: MatDialogRef<FiltersDialogComponent>
  ) { }

  ngOnInit(): void {}

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.removeEmptySpacesTextFields();
    this.callback(this.model);
    this.dialogRef.close();
  }

  removeEmptySpacesTextFields() {
    const keys = Object.keys(this.model);
    keys.forEach(key => {
      if (this.model[key]) {
        try {
          const property = this.filters.find(filter => filter.field === key);
          if (property && property.type === 'text' && (!this.model[key] || (this.model[key] && !this.model[key].trim()))) {
            delete this.model[key];
          }
        } catch (exception) {
          Sentry.captureException(exception);
        }
      }
    });
  }

  convertDates(assign = true) {
    let startDate: Date, endDate: Date;
    if (this.model.startDate) {
      startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      if (assign) { Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name }); }
    }

    if (this.model.endDate) {
      endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      if (assign) { Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name }); }
    }

    if (!assign) {
      return {
        startDate: startDate && startDate.toISOString(),
        endDate: endDate && endDate.toISOString()
      };
    }
  }

  onDateChanged(dates: Date[], type = ''): void {
    if (type === 'endDate' && dates && dates.length) {
      dates[0].setHours(23, 59, 59);
    }
    this.model[type] = dates[0];
  }
}
