import { MatDialog } from '@angular/material/dialog';
import {
  Component, OnInit, Input, ViewChild, TemplateRef, OnChanges, SimpleChanges
} from '@angular/core';
import { clone } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { CollaboratorStatService } from '../../collaborators/collaborator-stat.service';
import { AuthenticationService } from '../../shared/index';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { TableConfig } from '../../shared/fancy-table/table.types';

@Component({
  selector: 'ruckit-collaborator-stats',
  templateUrl: './collaborator-stats.component.html',
  styleUrls: ['./collaborator-stats.component.scss']
})

export class CollaboratorStatsComponent implements OnInit, OnChanges {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'expand' },
    { key: 'collaborator', title: this.translationService.instant('Collaborator'), sortable: true, sortBy: 'organization__name' },
    { key: 'avg-trip-time', title: this.translationService.instant('AVG Trip Time'), sortable: false },
    { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks'), sortable: false },
    { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks'), sortable: false },
    { key: 'requested-trucks', title: this.translationService.instant('Requested Trucks'), sortable: false },
    { key: 'truck-types', title: this.translationService.instant('Truck Types'), sortable: false },
    { key: 'drivers-on-shift', title: this.translationService.instant('Drivers on Shift'), sortable: false },
    { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads'), sortable: false },
    { key: 'weight-in-transit', title: this.translationService.instant('Weight in Transit'), sortable: false },
    { key: 'ticketed-weight', title: this.translationService.instant('Ticketed Weight'), sortable: false },
    { key: 'dispatched-loads', title: this.translationService.instant('Dispatched Loads'), sortable: false },
    { key: 'dispatched-tons', title: this.translationService.instant('Dispatched Tons'), sortable: false },
    { key: 'invoice-total', title: this.translationService.instant('Invoice Total'), sortable: false },
    { key: 'haul-total', title: this.translationService.instant('Haul Total'), sortable: false },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];
  @Input() displayedColumns = [
    'select', 'expand', 'collaborator', 'avg-trip-time', 'dispatched-trucks',
    'confirmed-trucks', 'requested-trucks', 'truck-types', 'drivers-on-shift',
    'delivered-loads', 'weight-in-transit', 'ticketed-weight',
    'dispatched-loads', 'dispatched-tons', 'invoice-total', 'haul-total',
    'action'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() hideCancelled = true;
  @Input() jobEventDate: Date;
  loading = true;
  detailColumns = ['detail'];
  tableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: CollaboratorStatService,
    preferenceKey: 'DailyBoardListComponent-CollaboratorStatService',
    query: {},
    collectionTitle: this.translationService.instant('Collaborators'),
    noResultsText: this.translationService.instant('a collaborator'),
    newRecordRoute: ['/collaborators/new'],
    sortBy: 'organization__name',
    sortDirection: 'asc',
    customHeight: true,
    automaticallyHidePagination: false
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('collaboratorStatsTable') collaboratorStatsTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle') columnToggle;

  constructor(
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
      this.loading = false;
    } else {
      this.loading = false;
    }
    this.tableConfig['query'] = {
      ...this.tableConfig['query'], ...query
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    let jobEventDateStart = new Date();
    let jobEventDateEnd = clone(jobEventDateStart);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateEnd = clone(jobEventDateStart);
      }
    } else if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateEnd = clone(jobEventDateStart);
    }

    jobEventDateStart.setHours(0, 0, 0, 0);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    this.tableConfig['query'] = {
      exclude_pending: 'True',
      cancelled: !this.hideCancelled ? 'False' : undefined,
      shift1_start__gte: jobEventDateStart.toISOString(),
      shift1_start__lte: jobEventDateEnd.toISOString()
    };
    if (this.collaboratorStatsTable) {
      this.collaboratorStatsTable.config = <TableConfig>this.tableConfig;
      this.collaboratorStatsTable.getRecords();
    }
  }

  filtersModified(appliedFilters): void {
    // let filter = _find(this.appliedFilters, { key: '' });
    // if (!appliedFilters || !appliedFilters.length ) {
    //   this.filterChanges(_find(this.priceListsReq, { id: null }));
    // }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  refreshTable(): void {
    if (this.collaboratorStatsTable) {
      let query = {};
      if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
      this.collaboratorStatsTable.getRecords({ ...this.tableConfig['query'], ...query });
    }
  }
}
