import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { find as _find } from 'lodash';
import { MatDialog } from '@angular/material/dialog';

import { ConnectionService } from './connection.service';
import { ConnectionDialogComponent } from './connection-dialog.component';
import { InvitationService } from '../invitation/invitation.service';

@Component({
  selector: 'ruckit-connection',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
  providers: [ConnectionService]
})
export class ConnectionsComponent implements OnInit {
  connections: any = [];
  connectionType = true;
  noConnections = false;
  type = '';
  selectedType: any = {};
  loading = true;
  errors = [];
  search = '';
  actionDropdownOptions = [
    {id: '', name: 'All Connections', routerLink: '/connections'},
    {id: 'broker', name: 'Brokers', routerLink: '/connections/broker'},
    {id: 'carrier', name: 'Carriers', routerLink: '/connections/carrier'},
    {id: 'contractor', name: 'Contractors', routerLink: '/connections/contractor'}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService,
    private invitationService: InvitationService,
    public dialog: MatDialog
  ) {
    this.setSelectedRoute(this.actionDropdownOptions);
  }

  openDialog(view) {
    const dialog = this.dialog.open(ConnectionDialogComponent, {
      width: '640px'
    });
    dialog.componentInstance.activeView = view;
  }

  changeSearch(term = '') {
    this.search = term;
  }

  ngOnInit() {
    if (this.route && this.route.params) {
      this.route.params.forEach((params: Params) => {
        this.determineDataType(params['type']);
      });
    }
  }

  setSelectedOption(option) {
    this.router.navigate([option.routerLink]);
  }

  private determineDataType(type: string) {
    this.type = type;
    switch (type) {
      case 'sent':
      case 'received':
        this.connectionType = false;
        break;
      default:
        this.connectionType = true;
        break;
    }

    this.selectedType = _find(this.actionDropdownOptions, { id: type });
  }

  private setSelectedRoute(options) {
    let currentRoute = this.router.url;
    this.actionDropdownOptions = options.map(function(option) {
      return option = option.routerLink === currentRoute ? Object.assign(option, {selected: true}) : option;
    });
  }
}
