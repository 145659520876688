import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Assignment } from '../assignments/assignment';
import { JobEvent } from '../job-events/job-event';
import { PreferenceService } from '../preferences/preference.service';
import { Preference } from '../preferences/preference';
import { AuthenticationService } from '../shared';

@Component({
  selector: 'mobile-dispatch-edit-assignment',
  templateUrl: './mobile-dispatch-edit-assignment.component.html',
  styleUrls: ['./mobile-dispatch-edit-assignment.component.scss']
})
export class MobileDispatchEditAssignmentComponent implements OnInit {
  @Input() assignment: Assignment;
  @Input() jobEvent: JobEvent;
  @Input() selectedShift;
  @Output() updateAssignment: EventEmitter<any> = new EventEmitter<any>();
  @Output() numberOfLoadsType: string;
  numberOfLoadsTypeOutput;
  user;
  preference: Preference;
  preferenceKey;
  maxLoads = 1;

  constructor (
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.getPreferences();
  }

  getPreferences(): void {
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: this.user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        preferences.forEach(preference => {
          if (preference.blob.dispatchPreference) {
            this.preference = preference;
            this.numberOfLoadsType = this.preference.name === 'all-day' ? 'allDay' : 'numbered';
            this.assignment.maxNumberOfLoads = this.numberOfLoadsType === 'allDay' ? 0 : this.maxLoads;
          }
        });
      }
    });
  }
}
