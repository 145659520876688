<div class="app-content driver-pay-container" [ngClass]="{ 'read-only': readOnly }">
  <my-toolbar
    class="desktop-visible"
    title="{{ headerTitle() | translate }}"
    icon="icon-back" [iconLink]="headerUrl()"
  ></my-toolbar>
  <notification *ngFor="let error of errors" translate>{{error}}</notification>
  <action-bar>
    <div class="left-actions">
      <div class="segmented-buttons" [ngClass]="{'received': receivedReport}">
        <a (click)="switchView('prereport')" class="btn btn-default" [ngClass]="{active: view === 'prereport'}">
          <i class="icon-steering-wheel" matTooltip="{{ 'Precheck' | translate }}"></i>
        </a>
        <a (click)="switchView('table')" class="btn btn-default" [ngClass]="{active: view === 'table'}">
          <i class="icon-stats" matTooltip="{{ 'Table' | translate }}"></i>
        </a>
        <a (click)="switchView('timeline')" class="btn btn-default" [ngClass]="{active: view === 'timeline'}">
          <i class="icon-timeline" matTooltip="{{ 'Timeline' | translate }}"></i>
        </a>
      </div>
      <date-range-picker *ngIf="!receivedReport" [selectedDates]="[jobEventDate]"
                         [config]="{decreaseControl: false, increaseControl: false}"
                         (dateChanged)="onDateChanged($event)"></date-range-picker>
      <p *ngIf="receivedReport">{{ jobEventDate | moment: 'MMMM Do, YYYY' | translate }}</p>
      <dropdown #intervalSelect
                title="{{ 'Select Interval' | translate }}"
                [disabled]="empty || !reportActive || view === 'table' || view === 'prereport'"
                [config]="timeIntervalConfig"
                [options]="timeIntervalOptions"
                (onSelect)="timeInterval = $event.amount"></dropdown>
      <dropdown title="Actions" [options]="actionDropdownOptions" [changeTitle]="false"
                (onSelect)="setSelectedAction($event)" *ngIf="!receivedReport && view !== 'prereport' && (!empty && !processing && !readOnly && selectedRows && selectedRows.length > 0)"
                class="actions-dropdown"></dropdown>
      <button class="btn btn-default btn-reset"
              *ngIf="!receivedReport && view !== 'prereport' && (empty || processing || readOnly || !selectedRows || selectedRows.length === 0)"
              (click)="openGenerateReportDialog(true)"
              translate>
        Reset Report
      </button>
      <button class="btn btn-default btn-filters"
              *ngIf="!receivedReport && view !== 'prereport'"
              [disabled]="!reportActive || readOnly"
              (click)="openFilters()"
              [ngClass]="{active: filtersActive(filters)}">
        <i class="icon-filter"></i>
        <ng-container translate>Filters</ng-container>
      </button>
    </div>
    <div class="right-actions">
      <span *ngIf="lastSaved && !saving && view !== 'prereport'" class="status" translate>
        {{ (!readOnly ? 'Last Saved' : 'Approved') | translate }}<br/>{{ lastSaved | translate }}
      </span>
      <span *ngIf="saving" class="status" translate>
        Saving <i class="icon-resend"></i>
      </span>
      <search
        (searchTermChange)="changeSearch($event)"
        [(searchTerm)]="search">
      </search>
      
      <button *ngIf="view !== 'prereport'" class="btn btn-default" (click)="exportPayRecords()" [disabled]="empty || !reportActive" translate>Export</button>
      <button *ngIf="!readOnly && view !== 'prereport'" class="btn btn-primary" (click)="changeReportStatus('approve')" [disabled]="empty || !reportActive" translate>{{ readOnly ? 'Approved' : 'Approve' }}</button>
      <button *ngIf="view === 'prereport' && !reportActive && !readOnly" class="btn btn-primary" (click)="openGenerateReportDialog()" [disabled]="empty" translate>Generate Report</button>
    </div>
  </action-bar>
  <div class="main">
    <div class="loader" *ngIf="processing">
      <h2 translate>Generating Pay Report</h2>
      <p>{{ processingText | translate }}</p>
      <my-loader></my-loader>
    </div>
    <div class="applied-filter-content">
      <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)"></applied-filters>
    </div>
    <div class="new-report" *ngIf="!processing && !reportActive && !empty && view !== 'prereport'">
      <div class="icon">
        <i class="icon-fleet"></i>
      </div>
      <h2 translate>No Pay Report Created</h2>
      <p translate>Select a day to generate a pay report for drivers to review and edit work done for the day.</p>
      <button class="btn btn-primary" (click)="openGenerateReportDialog()" translate>Generate Pay Report</button>
    </div>
    <div class="new-report" *ngIf="view === 'failed'">
      <div class="icon">
        <i class="icon-fleet"></i>
      </div>
      <h2 translate>Pay Report Failed to Generate</h2>
      <p translate>Select a day to generate a pay report for drivers to review and edit work done for the day.</p>
      <button class="btn btn-primary" (click)="openGenerateReportDialog()">Generate Pay Report</button>
    </div>
    <div class="empty-report" *ngIf="empty && !processing && view !== 'prereport'">
      <div class="icon">
        <i class="icon-info"></i>
      </div>
      <h2 translate>No {{ (reportType ? (reportType + ' ' | titlecase) : '') }}Driver Activity</h2>
      <p translate>The day selected has no driver activity available. Select another day from the date picker to generate a pay report.</p>
    </div>
    <driver-pay-prereport *ngIf="view === 'prereport'" [selectedDate]="jobEventDate" [reportType]="reportType" 
                          [search]="search" (searchClear)="changeSearch($event)"></driver-pay-prereport>
    <driver-pay-table *ngIf="view === 'table' && !processing"
                      [(rows)]="payRecords" [readOnly]="readOnly"
                      (selectedRows)="selectedRows = $event"
                      (selectAll)="selectAll($event)"
                      (sortRows)="sort($event)"
                      (rowEdits)="savePaySheet($event)"></driver-pay-table>
    <driver-pay-timeline #driverPayTimeline *ngIf="view === 'timeline' && !processing"
                         [timeInterval]="timeInterval" [timelineStats]="timelineStats"
                         [(payRecords)]="payRecords" [timelineRange]="timelineRange" [readOnly]="readOnly"
                         (selectedRows)="selectedRows = $event" (selectAll)="selectAll($event)"
                         (resetRowIndex)="resetRow($event)" (rowEdits)="savePaySheet($event)"></driver-pay-timeline>
    <div title="{{ 'This cannot be changed. Set at Page Size 10' | translate }}">
      <mat-paginator *ngIf="view !== 'prereport' && !processing" #paySheetPaginator [length]="paySheetService.count"
                   [pageSize]="pageSize" (page)="setPaySheetPage($event)"></mat-paginator>
    </div>
  </div>
</div>
