import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  filter,
  find as _find,
  findIndex,
  intersection,
  intersectionWith,
  isEqual,
  uniqBy,
} from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Preference } from '../../preferences/preference';
import { PreferenceService } from '../../preferences/preference.service';
import { fancyTableAnimation } from '../animations/fancy-table.animation';
import { parseErrors } from '../api.service';
import { AuthenticationService } from '../authentication.service';
import { ResourceService } from '../resource.service';
import {
  tableClickAction,
  tableDragAction,
  tableDropAction,
  tableMenuAction,
  tableParseRecords,
  tableRemoveRecord,
  tableReorderStuckFields,
} from './table.actions';
import defaultConfig from './table.default';
import { Selection, TableColumn, TableConfig, TableData } from './table.types';

@Component({
  selector: 'fancy-table',
  templateUrl: './fancy-table.component.html',
  styleUrls: ['./fancy-table.component.scss'],
  animations: [fancyTableAnimation],
})
export class FancyTableComponent implements OnInit, OnChanges, OnDestroy {
  // inputs
  @Input() query = {};
  @Input() availableColumns: TableColumn[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() detailColumns = [];
  @Input() filters = [];
  @Input() availableFilters = [];
  @Input() search = '';
  @Input() templateRef: TemplateRef<any>;
  @Input() config: TableConfig = { ...defaultConfig };
  @Input() count = 0;
  @Input() filtersDisplayed: boolean;
  @Input() customClasses = '';

  // outputs
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  @Output() filtersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() onClickAction: EventEmitter<any[]> = new EventEmitter();
  @Output() onDropAction: EventEmitter<any[]> = new EventEmitter();
  @Output() checkBoxToggle: EventEmitter<any[]> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<Selection> = new EventEmitter();
  @Output() masterCheckBoxToggle: EventEmitter<any[]> = new EventEmitter();
  @Output() columnChange: EventEmitter<any[]> = new EventEmitter();
  @Output() onMenuAction: EventEmitter<any[]> = new EventEmitter();
  @Output() onDataLoaded: EventEmitter<TableData> = new EventEmitter();

  // content children
  @ContentChild('columnTemplates') columnTemplates;
  @ContentChild('detailTemplates') detailTemplates;

  // view children
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('fancyTable') fancyTable: MatTable<any>;
  @ViewChild('masterCheckbox') masterCheckbox: MatCheckbox;

  // variables
  dataSource = new MatTableDataSource();
  preferenceKey = '';
  preference: Preference;
  loading = false;
  errors = [];
  record: any = {};
  records: any = [];
  page = 1;
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  exclusion: SelectionModel<any> = new SelectionModel<any>(true, []);
  allSelected = false;
  userColumns: TableColumn[] = [];
  internalColumns: string[] = ['select', 'expand', 'actions'];
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  routerPageNumber = 0;
  loadingPreference = false;
  apiCall: Subscription = null;
  queryDateParam: string = null;
  queryDateParamInitialLoad = true;

  // dragula
  dragulaName = `order-columns-${this.generateRandomId()}`;
  dragStarted = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.dragStarted) {
      this.dragStarted = false;
      this.dragulaService.destroy(this.dragulaName);

      // create a new instance of dragula
      this.dragulaName = this.generateRandomId();
      this.startDragula(this.dragulaName);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public injector: Injector,
    private dragulaService: DragulaService,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
    public renderer2: Renderer2
  ) {
    this.dragulaName = this.generateRandomId();
    this.startDragula(this.dragulaName);
  }

  ngOnInit() {
    this.selection.isSelected = this.isSelected.bind(this);
    this.exclusion.isSelected = this.isExcluded.bind(this);
    this.config = { ...defaultConfig, ...this.config };

    this.allSubscriptionsToUnsubscribe.push(
      this.route.queryParams.subscribe((params) => {
        if (params.page && params.date && params.date !== this.queryDateParam) {
          this.queryDateParam = params.date;
          if (this.queryDateParamInitialLoad) {
            this.queryDateParamInitialLoad = false;
          } else {
            this.updateUrl({}, true, false);
          }
        }
      })
    );

    this.userColumns = filter(this.availableColumns, (column) => {
      return !this.internalColumns.includes(column.key);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config || changes.query) {
      if (
        (changes.config && changes.config.firstChange) ||
        (changes.query && changes.query.firstChange)
      ) {
        this.getPreferences();
      } else if (this.config.updateForConfigChanges) {
        this.getRecords();
      } else {
        // Do nothing...
        // this.updateUrl({ page: 1 });
      }
    }
    if (
      changes['displayedColumns'] &&
      !changes['displayedColumns'].firstChange
    ) {
      if (!this.config.ignorePreferences) {
        this.savePreferences();
      }
    }

    if (changes['filters'] && !changes['filters'].firstChange) {
      this.page = 1;
      this.filters = changes.filters.currentValue;
      if (
        !this.config.ignorePreferences &&
        this.preference &&
        this.preference.blob &&
        this.preference.blob['filters'] &&
        !isEqual(this.preference.blob['filters'].sort(), this.filters.sort())
      ) {
        this.savePreferences();
        this.getRecords();
      }
    }

    if (
      changes.search &&
      !changes.search.firstChange &&
      changes.search.currentValue !== changes.search.previousValue
    ) {
      this.search = changes.search.currentValue;
      this.updateUrl({ page: 1, search: changes.search.currentValue });
    }

    if (
      changes.config &&
      !changes.config.firstChange &&
      !isEqual(changes.config.currentValue.query, changes.config.previousValue.query)
    ) {
      this.getRecords();
    }
  }

  ngOnDestroy() {
    // closing router and filters subscriptions
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
    if (this.dialog && typeof this.dialog.closeAll === 'function') {
      this.dialog.closeAll();
    }
    this.dragulaService.destroy(this.dragulaName);
  }

  startDragula(dragulaName) {
    const nonMovableColumns = [
      'mat-column-select',
      'mat-column-expand',
      'mat-column-actions',
    ];
    const dragulaOptions = {
      moves: (el) => {
        return intersection(el.classList, nonMovableColumns).length === 0;
      },
      revertOnSpill: true,
      direction: 'horizontal',
    };

    this.allSubscriptionsToUnsubscribe.push(
      this.dragulaService.drag().subscribe(() => {
        this.dragStarted = true;
      })
    );
    this.dragulaService.createGroup(dragulaName, dragulaOptions);

    this.allSubscriptionsToUnsubscribe.push(
      this.dragulaService
        .dropModel(dragulaName)
        .pipe(delay(50))
        .subscribe(() => {
          this.dragStarted = false;
          this.preferenceKey = `table-preferences[${this.config.preferenceKey}]`;
          this.reorderStuckFields();
          this.savePreferences();
        })
    );
  }

  getRecords(query = {}) {
    if (this.apiCall && typeof this.apiCall.unsubscribe === 'function') {
      this.apiCall.unsubscribe();
    }
    let service: ResourceService<any> = this.injector.get<any>(
      this.config.service
    );
    this.loading = true;
    let order =
      (this.config.sortDirection === 'asc' ? '' : '-') + this.config.sortBy;
    let configQuery = { ...this.config.query };
    if (this.filters && this.filters.length) {
      if (this.config.filterQuery) {
        const filtersToUse = this.filters.filter(
          (f) => f.removeFromFiltersQuery !== true
        );
        const filtersForQuery = this.filters.filter(
          (f) => f.removeFromFiltersQuery === true
        );
        if (filtersToUse && filtersToUse.length) {
          query['filters'] = filtersToUse;
        }
        filtersForQuery.forEach((filterQuery) => {
          if (
            filterQuery.key &&
            filterQuery.values &&
            filterQuery.values.length
          ) {
            query[filterQuery.key] = filterQuery.values[0];
          }
        });
      } else {
        this.filters.forEach((f) => {
          query = { ...query, ...f.query };
        });
      }
      if (
        configQuery['user_tags'] &&
        configQuery['user_tags'] === 'True' &&
        this.filters.findIndex((f) => f['key'] === 'tags') > -1
      ) {
        delete configQuery['user_tags'];
      }
    }
    if (this.query) {
      query = { ...query, ...this.query };
    }
    service.slug = this.config.slug || undefined;
    const listFunction = this.config.serviceFunction
      ? this.config.serviceFunction
      : 'list';

    const fullQuery = {
      ordering: order,
      search: this.search,
      page_size: this.config.pageSize,
      page: this.page || this.paginator.pageIndex + 1,
      ...configQuery,
      ...query,
    };

    this.apiCall = service[listFunction](fullQuery).subscribe(
      (records: unknown[]) => {
        this.dataSource.data = tableParseRecords(records, this.config);
        this.count = service.count || records.length || 0;
        this.onDataLoaded.emit({
          data: records,
          query: fullQuery,
          count: this.count,
        });
        if (this.routerPageNumber > 1) {
          this.setPageNumber(this.routerPageNumber);
          this.routerPageNumber = 0;
        }
        this.config.customFieldsLoaded = true;
        this.loading = false;
      },
      (error) => {
        this.errors = parseErrors(error);
        this.loading = false;
      }
    );
  }

  /**
   * @param  {} updatedObj
   * @returns void
   * Update the table without making get request
   * update the edited field of the table without reloading entire table
   */
  updateTable(updatedObj): void {
    const index = findIndex(this.dataSource.data, { id: updatedObj.id });
    this.dataSource.data.splice(index, 1, updatedObj);
    if (this.fancyTable) {
      this.fancyTable.renderRows();
    }
  }

  updateUrl(
    params: {
      page?: number;
      pageSize?: number;
      sortBy?: string;
      sortAsc?: string;
      search?: string;
      [key: string]: any;
    },
    getRecords = true,
    savePreferences = true
  ) {
    this.saveGlobalTableVariables({ ...params }, savePreferences);
    params['search'] = params['search'] || this.search;
    if (this.route && this.route.snapshot) {
      params = { ...this.route.snapshot.queryParams, ...params };
    }

    this.router
      .navigate(['.'], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
      .then(() => {
        if (getRecords) {
          this.getRecords();
        }
      });
  }

  saveGlobalTableVariables(
    {
      page = this.page,
      pageSize = this.config.pageSize,
      sortBy = this.config.sortBy,
      sortAsc = this.config.sortDirection,
      search = this.search,
    },
    savePreferences = true
  ) {
    this.page = page;
    this.config.pageSize = pageSize;
    this.config.sortBy = sortBy;
    this.config.sortDirection = sortAsc;
    this.search = search;
    if (savePreferences) {
      this.savePreferences();
    }
    if (this.paginator) {
      this.paginator.pageIndex = page - 1;
    }
  }

  sortData(event): void {
    if (event) {
      let sortBy = this.config.sortBy;
      let sortDirection = event.direction === 'asc' ? 'asc' : 'desc';
      this.config.sortDirection = sortDirection;
      let column = _find(this.availableColumns, { key: event.active });
      if (column) {
        sortBy = column['sortBy'] || event.active;
        this.config.sortBy = sortBy;
      }

      this.updateUrl({
        page: 1,
        pageSize: this.config.pageSize,
        sortBy,
        sortAsc: sortDirection,
      });
    }
  }

  pageChange(event: PageEvent) {
    const initialPageSize = this.config.pageSize;
    if (event) {
      if (initialPageSize !== event.pageSize) {
        this.paginator.firstPage();
        this.updateUrl({ page: 1, pageSize: event.pageSize }, true, false);
      } else {
        const page =
          event.pageIndex >= this.page ? this.page + 1 : this.page - 1;
        const pageSize = event.pageSize || this.config.pageSize;
        this.updateUrl(
          {
            page,
            pageSize,
          },
          true,
          false
        );
      }
      document.querySelector('mat-table').scrollTop = 0;
    }
  }

  clearSearch() {
    this.loading = true;
    this.filters = [];
    this.search = '';
    this.searchChange.emit('');
    this.filtersChange.emit([]);
    this.updateUrl({ search: '' });
  }

  openNewRecord() {
    this.router.navigate(this.config.newRecordRoute);
  }

  resetSelections() {
    this.selection.clear();
    this.exclusion.clear();
    this.allSelected = false;
  }

  masterToggle(event) {
    let service = this.injector.get<any>(this.config.service);
    if (this.allSelected || !event.checked) {
      this.resetSelections();
    }
    service.allSelected = event.checked;
    this.masterCheckBoxToggle.emit([event.checked, this.dataSource.data]);
    this.allSelected = event.checked;
    this.selectionChanged.emit({
      allSelected: event.checked,
      selection: this.selection,
      exclusion: this.exclusion,
    });
  }

  toggleSelection(selection, row, event: MatCheckboxChange) {
    let service = this.injector.get<any>(this.config.service);
    if (service.allSelected && !event.checked) {
      this.exclusion.select(<any>row);
      const startRowCount = this.selection.selected.length;
      this.selection.deselect(<any>row);
      const stopRowCount = this.selection.selected.length;
      if (startRowCount === stopRowCount && row.id) {
        let _row = _find(this.selection.selected, { id: row.id });
        this.selection.deselect(<any>_row);
      }
    } else if (!event.checked) {
      const startRowCount = this.selection.selected.length;
      this.selection.deselect(<any>row);
      const stopRowCount = this.selection.selected.length;
      if (startRowCount === stopRowCount && row.id) {
        let _row = _find(this.selection.selected, { id: row.id });
        this.selection.deselect(<any>_row);
      }
    } else {
      this.selection.select(<any>row);
      const startRowCount = this.exclusion.selected.length;
      this.exclusion.deselect(<any>row);
      const stopRowCount = this.exclusion.selected.length;
      if (startRowCount === stopRowCount && row.id) {
        let _row = _find(this.exclusion.selected, { id: row.id });
        this.exclusion.deselect(<any>_row);
      }
    }
    this.checkBoxToggle.emit([service.allSelected, event.checked, row]);
    this.allSelected = service.allSelected;
    this.selectionChanged.emit({
      allSelected: this.allSelected,
      selection: this.selection,
      exclusion: this.exclusion,
    });
  }

  isSelected(row: any): boolean {
    return this.selection.selected.find((el) => el.id === row.id);
  }

  isExcluded(row: any): boolean {
    return this.exclusion.selected.find((el) => el.id === row.id);
  }

  reorderStuckFields(): void {
    tableReorderStuckFields(this.displayedColumns);
  }

  clickAction(event, row): void {
    tableClickAction(event, row, this.record, this.onClickAction);
  }

  dragAction(event, row): void {
    tableDragAction(event, row);
  }

  dropAction(event, row): void {
    tableDropAction(event, row, this.config, this.onDropAction);
  }

  menuAction(name, row) {
    tableMenuAction(name, row, this.onMenuAction);
  }

  getPreferences(): void {
    if (this.config.ignorePreferences) {
      return;
    }
    this.loadingPreference = true;
    const currentUser = this.authenticationService.user();
    this.preferenceKey = `table-preferences[${this.config.preferenceKey}]`;
    if (this.preference && this.preference.id) {
      this.preferenceService.get(this.preference.id).subscribe((preference) => {
        this.preference = preference;
        this.parsePreferences();
      });
    } else {
      this.preferenceService
        .list({
          name: this.preferenceKey,
          type: 'user',
          profile: currentUser.id,
        })
        .subscribe((preferences) => {
          if (preferences && preferences.length) {
            this.preference = preferences[0];
            this.parsePreferences();
          } else {
            this.preference = <Preference>{};
            this.savePreferences();
          }
        });
    }
  }

  savePreferences(): void {
    if (this.config.ignorePreferences) { return; }
    if (this.preferenceKey) {
      const currentUser = this.authenticationService.user();
      const newPreference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id,
        blob: {
          columns: this.displayedColumns,
          filters: this.filters,
          pageSize: this.config.pageSize,
        },
      };
      this.preferenceService.save(newPreference).subscribe((preference) => {
        this.preference = preference;
      });
    }
  }

  /**
   * Examine the found preference to determine if it has columns and that those
   * columns are included in the list of `availableColumns` for this table.
   *
   * Capture the available columns and set the displayed column list to those
   * keys.
   */
  parsePreferences(): void {
    if (!this.preference || !this.preference.blob) { return; }

    if (this.preference.blob['columns']) {
      const columns: string[] = intersectionWith(
        this.preference.blob['columns'],
        this.availableColumns,
        (col, c) => col === c.key
      );
      this.displayedColumns = columns;
      this.columnChange.emit(columns);
    }

    if (this.preference.blob['filters']) {
      let filters = this.preference.blob['filters'];
      filters = filters.filter((field) => !field['default']);
      filters.forEach((f) => {
        const filterIndex = findIndex(this.filters, {
          key: f.key,
          default: true,
        });
        if (filterIndex !== -1) {
          this.filters[filterIndex] = f;
        }
      });
      this.filters = uniqBy([...this.filters, ...filters], 'key');
      this.filtersChange.emit(this.filters);
    }

    if (this.preference.blob['pageSize']) {
      let pageSize = this.preference.blob['pageSize'];
      this.config.pageSize = pageSize;
      this.updateUrl({
        page: 1,
        pageSize: this.config.pageSize,
      });
    } else {
      this.updateUrl({ page: 1 });
    }
  }

  rowIsExpanded(row): boolean {
    return row && row.expanded ? row.expanded : false;
  }

  rowCanExpand(row): boolean {
    return row.hasOwnProperty('canExpand') ? row.canExpand : true;
  }

  resetPageNumber(queryParams: any = {}, getRecords?: boolean) {
    this.page = 1;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.updateUrl(queryParams, getRecords);
  }

  setPageNumber(page: number) {
    this.page = page;
    const queryParams = this.route.snapshot.queryParams;
    if (this.paginator) {
      this.paginator.pageIndex = page - 1;
    }

    this.updateUrl({
      ...queryParams,
      page: this.page,
      pageSize: this.config.pageSize,
    });
  }

  deselectAll() {
    this.masterToggle({ checked: false });
    this.masterCheckbox.checked = false;
  }

  removeRecord(value: string, property = 'id') {
    tableRemoveRecord(this.dataSource, value, property);
  }

  generateRandomId(): string {
    return (Math.random() + 1).toString(36).substring(7);
  }
}
