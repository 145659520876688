import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownComponent } from '../../shared';

export type ReportTypeOption = {
  value: string,
  name: string
};

@Component({
  selector: 'report-type-dialog',
  templateUrl: './report-type-dialog.component.html',
  styleUrls: ['./report-type-dialog.component.scss']
})

export class ReportTypeDialogComponent implements OnInit {
  loading = false;
  errors = [];
  callback: Function;
  typeDropdownConfig = { nameProperty: 'name' };
  typeOptions: ReportTypeOption[] = [
    {
      value: 'all',
      name: 'Internal & External Fleets'
    },
    {
      value: 'internal',
      name: 'Internal Fleet'
    },
    {
      value: 'external',
      name: 'External Fleet'
    }
  ];
  selectedOption: ReportTypeOption;

  @ViewChild('typeSelect') typeSelect: DropdownComponent;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.selectedOption = this.typeOptions.find(option => (data.selectedOption === option.value)); }

  ngOnInit() {
    if (this.selectedOption && this.typeSelect) { this.typeSelect.selectedOption = this.selectedOption; }
  }

  submit() {
    this.callback(this.selectedOption);
    this.dialogRef.close();
  }
}
