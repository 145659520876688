import { Organization } from './organization';
// import { USER } from '../users/mock-user';
import { BROKER } from '../brokers/mock-broker';
import { CARRIER } from '../carriers/mock-carrier';
import { DriverNotifications, User } from 'app/users/user';


// TODO: Fix the circular dependency
export const USER: User = <User> {
  id: '00000000-0000-0000-0000-000000000001',
  firstName: 'John',
  lastName: 'Smith',
  name: 'John Smith',
  email: 'jsmith@ruckit.me',
  username: 'jsmith@ruckit.me',
  title: 'Mock User',
  organization: null,
  phoneNumber: '8675309',
  status: 'active',
  token: '',
  image: 'http://lorempixel.com/52/52/people',
  remittanceInfo: null,
  sidebar: false,
  createdAt: null,
  driverNotificationEmail: true,
  jobNotificationEmail: true,
  afCarrierDispatchNotificationEmail: false,
  notifications: <DriverNotifications>{},
  lastModified: null,
  loading: false,
  selected: false,
  driver: null,
  save: null,
  isDriver: false,
  isCarrier: false,
  isRuckit: false,
  isCrh: false,
  isScaleit: true,
  organizationName: null,
  favoriteTags: [],
  favoriteLocation: null,
  tags: [],
  readOnlyUser: false,
  passwordCurrent: null,
  password: null,
  passwordConfirmation: null,
  classes: '',
  workOrderSignatureImage: '',
  workOrderSignatureName: '',
  features: null,
  enabledFeatures: null,
  hasSignature: true,
  lafargeRegion: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  }
};


export let ORGANIZATION: Organization = <Organization> {
  id: '00000000-0000-0000-0000-000000000001',
  createdAt: '2017-01-09T19:38:51.304432Z',
  lastModified: '2017-01-11T15:26:21.137791Z',
  name: 'Sample Organization',
  email: '',
  contactName: '',
  inviteCode: 'VT4RFJ',
  image: null,
  street: '206 E. 9th Street',
  address2: null,
  city: 'Austin',
  state: 'TX',
  country: 'US',
  zipcode: '78701',
  phoneNumber: '1235556789',
  faxNumber: '',
  paymentTerms: '5',
  primaryContact: USER,
  billingContact: USER,
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingCountry: 'US',
  billingZipcode: '',
  billingPhoneNumber: '',
  billingEmail: '',
  broker: BROKER,
  carrier: CARRIER,
  contractor: null,
  detail: 'San Diego, CA<br />Carrier',
  remittanceInfo: null,
  dvirRequired: false,
  driverFleetCanViewRates: true,
  driverFleetQuickTicket: true,
  canCreateJobs: true,
  invoiceablePunchcardCount: '0',
  invoiceableTripCount: '0',
  posEnabled: false,
  isCrh: false,
  allDriversEnabled: false,
  hasLeasedOrgs: false,
  startingTicketNumber: '0',
  startingInvoiceNumber: '0',
  posPriceList: null,
  uniqueBillingId: '',
  driverFleetRequireAssignmentConfirmation: false,
  avoidTolls: false,
  qrEnabled: false,
  advBillingEnabled: false,
  hasCopyAssignment: false,
  hasWorkOrder: false,
  workOrderDbaName: 'Sample Organization, LLC',
  workOrderCompanyType: 'llc',
  features: null,
  enabledFeatures: null,
  dotNumber: '0001',
  mcNumber: '0001',
  dirNumber: '0001',
  einNumber: '0001',
  isAb5Compliant: true,
  ab5SubmittedDate: '2017-01-09T19:38:51.304432Z',
  ab5SubmittedBy: '00000000-0000-0000-0000-000000000001',
  insuranceDocument: 'overweight-permit.pdf',
  insuranceExpirationDate: '2020-12-12T19:38:51.304432Z',
  overweightPermitExpirationDate: '2020-12-12T19:38:51.304432Z',
  overweightPermit: 'overweight-permit.pdf',
  senderEmail: '',
  senderName: ''
};
