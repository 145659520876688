<div class="mobile-dispatch-trucks">
  <div class="active-driver" *ngIf="selectedDriver">{{ selectedDriver.name }} - {{ selectedDriver.carrier && selectedDriver.carrier.name }}</div>
  <div *ngFor="let truck of trucks" class="slot"
        (click)="selectedTruck = ((selectedTruck && selectedTruck.id) === truck.id) ? null : truck">
    <div class="icon">
      <span *ngIf="!truck.image"><i class="icon-truck"></i></span>
      <img *ngIf="truck.image" [src]="truck.image"/>
    </div>
    <div class="info">
      <h3>{{ truck.name }}</h3>
      <h4>{{ truck.truckType.name }}</h4>
    </div>
    <div class="actions">
      <button class="radio-button" [ngClass]="{ 'active': (selectedTruck && selectedTruck.id) === truck.id }"></button>
    </div>
  </div>
  <div class="no-results" *ngIf="trucks && trucks.length === 0">
    <div>
      <h2 translate>No Results</h2>
      <p translate>We weren't able to find any available trucks</p>
    </div>
  </div>
  <my-loader *ngIf="loading" class="mobile-loader full-height"></my-loader>
  <div class="bottom-action" *ngIf="selectedTruck">
    <button translate (click)="select(selectedTruck)">ASSIGN TRUCK</button>
  </div>
</div>
