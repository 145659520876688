<form #filters="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Jobs</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group job-name">
          <label translate>Job</label>
          <ruckit-dropdown
            #jobsDropdown
            [options]="jobs"
            [selectedOption]="selectedJob"
            [config]="jobsConfig"
            (onSelect)="onSelect('Job', $event)"
            (onSearch)="onSearch('jobs', $event)"
            title="&ndash;"
          ></ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group project-name">
          <label translate>Project</label>
          <ruckit-dropdown
            #projectsDropdown
            [options]="projects"
            (onSelect)="onSelect('Project', $event)"
            (onSearch)="onSearch('projects', $event)"
            [selectedOption]="selectedProject"
            [config]="projectsConfig"
            title="&ndash;"
          ></ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group customer-name">
          <label translate>Customer</label>
          <ruckit-dropdown
            #customersDropdown
            [options]="customers"
            (onSelect)="onSelect('Customer', $event)"
            (onSearch)="onSearch('customers', $event)"
            [selectedOption]="selectedCustomer"
            [config]="customersConfig"
            title="&ndash;"
          ></ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group start-location">
          <label translate>Start Location</label>
          <ruckit-dropdown
            #startLocationsDropdown
            [options]="startLocations"
            (onSelect)="onSelect('Start Location', $event)"
            (onSearch)="onSearch('startLocations', $event)"
            [selectedOption]="selectedStartLocation"
            [config]="locationsConfig"
            title="&ndash;"
          ></ruckit-dropdown>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group end-location">
          <label translate>End Location</label>
          <ruckit-dropdown
            #endLocationsDropdown
            [options]="endLocations"
            (onSelect)="onSelect('End Location', $event)"
            (onSearch)="onSearch('endLocations', $event)"
            [selectedOption]="selectedEndLocation"
            [config]="locationsConfig"
            title="&ndash;"
          ></ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group job-number">
          <label translate>Job #</label>
          <input 
            type="text" 
            name="job-number" 
            [(ngModel)]="model.jobNumber" 
          />
        </div>
      </div>
    </div>
    <a (click)="clear()" translate>Clear Filters</a>

    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); (false)" translate>
      Filter Results
    </button>
  </div>
</form>
