import {
  Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { DrawingManager } from '@ngui/map';
import { uniqWith, isEqual, find as _find, reject, filter } from 'lodash';
import { Subscription } from 'rxjs';

import { Location } from '../../locations/location';
import { LocationService } from '../location.service';
import { AuthenticationService } from '../../shared/authentication.service';
import { ApiService, parseErrors } from '../../shared/api.service';
import { LocationOwnerService } from '../location-owner.service';
import { DropdownComponent } from '../../shared';
import { Address } from '../address';
import { LocationTypeService } from '../location-type.service';
import { LocationOwnerSerializer } from '../location-owner.serializer';
import { Tag } from '../../tags/tag';

declare let google: any;

@Component({
  selector: 'edit-location-details',
  templateUrl: './edit-location-details.component.html',
  styleUrls: ['./edit-location-details.component.scss']
})
export class EditLocationDetailsComponent implements OnInit, OnDestroy {
  @Input() includeInstructions = true;
  @Input() location: Location;
  @Input() isGeoFenceAvailable = false;
  @Input() selectedGeoFence: any;
  @Output() onPlaceChanged: EventEmitter<Location> = new EventEmitter();
  @Output() onEditCompletion: EventEmitter<Location> = new EventEmitter();
  @Output() clearGeoFence: EventEmitter<any> = new EventEmitter();
  loading = false;
  showLocationOwnerTextField = false;
  errors = [];
  states = [];
  stateOptions = [];
  countryOptions = [];
  ownerOptions = [];
  ownersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  locationOwnersReq: Subscription;
  zoom = 8;
  callback: Function;
  drawingMode = undefined;
  polygon;
  paths = [];
  returnTo: string;
  marker;
  address: Address = new Address();
  selectedAddress: Address = new Address();
  addresses: Address[] = [];
  showingAddresses: boolean;
  selectedOverlay: any;
  mapOptions = {};
  qrEnabled = false;
  laserFicheEnabled = false;
  map: google.maps.Map;

  locationTypeDropdownConfig = {
    service: LocationTypeService,
    selectText: 'Select Location Type',
    loadingText: 'Loading Location Types...',
    noResultsText: 'No Location Types'
  };

  @ViewChild(DrawingManager) drawingManager: DrawingManager;
  @ViewChild('ownersDropdown') ownersDropdown: DropdownComponent;

  constructor(
    private locationService: LocationService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private locationOwnerService: LocationOwnerService
  ) { }

  ngOnInit() {
    this.states = this.apiService.getStates();
    this.countryOptions = this.apiService.getCountries().map(country => {
      return {
        id: country.abbreviation,
        name: country.name
      };
    });
    if (this.location && this.location.country) {
      this.selectCountry(this.location.country);
    } else {
      this.selectCountry('US');
    }

    const user = this.authenticationService.user();
    this.qrEnabled = user && user.organization && user.organization.qrEnabled;

    const enabledFeatures = this.authenticationService.enabledFeatures();
    if (enabledFeatures && enabledFeatures.includes('useLaserficheExport')) {
      this.laserFicheEnabled = true;
    }

    this.getLocationOwners();
  }

  ngOnDestroy() {
    if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
      this.locationOwnersReq.unsubscribe();
    }
  }

  getLocationOwners(query = {}) {
    if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
      this.locationOwnersReq.unsubscribe();
    }

    this.locationOwnersReq = this.locationOwnerService.list({
      ordering: 'name',
      ...query
    }).subscribe(locationOwners => {
      this.ownerOptions = locationOwners.map(locationOwner => {
        return { name: locationOwner.name, id: locationOwner.id };
      });
      let ownOrganization = this.authenticationService.getOrganization();
      if (ownOrganization) {
        let _locationOwner = new LocationOwnerSerializer().fromJson({
          organization: ownOrganization.id,
          name: ownOrganization.name
        });
        this.ownerOptions = reject(this.ownerOptions, _locationOwner);
        this.ownerOptions.unshift(_locationOwner);
      }
      if (this.location.locationOwner && this.location.locationOwner.id) {
        this.ownerOptions = reject(this.ownerOptions, this.location.locationOwner);
        this.ownerOptions.unshift(this.location.locationOwner);
        this.ownersDropdown.selectedOption = _find(this.ownerOptions, { id: this.location.locationOwner.id });
      }
      this.ownerOptions.unshift({
        name: 'Add a New Owner',
        id: 'new-location-owner',
        button: true
      });
      if (this.ownersDropdown) {
        this.ownersDropdown.config.loadingOptions = false;
      }
      this.ownersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.ownersConfig.loadingOptions = false;
    });
  }

  dropdownNextPage(e, type) {
    let config;
    let service;
    let options;

    switch (type) {
      case 'owner':
        config = this.ownersConfig;
        service = this.locationOwnerService;
        options = this.ownerOptions;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'owner':
              let _ownerOptions = results.map(result => {
                return { name: result.name, id: result.id };
              });
              this.ownerOptions = this.ownerOptions.concat(_ownerOptions);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'owner':
        this.getLocationOwners({ search: term });
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  submit(): void {
    this.loading = true;
    let coordinates = null;
    if (this.polygon) {
      let polygonPath = this.polygon.getPath();
      if (polygonPath) {
        let pathArray = polygonPath.getArray();
        if (pathArray) {
          coordinates = pathArray.map(path => [path.lng(), path.lat()]);
          coordinates = uniqWith(coordinates, isEqual);
          if (coordinates && coordinates[0] !== coordinates.reverse[0]) {
            coordinates.push(coordinates[0]);
          }
        }
      }
    }
    if (this.selectedGeoFence) {
      this.location.geofence = this.selectedGeoFence;
    }
    if (this.location.geofence && this.location.geofence instanceof Array) {
      let geofence = this.location.geofence[0];
      if (geofence && geofence.geometry) {
        this.location.geofence = geofence.geometry;
      }
    } else if (coordinates) {
      this.location.geofence = {
        type: 'Polygon',
        coordinates: [coordinates]
      };
    }
    this.locationService.save(this.location).subscribe(location => {
      this.loading = false;
      this.onEditCompletion.emit(location);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  cancel(): void {
    this.onEditCompletion.emit();
  }

  placeChanged(place): void {
    this.location.latitude = place.geometry.location.lat();
    this.location.longitude = place.geometry.location.lng();
    if (this.location.latitude && this.location.longitude) {
      this.marker = null;
      this.marker = {
        latLng: new google.maps.LatLng(
          parseFloat(this.location.latitude), parseFloat(this.location.longitude)
        ),
        mappable: true
      };
      this.location.marker = this.marker;
    }
    this.zoom = 14;
    this.address.street = null;

    this.location.city = this.extractFromAddress(place.address_components, 'locality');
    this.countryOptions.map(country => {
      if (country.name === this.extractFromAddress(place.address_components, 'country')) {
        this.selectCountry(country.id);
      }
    });
    this.states.map(state => {
      if (state.name === this.extractFromAddress(place.address_components, 'administrative_area_level_1')) {
        this.location.state = state.abbreviation;
      }
    });
    this.location.zipcode = this.extractFromAddress(place.address_components, 'postal_code');
    this.location.name = place.name;
    this.location.street = this.extractFromAddress(place.address_components, 'street_number') + ' ' +
      this.extractFromAddress(place.address_components, 'route');
    if (this.location.street && this.location.street.trim().length === 0) {
      this.location.street = this.location.name;
    }
    this.onPlaceChanged.emit(this.location);
  }

  selectState(state): void {
    this.location.state = state;
  }

  selectCountry(country: string): void {
    this.location.country = country;
    this.stateOptions = filter(this.states, { country: country }).map(state => {
      return {
        id: state.abbreviation,
        name: state.name
      };
    });
  }

  selectOwner(owner): void {
    if (owner['id'] === 'new-location-owner') {
      this.showLocationOwnerTextField = true;
      this.location.locationOwner.id = null;
    } else {
      this.location.locationOwner = owner;
    }
  }

  extractFromAddress(components, type) {
    for (let i = 0; i < components.length; i++) {
      for (let j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] === type) {
          return components[i].long_name;
        }
      }
    }
    return '';
  }

  geolocate(): void {
    let displayName = `${this.location.street} ${this.location.city}, ${this.location.state} ${this.location.zipcode}`;
    if (this.location.street && this.location.street.trim().length > 0) {
      displayName = displayName.replace('undefined', '');
      displayName = displayName.replace(' ,', ',');
    }
    this.address = {
      street: this.location.street,
      street2: null,
      city: this.location.city,
      state: this.location.state,
      zipcode: this.location.zipcode,
      country: this.location.country,
      displayName: displayName
    };

    let status = Object.keys(this.address).every((x) => {
      return this.address[x] && this.address[x].length;
    }) === true;
    let address = Object.keys(this.address).map((x) => {
      return this.address[x];
    }).join(', ');
    this.copyUpdatedAddress();

    if (status) {
      this.locationService.getLocationByAddress(address).subscribe((res) => {
        let coordinates = res && res[0];
        this.location.latitude = coordinates.geometry.location.lat;
        this.location.longitude = coordinates.geometry.location.lng;
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  copyUpdatedAddress(): void {
    if (this.address && this.location && this.address.street !== this.location.street) {
      this.location.street = this.address.street;
      this.location.city = this.address.city;
      this.location.state = this.address.state;
      this.location.zipcode = this.address.zipcode;
      this.location.country = this.address.country;
    }
    this.address = new Address();
  }

  reverseGeocode(): void {
    if (this.location.latitude && this.location.longitude) {
      let coordinates = [this.location.latitude, this.location.longitude];
      this.locationService.getAddressByCoordinates(coordinates).subscribe((res) => {
        this.addresses = this.processAddresses(res);
        this.selectedAddress = this.addresses[0];
        this.address = this.addresses[0];
        if (this.location && !this.location.street) { this.copyUpdatedAddress(); }
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  processAddresses(results): Address[] {
    let addresses: Address[] = [];

    results.forEach(result => {
      let address: Address = new Address();
      address.street = this.extractFromAddress(result.address_components, 'street_number')
        + ' '
        + this.extractFromAddress(result.address_components, 'route');
      address.city = this.extractFromAddress(result.address_components, 'locality');
      this.apiService.getStates().map((state) => {
        if (state.name === this.extractFromAddress(result.address_components, 'administrative_area_level_1')) {
          address.state = state.abbreviation;
        }
      });
      address.zipcode = this.extractFromAddress(result.address_components, 'postal_code');
      address.displayName = `${address.street} ${address.street2}, ${address.city}, ${address.state} ${address.zipcode}`;
      if (address.street && address.street.trim().length > 0) {
        address.displayName = address.displayName.replace('undefined', '');
        address.displayName = address.displayName.replace(' ,', ',');
        addresses.push(address);
      }
    });

    return addresses;
  }

  updateAddress(address: Address): void {
    if (address && this.location) {
      this.location.street = address.street;
      this.location.city = address.city;
      this.location.state = address.state;
      this.location.zipcode = address.zipcode;
      this.location.country = address.country;
    }
  }

  tagChange(tags: Tag[]): void {
    this.location.tags = tags.map(t => (t.name));
  }

  onSelect(propertyName: string, e): void {
    this.location[propertyName] = e;
  }

  onClearGeoFence() {
    this.clearGeoFence.emit();
  }
}
