<form #newTruck="ngForm" [ngClass]="{
  'mobile': device && device.mobile,
  'tablet': device && device.tablet,
  'desktop': device && device.desktop
}">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add a Truck</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content class="add-truck">
    <div class="form-content">

      <div class="field-group truck-type full-width">
        <label class="required" translate>Truck Type</label>
        <input type="hidden" name="truckType" required [(ngModel)]="selectedTruckType" #truckType="ngModel" />
        <ruckit-dropdown [config]="truckTypesDropdownConfig"
                  [selectedOption]="selectedTruckType"
                  (onSelect)="selectTruckType($event)">
        </ruckit-dropdown>
      </div>

      <div class="field-group truck-type full-width">
        <label class="required" translate>Carrier</label>
        <input type="hidden" name="carrier" required [(ngModel)]="carrierId" #carrier="ngModel" />
        <ruckit-dropdown [config]="carriersConfig"
                  [selectedOption]="carrierId"
                  (onSelect)="selectCarrier($event)">
        </ruckit-dropdown>
      </div>

      <div class="field-row">
        <div class="field-group truck-number">
          <label class="required" translate>Truck #</label>
          <input type="text" name="truck-number" placeholder="{{ 'Truck Number' | translate }}" required [(ngModel)]="model.name" #name="ngModel" />
          <span [ngClass]="{'ng-valid': name.valid, 'ng-invalid': name.invalid, 'ng-pristine': name.pristine}"></span>
        </div>

        <div class="field-group license-plate">
          <label class="required" translate>License Plate</label>
          <input type="text" name="license-plate" placeholder="{{ 'License Plate' | translate }}" required [(ngModel)]="model.licensePlate" #licensePlate="ngModel" />
          <span [ngClass]="{'ng-valid': licensePlate.valid, 'ng-invalid': licensePlate.invalid, 'ng-pristine': licensePlate.pristine}"></span>
        </div>
      </div>
      <div class="field-row">
        <div class="field-group">
          <label>Markets</label>
          <tag-input name="tags" (onSelect)="tagChange($event)"></tag-input>
        </div>
      </div>
      <div *ngFor="let field of customFields" class="field-group custom-field full-width">
        <label>{{ field.displayName }}</label>
        <input
          type="text"
          placeholder="Value"
          [name]="field.key"
          [value]="field.value"
          [(ngModel)]="model.customFieldData[field.key]"
        >
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="newTruck.form.valid && submit(); false"
            [disabled]="!newTruck.form.valid || loading"
            [ngClass]="{loading: loading}">
      + {{ 'Add Truck' | translate }}
    </button>
  </div>
</form>
