import { Component, ViewChild, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

// libraries
import * as moment from 'moment-timezone';

// angular material
import { MatDialogRef } from '@angular/material/dialog';

// services
import { TripService } from './../trip.service';
import { JobEventService } from '../../job-events/job-event.service';
import { parseErrors } from '../../shared/api.service';
import { AuthenticationService } from '../../shared';
import { AttachmentService } from '../../attachments/attachment.service';
import { CheckinService } from '../../checkins/checkin.service';

// models
import { Job } from '../../jobs/job';
import { JobEvent } from '../../job-events/job-event';
import { CondensedTrip } from '../condensed-trip';
import { Carrier } from '../../carriers/carrier';
import { Driver } from '../../drivers/driver';
import { Truck } from '../../trucks/truck';

// components
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

// constants
import {
  LOADINGTRIPSIGNATUREIMAGETYPE,
  LOADINGTRIPTICKETIMAGETYPE,
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
  UNLOADINGTRIPSIGNATUREIMAGETYPE,
  UNLOADINGTRIPTICKETIMAGETYPE,
} from '../../app.constants';
import {
  JOB_DROPDOWN_CONFIG,
  JOB_EVENTS_DROPDOWN_CONFIG,
  CARRIER_DROPDOWN_CONFIG,
  DRIVER_DROPDOWN_CONFIG,
  TRUCK_DROPDOWN_CONFIG,
} from './dropdownsConfig';

// validators
import { globalTripTimeValidator } from '../trip-time-validators';

@Component({
  selector: 'edit-trip-dialog',
  templateUrl: './edit-trip-dialog.component.html',
  styleUrls: ['./edit-trip-dialog.component.scss'],
})
export class EditTripDialogComponent implements OnInit {
  lockedFields = true;
  jobEventDate = null;
  jobEventDatePickerOptions = null;
  highlightedDates = [];
  jobEvents = [];
  edittedTrip: CondensedTrip = null;
  tripId: string;

  tripLoading = true;
  loading = false;
  errors = [];
  jobEventError = false;
  job: Job;
  jobId: string;

  jobEvent: JobEvent;
  callback: Function;
  activeTab = 'trip-data';
  carrier: Carrier;
  truck: Truck;
  driver: Driver;

  @ViewChild('jobDropdown')
  jobsDropdown: RuckitDropdownComponent;
  jobDropdownConfig = JOB_DROPDOWN_CONFIG(this.translate);

  @ViewChild('jobEventsDropdown')
  jobEventsDropdown: RuckitDropdownComponent;
  jobEventsDropdownConfig = JOB_EVENTS_DROPDOWN_CONFIG(this.translate);

  @ViewChild('carriersDropdown')
  carriersDropdown: RuckitDropdownComponent;
  carrierDropdownConfig = CARRIER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('driversDropdown')
  driversDropdown: RuckitDropdownComponent;
  driverDropdownConfig = DRIVER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('truckDropdown')
  truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = TRUCK_DROPDOWN_CONFIG(this.translate);

  firstLoad = true;

  loadingTicketImages = [];
  unloadingTicketImages = [];
  loadingSignatures = [];
  unloadingSignatures = [];
  imagesToDelete = [];
  editTripForm: FormGroup;
  uploading = false;

  fieldsToCheck = [
    'activeTracking',
    'ticketImage',
    'signatureImage',
    'ticketNumber',
    'weight',
  ];
  disabledFields = {
    carrier: false,
    driver: false,
    calendar: false,
  };

  requiredImages = {
    loadingImages: false,
    unloadingImages: false,
    loadingSignature: false,
    unloadingSignature: false,
  };

  checkinsReq = { loading: null, unloading: null };

  constructor(
    public dialogRef: MatDialogRef<EditTripDialogComponent>,
    private tripService: TripService,
    private attachmentsService: AttachmentService,
    private checkinService: CheckinService,
    private jobEventService: JobEventService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const user = this.authenticationService.user();
    this.carrierDropdownConfig.query = {
      exclude: user.organization.id,
    };
    this.carrierDropdownConfig.prefilledOptions.push(user.organization);
    this.getTrip(this.tripId);
  }

  getTrip(id: string): void {
    this.tripService.getCondensedTrip(id).subscribe(
      (trip) => {
        this.edittedTrip = trip;
        this.job = {
          id: trip.jobId,
          name: trip.job,
        } as Job;
        this.jobEvent = new JobEvent({ id: trip.jobEventId });
        this.carrier = new Carrier({
          id: trip.carrierId,
          name: trip.carrier,
        });
        this.driver = {
          id: trip.driverId,
          name: trip.driver,
        } as Driver;
        this.truck = {
          id: trip.truckId,
          name: trip.truck,
        } as Truck;

        this.getCheckins(this.edittedTrip.id, 'loading');
        this.getCheckins(this.edittedTrip.id, 'unloading');
        this.initForm();
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  getCheckins(tripId: string, kind = 'loading'): void {
    if (
      this.checkinsReq[kind] &&
      typeof this.checkinsReq[kind].unsubscribe === 'function'
    ) {
      this.checkinsReq[kind].unsubscribe();
    }

    this.checkinsReq[kind] = this.checkinService
      .getCheckins({
        ordering: 'name',
        kind: kind,
        trip: tripId,
      })
      .subscribe(
        (checkins) => {
          if (checkins && checkins.length) {
            let checkin = checkins[0];
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'ticketNumber'],
              checkin.ticketNumber
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'weight'],
              checkin.weight
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'date'],
              checkin.date
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'time'],
              moment(checkin.date).format('HH:mm')
            );
            switch (kind) {
              case 'loading':
                this.edittedTrip.loadingCheckin = checkin;
                const loadingTicketImages =
                  this.edittedTrip.loadingCheckin.attachments
                    .filter((img) => img.fileType === TRIPTICKETIMAGETYPE)
                    .map((a) => ({
                      id: a.id,
                      src: a.file,
                      file: a.file,
                      legacy: a.legacy,
                      imgType: LOADINGTRIPTICKETIMAGETYPE,
                    }));
                const loadingSignatures =
                  this.edittedTrip.loadingCheckin.attachments
                    .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
                    .map((file) => ({
                      id: file.id,
                      src: file.file,
                      file: file.file,
                      legacy: file.legacy,
                      imgType: LOADINGTRIPSIGNATUREIMAGETYPE,
                    }));

                this.loadingTicketImages = loadingTicketImages;
                this.loadingSignatures = loadingSignatures;
                this.editTripFormPatchValueNested(
                  ['loadingCheckin', 'images'],
                  [loadingTicketImages]
                );
                this.editTripFormPatchValueNested(
                  ['loadingCheckin', 'signatures'],
                  [loadingSignatures]
                );
                break;

              case 'unloading':
                this.edittedTrip.unloadingCheckin = checkin;
                const unloadingTicketImages =
                  this.edittedTrip.unloadingCheckin.attachments
                    .filter((img) => img.fileType === TRIPTICKETIMAGETYPE)
                    .map((a) => ({
                      id: a.id,
                      src: a.file,
                      file: a.file,
                      legacy: a.legacy,
                      imgType: UNLOADINGTRIPTICKETIMAGETYPE,
                    }));
                const unloadingSignatures =
                  this.edittedTrip.unloadingCheckin.attachments
                    .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
                    .map((file) => ({
                      id: file.id,
                      src: file.file,
                      file: file.file,
                      legacy: file.legacy,
                      imgType: UNLOADINGTRIPSIGNATUREIMAGETYPE,
                    }));
                this.unloadingTicketImages = unloadingTicketImages;
                this.unloadingSignatures = unloadingSignatures;
                this.editTripFormPatchValueNested(
                  ['unloadingCheckin', 'images'],
                  [unloadingTicketImages]
                );
                this.editTripFormPatchValueNested(
                  ['unloadingCheckin', 'signatures'],
                  [unloadingSignatures]
                );
                break;
            }
          }
        },
        (err) => {
          this.errors = err;
        },
        () => {
          this.loading = false;
        }
      );
  }

  submit(): void {
    this.loading = true;
    this.errors = [];

    const requests = this.attachmentsService.combineTicketImagesAndAttachments(
      this.imagesToDelete,
      this.loadingTicketImages,
      this.loadingSignatures,
      this.unloadingTicketImages,
      this.unloadingSignatures,
      this.edittedTrip.loadingCheckin.id,
      this.edittedTrip.unloadingCheckin.id
    );

    const parsedTrip = this.parseTrip();
    delete parsedTrip.loadingCheckin.attachments;
    delete parsedTrip.unloadingCheckin.attachments;

    const updateTrip$ =
      this.tripService.saveWithCheckinsAndAttachments(parsedTrip);

    forkJoin([...requests, updateTrip$]).subscribe(
      (res) => {
        this.callback(res && res.length ? res[0] : null);
        this.dialogRef.close();
      },
      (err) => {
        console.log(err);
        this.errors = parseErrors(Array.isArray(err) ? err : [err]);
        this.loading = false;
      }
    );
  }

  parseTrip(): CondensedTrip {
    const formTrip = this.editTripForm.value;

    const trip: CondensedTrip = {
      ...this.edittedTrip,
      ...this.editTripForm.value,
      id: this.edittedTrip.id,
      job: formTrip.job.id,
      jobEvent: formTrip.jobevent,
      jobevent: formTrip.jobevent,
      carrier: this.editTripForm.value.carrier.id,
      driver: this.editTripForm.value.driver.id,
      truck: this.editTripForm.value.truck.id,
      startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time),
      endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time),
      loadingCheckin: {
        ...formTrip.loadingCheckin,
        id: this.edittedTrip.loadingCheckin.id,
        date: this.parseDateTime(
          formTrip.loadingCheckin.date,
          formTrip.loadingCheckin.time
        ),
      },
      unloadingCheckin: {
        ...formTrip.unloadingCheckin,
        id: this.edittedTrip.unloadingCheckin.id,
        date: this.parseDateTime(
          formTrip.unloadingCheckin.date,
          formTrip.unloadingCheckin.time
        ),
      },
    };
    return trip;
  }

  parseDateTime = (date: any, time: string): string => {
    return !!date && !!time
      ? moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toISOString()
      : null;
  }

  onJobEventDateChanged(values: Date[]): void {
    this.jobEventError = false;
    if (!this.firstLoad && this.job && values && values.length) {
      if (!this.disabledFields.carrier && !this.disabledFields.driver) {
        this.edittedTrip.carrier = null;
        this.edittedTrip.driver = null;
      }
      this.edittedTrip.truck = null;

      const selectedDate = moment(values[0]).format().split('T')[0];
      const jobEvent = this.jobEvents.find((j) =>
        j.shift1Start.includes(selectedDate)
      );
      this.jobEventDate = values[0];

      if (jobEvent) {
        this.jobEvent = jobEvent;
        this.editTripFormSetRequiredFields(jobEvent);
        if (
          jobEvent.job &&
          jobEvent.job.checkinOptions &&
          jobEvent.job.checkoutOptions
        ) {
          this.setRequiredImages(
            jobEvent.job.checkinOptions,
            jobEvent.job.checkoutOptions
          );
        }

        const query = {
          ...this.carrierDropdownConfig.query,
          carriers_for_jobevent: jobEvent.id,
        };

        this.carriersDropdown.config.query = query;
        this.carriersDropdown.getRecords(query);

        this.edittedTrip.jobEventId = jobEvent.id;
        this.editTripFormPatchValue('jobevent', jobEvent.id);
      } else {
        this.jobEventError = true;
        this.jobEvent = null;
      }
    }
  }

  onDateChange(args: string[], dates: Date[]) {
    if (args && args.length > 0 && dates) {
      this.editTripFormPatchValueNested(args, dates[0]);
    }
  }

  editDateTime(field: 'loadingTime' | 'unloadingTime', value: any) {
    if (value) {
      let checkinField =
        'loadingTime' === field ? 'loadingCheckin' : 'unloadingCheckin';

      this.edittedTrip[checkinField].time = value;
      this.editTripFormPatchValueNested([checkinField, 'time'], value);
    }
  }

  enableEditing(): void {
    this.lockedFields = false;
    this.firstLoad = true;
    this.disabledFields.carrier = true;
    this.disabledFields.calendar = true;
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        if (this.disabledFields.carrier || this.disabledFields.calendar) {
          this.disabledFields.carrier = false;
          this.disabledFields.calendar = false;
        }
        // if newly selected job is the same as existing selected job
        if (this.job && this.job.id === e.id) {
          if (this.firstLoad) {
            this.firstLoad = false;
            this.getJobEvents(e.id);
            this.editTripFormPatchValue(filterType, e);
          }
          break;
        }
        this.jobEventDatePickerOptions = null;
        this.job = e;
        this.jobId = e.id;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.resetForm();
        }
        this.getJobEvents(e.id);
        this.editTripFormPatchValue(filterType, e);
        break;
      case 'carrier':
        const carrier = e.carrier;
        if (
          this.jobEvent &&
          (this.edittedTrip.carrier === null ||
            this.edittedTrip.carrier !== carrier.id)
        ) {
          this.edittedTrip.carrier = carrier;
          this.edittedTrip.driver = null;
          this.edittedTrip.truck = null;
          this.driversDropdown.getRecords({
            carrier: carrier.id,
            shared_jobevent: this.jobEvent.id,
          });
          this.truckDropdown.getRecords({
            carrier: carrier.id,
          });
          this.editTripFormPatchValue('driver', null);
          this.editTripFormPatchValue('truck', null);
        }
        this.editTripFormPatchValue(filterType, carrier);
        break;
      default:
        this.edittedTrip[filterType] = e;
        this.editTripFormPatchValue(filterType, e);
        break;
    }
  }

  getJobEvents(jobId: string, query = {}): void {
    this.loading = true;
    this.jobEventError = false;

    // shift1Start
    this.jobEventService
      .list({
        ordering: 'shift1_start_timestamp',
        job: jobId,
        page_size: 100,
        ...query,
      })
      .subscribe(
        (jobEvents) => {
          const newDates = jobEvents.map((j) => j.shift1Start);
          this.jobEvents = [...jobEvents];
          const jobEvent = this.jobEvent
            ? this.jobEvent
            : this.getJobEventWithDateAndJobEvents(
                jobEvents,
                this.jobEventDate || newDates[0]
              );

          if (newDates.length && jobEvent && jobEvent.id) {
            this.highlightedDates = newDates;
            this.jobEventDatePickerOptions = {
              min: newDates[0],
              max: newDates[newDates.length - 1],
            };
            const dropdownQuery = {
              ...this.carrierDropdownConfig.query,
              ...(jobEvent &&
                jobEvent.id && {
                  carriers_for_jobevent: jobEvent.id,
                }),
            };
            this.jobEvent = jobEvent;

            this.edittedTrip.jobEventId = jobEvent.id;
            this.editTripFormPatchValue('jobevent', jobEvent.id);

            this.carriersDropdown.config.query = dropdownQuery;
            if (!this.disabledFields.carrier && !this.disabledFields.driver) {
              this.carriersDropdown.getRecords(dropdownQuery);
            }
            this.editTripFormSetRequiredFields(jobEvent);
          } else {
            this.jobEventError = true;
          }
          this.loading = false;
        },
        (err) => {
          this.errors = err;
        }
      );
  }

  getJobEventWithDateAndJobEvents(jobEvents, jobEventDate) {
    const selectedDate = moment(jobEventDate).format().split('T')[0];
    return jobEvents.find((j) => j.shift1Start.includes(selectedDate));
  }

  getJobEventWithId(jobEventId: string) {
    this.jobEventService.get(jobEventId).subscribe((jobEvent) => {
      this.jobEvent = jobEvent;
      this.job = jobEvent.job;
      this.editTripFormSetRequiredFields(jobEvent);

      const selectedDate = moment(jobEvent.shift1Start).format().split('T')[0];

      this.jobEventDate = jobEvent.shift1Start;
      // if (
      //   jobEvent.job &&
      //   jobEvent.job.checkinOptions &&
      //   jobEvent.job.checkoutOptions
      // ) {
      //   this.setRequiredImages(
      //     jobEvent.job.checkinOptions,
      //     jobEvent.job.checkoutOptions
      //   );
      // }
    });
  }

  editTripFormPatchValue(key: string, value: any): void {
    this.editTripForm.patchValue({
      [key]: value,
    });
  }

  editTripFormPatchValueNested(keys: string[], value: any) {
    if (keys && keys.length > 0 && value) {
      let objToPatch = {};
      keys.reduce((prev, curr, i) => {
        return (prev[curr] = i + i === keys.length ? value : {});
      }, objToPatch);
      this.editTripForm.patchValue(objToPatch);
    }
  }

  editTripFormSetRequiredFields(jobEvent: JobEvent) {
    this.fieldsToCheck.forEach((field) => {
      this.editTripFormValidatorsSwitch(
        'loadingCheckin',
        field,
        jobEvent.job.checkinOptions[`${field}Config`] === 'required'
      );
    });
    this.fieldsToCheck.forEach((field) => {
      this.editTripFormValidatorsSwitch(
        'unloadingCheckin',
        field,
        jobEvent.job.checkoutOptions[`${field}Config`] === 'required'
      );
    });

    this.updateFormValueAndValidity(this.editTripForm);
  }

  updateFormValueAndValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.updateFormValueAndValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  editTripFormValidatorsSwitch(parent: string, key: string, required: boolean) {
    switch (key) {
      case 'activeTracking':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'date'],
            [Validators.required, Validators.minLength(1)]
          );
          this.editTripFormSetValidators(
            [parent, 'time'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'date']);
          this.editTripFormClearValidators([parent, 'time']);
        }
        break;

      case 'ticketImage':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'images'],
            [Validators.required]
          );
        } else {
          this.editTripFormClearValidators([parent, 'images']);
        }
        break;

      case 'signatureImage':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'signatures'],
            [Validators.required]
          );
        } else {
          this.editTripFormClearValidators([parent, 'signatures']);
        }
        break;

      case 'ticketNumber':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'ticketNumber'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'ticketNumber']);
        }
        break;

      case 'weight':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'weight'],
            [Validators.required, Validators.min(0)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'weight']);
        }
        break;

      default:
        break;
    }
  }

  editTripFormSetValidators(field: string[], validators) {
    this.editTripForm.get(field).setValidators(validators);
  }

  editTripFormClearValidators(field: string[]) {
    this.editTripForm.get(field).clearValidators();
  }

  getMomentDateFromDate(date: Date, format = 'YYYY-MM-DD') {
    return date ? moment(date).format(format) : null;
  }

  initForm() {
    this.editTripForm = this.fb.group(
      {
        job: [this.job, Validators.required],
        jobevent: [this.edittedTrip.jobEventId, Validators.required],
        driver: [this.edittedTrip.driver, Validators.required],
        carrier: [this.edittedTrip.carrier, Validators.required],
        truck: [this.edittedTrip.truck, Validators.required],
        start: this.fb.group({
          date: [
            this.getMomentDateFromDate(this.edittedTrip.startDate),
            [Validators.required],
          ],
          time: [
            this.getMomentDateFromDate(this.edittedTrip.startDate, 'HH:mm'),
            [Validators.required],
          ],
        }),
        end: this.fb.group({
          date: [this.getMomentDateFromDate(this.edittedTrip.endDate)],
          time: [this.getMomentDateFromDate(this.edittedTrip.endDate, 'HH:mm')],
        }),

        loadingCheckin: this.fb.group({
          ticketNumber: [this.edittedTrip.loadingCheckin.ticketNumber],
          weight: [this.edittedTrip.loadingCheckin.weight],
          date: [
            this.getMomentDateFromDate(this.edittedTrip.loadingCheckin.date),
          ],
          time: [
            this.getMomentDateFromDate(
              this.edittedTrip.loadingCheckin.date,
              'HH:mm'
            ),
          ],
          images: [null],
          signatures: [null],
        }),
        unloadingCheckin: this.fb.group({
          ticketNumber: [this.edittedTrip.unloadingCheckin.ticketNumber],
          weight: [this.edittedTrip.unloadingCheckin.weight],
          date: [
            this.getMomentDateFromDate(this.edittedTrip.unloadingCheckin.date),
          ],
          time: [
            this.getMomentDateFromDate(
              this.edittedTrip.unloadingCheckin.date,
              'HH:mm'
            ),
          ],
          images: [null],
          signatures: [null],
        }),
      },
      { validators: globalTripTimeValidator }
    );

    if (this.edittedTrip.jobEventId) {
      this.getJobEventWithId(this.edittedTrip.jobEventId);
    }
    this.tripLoading = false;
  }

  parseImageOnChange(image: any) {
    if (image && (image.isNew || image.isEdited)) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
    }
    if (image && image.isDeleted) {
      this.imagesToDelete.push(image);
    }
    return image;
  }

  onImagesChangeParser = (images: any[]): any[] =>
    images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    )

  onImagesChange(
    type:
      | 'loadingTicketImages'
      | 'unloadingTicketImages'
      | 'unloadingSignatures'
      | 'loadingSignatures',
    checkin: 'loadingCheckin' | 'unloadingCheckin',
    images: any[]
  ) {
    const parsedImages = this.onImagesChangeParser(images);
    this[type] = parsedImages;
    this.editTripFormPatchValueNested(
      [checkin, type.includes('Signature') ? 'signatures' : 'images'],
      [...parsedImages]
    );
  }

  setRequiredImages(checkinOptions, checkoutOptions) {
    this.requiredImages = {
      loadingImages: checkinOptions.ticketImageConfig === 'required',
      unloadingImages: checkoutOptions.ticketImageConfig === 'required',
      loadingSignature: checkinOptions.signatureImageConfig === 'required',
      unloadingSignature: checkoutOptions.signatureImageConfig === 'required',
    };
  }

  fileUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  resetForm() {
    this.edittedTrip.jobEventId = null;
    this.jobEventDate = null;
    this.edittedTrip.truck = null;
    if (!this.disabledFields.carrier) {
      this.edittedTrip.carrier = null;
    }
    if (!this.disabledFields.driver) {
      this.edittedTrip.driver = null;
    }

    this.editTripFormPatchValue('carrier', null);
    this.editTripFormPatchValue('jobevent', null);
    this.editTripFormPatchValue('driver', null);
    this.editTripFormPatchValue('truck', null);
    this.updateFormValueAndValidity(this.editTripForm);
  }

  get startDate() {
    return this.editTripForm.get(['start', 'date']).value;
  }

  get endDate() {
    return this.editTripForm.get(['end', 'date']).value;
  }

  get loadingCheckingDate() {
    return this.editTripForm.get(['loadingCheckin', 'date']).value;
  }

  get unloadingCheckingDate() {
    return this.editTripForm.get(['unloadingCheckin', 'date']).value;
  }

  get loadingCheckingTime() {
    return this.editTripForm.get(['loadingCheckin', 'time']).value;
  }

  get unloadingCheckingTime() {
    return this.editTripForm.get(['unloadingCheckin', 'time']).value;
  }
}
