import { Component, OnInit, SimpleChanges, Input, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';

import { JobEventShareStatService } from '../../job-event-stats/job-event-share-stat.service';

@Component({
  selector: 'collaborator-jobs',
  templateUrl: './collaborator-jobs.component.html',
  styleUrls: ['./collaborator-jobs.component.scss']
})
export class CollaboratorJobsComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'name', title: this.translationService.instant('Job Name') },
    { key: 'customer', title: this.translationService.instant('Customer') },
    { key: 'status', title: this.translationService.instant('Status') },
    { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks') },
    { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
    { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
    { key: 'truck-type', title: this.translationService.instant('Truck Type') },
    { key: 'drivers-on-shift', title: this.translationService.instant('Drivers on Shift') },
    { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads') },
    { key: 'delivered-tons', title: this.translationService.instant('Delivered Tons') },
    { key: 'daily-delivery-target', title: this.translationService.instant('Daily Delivery Target') },
    { key: 'ordered-loads', title: this.translationService.instant('Ordered Loads') },
    { key: 'shares-count', title: this.translationService.instant('Shares Count') },
    { key: 'loading-location', title: this.translationService.instant('Loading Location') },
    { key: 'unloading-location', title: this.translationService.instant('Unloading Location') },
    { key: 'order-number', title: this.translationService.instant('Order Number') },
    { key: 'material', title: this.translationService.instant('Material') },
    { key: 'start-time', title: this.translationService.instant('Start Time') },
    { key: 'haul-total', title: this.translationService.instant('Haul Total') },
  ];
  @Input() displayedColumns = [
    'name', 'customer', 'status', 'dispatched-trucks', 'confirmed-trucks',
    'num-trucks', 'truck-type', 'drivers-on-shift', 'delivered-loads',
    'delivered-tons', 'daily-delivery-target', 'ordered-loads', 'shares-count',
    'loading-location', 'unloading-location', 'order-number', 'material',
    'start-time', 'haul-total'
  ];
  @Input() jobEventDate: Date;
  @Input() filters = [];
  @Input() jobEventStat;
  collaboratorJobsTableConfig = {
    hasHeader: false,
    service: JobEventShareStatService,
    preferenceKey: 'CollaboratorJobsComponent-JobEventShareStatService',
    query: {},
    collectionTitle: this.translationService.instant('Collaborators'),
    noResultsText: this.translationService.instant('a collaborator'),
    customHeight: true,
    automaticallyHidePagination: true
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('collaboratorsJobTable') collaboratorsJobTable;

  constructor (
    private jobEventShareStatService: JobEventShareStatService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.jobEventShareStatService.getJobEventStatId(this.jobEventStat.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    let jobEventDateStart = new Date();
    jobEventDateStart.setHours(0, 0, 0, 0);
    let jobEventDateEnd = clone(jobEventDateStart);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate && jobEventDate.year !== 0) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd = clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
      }
    }
  }
}
