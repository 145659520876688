import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ruckit-assignment-error-dialog',
  templateUrl: './assignment-error-dialog.component.html',
  styleUrls: ['./assignment-error-dialog.component.scss'],
})
export class AssignmentErrorDialogComponent {
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<AssignmentErrorDialogComponent>
  ) { }
}
