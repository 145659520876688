<form #newLocation="ngForm" [ngClass]="{'mobile': !device.desktop}">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add a New Location</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content class="mat-dialog-content">
    <div class="left-side">
      <ng-container *ngIf="device.desktop">
        <h2 translate>Enter Details and Draw Geofence</h2>
        <div class="instructions">
          <p translate>To improve location accuracy and trip status times, draw a geofence around the location on the map to the right.</p>
          <img src="assets/img/location-geofence.gif" alt="{{ 'Geofencing steps' | translate }}" />
        </div>
      </ng-container>

      <div class="field-row">
        <div class="field-group street">
          <label translate>Address</label>
          <input places-auto-complete (place_changed)="placeChanged($event)"
                 type="text" id="street" name="street"
                 placeholder="{{ 'Address' | translate }}" autocomplete="off"
                 autocorrect="off" autocapitalize="off" spellcheck="off"
                 (blur)="geolocate()" [(ngModel)]="model.street"
                 #street="ngModel" />
          <span [ngClass]="{'ng-valid': street.valid, 'ng-invalid': street.invalid, 'ng-pristine': street.pristine}"></span>
          <address-select [addresses]="addresses" [selectedAddress]="selectedAddress" [showingAddresses]="showingAddresses"
            (addressChanged)="updateAddress($event)"></address-select>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group country">
          <label translate>Country</label>
          <select name="country" [disabled]="loading" required (change)="selectCountry($event.target.value)">
            <option value="" selected disabled hidden>{{ 'Select Country' | translate }}</option>
            <option *ngFor="let countryOption of countryOptions" [value]="countryOption.id" [selected]="countryOption.id === model.country">{{ countryOption.name }}</option>
          </select>
        </div>
        <div class="field-group state">
          <ng-container *ngIf="model.country !== 'CA'">
            <label translate>State</label>
            <select name="state" [disabled]="loading" (change)="selectState($event.target.value)">
              <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
              <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === model.state">{{ stateOption.name }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="model.country === 'CA'">
            <label translate>Province</label>
            <select name="state" [disabled]="loading" (change)="selectState($event.target.value)">
              <option value="" selected disabled hidden>{{ 'Select Province' | translate }}</option>
              <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === model.state">{{ stateOption.name }}</option>
            </select>
          </ng-container>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group city">
          <ng-container *ngIf="model.country !== 'CA'">
            <label translate>City</label>
            <input type="text" [disabled]="loading" name="city" placeholder="{{ 'City' | translate }}" [(ngModel)]="model.city" #city="ngModel" (blur)="geolocate()" />
            <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
          </ng-container>
          <ng-container *ngIf="model.country === 'CA'">
            <label translate>Municipality</label>
            <input type="text" [disabled]="loading" name="city" placeholder="{{ 'Municipality' | translate }}" [(ngModel)]="model.city" #city="ngModel" (blur)="geolocate()" />
            <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
          </ng-container>
        </div>
        <div class="field-group zipcode">
          <ng-container *ngIf="model.country !== 'CA'">
            <label translate>Zip</label>
            <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Zip' | translate }}" (blur)="geolocate()" [(ngModel)]="model.zipcode" #zipcode="ngModel" />
            <span [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
          </ng-container>
          <ng-container *ngIf="model.country === 'CA'">
            <label translate>Postal Code</label>
            <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Postal Code' | translate }}" (blur)="geolocate()" [(ngModel)]="model.zipcode" #zipcode="ngModel" />
            <span [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
          </ng-container>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group location-name">
          <label translate>Location Name</label>
          <input type="text" name="location-name"
                 placeholder="{{ 'Location Name' | translate }}" required 
                 [(ngModel)]="model.name" #name="ngModel" />
          <span [ngClass]="{'ng-valid': name.valid, 'ng-invalid': name.invalid, 'ng-pristine': name.pristine}"></span>
        </div>
      </div>

      <div class="field-column">
        <div class="field-row">
          <div class="field-group latitude">
            <label translate>Latitude</label>
            <input type="text" id="latitude" name="latitude" [(ngModel)]="model.latitude" (blur)="geolocate()" #latitude="ngModel" required />
          </div>
          <div class="field-group longitude">
            <label translate>Longitude</label>
            <input type="text" id="longitude" name="longitude" [(ngModel)]="model.longitude"  (blur)="geolocate()" #longitude="ngModel" required />
          </div>
        </div>
        <div (click)="getMyLocation()" class="geolocate-device" translate *ngIf="!device.desktop && canGeolocate">
          <mat-icon>gps_fixed</mat-icon>Use my device's location
        </div>
      </div>

      <div class="field-row">
        <div class="field-group location-type">
          <label translate>Select Location Type</label>
          <ruckit-dropdown [config]="locationTypeDropdownConfig"
            [selectedOption]="model.locationType && model.locationType.id"
            (onSelect)="onSelect('locationType', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" *ngIf="qrEnabled || laserFicheEnabled">
        <div class="field-group location-owner" *ngIf="!showLocationOwnerTextField">
          <label translate>Select Owner</label>
          <dropdown title="{{ 'Location Owner' | translate }}" #ownersDropdown
                    [options]="ownerOptions" (onSelect)="selectOwner($event)"
                    [selectedOption]="model.locationOwner"
                    (onSearch)="dropdownSearch($event, 'owner')"
                    (nextPage)="dropdownNextPage($event, 'owner')"
                    [config]="ownersConfig"></dropdown>
        </div>
        <div class="field-group location-owner" *ngIf="showLocationOwnerTextField">
          <label translate>New Owner</label>
          <input type="text" id="location-owner" [disabled]="loading"
                name="location_owner" autocomplete="off" autocorrect="off"
                autocapitalize="off" spellcheck="off"
                [(ngModel)]="model.locationOwner.name"
                #locationOwnerName="ngModel" />
        </div>
      </div>

      <div class="field-row">
        <div class="field-group action">
          <button id="new-location-button" class="btn btn-primary"
                  (click)="newLocation.form.valid && submit(); false"
                  [disabled]="!newLocation.form.valid || loading"
                  [ngClass]="{loading:loading}"
                  translate>
            Save New Location
          </button>
        </div>
      </div>
    </div>

    <div class="right-side" *ngIf="device.desktop">
      <notification *ngFor="let error of errors">{{error}}</notification>

      <div class="field-group map" >
        <ngui-map [options]="mapOptions" (mapReady$)="initMap($event)" (mapClick)="addMarker($event)">
          <marker *ngIf="model.marker"
            draggable="true"
            [label]="''"
            [title]="model.name"
            [position]="[model.marker.latitude, model.marker.longitude]"
            [geoFallbackPosition]="[model.marker.latitude, model.marker.longitude]"
            (dragend)="markerDragEnd($event)">
          </marker>
          <drawing-manager
            [drawingMode]="''"
            [drawingControl]="true"
            [drawingControlOptions]="{
              position: 'TOP_RIGHT',
              drawingModes: ['polygon']
             }"
            [polygonOptions]="{
               strokeColor: '#FF8C00',
               strokeOpacity: 0.8,
               strokeWeight: 2,
               fillColor: '#FF8C00',
               fillOpacity: 0.35
            }">
          </drawing-manager>
        </ngui-map>
      </div>
    </div>
  </div>
</form>
