import { Observable, combineLatest, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { JobEvent } from './job-event';
import { JobEventMap } from './job-event-map';
import { requestHeaders, handleError } from '../shared/api.service';
import { CondensedJobEvent } from './condensed-job-event';
import { Job } from '../jobs/job';
import { sortBy } from 'lodash';
import { FieldOption } from '../shared/export-dialog/export-dialog.component';
import { BulkEditJobEvent, BulkResponse } from './bulk-edit-job-event';
import { BulkEditJobEventsSerializer } from './bulk-edit-job-events.serializers';
import { AppUtilities } from '../shared/app-utilities';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class JobEventService {
  baseUrl = environment.serverUrl;
  public nextUri;
  progress$;
  progress;
  public listAllProgress = new Subject<number>();
  public count = 0;

  constructor(private http: HttpClient) { }

  get(jobEventId: string): Observable<JobEvent> {
    let jobEventUrl = this.baseUrl + 'jobevents/' + jobEventId + '/';

    return this.http.get(jobEventUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getJobEvent(jobEventId: string): Observable<JobEvent> {
    let jobEventUrl = this.baseUrl + 'jobevents/' + jobEventId + '/';

    return this.http.get(jobEventUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getJobEvents(query: any = null): Observable<JobEvent[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let jobEventsUrl = this.baseUrl + 'jobevents/';
    return this.http.get(jobEventsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  list(query: any = null): Observable<JobEvent[]> {
    return this.getJobEvents(query);
  }

  listCompact(query: any = null): Observable<JobEvent[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let jobEventsUrl = this.baseUrl + 'jobevents/compact/';
    return this.http.get(jobEventsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  listAll(pageSize = 50, query?: any): Observable<JobEvent[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    params = params.set('page_size', '1');

    let requestCount = 0;
    this.listAllProgress.next(0);

    let jobEventsUrl = this.baseUrl + 'jobevents/';
    return this.http.get(jobEventsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res)),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<JobEvent[]>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(jobEventsUrl, {
              headers: requestHeaders(),
              params: params
            }).map(res => {
              this.listAllProgress.next(1);
              return this.extractData(res);
            })
          );
        } else {
          this.listAllProgress.next(1 / Math.ceil(this.count / pageSize));
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(jobEventsUrl, {
                headers: requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  this.listAllProgress.next(requestCount / Math.ceil(this.count / pageSize));
                  return res;
                }),
                map(res => this.extractData(res))
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map(data => {
        let mergedList: JobEvent[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return mergedList;
      }),
      catchError((res: Response) => handleError(res))
    );
  }

  save(model) {
    const uri = this.baseUrl + 'jobevents/';
    let _model = decamelizeKeysDeep(model);
    _model.job = _model.job && _model.job.id;

    _model.truck_types = _model.truck_types && _model.truck_types.map(truck_type => {
      if (typeof(truck_type) === 'object') {
        return truck_type.id;
      } else {
        return truck_type;
      }
    });

    if (_model.default_yard_buffer_minutes && parseFloat(_model.default_yard_buffer_minutes) > 0) {
      _model.default_yard_buffer_time = parseFloat(_model.default_yard_buffer_minutes) * 60;
    }

    if (!_model.id) {
      return this.http.post(uri, _model, {headers: requestHeaders()}).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return this.http.put(uri + _model.id + '/', _model, {headers: requestHeaders()}).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    }
  }

  remove(model) {
    const id = typeof model === 'string' ? model : model.id;
    return this.http.delete(this.baseUrl + 'jobevents/' + id + '/', {
      headers: requestHeaders()
    });
  }

  cancel(model) {
    const uri = this.baseUrl + 'jobevents/';
    let _model = decamelizeKeysDeep(model);

    return this.http.put(uri + _model.id + '/', _model, {headers: requestHeaders()}).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  autoAssign(jobEventId: string, force = false) {
    const jobEventsUrl = this.baseUrl + 'jobevents/' + jobEventId + '/autoassign/?';
    let params: HttpParams = new HttpParams();
    if (force) { params = params.set('force', 'True'); }

    return this.http.post(jobEventsUrl + params.toString(), {}, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res); })
    );
  }

  getMap(query: any = null): Observable<JobEventMap> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let jobEventsUrl = this.baseUrl + 'jobevents/map/';
    return this.http.get(jobEventsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractMapData(res); }),
      catchError(handleError)
    );
  }

  getNext(): Observable<JobEvent[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError((res: Response) => handleError(res))
      );
    } else {
      return null;
    }
  }

  listNext(): Observable<JobEvent[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError((res: Response) => handleError(res))
      );
    } else {
      return null;
    }
  }

  getExportFields(customUrl = null) {
    let exportFieldUrl = `${this.baseUrl}jobevents/export/`;
    if (customUrl && customUrl.length) {
      exportFieldUrl = `${this.baseUrl}${customUrl}`;
    }
    return this.http.get(exportFieldUrl, { headers: requestHeaders() }).pipe(
      map(res => sortBy(<FieldOption[]>res, 'label')),
      catchError(handleError)
    );
  }

  export(body, query: any = null, endpointUrl = 'export/'): Observable<any> {
    let exportUrl = `${this.baseUrl}jobevents/${endpointUrl}`;
    let params: HttpParams = new HttpParams();

    if (query) {
      if (typeof query === 'string') {
        exportUrl = exportUrl + '?' + query;
      } else {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
    }

    return this.http.post(exportUrl, body, {
      responseType: 'text',
      headers: requestHeaders(),
      params
    });
  }

  convertCondensedJobEvent(jobEvent: CondensedJobEvent): JobEvent {
    return Object.assign(new JobEvent(jobEvent), {
      job: {
        id: jobEvent.jobId,
        name: jobEvent.jobName,
        deliveryInterval: jobEvent.deliveryInterval,
        orderNumber: jobEvent.orderNumber,
        project: { name: jobEvent.projectName },
        startLocation: {
          id: jobEvent.startLocation,
          name: jobEvent.startLocationName
        },
        endLocation: {
          id: jobEvent.endLocation,
          name: jobEvent.endLocationName
        }
      },
    });
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(jobEvent => {
        return new JobEvent(jobEvent);
      });
    } else if (resObj) {
      return new JobEvent(resObj);
    }
  }

  private extractMapData(res: Object) {
    let resObj = res;
    if (resObj) {
      return new JobEventMap(resObj);
    }
  }

  /**
   * Allow update multiple job events in the same request
   * @param model - data to update in the job events
   * @param query - filters
   * @returns
   */
  bulkUpdate(model: BulkEditJobEvent, query?: any): Observable<BulkResponse> {
    const params = AppUtilities.getParamsFromQuery(query);
    const updatedModel = new BulkEditJobEventsSerializer().toJson(model);
    const jobEventsBulkUrl = this.baseUrl + 'jobevents/bulk-update/';

    return this.http.put<{permission_denied: any, updated: any }>(jobEventsBulkUrl, updatedModel, {
      headers: requestHeaders(),
      params: params
    }).pipe(map((response) => ({
      errors: response.permission_denied,
      success: response.updated
    })));
  }
}
