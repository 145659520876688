import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { environment } from '../../environments/environment';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class ApiService {
  title = 'Ruckit';
  public token: string;
  baseUrl = environment.serverUrl;

  constructor() {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  getStates() {
    return [
      { name: 'Alabama', abbreviation: 'AL', country: 'US' },
      { name: 'Alaska', abbreviation: 'AK', country: 'US' },
      { name: 'American Samoa', abbreviation: 'AS', country: 'US' },
      { name: 'Arizona', abbreviation: 'AZ', country: 'US' },
      { name: 'Arkansas', abbreviation: 'AR', country: 'US' },
      { name: 'California', abbreviation: 'CA', country: 'US' },
      { name: 'Colorado', abbreviation: 'CO', country: 'US' },
      { name: 'Connecticut', abbreviation: 'CT', country: 'US' },
      { name: 'Delaware', abbreviation: 'DE', country: 'US' },
      { name: 'District Of Columbia', abbreviation: 'DC', country: 'US' },
      { name: 'Federated States Of Micronesia', abbreviation: 'FM', country: 'US' },
      { name: 'Florida', abbreviation: 'FL', country: 'US' },
      { name: 'Georgia', abbreviation: 'GA', country: 'US' },
      { name: 'Guam', abbreviation: 'GU', country: 'US' },
      { name: 'Hawaii', abbreviation: 'HI', country: 'US' },
      { name: 'Idaho', abbreviation: 'ID', country: 'US' },
      { name: 'Illinois', abbreviation: 'IL', country: 'US' },
      { name: 'Indiana', abbreviation: 'IN', country: 'US' },
      { name: 'Iowa', abbreviation: 'IA', country: 'US' },
      { name: 'Kansas', abbreviation: 'KS', country: 'US' },
      { name: 'Kentucky', abbreviation: 'KY', country: 'US' },
      { name: 'Louisiana', abbreviation: 'LA', country: 'US' },
      { name: 'Maine', abbreviation: 'ME', country: 'US' },
      { name: 'Marshall Islands', abbreviation: 'MH', country: 'US' },
      { name: 'Maryland', abbreviation: 'MD', country: 'US' },
      { name: 'Massachusetts', abbreviation: 'MA', country: 'US' },
      { name: 'Michigan', abbreviation: 'MI', country: 'US' },
      { name: 'Minnesota', abbreviation: 'MN', country: 'US' },
      { name: 'Mississippi', abbreviation: 'MS', country: 'US' },
      { name: 'Missouri', abbreviation: 'MO', country: 'US' },
      { name: 'Montana', abbreviation: 'MT', country: 'US' },
      { name: 'Nebraska', abbreviation: 'NE', country: 'US' },
      { name: 'Nevada', abbreviation: 'NV', country: 'US' },
      { name: 'New Hampshire', abbreviation: 'NH', country: 'US' },
      { name: 'New Jersey', abbreviation: 'NJ', country: 'US' },
      { name: 'New Mexico', abbreviation: 'NM', country: 'US' },
      { name: 'New York', abbreviation: 'NY', country: 'US' },
      { name: 'North Carolina', abbreviation: 'NC', country: 'US' },
      { name: 'North Dakota', abbreviation: 'ND', country: 'US' },
      { name: 'Northern Mariana Islands', abbreviation: 'MP', country: 'US' },
      { name: 'Ohio', abbreviation: 'OH', country: 'US' },
      { name: 'Oklahoma', abbreviation: 'OK', country: 'US' },
      { name: 'Oregon', abbreviation: 'OR', country: 'US' },
      { name: 'Palau', abbreviation: 'PW', country: 'US' },
      { name: 'Pennsylvania', abbreviation: 'PA', country: 'US' },
      { name: 'Puerto Rico', abbreviation: 'PR', country: 'US' },
      { name: 'Rhode Island', abbreviation: 'RI', country: 'US' },
      { name: 'South Carolina', abbreviation: 'SC', country: 'US' },
      { name: 'South Dakota', abbreviation: 'SD', country: 'US' },
      { name: 'Tennessee', abbreviation: 'TN', country: 'US' },
      { name: 'Texas', abbreviation: 'TX', country: 'US' },
      { name: 'Utah', abbreviation: 'UT', country: 'US' },
      { name: 'Vermont', abbreviation: 'VT', country: 'US' },
      { name: 'Virgin Islands', abbreviation: 'VI', country: 'US' },
      { name: 'Virginia', abbreviation: 'VA', country: 'US' },
      { name: 'Washington', abbreviation: 'WA', country: 'US' },
      { name: 'West Virginia', abbreviation: 'WV', country: 'US' },
      { name: 'Wisconsin', abbreviation: 'WI', country: 'US' },
      { name: 'Wyoming', abbreviation: 'WY', country: 'US' },
      { name: 'Alberta', abbreviation: 'AB', country: 'CA' },
      { name: 'British Columbia', abbreviation: 'BC', country: 'CA' },
      { name: 'Manitoba', abbreviation: 'MB', country: 'CA' },
      { name: 'New Brunswick', abbreviation: 'NB', country: 'CA' },
      { name: 'Newfoundland and Labrador', abbreviation: 'NL', country: 'CA' },
      { name: 'Northwest Territories	', abbreviation: 'NT', country: 'CA' },
      { name: 'Nova Scotia', abbreviation: 'NS', country: 'CA' },
      { name: 'Nunavut', abbreviation: 'NU', country: 'CA' },
      { name: 'Ontario', abbreviation: 'ON', country: 'CA' },
      { name: 'Prince Edward Island', abbreviation: 'PE', country: 'CA' },
      { name: 'Quebec', abbreviation: 'QC', country: 'CA' },
      { name: 'Saskatchewan', abbreviation: 'SK', country: 'CA' },
      { name: 'Yukon', abbreviation: 'YT', country: 'CA' },
      { name: 'New South Wales', abbreviation: 'NSW', country: 'AU' },
      { name: 'Queensland', abbreviation: 'QLD', country: 'AU' },
      { name: 'South Australia', abbreviation: 'SA', country: 'AU' },
      { name: 'Tasmania', abbreviation: 'TAS', country: 'AU' },
      { name: 'Victoria', abbreviation: 'VIC', country: 'AU' },
      { name: 'Western Australia', abbreviation: 'WA', country: 'AU' }
    ];
  }

  getCountries() {
    return [
      { name: 'United States', abbreviation: 'US' },
      { name: 'Canada', abbreviation: 'CA' },
      { name: 'Australia', abbreviation: 'AU' }
    ];
  }

  getCompanyTypes() {
    return [
      { id: null, name: '-- Company Type --'},
      { id: 'sole-proprietorship', name: 'Sole Proprietorship' },
      { id: 'corporation', name: 'Corporation' },
      { id: 'llc', name: 'Limited Liability Company' }
    ];
  }
}

export function parseErrors(err): string[] {
  let errors = [];
  if (err.status >= 500) {
    errors.push(err.statusText);
  } else if (typeof err._body === 'string') {
    try {
      let body = JSON.parse(err._body);
      if (body.detail || body.errors) {
        const _err = body.detail ? body.detail : body.errors;
        errors.push(_err);
      } else {
        errors = rescurseErrorObject(body, errors);
      }
    } catch (e) {}
  } else if (err.name === 'HttpErrorResponse' || err.constructor && err.constructor.name === 'HttpErrorResponse') {
    errors = rescurseErrorObject(err.error, errors);
  } else if (err.length && err.length > 0 && Array.isArray(err)) {
    err.forEach(e => {
      if (e.name === 'HttpErrorResponse' || e.constructor && e.constructor.name === 'HttpErrorResponse') {
        errors = rescurseErrorObject(e.error, errors);
      } else {
        errors = rescurseXHRErrorObject(e, errors);
      }
    });
  } else if (err['errors'] && err['errors'].length && err['errors'].length > 0) {
    err['errors'].forEach(e => {
      if (e.name === 'HttpErrorResponse' || e.constructor && e.constructor.name === 'HttpErrorResponse') {
        errors = rescurseErrorObject(e.error, errors);
      } else {
        errors = rescurseXHRErrorObject(e['errors'], errors);
      }
    });
  } else {
    errors.push(err);
  }
  return errors;
}
function rescurseErrorObject(obj, errors) {
  if (typeof obj  === 'string') {
    errors.push(obj);
  } else if (obj.constructor.name === 'ProgressEvent') {
    errors.push('Request failed to load.');
  } else {
    each(obj, (msg, key) => {
      if (Array.isArray(msg)) {
        errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err ));
      } else if (typeof msg === 'string') {
        if (key !== 'non_field_errors') {
          try {
            errors.push(key.replace(/_/g, ' ') + ': ' + msg);
          } catch (e) {
            errors.push(msg);
          }
        } else {
          errors.push(msg);
        }
      } else if (typeof msg === 'object') {
        errors = rescurseErrorObject(msg, errors);
      }
    });
  }
  return errors;
}

function rescurseXHRErrorObject(obj, errors) {
  if (typeof obj === 'string') {
    try {
      let jsonObj = JSON.parse(obj);
      each(jsonObj, (msg, key) => {
        if (typeof msg === 'string') {
          errors.push(key.replace(/_/g, ' ') + ': ' + msg);
        } else {
          each(msg, (msg2, key2) => {
            errors.push(key.replace(/_/g, ' ') + ' - ' + key2.replace(/_/g, ' ') + ': ' + msg2);
          });
        }
      });
    } catch (e) {
      errors.push(obj);
    }
  } else {
    errors = rescurseErrorObject(obj, errors);
  }
  return errors;
}

export function requestHeaders(xhr: XMLHttpRequest = null, multipart = false): HttpHeaders {
    let tokenString;
    let headerObject = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    if (multipart) {
      headerObject = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      };
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const token = currentUser && currentUser.token;
      tokenString = 'Token ' + token;
    }
    if (tokenString) { headerObject['Authorization'] = tokenString; }
    if (xhr && tokenString) { xhr.setRequestHeader('Authorization', tokenString); }

    return new HttpHeaders(headerObject);
  }

export function handleError (error: HttpResponse<any> | any) {
  return observableThrowError(parseErrors(error));
}

export function getFirstFieldError(err: HttpErrorResponse): string {
  const arrayError = err && err.error && err.error.length && err.error[0];
  const error = err && err.error && err.error.errors && err.error.errors.length &&
    err.error.errors[0].errors && err.error.errors[0].errors.non_field_errors;
  return arrayError ? arrayError : error && error.length && error[0];
}
