import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[trim]'
})
export class TextTrimDirective implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.listen(this.elementRef.nativeElement, 'input', $event => {
      this.trimText($event);
    });

    this.renderer.listen(this.elementRef.nativeElement, 'blur', $event => {
      this.trimTextOnBlur($event);
    });
  }

  private trimText(event) {
    let trimmedText = event.target.value.trim();

    if (trimmedText === '') {
      event.target.value = trimmedText;
    }
  }

  private trimTextOnBlur(event) {
    event.target.value = event.target.value.trim();
  }
}
