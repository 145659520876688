import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Note } from './note.types';

@Injectable()
export class NotesService {
  baseUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return {
      Authorization: `Token ${currentUser.token}`,
    };
  }

  saveNote = (note: Note) =>
    this.http.post(`${this.baseUrl}notes/`, note, {
      headers: this.getHeaders(),
    })

  patchNote = (noteId: string, note: Partial<Note>) =>
    this.http.patch(`${this.baseUrl}notes/${noteId}/`, note, {
      headers: this.getHeaders(),
    })

  updateNote = (note: Note) =>
    this.http.put(`${this.baseUrl}notes/${note.id}/`, note, {
      headers: this.getHeaders(),
    })

  deleteNote = (id: string) =>
    this.http.delete(`${this.baseUrl}notes/${id}/`, {
      headers: this.getHeaders(),
    })
}
