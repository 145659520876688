<div *ngIf="!loading && type === 'received'">
  <div *ngFor="let invitation of invitations" class="list-row" [@statusAnimation]="invitation.status">
    <div class="organization-info">
      <h2 class="organization-name">{{ invitation.senderOrganization?.name }}</h2>
      <div class="organization-type">{{ invitation.senderOrganizationTypes }}</div>
    </div>
    <div class="organization-location">
      <div class="organization-location-value">{{ invitation.senderLocation || '&nbsp;' }}</div>
      <div class="organization-location-label" *ngIf="invitation.senderLocation">Location</div>
    </div>
    <div class="organization-identifier">
      <div class="organization-identifier-value">{{ invitation.senderIndentifiers || '&nbsp;' }}</div>
      <div class="organization-identifier-label" *ngIf="invitation.senderIndentifiers">DOT</div>
    </div>
    <div class="organization-contact">
      <div class="organization-contact-value">{{ invitation.senderOrganization?.primaryContact?.firstName || '&nbsp;' }} {{ invitation.senderOrganization?.primaryContact?.lastName || '&nbsp;' }}</div>
      <div class="organization-contact-label">Primary Contact</div>
    </div>
    <div class="invitation-actions">
      <a class="invitation-reject" (click)="reject(invitation)" title="Reject this invitation"><i class="icomoon icon-reject"></i></a>
      <a class="invitation-accept" (click)="accept(invitation)" title="Accept this invitation"><i class="icomoon icon-accept"></i></a>
    </div>
  </div>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="no-results" *ngIf="!loading && ! errors.length && invitations.length === 0">
    <div class="icon"><i class="icon-connections"></i></div>
    <h2>No Connections</h2>
    <p>When you make a connection you’ll see it here.</p>
    <div class="actions">
      <button class="btn btn-primary" (click)="openConnectionDialog()"><span class="icon-plus"></span> Add Connection</button>
    </div>
  </div>
</div>

<div *ngIf="!loading && type === 'sent'">
  <div *ngFor="let invitation of invitations" class="list-row" [@statusAnimation]="invitation.status">
    <div class="organization-info">
      <h2 class="organization-name">{{ invitation.recipientOrganization?.name }}</h2>
      <div class="organization-type">{{ invitation.recipientOrganizationTypes }}</div>
    </div>
    <div class="organization-location">
      <div class="organization-location-value">{{ invitation.recipientLocation || '&nbsp;' }}</div>
      <div class="organization-location-label" *ngIf="invitation.recipientLocation">Location</div>
    </div>
    <div class="organization-identifier">
      <div class="organization-identifier-value">{{ invitation.recipientIndentifiers || '&nbsp;' }}</div>
      <div class="organization-identifier-label" *ngIf="invitation.recipientIndentifiers">DOT</div>
    </div>
    <div class="organization-contact">
      <div class="organization-contact-value">{{ invitation.recipientOrganization?.primaryContact?.firstName || '&nbsp;' }} {{ invitation.recipientOrganization?.primaryContact?.lastName || '&nbsp;' }}</div>
      <div class="organization-contact-label">Primary Contact</div>
    </div>
    <div class="organization-timestamp">
      <span *ngIf="invitation.createdAt && !invitation.resentAt && !invitation.resent">Sent: {{ invitation.createdAt | date: 'dd/M/yyyy' }}</span>
      <span *ngIf="invitation.resentAt">Resent: {{ invitation.resentAt | date: 'dd/M/yy' }}</span>
      <span *ngIf="invitation.resent" class="resent-notice"><i class="icomoon icon-checkmark"></i></span>
    </div>
    <div class="invitation-actions">
      <a class="invitation-reject" (click)="cancel(invitation)" title="Cancel this invitation"><i class="icomoon icon-delete"></i></a>
      <a class="invitation-accept" (click)="resend($event, invitation)" title="Resend this invitation" [ngClass]="{'disabled': invitation.resentAt}"><i class="icomoon icon-resend"></i></a>
    </div>
  </div>

  <div class="no-results" *ngIf="!loading && invitations.length === 0">
    <div class="icon"><i class="icon-connections"></i></div>
    <h2>No Connections</h2>
    <p>When you make a connection you’ll see it here.</p>
    <div class="actions">
      <button class="btn btn-primary" (click)="openConnectionDialog()"><span class="icon-plus"></span> Add Connection</button>
    </div>
  </div>
</div>
