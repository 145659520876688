import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { mergeMap } from 'rxjs/operators';
import { find as _find, reject, filter } from 'lodash';
import { Subscription } from 'rxjs';
import { DrawingManager } from '@ngui/map';
import { DeviceDetectorService } from 'ngx-device-detector';

import { LocationService } from './location.service';
import { LocationTypeService } from './location-type.service';
import { AuthenticationService } from '../shared/authentication.service';
import { ApiService, parseErrors } from '../shared/api.service';
import { mapOptions } from '../shared/static-data';
import { LocationOwnerSerializer } from './location-owner.serializer';
import { LocationOwnerService } from './location-owner.service';
import { Address } from './address';

declare let google: any;
declare let navigator: any;

@Component({
  selector: 'ruckit-new-location-dialog',
  templateUrl: './new-location-dialog.component.html',
  styleUrls: ['./new-location-dialog.component.scss'],
  providers: [
    LocationService, ApiService, AuthenticationService
  ]
})
export class NewLocationDialogComponent implements OnInit {
  loading = false;
  showLocationOwnerTextField = false;
  model: any = {
    latitude: 29.9291564, longitude: -94.9320884, geofence: null, tags: [],
    locationOwner: { name: '', id: null, organization: null }
  };
  selectedAddress: Address = new Address();
  addresses: Address[] = [];
  showingAddresses: boolean;
  errors = [];
  states = [];
  stateOptions = [];
  countryOptions = [];
  ownerOptions = [];
  ownersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  locationOwnersReq: Subscription;
  organizationLocationReq: Subscription;
  address;
  zoom = 14;
  callback;
  drawingMode = undefined;
  paths = [];
  selectedOverlay: any;
  mapOptions = {};
  map;
  organization;
  qrEnabled = false;
  canGeolocate = false;
  laserFicheEnabled = false;
  geolocationId: number;
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  @ViewChild(DrawingManager) drawingManager: DrawingManager;
  @ViewChild('ownersDropdown') ownersDropdown;

  locationTypeDropdownConfig = {
    service: LocationTypeService,
    selectText: 'Select Location Type',
    loadingText: 'Loading Location Types...',
    noResultsText: 'No Location Types'
  };

  constructor(
    public dialogRef: MatDialogRef<NewLocationDialogComponent>,
    private locationService: LocationService,
    private apiService: ApiService,
    private locationOwnerService: LocationOwnerService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private locationStrategy: LocationStrategy,
    private ngZone: NgZone
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    if (navigator && navigator.geolocation) {
      this.canGeolocate = true;
    }

    this.locationStrategy.onPopState(() => {
      this.dialogRef.close();
    });
  }

  ngOnInit() {
    this.states = this.apiService.getStates();
    this.countryOptions = this.apiService.getCountries().map(country => {
      return {
        id: country.abbreviation,
        name: country.name
      };
    });
    this.selectCountry('US');

    const user = this.authenticationService.user();
    this.qrEnabled = user && user.organization && user.organization.qrEnabled;
    const enabledFeatures = this.authenticationService.enabledFeatures();
    if (enabledFeatures && enabledFeatures.includes('useLaserficheExport')) {
      this.laserFicheEnabled = true;
    }

    this.getLocationOwners();

    this.organization = this.authenticationService.getOrganization();
    if (this.organization && this.organization.defaultAverageLoadingTime) {
      this.model.averageLoadingTime = this.organization.defaultAverageLoadingTime;
    }
    if (this.organization && this.organization.defaultAverageUnloadingTime) {
      this.model.averageUnloadingTime = this.organization.defaultAverageUnloadingTime;
    }
    this.mapOptions = mapOptions({
      zoom: 10,
      disableDefaultUI: true,
      scrollwheel: true,
      fullscreenControl: false,
      streetViewControl: false,
      center: this.organization.city + ', ' + this.organization.state
    }, {}, {
      mapStyle: 'google-map-style'
    });
  }

  submit(): void {
    this.loading = true;
    if (this.model.latitude && this.model.longitude) {
      let coordinates = this.model.latitude.toString() + ',' + this.model.longitude.toString();
      this.locationService.getLocationTimezone(coordinates).pipe(
        mergeMap(timezoneRes => {
          this.model.timezone = timezoneRes['timeZoneId'];
          return this.locationService.save(this.model);
        })
      ).subscribe(location => {
        this.loading = false;
        this.dialogRef.close();
        this.callback(location);
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    } else {
      this.errors.push('No location coordinates provided!');
      this.loading = false;
    }
  }

  selectState(state: string): void {
    this.model.state = state;
    this.geolocate();
  }

  selectCountry(country: string): void {
    this.model.country = country;
    this.stateOptions = filter(this.states, { country: country }).map(state => {
      return {
        id: state.abbreviation,
        name: state.name
      };
    });
  }

  getLocationOwners(query = {}): void {
    if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
      this.locationOwnersReq.unsubscribe();
    }

    this.locationOwnersReq = this.locationOwnerService.list({
      ordering: 'organization__name',
      ...query
    }).subscribe(locationOwners => {
      this.ownerOptions = locationOwners.map(locationOwner => {
        return { name: locationOwner.name, id: locationOwner.id };
      });
      let ownOrganization = this.authenticationService.getOrganization();
      if (ownOrganization) {
        let _locationOwner = new LocationOwnerSerializer().fromJson({
          organization: ownOrganization.id,
          name: ownOrganization.name
        });
        this.ownerOptions = reject(this.ownerOptions, _locationOwner);
        this.ownerOptions.unshift(_locationOwner);
      }
      if (this.model.locationOwner && this.model.locationOwner.id) {
        this.ownerOptions = reject(this.ownerOptions, this.model.locationOwner);
        this.ownerOptions.unshift(this.model.locationOwner);
        this.ownersDropdown.selectedOption = _find(this.ownerOptions, { id: this.model.locationOwner.id });
      }
      this.ownerOptions.unshift({
        name: 'Add a New Owner',
        id: 'new-location-owner',
        button: true
      });
      if (this.ownersDropdown) {
        this.ownersDropdown.config.loadingOptions = false;
      }
      this.ownersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.ownersConfig.loadingOptions = false;
    });
  }

  dropdownNextPage(e, type: string): void {
    let config;
    let service;
    let options;

    switch (type) {
      case 'owner':
        config = this.ownersConfig;
        service = this.locationOwnerService;
        options = this.ownerOptions;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'owner':
              let _ownerOptions = results.map(result => {
                return { name: result.name, id: result.id };
              });
              this.ownerOptions = this.ownerOptions.concat(_ownerOptions);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type: string): void {
    switch (type) {
      case 'owner':
        this.getLocationOwners({ search: term });
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  selectOwner(owner): void {
    if (owner['id'] === 'new-location-owner') {
      this.showLocationOwnerTextField = true;
      this.model.locationOwner.id = null;
    } else {
      this.model.locationOwner = owner;
    }
  }

  placeChanged(place): void {
    // set latitude, longitude and zoom
    if (place && place.geometry) {
      this.model.latitude = place.geometry.location && place.geometry.location.lat();
      this.model.longitude = place.geometry.location && place.geometry.location.lng();
      this.model.marker = {
        latitude: this.model.latitude, longitude: this.model.longitude
      };
      this.zoom = 14;

      this.model.street = this.extractFromAddress(place.address_components, 'street_number')
        + ' '
        + this.extractFromAddress(place.address_components, 'route');
      this.model.city = this.extractFromAddress(place.address_components, 'locality');
      this.countryOptions.map(country => {
        if (country.name === this.extractFromAddress(place.address_components, 'country')) {
          this.selectCountry(country.id);
        }
      });
      this.states.map(state => {
        if (state.name === this.extractFromAddress(place.address_components, 'administrative_area_level_1')) {
          this.model.state = state.abbreviation;
        }
      });
      this.model.zipcode = this.extractFromAddress(place.address_components, 'postal_code');
      this.centerOnCoordinates({ latitude: this.model.latitude, longitude: this.model.longitude });
      this.model.name = place.name;
      if (this.model.street && this.model.street.trim().length === 0) {
        this.model.street = this.model.name;
      }
    } else {
      this.errors.push('No location data could be found for the input address. Please try again.');
    }
  }

  geolocate() {
    let displayName = `${this.model.street} ${this.model.street2}, ${this.model.city}, ${this.model.state} ${this.model.zipcode}`;
    if (this.model.street && this.model.street.trim().length > 0) {
      displayName = displayName.replace('undefined', '');
      displayName = displayName.replace(' ,', ',');
    }
    this.address = {
      street: this.model.street,
      city: this.model.city,
      state: this.model.state,
      zipcode: this.model.zipcode,
      displayName: displayName
    };

    let status = Object.keys(this.address).every((x) => {
      return this.address[x] && this.address[x].length;
    }) === true;
    let address = Object.keys(this.address).map((x) => {
      return this.address[x];
    }).join(', ');

    if (status) {
      this.locationService.getLocationByAddress(address).subscribe((res) => {
        let coordinates = res && res[0];
        this.model.latitude = coordinates.geometry.location.lat;
        this.model.longitude = coordinates.geometry.location.lng;
        this.model.marker = {
          latitude: this.model.latitude, longitude: this.model.longitude
        };
        this.centerOnCoordinates({ latitude: this.model.latitude, longitude: this.model.longitude });
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    } else {
      this.centerOnCoordinates({ latitude: this.model.latitude, longitude: this.model.longitude });
    }
  }

  initMap(event: any): void {
    this.map = event;
    this.initDrawingManager();

    if (!navigator.geolocation) {
      this.centerOnOrganization();
      console.log('Position not supported!');
    } else {
      this.geolocationId = navigator.geolocation.watchPosition(position => {
        this.centerOnCoordinates(position.coords);
      }, (error) => {
        switch (error.code) {
          case error.TIMEOUT:
            console.log('Position timed out!');
            break;
          case error.PERMISSION_DENIED:
            console.log('Permission was denied!');
            break;
          case error.POSITION_UNAVAILABLE:
            console.log('Position unavailable!');
            break;
          default:
            console.log('error', error);
            break;
        }
        this.centerOnOrganization();
      }, {
        maximumAge: 600000, timeout: 10000
      });
    }
  }

  centerOnCoordinates(coordinates): void {
    let fallbackCenter = new google.maps.LatLng(
      coordinates.latitude, coordinates.longitude
    );
    this.mapOptions = mapOptions({
      zoom: 15,
      disableDefaultUI: true,
      scrollwheel: true,
      fullscreenControl: false,
      streetViewControl: false,
      center: fallbackCenter,
      geoFallbackCenter: fallbackCenter
    }, {}, {
      mapStyle: 'google-map-style'
    });
    navigator.geolocation.clearWatch(this.geolocationId);
    this.geolocationId = null;
    google.maps.event.trigger(this.map, 'resize');
  }

  centerOnOrganization(): void {
    if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
      this.organizationLocationReq.unsubscribe();
    }
    this.locationService.getLocationByAddress(`${this.organization.street}, ${this.organization.location}`).subscribe(coords => {
      if (coords && coords[0] && coords[0].geometry) {
        let fallbackCenter = new google.maps.LatLng(
          coords[0].geometry.location.lat, coords[0].geometry.location.lng
        );
        this.mapOptions = mapOptions({
          zoom: 10,
          disableDefaultUI: true,
          scrollwheel: true,
          fullscreenControl: false,
          streetViewControl: false,
          center: fallbackCenter,
          geoFallbackCenter: fallbackCenter
        }, {}, {
          mapStyle: 'google-map-style'
        });
        navigator.geolocation.clearWatch(this.geolocationId);
        this.geolocationId = null;
        google.maps.event.trigger(this.map, 'resize');
      }
    }, (err) => this.errors = ['Cannot get your organizations location']);
  }

  initDrawingManager(): void {
    if (this.drawingManager) {
      this.drawingManager['initialized$'].subscribe(dm => {
        google.maps.event.addListener(dm, 'overlaycomplete', event => {
          dm.map.data.setStyle({
            editable: true,
            draggable: true
          });

          if (event.type !== google.maps.drawing.OverlayType.MARKER) {
            this.drawingComplete(event.overlay);
            dm.setDrawingMode(null);
          }
        });
      });
    }
  }

  addMarker(event: any): void {
    if (!this.model.marker && event.latLng) {
      this.model.latitude = event.latLng.lat();
      this.model.longitude = event.latLng.lng();

      this.model.marker = {
        latitude: this.model.latitude, longitude: this.model.longitude
      };
      this.zoom = 18;
    }
    this.reverseGeocode();
  }

  markerDragEnd(event): void {
    if (event.coords) {
      this.model.latitude = event.coords.lat;
      this.model.longitude = event.coords.lng;
    } else if (event.latLng) {
      this.model.latitude = event.latLng.lat();
      this.model.longitude = event.latLng.lng();
    }
    this.reverseGeocode();
  }

  extractFromAddress(components, type: string): string {
    for (let i = 0; i < components.length; i++) {
      for (let j = 0; j < components[i].types.length; j++) {
        if (components[i].types[j] === type) {
          return components[i].long_name;
        }
      }
    }
    return '';
  }

  reverseGeocode(): void {
    if (this.model.latitude && this.model.longitude) {
      let coordinates = [this.model.latitude, this.model.longitude];
      this.locationService.getAddressByCoordinates(coordinates).subscribe(res => {
        this.addresses = this.processAddresses(res);
        this.selectedAddress = this.addresses[0];
        this.address = this.addresses[0];
        if (this.model && !this.model.street) { this.copyUpdatedAddress(); }
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  processAddresses(results): Address[] {
    let addresses: Address[] = [];

    results.forEach(result => {
      let address: Address = new Address();
      address.street = this.extractFromAddress(result.address_components, 'street_number')
        + ' '
        + this.extractFromAddress(result.address_components, 'route');
      address.city = this.extractFromAddress(result.address_components, 'locality');
      this.apiService.getStates().map((state) => {
        if (state.name === this.extractFromAddress(result.address_components, 'administrative_area_level_1')) {
          address.state = state.abbreviation;
        }
      });
      address.zipcode = this.extractFromAddress(result.address_components, 'postal_code');
      address.displayName = `${address.street} ${address.street2}, ${address.city}, ${address.state} ${address.zipcode}`;
      if (address.street && address.street.trim().length > 0) {
        address.displayName = address.displayName.replace('undefined', '');
        address.displayName = address.displayName.replace(' ,', ',');
        addresses.push(address);
      }
    });

    return addresses;
  }

  updateAddress(address: Address): void {
    if (address && this.model) {
      this.model.street = address.street;
      this.model.city = address.city;
      this.model.state = address.state;
      this.model.zipcode = address.zipcode;
      this.model.country = address.country;
    }
  }

  copyUpdatedAddress(): void {
    if (this.address && this.model && this.address.street !== this.model.street) {
      this.model.street = this.address.street;
      this.model.city = this.address.city;
      this.model.state = this.address.state;
      this.model.zipcode = this.address.zipcode;
      this.model.country = this.address.country;
      if (this.model.street && (!this.model.name || this.model.name.trim().length === 0)) {
        this.model.name = this.model.street;
      }
    }
    this.address = new Address();
  }

  drawingComplete(overlay): void {
    let map = overlay.map;

    // Remove all existing features since we only support one right now.
    map.data.forEach((feature) => {
      map.data.remove(feature);
    });
    map.data.add(new google.maps.Data.Feature({
      geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()])
    }));
    map.data.forEach((feature) => {
      map.data.overrideStyle(feature, {
        strokeColor: '#FF8C00',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF8C00',
        fillOpacity: 0.35
      });
    });
    overlay.setMap(null); // Remove the original overlay from the map
    map.data.toGeoJson((json) => {
      if (json && json.features && json.features.length) {
        this.model.geofence = json.features[0].geometry;
      }
    });

    map.data.addListener('click', event => {
      this.setSelectedOverlay(map, event.feature);
    });

    map.data.addListener('rightclick', event => {
      this.deleteSelectedOverlay(map, event.feature);
    });

    map.data.addListener('setgeometry', event => {
      this.setSelectedOverlay(map, event.feature);
      map.data.toGeoJson((json) => {
        if (json && json.features && json.features.length) {
          this.model.geofence = json.features[0].geometry;
        }
      });
    });
  }

  setSelectedOverlay(map, feature): void {
    map.data.revertStyle();
    this.selectedOverlay = feature;
    map.data.overrideStyle(this.selectedOverlay, {
      fillColor: '#1D68D1'
    });
  }

  deleteSelectedOverlay(map, overlay = null): void {
    if (overlay) { this.selectedOverlay = overlay; }
    if (this.selectedOverlay) {
      map.data.remove(this.selectedOverlay);
      map.data.toGeoJson((json) => {
        if (json && json.features && json.features.length) {
          this.model.geofence = json.features[0].geometry;
        }
      });
      delete this.selectedOverlay;
    }
  }

  onSelect(propertyName: string, e): void {
    this.model[propertyName] = e;
  }

  getMyLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.model.longitude = position.coords.longitude;
        this.model.latitude = position.coords.latitude;
      });
    } else {
      console.log('No support for geolocation');
    }
  }
}
