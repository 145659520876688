import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// services
import { TrackableService } from './trackable.service';
import { TrackableTypeService } from '../trackable-types/trackable-type.service';
import { TruckService } from '../trucks/truck.service';
import { parseErrors } from '../shared/api.service';
import { AuthenticationService } from '../shared';

// types
import { TrackableType } from '../trackable-types/trackable-type';
import { Truck } from '../trucks/truck';
import { Trackable } from './trackable';

@Component({
  selector: 'new-trackable-dialog',
  templateUrl: './new-trackable-dialog.component.html',
  styleUrls: ['./new-trackable-dialog.component.scss'],
})
export class NewTrackableDialogComponent implements OnInit {
  loading = false;
  model: Trackable = new Trackable();
  errors = [];
  truck: Truck;
  selectedTrackableType: TrackableType;
  callback;
  device;

  trackableTypesDropdownConfig = {
    selectText: this.translate.instant('Select Trackable Type'),
    loadingText: this.translate.instant('Loading Trackable Types...'),
    noResultsText: this.translate.instant('No Trackable Types'),
    nameProperty: 'name',
    service: TrackableTypeService,
    query: { ordering: 'name' },
  };

  // trucks dropdown config
  trucksConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'id',
    selectText: this.translate.instant('Select Truck'),
    loadingText: this.translate.instant('Loading Trucks...'),
    noResultsText: this.translate.instant('No Trucks'),
    service: TruckService,
    serviceFunction: 'list',
    query: {
      ordering: 'name',
    },
    prefilledOptions: [],
  };

  constructor(
    public dialogRef: MatDialogRef<NewTrackableDialogComponent>,
    private truckService: TruckService,
    private trackableService: TrackableService,
    private elementRef: ElementRef,
    private deviceDetectorService: DeviceDetectorService,
    private translate: TranslateService,
    private authentiationService: AuthenticationService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  ngOnInit() { }

  selectTrackableType(trackableType: TrackableType): void {
    this.selectedTrackableType = trackableType;
    this.model.trackableType = trackableType;
  }

  selectTruck(truck: Truck): void {
    this.truck = truck;
    this.model.truck = truck;
  }

  submit(): void {
    this.loading = true;
    let model = cloneDeep(this.model);
    this.trackableService.save(model).subscribe(trackable => {
        if (typeof this.callback === 'function') { this.callback(); }
        this.dialogRef.close();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }
}
