<form #filters="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Assignment Errors</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <ng-container *ngIf="visibleFields === 'both' || visibleFields === 'noTruck'">
        <p translate>The driver(s) below do not have an assigned truck or are off duty. Select a truck for each driver in order to assign them to <b>{{ assignmentErrors[0].assignment.jobevent.jobDisplayName }}</b></p>
        <ng-container *ngFor="let error of assignmentErrors">
          <div *ngIf="error.errors.truck" class="field-row">
            <div class="field-group">
              <label translate>{{ error.assignment && error.assignment.driver && error.assignment.driver.name }}</label>
              <div class="padding-btm">
                <dropdown #dutyStatusDropdown
                  *ngIf="error.assignment && error.assignment.driver && error.assignment.driver.dutyStatus === 'off-duty'"
                  title="Select Duty Status"
                  [config]="dutyStatusConfig"
                  [selectedOption]="error.assignment.driver.dutyStatus"
                  [options]="dutyStatusOptions"
                  (onSelect)="updateDutyStatus(error, $event.id)">
                </dropdown>
              </div>
              <div>
                <ruckit-dropdown [config]="configSetup(noTrucksDropdownConfig, error)"
                  *ngIf="error.assignment && error.assignment.driver && !error.assignment.driver.truck"
                  (onSelect)="selectTruck(error, $event)">
                </ruckit-dropdown>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="visibleFields === 'both' || visibleFields === 'truckType'">
        <p translate>The driver(s) below are not in a compatible truck type for <b>{{ assignmentErrors[0].assignment.jobevent.jobDisplayName }}</b> or are off duty. Trucks types allowed are {{ jobEventTruckTypes }}</p>
        <ng-container *ngFor="let error of assignmentErrors">
          <div *ngIf="!error.errors.truck" class="field-row">
            <div class="field-group">
              <label translate>{{ error.assignment && error.assignment.driver && error.assignment.driver.name }}</label>
              <div class="padding-btm">
                <dropdown #dutyStatusDropdown
                  *ngIf="error.assignment && error.assignment.driver && error.assignment.driver.dutyStatus === 'off-duty'"
                  title="Select Duty Status"
                  [config]="dutyStatusConfig"
                  [selectedOption]="error.assignment.driver.dutyStatus"
                  [options]="dutyStatusOptions"
                  (onSelect)="updateDutyStatus(error, $event.id)">
                </dropdown>
              </div>
              <ruckit-dropdown [config]="configSetup(trucksDropdownConfig, error)"
                               [selectedOption]="error.assignment.truck.id"
                               (onSelect)="selectTruck(error, $event)">
              </ruckit-dropdown>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Assign Trucks
    </button>
  </div>
</form>
