import { Pipe, PipeTransform } from '@angular/core';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { Trip } from '../../trips/trip';

@Pipe({
  name: 'getTripsByStatusAndSharePipe',
  pure: true,
})
export class GetTripsByStatusAndSharePipe implements PipeTransform {
  transform(
    trips: Trip[],
    share: JobEventShare,
    status: string,
    shares: JobEventShare[]
  ): number {
    if (trips && trips.length && status) {
      if (share) {
        if (status === 'unapproved') {
          return this.getUnapprovedTrips(trips, share);
        }
        return this.getTripsByStatus(trips, share, status);
      } else if (shares && shares.length) {
        return shares
          .map((mapShare) =>
            status === 'unapproved'
              ? this.getUnapprovedTrips(trips, mapShare)
              : this.getTripsByStatus(trips, mapShare, status)
          )
          .reduce((a, b) => a + b, 0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getTripsByStatus = (
    trips: Trip[],
    share: JobEventShare,
    status: string
  ): number =>
    trips
      .map((trip) =>
        trip && trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.project
          ? trip.jobEvent.job.id === share.jobId &&
            trip.latestDecisionStatus === status
          : false
      )
      .filter(Boolean).length

  getUnapprovedTrips = (trips: Trip[], share: JobEventShare): number =>
    trips
      .map((trip) =>
        trip && trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.project
          ? trip.jobEvent.job.id === share.jobId &&
            trip.latestDecisionStatus !== 'approved'
          : false
      )
      .filter(Boolean).length
}

// removed this check in getUnapprovedTrips and getTripsByStatus
// trip.jobEvent.job.project.ownerOrganization ===
// share.organizationId &&
