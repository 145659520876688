import {
  Component, OnInit, Input, SimpleChange, OnChanges
} from '@angular/core';
import {
  trigger, transition, style, state, animate
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Invitation } from '../invitation/invitation';
import { InvitationService } from '../invitation/invitation.service';
import { ConnectionDialogComponent } from '../connections/connection-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  providers: [InvitationService],
  animations: [
    trigger('statusAnimation', [
      state('suspended', style({opacity: 1, transform: 'translateX(0) scale(1)'})),
      state('accepted',  style({opacity: 0, display: 'none', transform: 'translateX(0) scale(1)'})),
      state('rejected',  style({opacity: 0, display: 'none', transform: 'translateX(0) scale(1)'})),
      state('resent',    style({opacity: 1, transform: 'translateX(0) scale(1)'})),
      state('cancelled', style({opacity: 0, display: 'none', transform: 'translateX(0) scale(1)'})),
      transition('* => cancelled', [
        animate('300ms ease-out', style({background: '#fbd0d1', opacity: 0 }))
      ]),
      transition('* => resent', [
        animate('300ms', style({background: '#d4e6f7' })),
        animate('300ms', style({background: '#FFFFFF' }))
      ])
    ])
  ]
})
export class InvitationsComponent implements OnInit, OnChanges {
  invitations: any = [];
  loading = true;
  errors = [];
  organizationId: string;
  @Input() type: string;
  confirmDialog: MatDialogRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invitationService: InvitationService,
    public dialog: MatDialog
  ) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.organization !== null) {
      this.organizationId = currentUser.organization.id;
    }
  }

  openConnectionDialog() {
    // let dialogRef = this.dialog.open(ConnectionDialog);
    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      width: '444px',
      height: '440px'
    });
    dialogRef.afterClosed().subscribe(result => {
      // result;
    });
  }

  accept(invitation: Invitation) {
    invitation.loading = true;
    this.invitationService.acceptInvitation(invitation.id).subscribe(result => {
      invitation.status = 'accepted';
      this.getInvitations();
      invitation.loading = false;
    }, err => {
      this.errors = err;
      invitation.loading = false;
    });
  }

  reject(invitation: Invitation) {
    invitation.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });

    let body = 'This organization will not be able to send or receive jobs with you';
    if (invitation.recipientOrganization) {
      body = `'${invitation.recipientOrganization && invitation.recipientOrganization.name}'`;
      body = `${body} will not be able to send or receive jobs with you.`;
    }

    this.confirmDialog.componentInstance.attributes = {
      title: 'Reject Connection Request?',
      body:  body,
      close: 'Cancel',
      accept: 'Reject'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.invitationService.rejectInvitation(invitation.id).subscribe(result => {
          invitation.status = 'rejected';
          invitation.loading = false;
        }, err => {
          this.errors = err;
          invitation.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  resend(event, invitation: Invitation) {
    invitation.loading = true;
    this.invitationService.resendInvitation(invitation.id).subscribe(result => {
      invitation.status = 'resent';
      invitation.loading = false;
      invitation.resent = true;
      invitation.resentAt = result.resentAt;
    }, err => {
      this.errors = err;
      invitation.loading = false;
    });
  }

  cancel(invitation: Invitation) {
    invitation.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Cancel Invitation?',
      body: 'Are you sure you want to cancel your invitation?',
      close: 'No',
      accept: 'Yes'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.invitationService.cancelInvitation(invitation.id).subscribe(result => {
          invitation.status = 'cancelled';
          invitation.loading = false;
        }, err => {
          this.errors = err;
          invitation.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  ngOnInit() {
    this.getInvitations();
  }

  ngOnChanges(changes: {[type: string]: SimpleChange}) {
    this.getInvitations();
  }

  changeSearch() {
    this.getInvitations();
  }

  private getInvitations() {
    this.loading = true;
    this.invitationService.getInvitations(this.type, this.organizationId).subscribe(invitations => {
      this.invitations = invitations;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }
}
