import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { find as _find, filter } from 'lodash';
import { Subscription } from 'rxjs';

import { TruckTypeService } from '../trucks/truck-type.service';
import { AuthenticationService } from './../shared/authentication.service';
import { ConnectionService } from '../connections/connection.service';
import { PriceListService } from '../price-lists/price-list.service';
import { Organization } from '../organizations/organization';
import { Multiplier } from '../multipliers/multiplier';
import { parseErrors, ApiService } from '../shared';
import { ConnectionSerializer } from '../connections/connection.serializer';

/**
 * Account Component: This component is used to create and edit an Account
 * {@link Connection}. If the route is `/accounts/new` no `accountId` is set and
 * the form is prepared to add a new Account. If the route is formatted as
 * `/accounts/:accountId/edit` the component requests the account data and
 * populates the form with that information.
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <account></account>
 */
@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
  accountId: string;
  accountType = 'leased-fleet';
  account = new ConnectionSerializer().fromJson({oneRate: true});
  posEnabled = false;
  loading = false;
  states = [];
  stateOptions = [];
  billingStateOptions = [];
  countryOptions = [];
  returnTo: string;
  returnToParams: any;
  errors = [];
  companyTypeOptions = [];
  truckTypesReq: Subscription;
  connectionReq: Subscription;
  priceListReq: Subscription;
  selectedPaymentType;
  paymentTypeOptions = [
    { id: 'none', name: 'None' },
    { id: 'card', name: 'Card' },
    { id: 'cash', name: 'Cash' },
    { id: 'check', name: 'Check' }
  ];
  truckTypeOptions = [];
  truckTypesLoading = true;
  rateTypeOptions = [{ id: 'percent', name: 'Percentage' }];

  selectedPriceList;
  priceListOptions = [];
  priceListDropdownConfig = {
    searchable: false,
    loadingOptions: false
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private truckTypeService: TruckTypeService,
    private connectionService: ConnectionService,
    private priceListService: PriceListService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) { }

  /**
  * Examines the route and query parameters to determine the Account Type and
  * persisted search value.
  *
  * If the route includes `leased-fleet` the account is marked as a Leased Org
  * by setting the `account.organization.leasedOrg` value to `true`.
  *
  * When initializaing the `account` variable, the One Default Rate option is
  * defaulted to `true` by seeing `oneRate` to `true`. Additionally, the
  * `multipliers` property is is stubbed as an array with an empty
  * {@link Multiplier} object.
  *
  * If the authenticated user's organization has the Point of Sale (POS) feature
  * enabled, the `posEnabled` variable is set to `true` and the POS section is
  * displayed in the template.
  *
  * When loading this component for a new account, the `getPriceLists()`
  * function is called to set the `priceListOptions` variable. In either case,
  * the `getTruckTypes()` and `getStates()` functions are called to populate
  * dropdown options.
  */
  ngOnInit() {
    this.accountType = this.route && this.route.snapshot &&
      this.route.snapshot.url[0].path === 'accounts' ? 'accounts' : 'leased-fleet';

    this.account = new ConnectionSerializer().fromJson({
      oneRate: true,
      organization: new Organization({}),
      multipliers: [new Multiplier({})]
    });

    this.companyTypeOptions = this.apiService.getCompanyTypes().map(companyType => {
      return { id: companyType.id, name: companyType.name };
    });

    const organization = this.authenticationService.getOrganization();
    this.posEnabled = organization && organization.posEnabled;

    if (this.route.queryParams) {
      this.route.queryParams.forEach((params: Params) => {
        if (params['returnTo']) {
          this.returnTo = decodeURIComponent(params['returnTo']);
        } else {
          if (this.accountType !== 'accounts' && this.account && this.account.organization) {
            this.returnTo = '/leased-fleet';
          } else {
            this.returnTo = '/accounts';
          }
        }
      });
    }

    this.route.params.forEach((params: Params) => {
      this.accountId = params['accountId'];
      if (this.accountId) {
        this.getAccount();
      } else {
        this.getPriceLists();
      }
    });

    this.getTruckTypes();

    this.states = this.apiService.getStates();
    this.countryOptions = this.apiService.getCountries().map(country => {
      return {
        id: country.abbreviation,
        name: country.name
      };
    });
    if (this.account && this.account.organization && this.account.organization.country) {
      this.selectCountry(this.account.organization.country, 'contact');
    } else {
      this.selectCountry('US', 'contact');
    }
    if (this.account && this.account.organization && this.account.organization.billingCountry) {
      this.selectCountry(this.account.organization.billingCountry, 'billing');
    } else {
      this.selectCountry('US', 'billing');
    }
  }

  ngOnDestroy() {
    if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
      this.truckTypesReq.unsubscribe();
    }
    if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
      this.connectionReq.unsubscribe();
    }
    if (this.priceListReq && typeof this.priceListReq.unsubscribe === 'function') {
      this.priceListReq.unsubscribe();
    }
  }

  /**
   * Sets the Account Organization's `state` and/or `billingState` properties to
   * the State selected from the dropdown based on the `type` parameter.
   *
   * @param {} state The state object being selected
   * @param {} type The type or section of the form the selection occurs in
   */
  selectState(state, type = 'contact'): void {
    if (type === 'contact') {
      this.account.organization.state = state;
    } else {
      this.account.organization.billingState = state;
    }
  }

  /**
  * Sets the Account Organization's `country` and/or `billingCountry` properties
  * to the Country selected from the dropdown based on the `type` parameter.
  *
  * @param {} country The country object being selected
  * @param {} type The type or section of the form the selection occurs in
  */
  selectCountry(country, area = 'organization', state: string = null): void {
    const filteredStates = filter(this.states, { country: country }).map(s => {
      return {
        id: s.abbreviation,
        name: s.name
      };
    });
    if (area === 'contact') {
      this.stateOptions = filteredStates;
      this.account.organization.country = country;
      if (state === null) {
        this.account.organization.state = filteredStates && filteredStates.length > 0 ? filteredStates[0].name : null;
      }
    } else {
      this.billingStateOptions = filteredStates;
      this.account.organization.billingCountry = country;
      if (state === null) {
        this.account.organization.billingState = filteredStates && filteredStates.length > 0 ? filteredStates[0].name : null;
      }
    }
  }

  /**
   * If true, this function will copy the organization address information to
   * the billing address fields.
   *
   * @param same Checkbox element indicating whether or not to copy the data
   */
  copyContactAddress(same): void {
    if (same.target.checked) {
      this.account.organization.billingAddress1 = this.account.organization.street;
      this.account.organization.billingAddress2 = this.account.organization.address2;
      this.account.organization.billingCity = this.account.organization.city;
      this.account.organization.billingState = this.account.organization.state;
      this.account.organization.billingZipcode = this.account.organization.zipcode;
    }
  }

  /**
   * If true, this function will copy the organization email address to the
   * billing email field.
   *
   * @param same Checkbox element indicating whether or not to copy the data
   */
  copyContactEmail(same): void {
    if (same.target.checked) {
      this.account.organization.billingEmail = this.account.organization.email;
    }
  }

  /**
   * Sets the `selectedPaymentType` and `account.posPaymentType` properties to
   * the Payment Type ID selected from the dropdown.
   *
   * @param {} paymentType The payment type object being selected
   */
  selectPaymentType(paymentType): void {
    this.selectedPaymentType = paymentType;
    this.account.posPaymentType = paymentType && paymentType.id;
  }

  /**
   * Requests the Account from the {@link ConnectionService} and maps the
   * results to `account` for use as the primary focus of this component's form.
   *
   * If the `account.multipliers` attribute is empty, a multiplier object is
   * pre-staged in the array.
   *
   * If the `account` has a {@link PaymentType}, that option is pre-selected
   * in the Payment Type dropdown.
   */
  getAccount(): void {
    this.errors = [];
    this.loading = true;
    if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
      this.connectionReq.unsubscribe();
    }

    this.connectionReq = this.connectionService.get(this.accountId).subscribe(account => {
      this.account = JSON.parse(JSON.stringify(account));
      this.selectCountry(account.organization.country, 'contact', account.organization.state);
      this.selectCountry(account.organization.billingCountry, 'billing', account.organization.billingState);
      if (!this.account.multipliers || !this.account.multipliers.length) {
        this.account.multipliers = [new Multiplier({})];
      }
      let paymentType = _find(this.paymentTypeOptions, { id: this.account.posPaymentType });
      if (paymentType) { this.selectedPaymentType = paymentType; }
      this.getPriceLists();
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
    });
  }

  /**
   * Requests the Price Lists from the {@link PriceListService} and maps the
   * results to `priceListOptinos` for use in the Price List dropdown.
   *
   * If the `account.posPriceList` is already set, that option is pre-selected.
   */
  getPriceLists(): void {
    this.loading = true;
    this.errors = [];
    if (this.priceListReq && typeof this.priceListReq.unsubscribe === 'function') {
      this.priceListReq.unsubscribe();
    }

    this.priceListReq = this.priceListService.list({}).subscribe(priceLists => {
      this.priceListOptions = [{ id: null, name: '-' }].concat(priceLists);
      if (this.account.posPriceList && this.account.posPriceList.id) {
        let priceList = _find(priceLists, { id: this.account.posPriceList.id });
        this.selectedPriceList = priceList;
        if (!priceList) { this.priceListOptions.unshift(this.selectedPriceList); }
      }
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  /**
   * Initially, the account is marked as a normal connection. If the route does
   * not include `accounts` then the account's {@link Organization} is marked as
   * a `leasedOrg`.
   *
   * If the account has an {@link Organization}, the Billing Contact object is
   * deleted from the request. TODO: Revise to occur in the serializer.
   *
   * Saves the connection using the {@link ConnectionService} and navigates the
   * user to the appropriate index route for Accounts/Leased Fleet.
   */
  submit(): void {
    this.errors = [];
    let leasedOrgFlag = false;
    this.loading = true;
    if (this.accountType !== 'accounts' && this.account && this.account.organization) {
      this.account.organization.leasedOrg = true;
      leasedOrgFlag = true;
    }
    if (this.account && this.account.organization) {
      delete this.account.organization.billingContact;
    }
    this.connectionService.save(this.account).subscribe(response => {
      this.loading = false;
      if (this.returnTo && this.returnTo.includes('?')) {
        let route = this.returnTo.split('?')[0];
        let params = {};
        this.returnTo.split('?')[1].split('&').forEach(param => {
          let paramData = param.split('=');
          params[paramData[0]] = paramData[1];
        });
        this.router.navigate([route], { queryParams: params });
      } else if (this.returnTo) {
        this.router.navigate([this.returnTo]);
      } else {
        leasedOrgFlag ? this.router.navigateByUrl('/leased-fleet') : this.router.navigateByUrl('/accounts');
      }
    }, err => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  rateSetupChanged(oneRate: boolean): void {
    if (oneRate) {
      this.account.oneRate = true;
    } else {
      this.account.oneRate = false;
    }
  }

  /**
   * Requests the Truck Types from the {@link TruckTypeService} and maps the
   * results to `truckTypeOptions` for use in the Truck Type dropdown.
   */
  getTruckTypes(): void {
    this.errors = [];
    this.truckTypesLoading = true;
    if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
      this.truckTypesReq.unsubscribe();
    }

    this.truckTypesReq = this.truckTypeService.list({
      ordering: 'name',
      page_size: 100
    }).subscribe(truckTypes => {
      this.truckTypeOptions = truckTypes.map(truckType => {
        return { id: truckType.id, name: truckType.name };
      });
      this.truckTypesLoading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.truckTypesLoading = false;
    });
  }

  /**
   * Sets the `truckType` for the multiplier at the `index` in the
   * `account.multipliers` array.
   *
   * @param {} truckType The truck type object being selected
   * @param {} index The index for the multiplier we are dealing with
   */
  selectTruckType(truckType, index): void {
    let multiplier = this.account && this.account.multipliers[index];
    if (multiplier) { multiplier.truckType = truckType; }
  }

  /**
   * Sets the `rateType` for the multiplier at the `index` in the
   * `account.multipliers` array.
   *
   * @param {} rateType The rate type object being selected
   * @param {} index The index for the multiplier we are dealing with
   */
  selectRateType(rateType, index): void {
    let multiplier = this.account && this.account.multipliers[index];
    if (multiplier) { multiplier.rateType = rateType; }
  }

  /**
   * Pushes a new {@link Multiplier} object onto the `account.multipliers`
   * array.
   *
   * @param index The index in the multiplier for loop that this action is being
   * performed from
   */
  addMultiplier(index): void {
    if (this.account) {
      this.account.multipliers.push(new Multiplier({}));
    }
  }

  /**
   * Marked the {@link Multiplier} object at `index` for removal by setting its
   * `remove` attribute to the inverse of what it currently is set to (this also
   * allows for an undo action)`false`the `account.multipliers`
   * array.
   *
   * Note: The template does not currently display this option.
   *
   * @param index The index in the multiplier for loop that this action is being
   * performed from
   * @param current The current state of the multiplier
   */
  removeMultiplier(index, current): void {
    let multiplier = this.account && this.account.multipliers[index];
    if (multiplier) { multiplier.remove = !current; }
  }

  /**
   * Sets the `selectedPriceList` and `account.posPriceList` properties to the
   * Price List selected from the dropdown.
   *
   * @param {} priceList The price list object being selected
   */
  selectPriceList(priceList): void {
    this.selectedPriceList = priceList;
    this.account.posPriceList = priceList;
  }
}

