import {
  Component, Input, Output, EventEmitter, OnInit, HostListener,
  ElementRef, ViewChild, OnDestroy, SimpleChanges, OnChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// libraries
import { includes, clone } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

// angular material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// services
import { TrackableService } from './trackable.service';
import { parseErrors } from '../shared/api.service';
import { TruckService } from '../trucks/truck.service';
import { TrackableTypeService } from '../trackable-types/trackable-type.service';

// components
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { DropdownComponent } from '../shared';

// types
import { Trackable } from './trackable';

@Component({
  selector: 'ruckit-edit-trackable',
  templateUrl: './edit-trackable.component.html',
  styleUrls: ['./edit-trackable.component.scss']
})
export class EditTrackableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modifiedTrackable: Trackable;
  @Input() trackable: Trackable;
  @Input() afterEdit;
  @Output() completed = new EventEmitter();
  @Output() closed = new EventEmitter();

  open = false;
  loading = false;
  errors = [];
  confirmDialog: MatDialogRef<any>;
  tag = [];
  device;

  // trucks dropdown
  @ViewChild('trucksDropdown') trucksDropdown: RuckitDropdownComponent;
  trucksConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'id',
    selectText: this.translateService.instant('Select Truck'),
    loadingText: this.translateService.instant('Loading Trucks...'),
    noResultsText: this.translateService.instant('No Trucks'),
    service: TruckService,
    serviceFunction: 'list',
    query: {
      ordering: 'name'
    },
    prefilledOptions: [],
  };
  // trackable types dropdown
  @ViewChild('trackableTypesDropdown') trackableTypesDropdown: RuckitDropdownComponent;
  trackableTypesConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'id',
    selectText: this.translateService.instant('Select Trackable Type'),
    loadingText: this.translateService.instant('Loading Trackable Types...'),
    noResultsText: this.translateService.instant('No Trackable Types'),
    service: TrackableTypeService,
    serviceFunction: 'list',
    query: {
      ordering: 'name'
    },
    prefilledOptions: [],
  };

  @ViewChild('trackableForm') trackableForm;

  @HostListener('document:click', ['$event', 'trackable']) documentClick(event) {
    let row = event && event.target && event.target.closest('.primary-row');
    if (!includes(event.target.classList, 'row') && !(row && row.contains(event.target)) && this.open) {
      let confirmDialog = document.querySelector('ruckit-confirm-dialog');
      if (!includes(event.target.classList, 'icon-more') &&
        !includes(event.target.classList, 'mat-chip-remove') &&
        !includes(event.target.classList, 'mat-option-text') &&
        !this._eref.nativeElement.contains(event.target) &&
        !(confirmDialog && confirmDialog.contains(event.target))) {
        if (this.trackableForm && !this.trackableForm.form.pristine) {
          this.confirmBeforeClosing().subscribe((dialogResult) => {
            if (dialogResult) {
              this.submit();
            } else {
              this.close();
            }
          });
        } else {
          this.close();
        }
      }
    }
  }

  constructor(
    private _eref: ElementRef,
    public dialog: MatDialog,
    private truckService: TruckService,
    private trackableTypeService: TrackableTypeService,
    private trackableService: TrackableService,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trackable && changes.trackable.currentValue && changes.trackable.currentValue.id) {
      this.modifiedTrackable = clone(this.trackable);
    }
  }

  ngOnDestroy() { }

  submit(): void {
    this.loading = true;
    this.trackableService.save(this.modifiedTrackable).subscribe((trackable) => {
      this.open = false;
      this.trackable.selected = false;
      this.completed.emit(this.modifiedTrackable);
      if (this.trackableForm) { this.trackableForm.form.markAsPristine(); }
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  remove(): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });

    if (this.confirmDialog && this.confirmDialog.componentInstance) {
      this.confirmDialog.componentInstance.attributes = {
        title: this.translateService.instant('Delete Trackable?'),
        body: this.translateService.instant('You will no longer be able to recieve GPS information from this trackable. Deleting a trackable cannot be undone.'),
        close: this.translateService.instant('Cancel'),
        accept: this.translateService.instant('Delete')
      };
      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.trackableService.remove(this.modifiedTrackable).subscribe(() => {
            this.modifiedTrackable.removed = true;
            this.open = false;
            this.trackable.selected = false;
            if (this.trackableForm) {
              this.trackableForm.form.markAsPristine();
            }
            this.loading = false;
            this.completed.emit(this.modifiedTrackable);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        }
        this.confirmDialog = null;
      });
    }
  }

  /**
   * Updates a specified field on the trackable object, with specified functionality for particular fields
   *
   * @param {any} value The updated value to be applied to the trackable object
   * @param {string} field The field name which the value will be set on
   */
  optionSelected(e: any, field: string) {
    let editsMade = false;
    switch (field) {
      case 'truck':
        if (
          !this.modifiedTrackable.truck ||
          (this.modifiedTrackable.truck && this.modifiedTrackable.truck.id !== e.id)
        ) {
          this.modifiedTrackable.truck = e;
          editsMade = true;
        }
        break;
      case 'trackableType':
        if (
          !this.modifiedTrackable.trackableType ||
          (this.modifiedTrackable.trackableType && this.modifiedTrackable.trackableType.id !== e.id)
        ) {
          this.modifiedTrackable.trackableType = e;
          editsMade = true;
        }
        break;
      default:
        this.modifiedTrackable[field] = e;
        editsMade = true;
        break;
    }
    if (this.trackableForm && this.trackableForm.form && editsMade) { this.trackableForm.form.markAsDirty(); }
  }

  close(): void {
    if (this.trackableForm && this.trackableForm.form) {
      this.trackableForm.form.markAsPristine();
      this.open = false;
      this.trackable.selected = false;
      if (this.trackable && this.modifiedTrackable && (this.trackable.id === this.modifiedTrackable.id)) {
        this.modifiedTrackable = undefined;
      }
      this.closed.emit();
    }
  }

  public markAsPristine(): void {
    if (this.trackableForm && this.trackableForm.form) {
      this.trackableForm.form.markAsPristine();
    }
  }

  public setOpen(): void {
    this.open = true;
    setTimeout(() => {
      if (this.trucksDropdown) {
        this.trucksDropdown.deselectOptions();
        this.trucksConfig = {
          ...this.trucksConfig,
          selectText:
            this.trackable && this.trackable.truck && this.trackable.truck.name
              ? this.trackable.truck.name
              : this.translateService.instant('Select Truck'),
        };
      }
      if (this.trackableTypesDropdown) {
        this.trackableTypesDropdown.deselectOptions();
        this.trackableTypesConfig = {
          ...this.trackableTypesConfig,
          selectText:
            this.trackable && this.trackable.trackableType && this.trackable.trackableType.name
              ? this.trackable.trackableType.name
              : this.translateService.instant('Select Trackable Type'),
        };
      }
    }, 500);
  }

  /**
   * To display confirm dialog
   * if user closes edit panel without saving data
   */
  confirmBeforeClosing(): Observable<any> {
    let observable: Observable<any>;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    if (this.confirmDialog && this.confirmDialog.componentInstance) {
      this.confirmDialog.componentInstance.attributes = {
        title: this.translateService.instant('You have unsaved Changes'),
        body: this.translateService.instant('Are you sure you want to leave? Your changes will not be saved.'),
        close: this.translateService.instant('Discard'),
        accept: this.translateService.instant('Save')
      };
      observable = this.confirmDialog.afterClosed();
      this.confirmDialog = null;
    }
    return observable;
  }
}
