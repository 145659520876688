import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { remove, get, find as _find } from 'lodash';
import { MatDialog } from '@angular/material/dialog';

import { JobInvitationFiltersDialogComponent } from './job-invitation-filters-dialog.component';
import { AcceptJobDialogComponent } from '../jobs/accept-job-dialog.component';
import { ProjectService } from  '../projects/project.service';
import { CollaboratorService } from  '../collaborators/collaborator.service';
import { ConnectionService } from '../connections/connection.service';
import { InvitationService } from '../invitation/invitation.service';
import { LocationService }  from '../locations/location.service';
import { AuthenticationService } from '../shared/index';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Device } from '../shared/device';

@Component({
  selector: 'ruckit-job-invitations',
  templateUrl: './job-invitations.component.html',
  styleUrls: ['./job-invitations.component.scss']
})
export class JobInvitationsComponent implements OnInit, OnDestroy {
  device: Device = new Device();
  invitations: any = [];
  invitation: any = {};
  loading = true;
  canCreateJobs = true;
  hasAllDriversEnabled = false;
  errors = [];
  search = '';
  sortBy: string;
  sortAsc = true;
  stringify = JSON.stringify;
  locations = [];
  customers = [];
  projects = [];
  filters = [];
  filtersDialog;

  locationsReq: Subscription;
  customersReq: Subscription;
  projectsReq: Subscription;
  invitationReq: Subscription;
  collaboratorsReq: Subscription;

  acceptJobCallback = () => {
    this.getInvitations();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    private projectService: ProjectService,
    private connectionService: ConnectionService,
    private locationService: LocationService,
    private collaboratorService: CollaboratorService,
    private invitationService: InvitationService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.dropdownNextPage = this.dropdownNextPage.bind(this);
    this.dropdownSearch = this.dropdownSearch.bind(this);
  }

  ngOnInit() {
    this.canCreateJobs = this.authenticationService.canCreateJobs();
    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    let invitationId;
    this.route.params.subscribe(params => {
      invitationId = params['invitationId'];
    });

    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      this.search = params['search'] || '';

      this.getInvitations();
      this.getLocations();
      this.getProjects();
      this.getCustomers();

      if (invitationId) {
        this.getInvitation(invitationId);
      }
    });

    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnDestroy() {
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }
    if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
      this.customersReq.unsubscribe();
    }
    if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
      this.projectsReq.unsubscribe();
    }
    if (this.collaboratorsReq && typeof this.collaboratorsReq.unsubscribe === 'function') {
      this.collaboratorsReq.unsubscribe();
    }
    if (this.invitationReq && typeof this.invitationReq.unsubscribe === 'function') {
      this.invitationReq.unsubscribe();
    }
  }

  getLocations(query = {}, next = false): void {
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }
    let request = this.locationService.list(query);
    if (next) {
      request = this.locationService.listNext();
    } else {
      this.customers = [];
    }
    if (this.filtersDialog) {
      this.filtersDialog.locationsConfig.loadingOptions = true;
    }
    this.locationsReq = this.locationService.list(query).subscribe(locations => {
      this.locations.push.apply(this.locations, locations);
    }, err => {
      this.errors = err;
    }, () => {
      if (this.filtersDialog) {
        this.filtersDialog.locationsConfig.loadingOptions = false;
      }
    });
  }

  getCustomers(query = {}, next = false): void {
    if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
      this.customersReq.unsubscribe();
    }
    let request = this.connectionService.list(query);
    if (next) {
      request = this.connectionService.listNext();
    } else {
      this.customers = [];
    }
    if (this.filtersDialog) {
      this.filtersDialog.customersConfig.loadingOptions = true;
    }
    this.customersReq = request.subscribe(res => {
      this.customers.push.apply(this.customers, res.map(connection => connection.organization));
    }, err => {
      this.errors = err;
    }, () => {
      if (this.filtersDialog) {
        this.filtersDialog.customersConfig.loadingOptions = false;
      }
    });
  }

  getProjects(query = {}, next = false): void {
    if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
      this.projectsReq.unsubscribe();
    }
    let request = this.projectService.list(query);
    if (next) {
      request = this.projectService.listNext();
    } else {
      this.projects = [];
    }
    if (this.filtersDialog) {
      this.filtersDialog.customersConfig.loadingOptions = true;
    }
    this.projectsReq = request.subscribe(res => {
      this.projects.push.apply(this.projects, res);
    }, err => {
      this.errors = err;
    }, () => {
      if (this.filtersDialog) {
        this.filtersDialog.customersConfig.loadingOptions = false;
      }
    });
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.collaboratorService.getNext();
      if (o) {
        this.loading = true;
        o.subscribe(
          invitations => {
            this.invitations = this.invitations.concat(invitations);
            this.loading = false;
          },
          err => this.errors = err
        );
      }
    }
  }

  getInvitations(query = {}, append = false): void {
    if (this.collaboratorsReq && typeof this.collaboratorsReq.unsubscribe === 'function') {
      this.collaboratorsReq.unsubscribe();
    }
    this.loading = true;
    this.collaboratorsReq = this.collaboratorService.getPending().subscribe(invitations => {
      this.invitations = invitations;
      this.loading = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  getInvitation(invitationId: string): void {
    if (this.invitationReq && typeof this.invitationReq.unsubscribe === 'function') {
      this.invitationReq.unsubscribe();
    }
    this.loading = true;
    this.invitationReq = this.invitationService.getInvitation(invitationId).subscribe(invitation => {
      this.invitation = invitation;
      this.openAcceptJob(this.invitation);
      this.loading = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  changeSearch(term?: string): void {
    this.search = term || '';
    this.getInvitations();
  }

  expandSearch(): void {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  sort(sortKey: string): void {
    if (this.sortBy === sortKey) { this.sortAsc = ! this.sortAsc; }
    this.sortBy = sortKey;
    this.loading = true;
    this.getInvitations({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openFilters(): void {
    const dialog = this.dialog.open(JobInvitationFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.customers = this.customers.map((customer) => {
      return {
        ...customer,
        selected: get(_find(this.filters, {key: 'customer'}), 'value.id') === customer.id
      };
    });
    dialog.componentInstance.locations = this.locations.map(location => {
      return {
        ...location,
        selected: get(_find(this.filters, {key: 'location'}), 'value.id') === location.id
      };
    });
    dialog.componentInstance.projects = this.projects.map(project => {
      return {
        ...project,
        selected: get(_find(this.filters, {key: 'project'}), 'value.id') === project.id
      };
    });
    dialog.componentInstance.dropdownNextPage = this.dropdownNextPage;
    dialog.componentInstance.dropdownSearch = this.dropdownSearch;
    dialog.componentInstance.callback = res => this.filterChanges(res);
    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  dropdownNextPage(e, type: string): void {
    switch (type) {
      case 'customer':
        this.getCustomers({}, true);
        break;
      case 'project':
        this.getProjects({}, true);
        break;
      case 'location':
        this.getLocations({}, true);
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  dropdownSearch(term = '', type: string): void {
    switch (type) {
      case 'customer':
        this.getCustomers({search: term});
        break;
      case 'project':
        this.getProjects({search: term});
        break;
      case 'location':
        this.getLocations({search: term});
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  filterChanges(filterRes): void {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'job__customer_organizationt',
      project: 'job__project',
      location: 'job__end_location'
    };
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      query[queryKeys[key]] = filterRes[key].id;
      return {
        key: key,
        value: filterRes[key],
        query: query
      };
    });
    this.getInvitations();
  }

  removeFilter(filter): void {
    remove(this.filters, filter);
    this.getInvitations();
  }

  selectInvitation(e, invitation): void {
    let target = e.target || e.srcElement || e.currentTarget;
    if (target && target.className === 'icomoon icon-more') {
      // Do nothing
    } else {
      this.openAcceptJob(invitation);
    }
  }

  openAcceptJob(invitation): void {
    const modalSize = this.device.mobile ? { height: '100vh', width: '730px' } : { width: '730px' };
    const dialog = this.dialog.open(AcceptJobDialogComponent, modalSize);
    dialog.componentInstance.invitation = invitation;
    dialog.componentInstance.callback = this.acceptJobCallback;
  }

  isAcceptable() {
    // TODO: What scenarios would render a Job "acceptable"?
    return true;
  }
}
