<form #editJobEventSLOrderID="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Set or Update SLOrder ID</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors"><notification *ngFor="let error of errors">{{error}}</notification></div>

    <div class="content">
      <div *ngIf="jobEvent" class="slorder-id">
        <div class="form-content">
          <div class="field-row">
            <div class="field-group">
              <label translate>SLOrder ID</label>
              <div class="input-group">
                <input type="text" [(ngModel)]="jobEvent.slorderId" name="slorderId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="editJobEventSLOrderID.form.valid && submit(); false"
            [disabled]="loading"
            [ngClass]="{loading: loading}" translate>
      Save SLOrder ID
    </button>
  </div>
</form>
