import {
  Component, Input, Output, SimpleChange, OnInit, OnChanges, EventEmitter,
  ViewChild, TemplateRef, OnDestroy
} from '@angular/core';
import {
  trigger, transition, style, state, animate
} from '@angular/animations';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ConnectionService } from './connection.service';
import { Connection } from './connection';
import { ConnectionDialogComponent } from './connection-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'ruckit-connections-list',
  templateUrl: './connections-list.component.html',
  styleUrls: ['./connections-list.component.scss'],
  animations: [
    trigger('statusAnimation', [
      state('suspended', style({ opacity: 1, transform: 'translateX(0) scale(1)' })),
      state('accepted', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      state('rejected', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      state('resent', style({ opacity: 1, transform: 'translateX(0) scale(1)' })),
      state('cancelled', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      transition('* => cancelled', [
        animate('300ms ease-out', style({ background: '#fbd0d1', opacity: 0 }))
      ]),
      transition('* => resent', [
        animate('300ms', style({ background: '#d4e6f7' })),
        animate('300ms', style({ background: '#FFFFFF' }))
      ])
    ])
  ]
})
export class ConnectionsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Name'), sortable: true },
    { key: 'location', title: this.translationService.instant('Location'), sortable: true },
    { key: 'identifiers', title: this.translationService.instant('Identifiers'), sortable: true },
    { key: 'primary-contact', title: this.translationService.instant('Primary Contact'), sortable: true },
    { key: 'primary-contact-email', title: this.translationService.instant('Email'), sortable: true },
    { key: 'billing-id', title: this.translationService.instant('Billing Id'), sortable: true },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];
  @Input() displayedColumns = [
    'name', 'location', 'identifiers', 'primary-contact',
    'primary-contact-email', 'billing-id', 'action'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  // config for fancy table
  connectionTableConfig = {
    hasHeader: true,
    service: ConnectionService,
    preferenceKey: 'ConnectionsListComponent-ConnectionService',
    query: { customer_only: 'False' },
    collectionTitle: this.translationService.instant('Connections'),
    noResultsText: this.translationService.instant('a connection'),
    sortBy: 'organization__name',
    sortDirection: 'asc',
    newRecordModal: this.openDialog.bind(this),
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Disconnect'), action: 'disconnect', link: false, external: false },
      { name: this.translationService.instant('Suspend'), action: 'suspend', link: false, external: false }
    ]
  };
  query: any = {};
  connections: any = [];
  count: number;
  page = 1;
  loading = true;
  errors = [];
  firstRun = true;
  confirmDialog: MatDialogRef<any>;
  connectionsReq;
  @Input() type = 'all';
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() noConnections: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('connectionTable') connectionTable;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle') columnToggle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private connectionService: ConnectionService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.route && this.route.params) {
      this.route.params.forEach((params: Params) => {
        this.type = params['type'] || 'all';
        this.search = params['search'];
      });
    }
  }

  ngOnChanges(changes: { [search: string]: SimpleChange }) {
    let url, type;
    this.query = [];
    if (this.route && this.route.snapshot) {
      url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
      type = this.route.snapshot.params['type'] || 'all';
    }

    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let chng = changes[propName];
        if (!this.firstRun) {
          if (propName === 'search') {
            this.search = chng.currentValue;
            this.query['search'] = this.search;
          } else if (propName === 'type') {
            this.type = chng.currentValue;
            this.query['type'] = this.type;
            // USING QUERY FIELD
            if (this.type === 'broker') {
              this.query['is_broker'] = 'True';
            } else if (this.type === 'contractor') {
              this.query['is_contractor'] = 'True';
            } else if (this.type === 'carrier') {
              this.query['is_carrier'] = 'True';
            }
          }
        } else {
          this.firstRun = false;
          if (this.route && this.route.snapshot) {
            this.search = this.route.snapshot.params['search'];
            this.type = this.route.snapshot.params['type'] || 'all';
          }
        }
      }
    }
    if (this.search || this.type) {
      if (this.search) {
        this.location.replaceState(url + '/' + type + '/' + this.search);
      } else {
        this.location.replaceState(url + '/' + type);
      }
      this.getConnections(this.query);
    }
  }

  ngOnDestroy() {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
  }

  openDialog(view = 'invite'): void {
    const dialog = this.dialog.open(ConnectionDialogComponent, {
      width: '640px'
    });
    dialog.componentInstance.activeView = view;
  }

  suspend(connection: Connection): void {
    connection.loading = true;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Suspend Connection?'),
      body: this.translationService.instant('You will no longer be able to send and receive jobs with \'' + connection.organization.name + '\'.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Suspend')
    };
    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.connectionService.suspendConnection(connection.id).subscribe(result => {
          connection.status = 'suspended';
          connection.loading = false;
          this.connectionTable.getRecords();
        }, err => {
          this.errors = parseErrors(err);
          connection.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  disconnect(connection: Connection): void {
    connection.loading = true;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Disconnect?'),
      body: this.translationService.instant('You will no longer be able to send and receive jobs with \'' + connection.organization.name + '\'.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Disconnect')
    };
    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.connectionService.disconnectConnection(connection.id).subscribe(result => {
          connection.status = 'disconnected';
          connection.loading = false;
          this.connectionTable.getRecords();
        }, err => {
          this.errors = parseErrors(err);
          connection.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  menuAction(action: string, connection: Connection): void {
    switch (action) {
      case 'edit':
        this.router.navigateByUrl(connection.url(action));
        break;
      case 'suspend':
        this.suspend(connection);
        break;
      case 'disconnect':
        this.disconnect(connection);
        break;
    }
  }

  getConnections(query = {}): void {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }

    query = { ...query, ...this.buildTypeQuery(query, this.type) };

    this.connectionsReq = this.connectionService.list({
      search: this.search,
      customer_only: 'False',
      ...query
    }).subscribe(connections => {
      this.connections = connections;
      this.connectionTable.getRecords();
      this.count = this.connectionService.count;
      if (this.connections.length === 0) {
        this.noConnections.emit(true);
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  buildTypeQuery(query, type: string): any {
    query['is_broker'] = null;
    query['is_carrier'] = null;
    query['is_contractor'] = null;

    switch (type) {
      case 'broker':
        query['is_broker'] = 'True';
        break;
      case 'carrier':
        query['is_carrier'] = 'True';
        break;
      case 'contractor':
        query['is_contractor'] = 'True';
        break;
    }

    return query;
  }

  /**
   * Used for reseting the filters or when filter is changed
   *
   * @param  {} appliedFilters
   */
  filtersModified(appliedFilters): void {
    if (!appliedFilters || !appliedFilters.length) {
      // Unimplemented
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
