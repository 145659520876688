<div class="app-content" [ngClass]="{'mobile': device.mobile}">
  <my-toolbar *ngIf="(!assignmentDetails || !assignmentDetails['driver']) && !jobOverviewOpen"
              [title]="jobEvent && jobEvent.jobDisplayName">
    <!-- <div class="mobile-toolbar-action right-actions" right-first>
      <button (click)="openJobOverview()"><span class="icon-help"></span></button>
    </div> -->
  </my-toolbar>
  <my-toolbar *ngIf="assignmentDetails && assignmentDetails['driver']"
              [title]="assignmentDetails && assignmentDetails['driver'] && assignmentDetails['driver']['name']">
    <div class="mobile-toolbar-action left-actions" left-first>
      <button (click)="openAssignmentDetails(null)"><span class="icon-close"></span></button>
    </div>
  </my-toolbar>

  <my-toolbar *ngIf="jobOverviewOpen" title="Overview">
    <div class="mobile-toolbar-action left-actions" left-first>
      <button (click)="openJobOverview()"><span class="icon-close"></span></button>
    </div>
  </my-toolbar>

  <action-bar *ngIf="job">
    <div class="left-actions">
      <dropdown title="{{ jobEventsLoading ? 'Loading Jobs...' : 'Select a Job' }}"
                [disabled]="!job || !jobEvents || jobEventsLoading"
                #jobEventsDropdown
                [options]="jobEvents"
                (onSelect)="selectJobEvent($event)"
                (nextPage)="getNextJobEvents()"
                [selectedOption]="jobEvent"
                class="job-events-dropdown"></dropdown>
    </div>
    <div *ngIf="job && jobEvent" class="right-actions">
      <dropdown #actionsDropdown title="Actions"
                [options]="actionsDropdownOptions"
                [config]="actionsDropdownConfig"
                (onSelect)="setSelectedAction($event, jobEvent)"
                class="actions-dropdown"></dropdown>
      <a *ngIf="job && jobEvent && jobEvent.canShare"
         [routerLink]="['/jobs', job.id, jobEvent.id, 'collaborators']"
         [queryParams]="{returnTo: '/jobs/' + job.id + '/' + jobEvent.id, returnToTitle: 'Job Detail'}"
         fragment="addCollaborator" class="btn btn-primary" translate>
        Add Collaborators
      </a>
      <a *ngIf="isDispatchable()" class="btn btn-primary" translate
         [routerLink]="['/dispatch/' + job.id + '/' + jobEvent.id]">
        Job Dispatch
      </a>
    </div>
  </action-bar>

  <div class="not-found" *ngIf="!loading && !jobEvent">
    <div>
      <img src="assets/img/icon-404-barricade.svg" />

      <h1 translate>404</h1>
      <h2 translate>Oops! We got lost.</h2>

      <p translate>The page you're looking for doesn't exist or couldn't be found.</p>
      <p class="actions">
        <a href="/" translate>Take me back to the home page</a>
      </p>
    </div>
  </div>

  <div class="mobile-view-controls" *ngIf="device.mobile">
    <button [ngClass]="{'active': view === 'stats'}" (click)="view = 'stats'">Stats</button>
    <button [ngClass]="{'active': view === 'map'}" (click)="view = 'map'">Map</button>
  </div>

  <div class="job-container" *ngIf="job && jobEvent && (!device.mobile || view === 'map')">
    <div class="job-assignments" (scroll)="onScroll($event)" [ngClass]="{
      'job-overview-open': jobOverviewOpen,
      'driver-list-open': (driverListOpen && !jobOverviewOpen && !assignmentDetails.truck) || searching,
      'assignment-detail-open': assignmentDetails && assignmentDetails.truck
    }">
      <job-map #jobMap [jobEvent]="jobEvent" [metaData]="assignmentService.metaData" *ngIf="jobEvent.stats"></job-map>

      <div class="dispatched-drivers" *ngIf="jobEvent && jobEvent.stats">
        <div class="mobile-drawer-header" *ngIf="!searching" (click)="openDriverList()">
          <h2 *ngIf="!driverListOpen" translate>Show Drivers</h2>
          <h2 *ngIf="driverListOpen" translate>Hide Drivers</h2>
          <p translate>{{ assignments.items.length }}/{{ jobEvent.stats.numTrucks }} Active</p>
        </div>
        <my-loader *ngIf="assignments.loading"></my-loader>
        <div class="no-results mobile" *ngIf="!assignments.loading && assignments.items.length === 0">
          <div class="icon"><i class="icon-team"></i></div>
          <h2 translate>No Drivers Active</h2>
          <p translate>When drivers begin their shift, you'll see them appear.</p>
        </div>
        <mobile-search-results *ngIf="searching" [results]="locationUpdates" (activeResult)="openAssignmentDetails($event.result, $event.index)"></mobile-search-results>
        <trip-assignments *ngIf="controlState != 'hourly' && assignments && assignments.items && assignments.items.length && !searching"
                          [assignments]="assignments.items"
                          [jobEvent]="jobEvent"
                          [controlState]="controlState"
                          [loading]="assignments.loading"
                          (activeAssignment)="openAssignmentDetails($event)">
          <ul display-controls class="segmented-control">
            <li *ngFor="let control of controls" class="item {{ control.classes }}"
                [ngClass]="{'only-child': controls.length === 1}">
              <input class="input" type="checkbox" [name]="control.name" [id]="control.name"
                    [checked]="control.selected" (change)="onSelectToggle(control, $event)">
              <label class="label" [for]="control.name" translate>{{ control.label }}</label>
            </li>
          </ul>
        </trip-assignments>
        <!-- <punch-card-assignments *ngIf="assignments && controlState === 'hourly' && !searching"
                                [controlState]="controlState"
                                [hours]="hours"
                                [assignments]="assignments.items"
                                [jobEvent]="jobEvent"
                                [loading]="assignments.loading">
          <ul display-controls class="segmented-control">
            <li *ngFor="let control of controls" class="item {{ control.classes }}"
                [ngClass]="{'only-child': controls.length === 1}">
              <input class="input" type="checkbox" [name]="control.name" [id]="control.name"
                    [checked]="control.selected" (change)="onSelectToggle(control, $event)">
              <label class="label" [for]="control.name" translate>{{ control.label }}</label>
            </li>
          </ul>
        </punch-card-assignments> -->

        <completed-trips [jobEvent]="jobEvent" *ngIf="jobEvent.invoiceType !== 'hour' && !searching"></completed-trips>
        <completed-punch-cards [jobEvent]="jobEvent" *ngIf="jobEvent.invoiceType === 'hour' && !searching"></completed-punch-cards>
      </div>

      <mobile-assignment-details [assignment]="assignmentDetails" [jobEvent]="jobEvent"></mobile-assignment-details>
      <mobile-job-overview *ngIf="jobOverviewOpen" [jobEvent]="jobEvent"></mobile-job-overview>
    </div>

    <day-details [job]="job" [jobEvent]="jobEvent" [includeJobStats]="true" controlPosition="left"></day-details>
  </div>

  <order-summary-status *ngIf="device.mobile && jobEvent && view === 'stats'" [jobEvent]="jobEvent"></order-summary-status>
</div>
