<form #newTrackable="ngForm" [ngClass]="{
  'mobile': device && device.mobile,
  'tablet': device && device.tablet,
  'desktop': device && device.desktop
}">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add a Trackable</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content class="add-trackable">
    <div class="form-content">
      <div class="field-group trackable-type full-width">
        <label class="required" translate>Trackable Type</label>
        <input type="hidden" name="trackableType" required [(ngModel)]="selectedTrackableType" #trackableType="ngModel" />
        <ruckit-dropdown [config]="trackableTypesDropdownConfig"
          [selectedOption]="selectedTrackableType"
          (onSelect)="selectTrackableType($event)">
        </ruckit-dropdown>
      </div>

      <div class="field-row">
        <div class="field-group device-id">
          <label class="required" translate>Device ID</label>
          <input type="text" name="device-id" placeholder="{{ 'Device ID' | translate }}" required [(ngModel)]="model.deviceId" #deviceId="ngModel" />
          <span [ngClass]="{'ng-valid': deviceId.valid, 'ng-invalid': deviceId.invalid, 'ng-pristine': deviceId.pristine}"></span>
        </div>
      </div>

      <div class="field-group truck full-width">
        <label translate>Truck</label>
        <input type="hidden" name="truck" [(ngModel)]="model.truck" #truck="ngModel" />
        <ruckit-dropdown [config]="trucksConfig"
          [selectedOption]="model.truck"
          (onSelect)="selectTruck($event)">
        </ruckit-dropdown>
      </div>

      <div class="field-group active full-width">
        <label translate>Trackable State</label>
        <label class="checkbox" translate>
          <input name="active" [checked]="model.active" [(ngModel)]="model.active" type="checkbox">
          Active
        </label>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="newTrackable.form.valid && submit(); false"
            [disabled]="!newTrackable.form.valid || loading"
            [ngClass]="{loading: loading}">
      + {{ 'Add Trackable' | translate }}
    </button>
  </div>
</form>
