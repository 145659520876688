<my-toolbar logo=true [userDropdown]=false title="" color="ruckit-dark-blue"></my-toolbar>

<div class="field-column">
  <div class="driver-invitation-form" *ngIf="!loading">
    <div id="step-1" *ngIf="step === 1">
      <h2 translate>Welcome to Ruckit!</h2>
      <p>
        {{ this.invitation.recipientOrganization && this.invitation.recipientOrganization.name }} <ng-container translate>has invited you to join their team on Ruckit as a driver.</ng-container>
      </p>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <form #invitationForm="ngForm">
        <div class="field-group half-width first-name">
          <label class="required" translate>First Name</label>
          <input type="text" name="firstName" placeholder="{{ 'First Name' | translate }}" required [(ngModel)]="data.profile.firstName" #firstName="ngModel"/>
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': firstName.valid, 'ng-invalid': firstName.invalid, 'ng-pristine': firstName.pristine}"></span>
        </div>
        <div class="field-group half-width last-name">
          <label class="required" translate>Last Name</label>
          <input type="text" name="lastName" placeholder="{{ 'Last Name' | translate }}" required [(ngModel)]="data.profile.lastName" #lastName="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': lastName.valid, 'ng-invalid': lastName.invalid, 'ng-pristine': lastName.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Email</label>
          <input type="email" name="email" placeholder="{{ 'Email Address' | translate }}" required [(ngModel)]="data.profile.email" #email="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>CDL</label>
          <input type="text" name="cdl" placeholder="{{ 'CDL' | translate }}" required [(ngModel)]="data.cdl" #cdl="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': cdl.valid, 'ng-invalid': cdl.invalid, 'ng-pristine': cdl.pristine}"></span>
        </div>

        <div class="field-group full-width">
          <hr class="divider" />
        </div>

        <div class="field-group full-width">
          <label class="required" translate>Password</label>
          <input type="password" name="password" placeholder="{{ 'Password' | translate }}" required pattern=".{8,}" title="8 characters minimum" [(ngModel)]="user.password" #password="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': password.valid, 'ng-invalid': password.invalid, 'ng-pristine': password.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Password Confirmation</label>
          <input type="password" name="passwordConfirmation" placeholder="{{ 'Re-enter Password' | translate }}" required pattern=".{8,}" title="8 characters minimum" validateEqual="password" [(ngModel)]="user.passwordConfirmation" #passwordConfirmation="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': passwordConfirmation.valid, 'ng-invalid': passwordConfirmation.invalid, 'ng-pristine': passwordConfirmation.pristine}"></span>
        </div>

        <div class="actions">
          <button class="btn btn-primary" (click)="invitationForm.form.valid && register(); false" [disabled]="!invitationForm.form.valid || loading" translate>Create Account</button>
        </div>
      </form>
    </div>

    <div id="step-2" *ngIf="step === 2">
      <h2 translate>Account Details</h2>
      <p><ng-container translate>Double check your account information to make sure it's accurate.</ng-container></p>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <form #accountDetailForm="ngForm" class="account-detail-form">
        <div class="field-row">
          <div class="field-group street">
            <label class="required" translate>Address</label>
            <input type="text" name="street" placeholder="{{ 'Address' | translate }}" required [(ngModel)]="driver.street" #street="ngModel" />
            <span [ngClass]="{'input-icon fa': true, 'ng-valid': street.valid, 'ng-invalid': street.invalid, 'ng-pristine': street.pristine}"></span>
          </div>
        </div>
        <div class="field-row">
          <div class="field-group city">
            <label class="required" translate>City</label>
            <input type="text" name="city" placeholder="{{ 'City' | translate }}" required [(ngModel)]="driver.city" #city="ngModel" />
            <span [ngClass]="{'input-icon fa': true, 'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
          </div>
        </div>

        <div class="field-row">
          <div class="field-group state">
            <label class="required" translate>State</label>
            <select name="state" required [(ngModel)]="driver.state" #state="ngModel">
              <option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
            </select>
          </div>

          <div class="field-group zipcode">
            <label class="required" translate>Zip</label>
            <input type="text" name="zip" placeholder="{{ 'Zip' | translate }}" required [(ngModel)]="driver.zipcode" #zip="ngModel" />
            <span [ngClass]="{'input-icon fa': true, 'ng-valid': zip.valid, 'ng-invalid': zip.invalid, 'ng-pristine': zip.pristine}"></span>
          </div>
        </div>

        <div class="field-row">
          <div class="field-group phone-number">
            <label class="required" translate>Phone Number</label>
            <input type="text" name="phoneNumber" placeholder="{{ 'Phone Number' | translate }}"
                   phoneInputMask required [(ngModel)]="driver.profile.phoneNumber" #phoneNumber="ngModel" />
            <span [ngClass]="{'input-icon fa': true, 'ng-valid': phoneNumber.valid, 'ng-invalid': phoneNumber.invalid, 'ng-pristine': phoneNumber.pristine}"></span>
          </div>
        </div>

        <div class="actions">
          <button class="btn btn-primary" (click)="accountDetailForm.form.valid && updateAccountDetail(); false" [disabled]="!accountDetailForm.form.valid || loading" translate>Next</button>
        </div>
      </form>
    </div>

    <div id="step-3" *ngIf="step === 3">
      <h2 translate>Driver Photo</h2>
      <p translate>A photo will help your carrier and material yards recognize who you are in Ruckit.</p>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <form #driverPhotoForm="ngForm" class="driver-photo-form">
        <div class="field-row">
          <div class="field-group driver-photo">
            <div class="driver-image round upload-image-dropzone" [ngClass]="{filled: driver.profile.image}">
              <img *ngIf='driver.profile.image' [src]="driver.profile.image" />

              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
              <label for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!driver.profile.image" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
                <span *ngIf="driver.profile.image" translate>Upload a new photo</span>
              </label>
            </div>
          </div>
        </div>

        <div class="actions">
          <button class="btn btn-primary" (click)="driverPhotoForm.form.valid && updateDriverPhoto(); false" [disabled]="loading" *ngIf="!driver.profile.image" translate>Skip</button>

          <button class="btn btn-inverted-delete cancel" (click)="driver.profile.image = null; false" [disabled]="loading" *ngIf="driver.profile.image" translate>Cancel</button>
          <button class="btn btn-primary use" (click)="driverPhotoForm.form.valid && updateDriverPhoto(); false" [disabled]="!driverPhotoForm.form.valid || loading" *ngIf="driver.profile.image" translate>Use</button>
        </div>
      </form>
    </div>

    <div id="step-4" *ngIf="step === 4">
      <h2 translate>Download Ruckit!</h2>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <img src="assets/img/ruckit-app-icon.png" alt="Ruckit Application Icon" />

      <p translate>
        Your Account information has been saved! Download the Ruckit app on 
        your phone so you can begin your trips.
      </p>

      <div class="actions android">
        <a class="btn btn-primary-alt" href="https://play.google.com/store/apps/details?id=com.ruckit.driver" translate>Download on Android</a>
      </div>
      <br />
      <div class="actions ios">
        <a class="btn btn-primary-alt" href="https://itunes.apple.com/us/app/ruckit/id1119306478" translate>Download on iOS</a>
      </div>
    </div>
  </div>
</div>
