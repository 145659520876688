import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent implements OnInit {
  @Input() title = 'Actions';
  @Input() disabled = false;
  @Input() primaryRouterLink = null;
  @Input() icon = 'keyboard_arrow_down';
  @Input() dropdownEnabled = true;
  @Input() buttonClasses = '';
  @Input() controlButtonClasses = '';
  @Input() primaryActionOpenMenu = false;
  @Output() onPrimaryAction: EventEmitter<any> = new EventEmitter();

  @ViewChild('splitMenuTrigger') splitMenuTrigger: MatMenuTrigger;

  constructor() { }

  ngOnInit() { }

  primaryActionClicked(event): void {
    if (this.primaryActionOpenMenu) {
      this.splitMenuTrigger.openMenu();
    } else {
      this.onPrimaryAction.emit(event);
    }
  }
}
