import { Component, ViewChild, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find } from 'lodash';

import { ProjectService } from  '../projects/project.service';
import { ConnectionService } from  '../connections/connection.service';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { TagService } from '../tags/tag.service';
import { CustomField } from '../custom-fields/custom-field';

@Component({
  selector: 'job-filter-dialog',
  templateUrl: './job-filters-dialog.component.html',
  styleUrls: ['./job-filters-dialog.component.scss']
})
export class JobFiltersDialogComponent {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];
  customersConfig = {
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Customer',
    loadingText: 'Loading Customer...',
    noResultsText: 'No Customers',
    service: ConnectionService,
    query: {
      ordering: 'organization__name',
      customer_only: 'True'
    },
    customOptionKeys: ['organization']
  };
  @ViewChild('customersDropdown', { static: true }) customersDropdown: RuckitDropdownComponent;
  projectsConfig = {
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Project',
    loadingText: 'Loading Project...',
    noResultsText: 'No Projects',
    service: ProjectService,
    query: { ordering: 'name' }
  };
  @ViewChild('projectsDropdown', { static: true }) projectsDropdown: RuckitDropdownComponent;
  @ViewChild('marketsDropdown', { static: true }) marketsDropdown: RuckitDropdownComponent;
  marketsConfig = {
    searchable: true,
    multiselect: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Markets',
    loadingText: 'Loading Markets...',
    noResultsText: 'No Markets',
    service: TagService,
    query: {}
  };

  callback: Function;
  model: { [field: string]: any } = {
    startDate: null,
    endDate: null,
    project: null,
    customer: null,
    payableTo: null,
    orderNumber: null,
    jobNumber: null,
    tags: []
  };

  customFields: CustomField[] = [];

  constructor(
    public dialogRef: MatDialogRef<JobFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.convertDates();
    if (this.model.orderNumber && !this.model.orderNumber.trim()) {
      delete this.model.orderNumber;
    }
    this.callback(this.model);
    this.dialogRef.close();
  }

  convertDates(assign = true) {
    let startDate: Date, endDate: Date;
    if (this.model.startDate) {
      startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      if (assign) { Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name }); }
    }

    if (this.model.endDate) {
      endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      if (assign) { Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name }); }
    }

    if (!assign) {
      return {
        startDate: startDate && startDate.toISOString(),
        endDate: endDate && endDate.toISOString()
      };
    }
  }

  onDateChanged(dates: Date[], type = ''): void {
    this.model[type] = dates[0];
  }
}
