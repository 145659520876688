import {
  Component, OnInit, Input, Output, EventEmitter,
  ViewChild, TemplateRef
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { TagService } from '../tags/tag.service';
import { OrganizationMarketDialogComponent } from './organization-market-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { OrganizationService } from './organization.service';

@Component({
  selector: 'ruckit-edit-organization-markets',
  templateUrl: './edit-organization-markets.component.html',
  styleUrls: ['./edit-organization-markets.component.scss'],
  providers: [OrganizationService, TagService]
})

export class EditOrganizationMarketsComponent implements OnInit {
  // NOTE: Make sortable to true after sorting support has been added to API
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Name'), sortable: false, sortBy: 'name' },
    { key: 'itemCount', title: this.translationService.instant('# Tagged'), sortable: false, sortBy: 'item_count' },
    { key: 'notes', title: this.translationService.instant('Notes'), sortable: false, sortBy: 'notes' },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];
  @Input() displayedColumns = [
    'name', 'itemCount', 'notes', 'action'
  ];
  @Input() organization: any;
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  // config for fancy table
  marketTableConfig = {
    hasHeader: true,
    service: TagService,
    preferenceKey: 'EditOrganizationMarketsComponent-TagService',
    query: {},
    collectionTitle: this.translationService.instant('Markets'),
    noResultsText: this.translationService.instant('a market'),
    newRecordModal: () => { this.openNewOrganizationMarket(); },
    sortBy: '',
    sortDirection: 'asc',
    customHeight: true,
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false },
    ]
  };
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  @ViewChild('marketTable') marketTable;
  orgMarkets: any = [];
  loading = true;
  errors = [];
  count = 0;
  tagsReq;
  confirmDialog: MatDialogRef<any>;

  newOrganizationMarketCallback = (e) => {
    this.refreshTableData();
  }

  constructor(
    private route: ActivatedRoute,
    private tagService: TagService,
    private translationService: TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
    });
  }


  /**
  * @param  {} event
  * This function would get called on the click of row of the table
  * On click of row it will open a dialog box for editing the market.
  */
  clickAction(event) {
    if (event) { this.editOrganizationMarket(event[1]); }
  }

  /**
   * @param  {} appliedFilters
   * Used for reseting the filters or when filter is changed
   */
  filtersModified(appliedFilters) {
    // NOTE: API does not support filter for now
  }

  /**
   * opens a dialog box for adding new market
   */
  openNewOrganizationMarket() {
    const dialog = this.dialog.open(OrganizationMarketDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = this.newOrganizationMarketCallback;
  }

  /**
   * @param  {} action
   * @param  {} market
   * Check the action to be performed and call the corresponding function.
   * other functions like export can be added here in future.
   */
  menuAction(action: string, market) {
    switch (action) {
      case 'edit':
        this.editOrganizationMarket(market);
        break;
      case 'remove':
        this.removeOrganizationMarket(market);
        break;
    }
  }

  /**
   * @param  {} market
   * opens a dialog box for editing the selected market
   */
  editOrganizationMarket(market) {
    const dialog = this.dialog.open(OrganizationMarketDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.model = market;
    dialog.componentInstance.callback = this.newOrganizationMarketCallback;
  }

  /**
   * @param  {} market
   * Removes the selected market and refresh the table with new data
   * Opens the remove confirm dialog
   */
  removeOrganizationMarket(market) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Market?'),
      body: this.translationService.instant('This market will be deleted and cannot be recovered.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.tagService.remove(market).subscribe((res) => {
          this.refreshTableData();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  /**
  * refresh/reload the product table on any action performed like
  * add, edit, remove
  */
  refreshTableData() {
    this.marketTable.getRecords();
  }
}
