import { Assignment } from './assignment';
import { Driver } from '../drivers/driver';
import { Truck } from '../trucks/truck';

type LoadType = 'allDay' | 'numbered';

export class Slot {
  id: string;
  over: boolean;
  updating: boolean;
  numberOfLoadsType: LoadType;
  saveButton: boolean;
  assignment: Assignment;
  driver: Driver;
  truck: Truck;
  dispatched: boolean;
}
