<div class="mobile-dispatch-assignment-edit mobile-list">
  <div class="slot">
    <div class="icon" *ngIf="assignment.driver">
      <span *ngIf="!assignment.driver.image">{{ assignment.driver.name[0] }}</span>
      <img *ngIf="assignment.driver.image" [src]="assignment.driver.dispatchImage"/>
    </div>
    <div class="info" *ngIf="assignment.driver">
      <h3>{{ assignment.driver.name }}</h3>
      <h4>{{ assignment.truck ? assignment.truck.displayName : '--' }}</h4>
    </div>
  </div>
  <div class="start-time">
    <label translate>Start Time: </label>
    <input type="text" 
            required
            name="driverStartTime"
            class="timepicker"
            [placeholder]="assignment.uniqueStart ? assignment.uniqueStart : (jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'h:mm a')"
            [(ngModel)]="assignment.uniqueStart"
            [options]="{
              disableTextInput: false,
              'timeFormat': 'g:i A',
              showDuration: false,
              step: 5,
              minTime: jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'h:mm a',
              maxTime: jobEvent['shift' + (selectedShift + 1) + 'EndTimestamp'] | date: 'h:mm a'
            }"/>
    <span class="icon-next-arrow"></span>
  </div>
  <div class="load-type grouped">
    <input type="radio" name="{{ 'max-loads-' + assignment.id }}" required [(ngModel)]="numberOfLoadsType" value="numbered"/>
    <label translate>Loads:</label>
    <input type="number" name="{{ 'load-count-' + assignment.id }}" [disabled]="numberOfLoadsType !== 'numbered'" [(ngModel)]="assignment.maxNumberOfLoads" min="1"/>    
  </div>
  <div class="load-type">
    <input type="radio" name="{{ 'max-loads-' + assignment.id }}" required (change)="assignment.maxNumberOfLoads = 0" [(ngModel)]="numberOfLoadsType" [value]="'allDay'"/>
    <label translate>All Day</label>
  </div>
</div>
