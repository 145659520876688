<form #editJobEvent="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Job Day Details</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">

      <div class="field-group delivery-target">
        <label translate>Daily Delivery Target</label>
        <div class="input-group">
          <input numberInRange type="text" placeholder="{{ 'Amount' | translate }}" name="dailyDeliveryTarget"
            [(ngModel)]="jobEvent.dailyDeliveryTarget"  (change)="dailyDeliveryTargetChange('dailyDeliveryTarget', $event, editJobEvent)"/>
          <input type="hidden" name="dailyDeliveryTargetType" [(ngModel)]="jobEvent.dailyDeliveryTargetType" />
          <dropdown
            [options]="dailyDeliveryTargetTypeOptions"
            title="-"
            [selectedOption]="selectedDailyDeliveryTargetType"
            (onSelect)="dailyDeliveryTargetTypeChange('dailyDeliveryTargetType', $event, editJobEvent)"></dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group truck-number">
          <label translate>Number of trucks requested?</label>
          <div class="truck-number-options">
            <input name="numTrucks" type="number" [(ngModel)]="jobEvent.numTrucks"/>
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group truck-type">
          <label translate>Types of trucks requested?</label>
          <div class="truck-type-options">
            <ruckit-dropdown #truckTypesDropdown class="truck-type-dropdown"
              [config]="truckTypeDropdownConfig" [disabled]="anyTruckType"
              [selectedItems]="selectedTrucks"
              (onUserSelect)="onSelect('anyTruckType', $event, editJobEvent)"
              (onSelect)="false">
            </ruckit-dropdown>
            <div class="any-truck-type">
              <input #anyTypeCheckbox name="anyTruckType" type="checkbox" [(ngModel)]="anyTruckType"
                [checked]="anyTypeSelected" (change)="anyTypeTruckSelected('anyTruckType', $event, editJobEvent)" />
              <label attr.data-label="{{ 'Any Type' | translate}}" for="select_all"></label>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row times">
        <div class="field-group daily-start-time">
          <label translate>Shift Start Time</label>
          <div class="timepicker-wrapper">
              <input 
              required
              type="time" 
              class="timepicker"
              name="shift1StartTime" 
              [(ngModel)]="shiftOneStartTime"
              (change)="timeChange('shift1StartTime', $event, editJobEvent)"
            />
          </div>
        </div>

        <div class="field-group shift-one-end-time">
          <label translate>Shift End Time</label>
          <div class="timepicker-wrapper">
              <input 
              required
              type="time" 
              class="timepicker"
              name="shift1EndTime" 
              [(ngModel)]="shiftOneEndTime"
              (change)="timeChange('shift1EndTime', $event, editJobEvent)"
            />
          </div>
        </div>

        <notification translate *ngIf="jobEvent.shift1Overnight" level="warn">
          Warning: This shift will end on the next day
        </notification>
      </div>

      <div class="field-row">
        <div class="field-group default-yard-buffer-time">
          <label translate>Default Yard Buffer Time</label>
          <input type="text" [(ngModel)]="jobEvent.defaultYardBufferMinutes" name="default_yard_buffer_minutes" />
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="editJobEvent.form.valid && submit(); false"
      [disabled]="!editJobEvent.form.valid || editJobEvent.pristine || loading" [ngClass]="{loading: loading}"
      translate>
      Save Day Details
    </button>
  </div>
</form>
