import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DriverSerializer } from './../drivers/driver.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

@Component({
  selector: 'auto-assign-conflict-dialog',
  templateUrl: './auto-assign-conflict-dialog.component.html',
  styleUrls: ['./auto-assign-conflict-dialog.component.scss'],
  providers: []
})
export class AutoAssignConflictDialogComponent implements OnInit {
  loading = false;
  @Input() results: any = {};
  errors = [];
  callback;
  successes = [];
  failures = [];
  drivers = [];
  canForce = false;

  constructor(
    public dialogRef: MatDialogRef<AutoAssignConflictDialogComponent>,
  ) { }

  ngOnInit() {
    if (this.results) {
      this.results = camelcaseKeysDeep(this.results);
      this.successes = this.results.success;
      this.failures = this.results.errors;
      this.drivers = this.results.driverConflicts.map(conflict => {
        return new DriverSerializer().fromJson(conflict);
      });
      this.canForce = this.drivers && this.drivers.length > 0;
    }
  }

  forceAssign() {
    this.callback(true);
    this.dialogRef.close();
  }

  close() {
    this.callback();
    this.dialogRef.close();
  }
}
