import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

// services
import { ShiftReportService } from '../../shifts/shift.service';

// models
import { Driver } from '../driver';

@Component({
  selector: 'driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss'],
})
export class DriverDetailsComponent implements OnInit {
  @Input() driver: Driver;
  currentDate = new Date();

  constructor(
    private shiftReportService: ShiftReportService,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    this.getShifts(this.driver);
  }

  getShifts(driver: Driver) {
    this.shiftReportService
      .list({
        driver: driver.id,
        page_size: 1,
        ordering: '-end_time',
        include_leased_fleet_drivers: 'True',
      })
      .subscribe(
        (shifts) => {
          if (shifts && shifts.length) {
            const shift = shifts[0];
            this.displayShiftStatus(
              driver,
              shift.id,
              shift.startTime,
              shift.endTime
            );
          } else {
            driver.displayShiftStatus =
              this.translationService.instant('No Shifts');
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  displayShiftStatus(
    driver: Driver,
    shiftId: string,
    startTime: string,
    endTime: string
  ) {
    driver.lastShiftId = shiftId;
    const totalHours = moment
      .duration(moment(endTime ? endTime : undefined).diff(startTime))
      .asHours();
    if (!endTime) {
      if (totalHours > 8) {
        driver.displayShiftStatus = this.translationService.instant(
          'HAS ACTIVE SHIFT of {{ hours }} Hours',
          { hours: totalHours.toFixed(2) }
        );
      } else {
        driver.displayShiftStatus =
          this.translationService.instant('HAS ACTIVE SHIFT');
      }
    } else if (totalHours > 8) {
      driver.displayShiftStatus = this.translationService.instant(
        '{{ hours }} Hours',
        { hours: totalHours.toFixed(2) }
      );
    } else {
      driver.displayShiftStatus = this.translationService.instant(
        'Ended {{ endTime }}',
        { endTime: moment(endTime).format('MM/DD/YYYY h:mm a') }
      );
    }
  }
}
