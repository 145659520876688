<form #filterTrips="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Trips</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row">
        <div class="field-group start-date">
          <label translate>From</label>
          <date-range-picker [selectedDates]="[model.startDate]"
                             (dateChanged)="onDateChanged($event, 'startDate')">
          </date-range-picker>
        </div>
        <div class="field-group end-date">
          <label translate>To</label>
          <date-range-picker [selectedDates]="[model.endDate]"
                             (dateChanged)="onDateChanged($event, 'endDate')">
          </date-range-picker>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Edited' | translate }}" class="toggle-label">
            <input type="checkbox" name="edited" checked="{model.edited}" [(ngModel)]="model.edited" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Retake' | translate }}" class="toggle-label">
            <input type="checkbox" name="retake" checked="{model.retake}" [(ngModel)]="model.retake" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Incomplete' | translate }}" class="toggle-label">
            <input type="checkbox" name="incomplete" checked="{model.incomplete}" [(ngModel)]="model.incomplete" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Uninvoiced' | translate }}" class="toggle-label">
            <input type="checkbox" name="uninvoiced" checked="{model.uninvoiced}" [(ngModel)]="model.uninvoiced" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Not voided' | translate }}" class="toggle-label">
            <input type="checkbox" name="unvoid" checked="{model.unvoid}" [(ngModel)]="model.unvoid" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group trip-state">
          <label attr.data-label="{{ 'Missed Assigned Locations' | translate }}" class="toggle-label">
            <input type="checkbox" name="missedAssignedLocations" checked="{model.missedAssignedLocations}" [(ngModel)]="model.missedAssignedLocations" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group job-name">
          <label translate>Job</label>
          <ruckit-dropdown #jobsDropdown [config]="jobDropdownConfig"
            [selectedOption]="model.job && model.job.id"
            (onSelect)="onSelect('job', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group project-name">
          <label translate>Project</label>
          <ruckit-dropdown #projectsDropdown [config]="projectDropdownConfig"
            [selectedOption]="model.project && model.project.id"
            (onSelect)="onSelect('project', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group customer-name">
          <label translate>Customer</label>
          <ruckit-dropdown #customersDropdown [config]="customerDropdownConfig"
            [selectedOption]="model.customer && model.customer.id"
            (onSelect)="onSelect('customer', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group driver-name">
          <label translate>Driver</label>
          <ruckit-dropdown #driversDropdown [config]="driverDropdownConfig"
            [selectedOption]="model.driver && model.driver.id"
            (onSelect)="onSelect('driver', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group truck-name">
          <label translate>Truck</label>
          <ruckit-dropdown #trucksDropdown [config]="truckDropdownConfig"
            [selectedOption]="model.truck && model.truck.id"
            (onSelect)="onSelect('truck', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group payable-to-name">
          <label translate>Payable To</label>
          <ruckit-dropdown #payablesDropdown [config]="payableToDropdownConfig"
            [selectedOption]="model.payableTo && model.payableTo.id"
            (onSelect)="onSelect('payableTo', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group carrier-name">
          <label translate>Carrier</label>
          <ruckit-dropdown #carriersDropdown [config]="carrierDropdownConfig"
            [selectedOption]="model.carrier && model.carrier.id"
            (onSelect)="onSelect('carrier', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group job-number">
          <label translate>Job #</label>
          <input type="text" name="job-number" [(ngModel)]="model.jobNumber" />
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
