import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'ratesCurrency'
})
export class RatesCurrencyPipe implements PipeTransform {
  constructor (private _currencyPipe: CurrencyPipe) {}
  transform(value: any, rateUnit: string, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string {
    if (!value && value !== 0) {
      return this._currencyPipe.transform(0, currencyCode, display, digitsInfo, locale);
    } else {
      const currency = this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
      return `${currency} / ${rateUnit}`;
    }
  }
}
