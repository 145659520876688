import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TimelineRowData, DateTimeRange, TimelineConfig, TimeInterval,
         TimelineLabel, TimelineComponent } from '../../../shared/index';
import { PayRecord } from '../../pay-record';
import { DriverPayStats } from '../driver-pay.component';

export type TimelineEdits = {
  duration: number,
  startTime: string,
  endTime: string
};

@Component({
  selector: 'driver-pay-timeline',
  templateUrl: './driver-pay-timeline.component.html',
  styleUrls: ['./driver-pay-timeline.component.scss']
})
export class DriverPayTimelineComponent {
  @ViewChild('driverPayList') driverPayList;
  @ViewChild('timeline') timeline: TimelineComponent;

  timelineRowData: TimelineRowData[];
  @Input() timelineRange: DateTimeRange;
  @Input() timeInterval: TimeInterval;
  @Input() timelineStats: DriverPayStats;
  statsMinimized = false;

  @Input() readOnly = false;
  selectedRowIds: string[] = [];
  @Output() selectedRows: EventEmitter<string[]> = new EventEmitter();
  allSelected = false;
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter();
  @Output() resetRowIndex: EventEmitter<number> = new EventEmitter();

  timelineLabels: TimelineLabel[] = [
    {
      name: 'Punchcards',
      color: '#09366c'
    },
    {
      name: 'Trips',
      color: '#015BC5'
    },
    {
      name: 'Scale Sync',
      color: '#7e34bf'
    },
    {
      name: 'Geofence Duration',
      color: '#f5a623'
    },
    {
      name: 'GeoTrip Duration',
      color: '#fde0af'
    },
    {
      name: 'Pay Duration',
      color: 'rgba(42, 129, 215, 0.15)'
    },
  ];
  timelineConfig: TimelineConfig = {
    currentTime: false,
    selectedTime: false,
    scroll: false,
    headerHeight: 47,
    rowHeight: 115,
    visibleBars: ['punchcards', 'trips', 'predictedTrips',
                  'truckStatuses', 'shifts', 'payPeriods']
  };
  editPanelOpen = false;
  editingRow: PayRecord = <PayRecord>{};
  editingRowIndex: number;
  @Output() rowEdits: EventEmitter<PayRecord[]> = new EventEmitter();

  payRecordsValue: PayRecord[];
  @Output() payRecordsChange: EventEmitter<PayRecord[]> = new EventEmitter();
  @Input() get payRecords(): PayRecord[] { return this.payRecordsValue; }
  set payRecords(data: PayRecord[]) {
    this.payRecordsValue = data;
    this.timelineRowData = data.map(row => (row.data.rowData));
    this.payRecordsChange.emit(data);
    this.selectedRowIds = [];
  }

  constructor() {}

  editTimelineRow(id: string) {
    this.payRecords.map(row => {
     row.data.editing = false;
     return row;
    });
    this.editingRowIndex = this.payRecords.findIndex(data => (data.data.referenceId === id));
    this.payRecords[this.editingRowIndex].data.editing = !this.readOnly;
    this.openEditPanel(this.payRecords[this.editingRowIndex]);
  }

  openEditPanel(data) {
    this.editPanelOpen = true;
    this.editingRow = data;
  }

  updateRowData(updates: TimelineRowData) {
    this.editingRow = this.payRecords[
      this.payRecords.findIndex(data => (data.data.referenceId === updates.referenceId))
    ];
    this.editingRow.data.rowData = updates;
    if (this.editingRow &&
        this.editingRow.data &&
        this.editingRow.data.rowData &&
        this.editingRow.data.rowData.referenceId === updates.referenceId) {
      this.editingRow.data.rowData = updates;
      this.editingRow = Object.assign({}, this.editingRow);
      this.resetTimelineSubs();
    }
    this.rowEdits.emit([this.editingRow]);
  }

  selectRows(rows: string[]) {
    this.selectedRowIds = rows;
    this.selectedRows.emit(rows);
    if (this.selectedRowIds.length !== this.payRecords.length) { this.selectAllRows(false); }
  }

  selectAllRows(value: boolean) {
    this.selectAll.emit(value);
    this.allSelected = value;
  }

  resetRow(index: number) {
    this.resetRowIndex.emit(index);
    this.resetTimelineSubs();
  }

  resetTimelineSubs() { if (this.timeline) { this.timeline.resetPayLineSubs(); } }
}
