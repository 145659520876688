import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  OnChanges,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { slideRightToLeftAnimation } from '../animations/slide-right-to-left.animation';

@Component({
  selector: 'multiple-images-edit',
  templateUrl: './multiple-images-edit.component.html',
  styleUrls: ['./multiple-images-edit.component.scss'],
  animations: [slideRightToLeftAnimation],
})
export class MultipleImagesEditComponent implements OnChanges, OnDestroy {
  open = false;
  @ViewChild('editImagesContainer')
  editImagesContainer: ElementRef;
  @Input() withAnimation = true;
  @Input() loadingImages = [];
  @Input() unloadingImages = [];
  @Input() loadingSignatureImages = [];
  @Input() unloadingSignatureImages = [];
  @Input() showLoadingImages = true;
  @Input() showUnloadingImages = true;
  @Input() showLoadingSignatureImages = true;
  @Input() showUnloadingSignatureImages = true;
  @Input() editable = true;
  @Input() buttonLoadingRef: TemplateRef<any>;
  @Input() buttonUnloadingRef: TemplateRef<any>;
  @Input() showOnSave = false;
  @Input() requiredFields = {
    loadingImages: false,
    unloadingImages: false,
    loadingSignature: false,
    unloadingSignature: false,
  };
  @Output() onLoadingImagesChange: EventEmitter<any[]> = new EventEmitter();
  @Output() onUnloadingImagesChange: EventEmitter<any[]> = new EventEmitter();
  @Output() onLoadingSignatureImagesChange: EventEmitter<any[]> =
    new EventEmitter();
  @Output() onUnloadingSignatureImagesChange: EventEmitter<any[]> =
    new EventEmitter();
  @Output() onSave: EventEmitter<null> = new EventEmitter();
  @Output() onUploading: EventEmitter<boolean> = new EventEmitter();

  currentLoadingImages = [];
  currentUnloadingImages = [];
  currentLoadingSignatureImages = [];
  currentUnloadingSignatureImages = [];
  loadingSave = false;
  saveDisabled = false;

  private unlistener: () => void;

  constructor(private renderer: Renderer2) {}

  ngOnChanges() {
    this.currentLoadingImages = this.loadingImages
      ? this.loadingImages.filter((img) => img.isDeleted !== true)
      : [];
    this.currentUnloadingImages = this.unloadingImages
      ? this.unloadingImages.filter((img) => img.isDeleted !== true)
      : [];
    this.currentLoadingSignatureImages = this.loadingSignatureImages
      ? this.loadingSignatureImages.filter((img) => img.isDeleted !== true)
      : [];
    this.currentLoadingSignatureImages = this.loadingSignatureImages
      ? this.loadingSignatureImages.filter((img) => img.isDeleted !== true)
      : [];
    this.currentUnloadingSignatureImages = this.unloadingSignatureImages
      ? this.unloadingSignatureImages.filter((img) => img.isDeleted !== true)
      : [];
  }

  ngOnDestroy(): void {
    if (this.unlistener) {
      this.unlistener();
    }
  }

  setListenerForOutsideClick() {
    this.unlistener = this.renderer.listen('window', 'click', (e: any) => {
      if (
        this.open &&
        this.editImagesContainer &&
        !this.editImagesContainer.nativeElement.contains(e.target) &&
        e.target.className !== 'icon-delete'
      ) {
        this.handleVisibility();
      } else {
        this.open = true;
      }
    });
  }

  handleVisibility(): void {
    const open = this.open;
    this.loadingSave = false;
    this.saveDisabled = false;
    if (open) {
      this.open = false;
      this.unlistener();
    } else {
      this.setListenerForOutsideClick();
    }
  }

  onLoadingImageChange(image: any, index: number) {
    const images = this.currentLoadingImages.length
      ? this.currentLoadingImages.map((img, i) => {
          return i === index ? image : img;
        })
      : [image];
    this.onLoadingImagesChange.emit(images);
  }

  onUnloadingImageChange(image: any, index: number) {
    const images = this.currentUnloadingImages.length
      ? this.currentUnloadingImages.map((img, i) => (i === index ? image : img))
      : [image];
    this.onUnloadingImagesChange.emit(images);
  }

  onLoadingSignatureImageChange(image: any, index: number) {
    const images = this.currentLoadingSignatureImages.length
      ? this.currentLoadingSignatureImages.map((img, i) =>
          i === index ? image : img
        )
      : [image];
    this.onLoadingSignatureImagesChange.emit(images);
  }

  onUnloadingSignatureImageChange(image: any, index: number) {
    const images = this.currentUnloadingSignatureImages.length
      ? this.currentUnloadingSignatureImages.map((img, i) =>
          i === index ? image : img
        )
      : [image];
    this.onUnloadingSignatureImagesChange.emit(images);
  }

  onLoadingImageAdd() {
    this.currentLoadingImages.push(null);
  }

  onLoadingSignatureImageAdd() {
    this.currentLoadingSignatureImages.push(null);
  }

  onUnloadingImageAdd() {
    this.currentUnloadingImages.push(null);
  }

  onUnloadingSignatureImageAdd() {
    this.currentUnloadingSignatureImages.push(null);
  }

  save() {
    this.loadingSave = true;
    this.saveDisabled = true;
    this.onSave.emit();
  }

  uploading(uploading: boolean) {
    this.onUploading.emit(uploading);
    this.saveDisabled = uploading;
  }
}
