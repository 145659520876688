import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { clone } from 'lodash';

import { environment } from '../../environments/environment';
import { Organization } from './organization';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class OrganizationService {
  baseUrl = environment.serverUrl;
  organizationId: string;
  token: string;
  params: HttpParams;
  public nextUri;
  public count = 0;

  constructor(private http: HttpClient) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.token = currentUser.token;
      this.organizationId = currentUser.organization && currentUser.organization.id;
    }
    this.params = new HttpParams();
  }

  getOrganization(organizationId: string) {
    let organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';

    return this.http.get(organizationUrl, {
      headers: requestHeaders(),
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  get(query: any = null): Observable<Organization[]> {
    let organizationsUrl = this.baseUrl + 'organizations/';
    let params: HttpParams = new HttpParams();
    if (query) {
      if (typeof query === 'string') {
        organizationsUrl = this.baseUrl + 'organizations/' + query + '/';
      } else {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
    }

    return this.http.get(organizationsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getOrganizations(search?: string): Observable<Organization[]> {
    let organizationsUrl = this.baseUrl + 'organizations/';
    this.buildSearchQuery(search);
    this.buildExclusionList(this.organizationId, true);

    return this.http.get(organizationsUrl, {
      headers: requestHeaders(),
      params: this.params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  list(search?: string): Observable<Organization[]> {
    let organizationsUrl = this.baseUrl + 'organizations/';
    this.buildSearchQuery(search);
    this.buildExclusionList(this.organizationId, true);

    return this.http.get(organizationsUrl, {
      headers: requestHeaders(),
      params: this.params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  save(organization) {
    const organizationUrl = this.baseUrl + 'organizations/';
    const _organization = decamelizeKeysDeep(clone(organization));
    delete _organization.image;
    delete _organization.attachments;
    if (typeof _organization.billing_contact === 'object') {
      _organization.billing_contact = _organization.billing_contact.id;
    }

    if (_organization.default_round_trip_minutes && parseFloat(_organization.default_round_trip_minutes) > 0) {
      _organization.default_round_trip_time = parseFloat(_organization.default_round_trip_minutes) * 60;
    }

    if (_organization.default_yard_prep_minutes && parseFloat(_organization.default_yard_prep_minutes) > 0) {
      _organization.default_yard_prep_time = parseFloat(_organization.default_yard_prep_minutes) * 60;
    }

    if (_organization.default_yard_buffer_minutes && parseFloat(_organization.default_yard_buffer_minutes) > 0) {
      _organization.default_yard_buffer_time = parseFloat(_organization.default_yard_buffer_minutes) * 60;
    }

    if (!organization.id ) {
      return this.http.post(organizationUrl, _organization, {headers: requestHeaders()});
    } else {
      let updateUrl = organizationUrl + organization.id + '/';
      return this.http.put(updateUrl, _organization, { headers: requestHeaders() }).pipe(
        map((res: HttpResponse<any>) => { return this.extractData(res); }),
        catchError(handleError)
      );
    }
  }

  patch(model: Partial<Organization>, id: string) {
    return this.http.patch(
      `${this.baseUrl}organizations/${id}/`,
      model,
      { headers: requestHeaders() }
    );
  }

  saveWithImage(organization, logoImage: File) {
    const _organization = decamelizeKeysDeep(clone(organization));

    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      delete _organization.image;
      delete _organization.tags;
      delete _organization.billing_contact;
      delete _organization.primary_contact;
      delete _organization.email;
      delete _organization.feature;
      delete _organization.enabled_features;
      delete _organization.carrier;
      delete _organization.broker;

      if (_organization.default_round_trip_minutes && parseFloat(_organization.default_round_trip_minutes) > 0) {
        _organization.default_round_trip_time = parseFloat(_organization.default_round_trip_minutes) * 60;
      } else {
        _organization.default_round_trip_time = 0;
      }

      if (_organization.default_yard_prep_minutes && parseFloat(_organization.default_yard_prep_minutes) > 0) {
        _organization.default_yard_prep_time = parseFloat(_organization.default_yard_prep_minutes) * 60;
      } else {
        _organization.default_yard_prep_time = 0;
      }

      if (_organization.default_yard_buffer_minutes && parseFloat(_organization.default_yard_buffer_minutes) > 0) {
        _organization.default_yard_buffer_time = parseFloat(_organization.default_yard_buffer_minutes) * 60;
      } else {
        _organization.default_yard_buffer_time = 0;
      }

      Object.keys(_organization).map(function (key, index) {
        let value = _organization[key];
        formData.append(key, value);
      });

      if (logoImage) {
        formData.append('image', logoImage, logoImage.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (_organization.id) {
        let organizationUrl = this.baseUrl + 'organizations/' + _organization.id + '/';
        xhr.open('PUT', organizationUrl, true);
      } else {
        let organizationUrl = this.baseUrl + 'organizations/';
        xhr.open('POST', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  saveOnlyImage(organizationId, logoImage: File) {

    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('image', logoImage, logoImage.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (organizationId) {
        let organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';
        xhr.open('PUT', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  getNext() {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: HttpResponse<any>) => { return this.extractData(res); }),
        catchError((res: HttpResponse<any>) => handleError(res))
      );
    } else {
      return null;
    }
  }

  listNext() {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: HttpResponse<any>) => { return this.extractData(res); }),
        catchError((res: HttpResponse<any>) => handleError(res))
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    let body = resObj['results'];
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    if (body) {
      return body.map(org => {
        return new Organization(org);
      });
    } else if (res) {
      return new Organization(res);
    } else {
      return [ ];
    }
  }

  private buildSearchQuery(search: string) {
    if (search && search.length > 0) {
      this.params = this.params.set('name__icontains', search);
    } else {
      this.params = this.params.delete('name__icontains');
    }
  }

  private buildExclusionList(organizationId: string, excludeConnected = true) {
    if (organizationId && organizationId.length > 0) {
      this.params = this.params.set('exclude', organizationId);
    } else {
      this.params = this.params.delete('exclude');
    }

    this.params = this.params.set('exclude_connected', excludeConnected ? 'True' : 'False');
  }
}
