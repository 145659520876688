<my-toolbar logo=true title="{{ 'Forgot Password' | translate }}" color="ruckit-platform-background"></my-toolbar>

<div class="field-column">
  <div class="password-reset-form" *ngIf="!loading">
    <h2 translate>Reset Your Password</h2>
    <div class="password-reset-header" *ngIf="data">
      <div class="user-photo">
        <span *ngIf="!data.avatar">{{ data.firstName && data.firstName[0] }}</span>
        <img *ngIf="data.avatar" alt="User Avatar" class="user-avatar" [src]="data.avatar" />
      </div>
      <div class="user-details">
        <h3>{{ data.firstName }} {{ data.lastName }}</h3><br />
        <p>{{ data.email }}</p>
      </div>
    </div>
    <notification *ngFor="let error of errors">{{error}}</notification>

    <form #passwordReset="ngForm" *ngIf="data">
      <div class="field-group full-width">
        <label class="required" translate>Password</label>
        <input type="password" name="password" placeholder="{{ 'Password' | translate }}" required pattern=".{8,}" title="8 characters minimum" [(ngModel)]="data.password" #password="ngModel" />
        <span [ngClass]="{'input-icon fa': true, 'ng-valid': password.valid, 'ng-invalid': password.invalid, 'ng-pristine': password.pristine}"></span>
      </div>
      <div class="field-group full-width">
        <label class="required" translate>Password Confirmation</label>
        <input type="password" name="passwordConfirmation" placeholder="{{ 'Re-enter Password' | translate }}" required pattern=".{8,}" title="8 characters minimum" validateEqual="password" [(ngModel)]="data.passwordConfirmation" #passwordConfirmation="ngModel" />
        <span [ngClass]="{'input-icon fa': true, 'ng-valid': passwordConfirmation.valid, 'ng-invalid': passwordConfirmation.invalid, 'ng-pristine': passwordConfirmation.pristine}"></span>
      </div>

      <div class="field-group submit-button">
        <ruckit-button title="{{ 'Next' | translate }}" (click)="passwordReset.form.valid && submit(); false" [isDisabled]="!passwordReset.form.valid || loading"></ruckit-button>
      </div>
    </form>
  </div>
</div>
