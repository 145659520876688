import { Component, OnInit, Input } from '@angular/core';

// angular material
import { MatDialog } from '@angular/material/dialog';

// services
import { PreferenceService } from '../preferences/preference.service';
import { AuthenticationService } from '../shared';

// types
import { Preference } from '../preferences/preference';

// serializers
import { PreferenceSerializer } from '../preferences/preference.serializer';

@Component({
  selector: 'ruckit-edit-user-preferences',
  templateUrl: './edit-user-preferences.component.html',
  styleUrls: ['./edit-user-preferences.component.scss'],
})
export class EditUserPreferencesComponent implements OnInit {
  @Input() user: any;
  loading = false;
  errors = [];
  preference: Preference = new PreferenceSerializer().fromJson({
    blob: {
      dispatchLoadType: 'all-day',
      mapStyle: 'ruckit-map-style',
      markerType: 'driver-initials',
      markerStatus: 'each-segment',
      staggerAssignmentTime: 'staggered_start',
      distanceUnit: 'miles'
    },
  });
  preferenceKey = 'GeneralUserPreferences';

  constructor(
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getPreferences();
  }

  selectDispatchLoadType(event): void {
    this.preference.blob['dispatchLoadType'] = event.target.value;
  }

  selectDispatchLoadCount(event): void {
    this.preference.blob['dispatchLoadCount'] = event.target.value;
  }

  selectMapStyle(event): void {
    this.preference.blob['mapStyle'] = event.target.value;
  }

  selectMarkerType(event): void {
    this.preference.blob['markerType'] = event.target.value;
  }

  selectDistanceunit(event): void {
    this.preference.blob['distanceUnit'] = event.target.value;
  }

  assignmentTimeChanged(event): void {
    this.preference.blob['staggerAssignmentTime'] = event.currentTarget.checked
      ? 'staggered_start'
      : 'shift1_start';
  }

  getPreferences(): void {
    let currentUser = this.authenticationService.user();
    if (this.preference && this.preference.id) {
      this.preferenceService.get(this.preference.id).subscribe((preference) => {
        this.preference = preference;
        if (this.preference.blob && !this.preference.blob.hasOwnProperty('distanceUnit')) {
          this.preference.blob['distanceUnit'] = 'miles';
        }
      });
    } else {
      this.preferenceService
        .list({
          name: this.preferenceKey,
          type: 'user',
          profile: currentUser && currentUser.id,
        })
        .subscribe((preferences) => {
          if (preferences && preferences.length) {
            this.preference = preferences[0];
          }
          if (this.preference.blob && !this.preference.blob.hasOwnProperty('distanceUnit')) {
            this.preference.blob['distanceUnit'] = 'miles';
          }
        });
    }
  }

  savePreferences(): void {
    this.loading = true;
    if (this.preferenceKey && this.user) {
      this.preference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'user',
        profile: this.user && this.user.id,
      };
      this.preferenceService.save(this.preference).subscribe((preference) => {
        this.preference = preference;
        this.loading = false;
      });
    }
  }
}
