import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const assignmentExpandMapAnimation = trigger('expandMap', [
  state(
    'collapsed',
    style({ width: '0px', minWidth: '0', visibility: 'hidden', padding: '0' })
  ),
  state('expanded', style({ width: '*', visibility: 'visible' })),
  transition(
    'expanded <=> collapsed',
    animate('350ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  ),
]);
