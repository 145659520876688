import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { find, clone, union } from 'lodash';
import * as moment from 'moment-timezone';

import { Job } from '../jobs/job';
import { JobService } from '../jobs/job.service';
import { JobEvent } from '../job-events/job-event';
import { JobEventService } from '../job-events/job-event.service';
import { Connection } from '../connections/connection';
import { ConnectionService } from '../connections/connection.service';
import { Tag } from '../tags/tag';
import { TagService } from '../tags/tag.service';
import { Driver } from '../drivers/driver';
import { DriverService } from '../drivers/driver.service';
import { Truck } from '../trucks/truck';
import { TruckService } from '../trucks/truck.service';
import { AssignmentSerializer } from '../assignments/assignment.serializer';
import { AssignmentService } from '../assignments/assignment.service';
import { DispatchService } from './dispatch.service';
import { AuthenticationService } from '../shared/index';
import { parseErrors } from '../shared/api.service';
import { PreferenceService } from '../preferences/preference.service';
import { Preference } from '../preferences/preference';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'quick-dispatch-dialog',
  templateUrl: './quick-dispatch-dialog.component.html',
  styleUrls: ['./quick-dispatch-dialog.component.scss']
})
export class QuickDispatchDialogComponent implements OnInit {
  loading = false;
  preference: Preference;
  preferenceKey = 'GeneralUserPreferences';
  jobDaysLoading = false;
  dispatching = false;
  isCrh = false;
  errors = [];
  job: Job;
  selectedDates: Date[];
  existingDates = [];
  dateStart;
  dateEnd;
  jobsDropdownConfig = {
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    service: JobService,
    nameProperty: 'displayName',
    query: {
      ordering: 'project__name,name,start_date',
      archived: 'False',
      serializer: 'JobGroupedDropdown'
    }
  };
  @ViewChild('jobsDropdown') jobsDropdown: RuckitDropdownComponent;
  selectedJob: Job;
  jobEventsDropdownConfig = {
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    service: JobEventService,
    nameProperty: 'jobDisplayName',
    query: {
      ordering: 'job__project__name,job__name',
      archived: 'False'
    }
  };
  @ViewChild('jobEventsDropdown') jobEventsDropdown: RuckitDropdownComponent;
  selectedJobEvent: JobEvent;
  selectedJobEventEndTime;
  carriersDropdownConfig = {
    selectText: 'Select Carrier',
    loadingText: 'Loading Carriers...',
    noResultsText: 'No Carriers',
    service: ConnectionService,
    prefilledOptions: [],
    query: {
      ordering: 'organization__name',
      allow_dispatch: 'True',
      is_carrier: 'True'
    }
  };
  @ViewChild('carriersDropdown') carriersDropdown: RuckitDropdownComponent;
  @Input() selectedCarrier: Connection;
  carrierId;
  marketsConfig = {
    multiselect: true,
    selectText: 'Select Markets',
    loadingText: 'Loading Markets...',
    noResultsText: 'No Markets',
    service: TagService,
    query: {}
  };
  @ViewChild('marketsDropdown') marketsDropdown: RuckitDropdownComponent;
  selectedTags: Tag[];
  favoriteTags: Tag[] = [];
  tagsUrlList: String;
  driversDropdownConfig = {
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    service: DriverService,
    query: { ordering: 'name', status: 'active' },
    customOptionKeys: ['truck']
  };
  @ViewChild('driversDropdown') driversDropdown: RuckitDropdownComponent;
  @Input() selectedDriver: Driver;
  trucksDropdownConfig = {
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    service: TruckService,
    query: { ordering: 'name' }
  };
  @ViewChild('trucksDropdown') trucksDropdown: RuckitDropdownComponent;
  @Input() selectedTruck: Truck;
  assignmentConflicts;
  dispatchFirstTime;
  daysReq;
  uniqueStart;
  numberOfLoadsType: string;
  maxLoads = 1;
  user;
  callback;

  constructor(
    public dialogRef: MatDialogRef<QuickDispatchDialogComponent>,
    private dispatchService: DispatchService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private driverService: DriverService,
    private assignmentService: AssignmentService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.user = this.authenticationService.user();
    this.isCrh = this.authenticationService.isCrh();
    this.getPreferences();
    this.getAssignmentDate();
    this.getDispatchFirstTime();

    let carrierOptions = [
      { name: 'My Drivers', id: 'my_drivers' },
      { name: 'All Carriers', id: 'all_carriers' },
    ];
    if (this.selectedJob && this.selectedJob.project
      && this.selectedJob.project.customerOrganization
      && this.selectedJob.project.customerOrganization['hasLeasedOrgs']) {
        carrierOptions.push({ name: 'Leased', id: 'all_leased' });
    }
    this.carriersDropdownConfig.prefilledOptions = carrierOptions;
  }

  getPreferences(): void {
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: this.user && this.user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
        this.parsePreferences();
      }
    });
  }

  parsePreferences(): void {
    if (this.preference.blob && this.preference.blob['dispatchLoadType']) {
      let loadType = this.preference.blob['dispatchLoadType'];
      this.numberOfLoadsType = loadType === 'all-day' ? 'allDay' : 'numbered';
    }
  }

  isValidAssignment() {
    return this.selectedJobEvent && this.selectedCarrier &&
      this.selectedDriver && this.selectedTruck && this.uniqueStart &&
      this.maxLoads;
  }

  getAssignmentDate() {
    this.dateStart = new Date();
    this.dateStart.setHours(0, 0, 0, 0);
    if (this.selectedDates && this.selectedDates.length) {
      this.dateStart = this.selectedDates[0];
    } else {
      this.selectedDates = [this.dateStart];
    }

    this.dateEnd = clone(this.dateStart);
    this.dateEnd.setHours(23, 59, 59, 999);

    this.jobsDropdownConfig.query['jobevents__end__gte'] = this.dateStart.toISOString();
    if (this.jobsDropdown) { this.jobsDropdown.getRecords(); }
    this.jobEventsDropdownConfig.query['end__gte'] = this.dateStart.toISOString();
    if (this.jobEventsDropdown) { this.jobEventsDropdown.getRecords(); }
  }

  getJobDays(jobId: string) {
    if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
      this.daysReq.unsubscribe();
    }
    this.jobDaysLoading = true;
    this.daysReq = this.jobService.getDays(jobId).subscribe(days => {
      this.existingDates = days.map(day => moment(day).toDate());
      this.jobDaysLoading = false;
    }, err => {
      this.errors = err;
      this.jobDaysLoading = false;
    });
  }

  getDispatchFirstTime() {
    this.dispatchFirstTime = moment();
    let intervals = Math.floor(this.dispatchFirstTime.minutes() / 5);
    if (this.dispatchFirstTime.minutes() % 5 !== 0) {
      intervals++;
      if (intervals === 20) {
        this.dispatchFirstTime.add(1, 'hours');
        intervals = 0;
      }
      this.dispatchFirstTime.minutes(intervals * 5);
      this.dispatchFirstTime.seconds(0);
    }
  }

  selectJobEvent(jobEventId = null) {
    let jobEventSelection;
    if (!this.job && !jobEventId) {
      this.loading = false;
      return;
    }

    const timezone = this.job && this.job.startLocation && this.job.startLocation.timezone;
    let start = moment(this.selectedDates[0]);
    start = moment.tz(start, timezone).startOf('day');
    const end = moment.tz(start, timezone).endOf('day');

    if (!jobEventId) {
      jobEventSelection = this.jobEventService.getJobEvents({
        job: this.job && this.job.id,
        shift1_start__gte: start && start.toISOString(),
        shift1_start__lte: end && end.toISOString()
      });
    } else {
      jobEventSelection = this.jobEventService.getJobEvents({
        id: jobEventId
      });
    }
    jobEventSelection.subscribe(jobEvents => {
      if (jobEvents.length > 0) {
        this.selectedJobEvent = jobEvents[0];
        if (this.job) {
          this.getJobDays(this.job.id);
        } else {
          const startDate = this.selectedJobEvent.shift1StartTimestamp.split('T')[0];
          this.selectedDates = [moment(startDate).toDate()];
        }
        let endTimestamp = this.selectedJobEvent && this.selectedJobEvent.shift1EndTimestamp;
        this.selectedJobEventEndTime = endTimestamp ? this.selectedJobEvent['shift1EndTimestamp'] : this.dateEnd;
        if (moment(this.dispatchFirstTime).isBefore(this.selectedJobEvent['shift1StartTimestamp'])) {
          this.dispatchFirstTime = moment(this.selectedJobEvent['shift1StartTimestamp']);
        } else {
          this.getDispatchFirstTime();
        }
        this.uniqueStart = this.dispatchFirstTime.format('h:mm a');
        if (this.user && this.user.organization && this.user.organization.carrier) {
          const userOrganization = this.carriersDropdown.options.find(c => c.id === this.user.organization.carrier.id);
          if (!userOrganization) {
            this.carriersDropdown.options.splice(0, 0, {
              id: this.user.organization.carrier.id, name: this.user.organization.carrier.name
            });
          }
          this.selectCarrier(this.user.organization.carrier);
        }
        if (this.selectedJobEvent && this.selectedJobEvent.job && this.selectedJobEvent.job.tags.length > 0) {
          this.selectTags(this.selectedJobEvent.job.tags);
        } else {
          this.authenticationService.hasFavoriteTags() ? this.selectTags(this.user.favoriteTags) : this.selectTags([]);
        }
        this.driversDropdownConfig.query['shared_jobevent'] = this.selectedJobEvent && this.selectedJobEvent.id;
        this.driversDropdownConfig.query['jobevent_available'] = this.selectedJobEvent && this.selectedJobEvent.id;
        if (this.driversDropdown) { this.driversDropdown.getRecords(); }
        this.trucksDropdownConfig.query['shared_jobevent'] = this.selectedJobEvent && this.selectedJobEvent.id;
        if (this.trucksDropdown) { this.trucksDropdown.getRecords(); }
      } else {
        // No Job Events found for selected job, default date selection
        this.dateChanged(this.selectedDates.length ? this.selectedDates : [new Date()]);
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  selectCarrier(e) {
    this.selectedCarrier = e;
    this.carrierId = this.selectedCarrier &&
      this.selectedCarrier.id !== 'my_drivers' &&
      this.selectedCarrier.id !== 'all_carriers' ? this.selectedCarrier.id : null;

    this.driversDropdownConfig.query['carrier'] = this.carrierId;
    this.driversDropdownConfig.query['all_carriers'] = this.selectedCarrier && this.selectedCarrier.id === 'all_carriers';
    if (this.driversDropdown) { this.driversDropdown.getRecords(); }
    this.trucksDropdownConfig.query['carrier'] = this.carrierId;
    this.trucksDropdownConfig.query['all_carriers'] = this.selectedCarrier && this.selectedCarrier.id === 'all_carriers';
    if (this.trucksDropdown) { this.trucksDropdown.getRecords(); }
  }

  selectTags(tags) {
    this.selectedTags = tags;
    if (tags.length > 0) {
      this.tagsUrlList = tags.map(tag => { return tag.name; }).join(',');
    } else {
      this.tagsUrlList = null;
    }
    this.driversDropdownConfig.query['tags'] = this.tagsUrlList;
    if (this.driversDropdown) { this.driversDropdown.getRecords(); }
    this.trucksDropdownConfig.query['tags'] = this.tagsUrlList;
    if (this.trucksDropdown) { this.trucksDropdown.getRecords(); }
  }

  selectDriver(e) {
    this.selectedDriver = e;
    if (this.selectedDriver.truck && this.selectedDriver.truck.id) {
      this.selectedTruck = this.selectedDriver.truck;
      if (this.trucksDropdown) {
        this.trucksDropdown.getRecords();
      }
    }
  }

  selectTruck(e) {
    this.selectedTruck = e;
    if (this.selectedDriver && (this.selectedDriver.truck && this.selectedDriver.truck.id !== this.selectedTruck.id)) {
      this.selectedDriver.truck = this.selectedTruck;
      this.driverService.assign(this.selectedDriver).subscribe((res) => {
        this.loading = false;
      }, (err) => {
        this.errors = parseErrors(err);
      });
    }
  }

  onSelect(type, e) {
    switch (type) {
      case 'carriers':
        this.selectCarrier(e);
        break;
      case 'drivers':
        this.selectDriver(e);
        break;
      case 'trucks':
        this.selectTruck(e);
        break;
      case 'jobs':
        this.job = e;
        if (this.job) {
          this.getJobDays(this.job.id);
          this.selectJobEvent();
        }
        break;
      case 'jobEvents':
        this.selectJobEvent(e.id);
        break;
    }
  }

  dispatch() {
    this.dispatching = true;
    let date = this.selectedDates[0];
    let dateString = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    let formatString = 'MM/DD/YYYY h:mm A';
    let uniqueStartFormatted = this.uniqueStart ?
      moment(dateString + ' ' + this.uniqueStart, [formatString]).format() :
      moment(dateString + ' ' + this.dispatchFirstTime, [formatString]).format();
    let assignment = new AssignmentSerializer().fromJson({
      driver: this.selectedDriver.id,
      truck: this.selectedTruck.id,
      jobevent: this.selectedJobEvent && this.selectedJobEvent.id,
      shift: 'shift1',
      uniqueStart: uniqueStartFormatted,
      maxNumberOfLoads: this.numberOfLoadsType === 'allDay' ? 0 : this.maxLoads
    });
    this.assignmentService.save(assignment).subscribe(_assignment => {
      this.dispatchService.save({
        jobEvent: this.selectedJobEvent && this.selectedJobEvent.id,
        notify_new: true
      }).subscribe(res => {
        this.dialogRef.close();
        this.dispatching = false;
        this.callback();
      }, (err) => { this.errors = parseErrors(err); });
    }, (err) => {
      this.errors = parseErrors(err);
      this.dispatching = false;
    });
  }

  dateChanged(dates: Date[]): void {
    if (this.job) {
      this.selectedDates = dates;
      this.loading = true;
      let newDates = this.selectedDates.filter(d => !this.existingDates.includes(d));
      if (this.existingDates && this.existingDates.length && newDates && newDates.length) {
        this.addJobDays(union(this.selectedDates, this.existingDates), this.job.id);
      } else {
        this.refresh();
      }
    }
  }

  addJobDays(dates: Date[], jobId): void {
    const _dates = dates.map(d => this.convertDateFormat(d));
    this.jobService.updateDays(jobId, { dates: _dates }).subscribe(result => {
      this.refresh();
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  convertDateFormat(date: Date) {
    if (isNaN(+date)) { return; }
    return date.toISOString().split('T')[0];
  }

  refresh(): void {
    this.getAssignmentDate();
    if (this.selectedJobEvent && this.selectedJobEvent.job) { this.job = this.selectedJobEvent.job; }
    this.selectJobEvent();
  }
}
