import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  clone, reject, pull, remove, difference, get, find as _find, omit as _omit
} from 'lodash';
import * as moment from 'moment';

import { parseErrors } from '../shared/api.service';
import { JobService } from  '../jobs/job.service';
import { PunchCardService } from  '../punch-cards/punch-card.service';
import { InvoiceService } from  './invoice.service';
import { DriverService } from  '../drivers/driver.service';
import { TruckService } from  '../trucks/truck.service';
import { CondensedPunchCard } from  '../punch-cards/condensed-punch-card';
import { PunchCardFiltersDialogComponent } from '../punch-cards/punch-card-filters-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { NewPunchCardDialogComponent } from '../punch-cards/new-punch-card-dialog.component';
import { OrganizationService } from '../organizations/organization.service';

@Component({
  selector: 'ruckit-invoice-punch-cards',
  templateUrl: './invoice-punch-cards-dialog.component.html',
  styleUrls: ['./invoice-punch-cards-dialog.component.scss'],
  providers: [
    PunchCardService, InvoiceService, JobService, DriverService, TruckService,
    OrganizationService
  ]
})
export class InvoicePunchCardsDialogComponent implements OnInit {
  job;
  jobId;
  jobs;
  jobsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  customer;
  customerId;
  invoice;
  count;
  selectedCount = 0;
  punchCards: any = [];
  allSelected = false;
  selectedPunchCards = [];
  excludePunchCards = [];
  punchCardFilters;
  loading = true;
  errors = [];
  routeToInvoice = true;
  invoiceReq;
  punchCardReq;
  jobReq;
  jobsReq;
  search = '';
  sortBy = '';
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  confirmDialog: MatDialogRef<any>;
  startDate = null;
  endDate = null;
  customersReq;
  customers = [];
  customersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  callback;
  menuOptions = [
    {name: 'Edit', action: 'edit', link: true},
    {name: 'Add to Invoice', action: 'add', link: false},
    {name: 'Void', action: 'void', link: false}
  ];
  @ViewChild('jobsDropdown', { static: true }) jobsDropdown;
  @ViewChild('customersDropdown', { static: true }) customersDropdown;
  savePunchCardCallback = (e) => {
    this.getPunchCards();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobService,
    private punchCardService: PunchCardService,
    private invoiceService: InvoiceService,
    private organizationService: OrganizationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoicePunchCardsDialogComponent>
  ) { }

  ngOnInit() {
    this.getJob();
    this.getCustomer();
    this.getJobs();
    this.getPunchCards();
    this.getCustomers('');
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.punchCardService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(punchCards => {
          this.punchCards = this.punchCards.concat(punchCards);
          this.loading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
        });
      }
    }
  }

  getPunchCards(query = {}, append = false) {
    if (!append) {
      this.punchCards = [];
    }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});

    if (this.punchCardReq) { this.punchCardReq.unsubscribe(); }
    if (this.startDate) {
      let date = new Date(this.startDate);
      date.setHours(0, 0, 0, 0);
      query['start_time__gte'] = date.toISOString();
    }
    if (this.endDate) {
      let date = new Date(this.endDate);
      date.setHours(23, 59, 59, 999);
      query['start_time__lte'] = date.toISOString();
    }

    this.punchCardFilters = {
      ordering: order,
      search: this.search,
      invoiceable: 'True',
      exclude_cf: 'True',
      invoiceable_to_customer: this.customerId,
      jobevent__job: this.jobId,
      ...filters,
      ...query
    };

    this.punchCardReq = this.punchCardService.getCondensedPunchCards(this.punchCardFilters).subscribe(
      punchCards => {
        if (append) {
          this.punchCards = this.punchCards.concat(punchCards);
        } else {
          this.punchCards = punchCards;
        }
        this.count = this.punchCardService.count;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  getJob() {
    if (this.jobId) {
      this.jobService.get(this.jobId).subscribe(job => {
        this.job = job;
      }, err => console.error(err));
    }
  }

  getCustomer() {
    if (this.customerId && !this.customer) {
      this.organizationService.getOrganization(this.customerId).subscribe((res) => {
        this.customer = res;
      }, err => console.error(err));
    }
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getPunchCards({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getPunchCards({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openNewPunchCard() {
    const dialog = this.dialog.open(NewPunchCardDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = this.savePunchCardCallback;
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getPunchCards();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    const dialog = this.dialog.open(PunchCardFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.filters, {key: 'startDate'}), 'value');
    if (startDate) {
      dialog.componentInstance.model.startDate = <Date>startDate;
    }
    let endDate = get(_find(this.filters, {key: 'endDate'}), 'value');
    if (endDate) {
      dialog.componentInstance.model.endDate = <Date>endDate;
    }
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.project = get(_find(this.filters, {key: 'project'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');
    dialog.componentInstance.model.driver = get(_find(this.filters, {key: 'driver'}), 'value');
    dialog.componentInstance.model.truck = get(_find(this.filters, {key: 'truck'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      payableTo: 'jobevent__owner_organization',
      job: 'jobevent__job',
      driver: 'driver',
      truck: 'truck',
      startDate: 'start_time__gte',
      endDate: 'start_time__lte',
      edited: 'edited',
      incomplete: 'completed',
      retake: 'retake_status'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (key === 'retake' && value) {
          value = 'requested';
          query[queryKeys[key]] = value;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = {
        key: key,
        value: displayValue || value,
        query: query
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getPunchCards();
  }

  removeFilter(filter) {
    remove(this.filters, filter);
    this.getPunchCards();
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  selector(event, punchCard = null) {
    if (punchCard) {
      if (!event.target.checked) {
        punchCard.selected = false;
        pull(this.selectedPunchCards, punchCard.id);
        if (this.allSelected) {
          this.excludePunchCards.push(punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedCount = this.selectedPunchCards.length;
        }
      } else {
        punchCard.selected = true;
        if (this.allSelected) {
          pull(this.excludePunchCards, punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedPunchCards.push(punchCard.id);
          this.selectedCount = this.selectedPunchCards.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.punchCards.forEach((_punchCard) => { _punchCard.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludePunchCards.length);
      }
      this.selectedPunchCards = [];
      this.excludePunchCards = [];
    }
  }

  addToInvoice(punchCards = null) {
    let model = {};
    if (this.invoice && this.invoice.id) {
      Object.assign(model, {id: this.invoice.id});
      if (punchCards || (this.selectedPunchCards && this.selectedPunchCards.length)) {
        Object.assign(model, {punchcards: punchCards || this.selectedPunchCards});
      } else if (this.allSelected) {
        let params = new HttpParams();
        let filters = _omit(this.punchCardFilters, ['ordering', 'invoice']);
        if (filters) {
          Object.keys(filters).map(key => {
            if (filters[key] && filters[key].length) {
              params = params.set(key, filters[key]);
            }
          });
        }
        if (params && params.toString().length) {
          Object.assign(model, { filters: params.toString() });
        }
        Object.assign(model, { excludePunchCards: this.excludePunchCards });
      }

      this.invoiceReq = this.invoiceService.addToInvoice(model, 'punchcards').subscribe((res) => {
        this.invoice = res;
        this.callback();
        this.dialogRef.close();
      }, err => console.error(err));
    } else {
      this.saveInvoice();
    }
  }

  voidSelectedPunchCards(punchCards = null) {
    punchCards.forEach((punchCard: CondensedPunchCard) => {
      punchCard.loading = true;
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: 'Void Punch Card?',
        body: 'This Punch Card will be marked as \'Void\' and will not be visible for the Job.',
        close: 'Cancel',
        accept: 'Void'
      };

      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.punchCardService.save({id: punchCard.id, void: true})
            .subscribe((result) => {
              punchCard.void = true;
              punchCard.loading = false;
              this.getPunchCards();
            }, (err) => {
              this.errors = err;
              punchCard.loading = false;
            });
        }
        this.confirmDialog = null;
      });
    });
  }

  saveInvoice() {
    let model = {
      job: this.jobId,
      customerOrganization: this.punchCards[0].customerId,
      punchcards: this.selectedPunchCards
    };

    if (this.selectedPunchCards.length) {
      Object.assign(model, {punchcards: this.selectedPunchCards});
    } else if (this.allSelected) {
      let params = new HttpParams();
      let filters = _omit(this.punchCardFilters, ['ordering', 'invoice']);
      if (filters) {
        Object.keys(filters).map(key => {
          if (filters[key] && filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
      }
      if (params.toString().length) {
        Object.assign(model, {filters: params.toString()});
      }
    }

    this.invoiceReq = this.invoiceService.save(model).subscribe((res) => {
      this.invoice = res;
      this.dialogRef.close();
      if (this.routeToInvoice) {
        this.router.navigate(['/invoices/' + this.invoice.id + '/edit']);
      }
    }, err => console.error(err));
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'add':
        punchCard ? this.addToInvoice([punchCard]) : this.addToInvoice();
        break;
      case 'void':
        punchCard ? this.voidSelectedPunchCards([punchCard]) : this.voidSelectedPunchCards();
        break;
    }
  }

  onDateChanged(values: Date[], type: string): void {
    if (values && values.length) {
      switch (type) {
        case 'startDate':
          this.startDate = values[0];
          break;
        case 'endDate':
          this.endDate = values[0];
          break;
      }
    }
    this.getPunchCards();
  }

  onSelect(filterType, e) {
    if (filterType === 'job') {
      this.jobId = e && e.id;
    } else {
      this.customerId = e && e.id;
    }
    this.getPunchCards();
  }

  getJobs(search = null) {
    this.jobs = [{ id: null, name: 'All Jobs' }];
    this.jobsConfig.loadingOptions = true;
    this.jobsConfig = clone(this.jobsConfig);
    if (this.jobsReq) { this.jobsReq.unsubscribe(); }

    this.jobsReq = this.jobService.list({
      has_billable_items: 'True',
      search: search,
      customer_organization: this.customerId
    }).subscribe(jobs => {
      this.jobs = [{ id: null, name: 'All Jobs' }];
      this.jobs = this.jobs.concat(jobs);
      if (this.job) {
        let selectedOption = _find(this.jobs, { id: this.job.id });
        this.jobs = reject(this.jobs, selectedOption);
        this.jobs.unshift(this.job);
        this.jobsDropdown.selectedOption = selectedOption;
      }
      this.jobsDropdown.config.loadingOptions = false;
      this.jobsConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
      this.jobsDropdown.config.loadingOptions = false;
      this.jobsConfig.loadingOptions = false;
      this.jobsConfig = clone(this.jobsConfig);
    });
  }

  getCustomers(q: string) {
    this.customers = [{ id: null, name: 'All Customers' }];
    this.customersConfig.loadingOptions = true;
    this.customersConfig = clone(this.customersConfig);
    if (this.customersReq) { this.customersReq.unsubscribe(); }

    this.customersReq = this.organizationService.getOrganizations(q).subscribe(customers => {
      this.customers = [{ id: null, name: 'All Customers' }];
      this.customers = this.customers.concat(customers);
      customers.forEach(customer => {
        if (customer) {
          let selectedOption = _find(this.customers, { id: customer.id });
          this.customers = reject(this.customers, selectedOption);
          this.customers.push(customer);
          this.customersDropdown.selectedOption = selectedOption;
        }
      });
      this.customersDropdown.config.loadingOptions = false;
      this.customersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
      this.customersDropdown.config.loadingOptions = false;
      this.customersConfig.loadingOptions = false;
      this.customersConfig = clone(this.customersConfig);
    });
  }

  dropdownNextPage(e, type) {
    let config;
    let options;

    switch (type) {
      case 'job':
        config = this.jobsConfig;
        options = this.jobs;
        break;
      case 'customer':
        config = this.customersConfig;
        options = this.customers;
        break;
    }

    if (!config.loadingOptions) {
      let o = this.jobService.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'job':
              this.jobs = this.jobs.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
      let op = this.organizationService.getNext();
      if (op) {
        config.loadingOptions = true;
        op.subscribe(results => {
          switch (type) {
            case 'customer':
              this.customers = this.customers.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'job':
        this.getJobs(term);
        break;
      case 'customer':
        this.getCustomers(term);
        break;
      default:
        throw 'invalid dropdown type';
    }
  }
}
