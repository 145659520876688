import {
  Component, OnInit, Input, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, forkJoin, Observable } from 'rxjs';

// angular material
import { MatDialogRef } from '@angular/material/dialog';

// lodash
import { filter, find as _find } from 'lodash';

// components
import { DropdownComponent } from '../../shared';

// services
import { parseErrors } from '../../shared/api.service';
import { CollaboratorService } from '../collaborator.service';
import { ConnectionService } from '../../connections/connection.service';
import { UnitsOfMeasureService } from '../../units/units-of-measure.service';

// models
import { JobEvent } from '../../job-events/job-event';
import { Connection } from '../../connections/connection';
import { JobLoad } from '../../dispatch/dispatch-by-job/job-load';
import { SimpleCollaborationTemplate } from '../collaboration-template/collaboration-template';

// constants
import { JOBWEIGHTOPTIONS } from './../../app.constants';

// animations
import { collaboratorsAnimation } from '../../shared/animations/collaborators.animation';

@Component({
  selector: 'add-collaborators-dialog',
  templateUrl: './add-collaborators-dialog.component.html',
  styleUrls: ['./add-collaborators-dialog.component.scss'],
  animations: [collaboratorsAnimation]
})
export class AddCollaboratorsDialogComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() brokerRateKey: string;
  @Input() selectedConnections: string[];
  @Input() loadList: JobLoad[] = [];
  @Input() hasLoadListsEnabled = false;
  loading = false;
  errors = [];
  callback: Function;
  search: string;
  allConnectionsSelected = false;
  connections: Connection[] = [];
  connectionsReq: Subscription;
  rateTrackingOptions = [
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  rateWeightConfig = {
    nameProperty: 'label',
    loadingOptions: false
  };
  weightOptions = [...JOBWEIGHTOPTIONS];
  weightOption;
  haulTypeOption;
  unitsOfMeasure: any[] = [];
  displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
  noResultsStyles = {
    'min-height': 'unset', 'height': '100%', 'display': 'flex',
    'flex-direction': 'column', 'justify-content': 'center'
  };
  addCollaborators: FormGroup;
  checkedConnections: string[] = [];

  @ViewChildren('jobEventsDropdown') jobEventDropdowns: QueryList<DropdownComponent>;

  get controls() {
    return this.addCollaborators.controls;
  }

  constructor(
    public dialogRef: MatDialogRef<AddCollaboratorsDialogComponent>,
    private collaboratorService: CollaboratorService,
    private unitsOfMeasureService: UnitsOfMeasureService,
    private connectionService: ConnectionService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    if (this.selectedConnections) {
      this.preselectConnections(this.selectedConnections);
    } else {
      this.getConnections();
    }
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });

    if (this.jobEvent.job.allowWeight) {
      this.rateTrackingOptions = this.rateTrackingOptions.concat([
        { value: 'weight', label: 'Weight', name: 'Weight' }
      ]);
    }
    this.weightOption = _find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
    this.getUnitsOfMeasure();
    this.addCollaborators = this.fb.group({});
  }

  ngOnDestroy() {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
  }

  private createConnectionFormGroup(connection: Connection): FormGroup {
    const invoiceType = this.rateTrackingOptions.find((opt) => opt.value === connection.collaboration.invoiceType);
    const invoiceWeightUnit = this.weightOptions.find((opt) => opt.value === connection.collaboration.invoiceWeightUnit);
    return this.fb.group({
      requestedAmount: [null, [Validators.required, Validators.min(1)]],
      requestedUnit: [connection.collaboration.requestedUnit],
      invoiceType: [invoiceType || this.haulTypeOption],
      invoiceRate: [connection.collaboration.invoiceRate, Validators.min(0)],
      invoiceWeightUnit: [invoiceWeightUnit || this.weightOption],
      brokerRateCode: [connection.collaboration.brokerRateCode],
      notes: ['']
    });
  }

  private getConnectionFormGroup(connectionId: string): FormGroup {
    return this.addCollaborators.controls[`organization_${connectionId}`] as FormGroup;
  }

  onCheckboxChange(event: Event, connection: Connection): void {
    if ((<HTMLInputElement>event.target).checked) {
      const newConnectionGroup = this.createConnectionFormGroup(connection);
      this.addCollaborators.addControl(`organization_${connection.id}`, newConnectionGroup);
      this.checkedConnections.push(connection.id);
    } else {
      this.addCollaborators.removeControl(`organization_${connection.id}`);
      const connIndex = this.checkedConnections.findIndex((conn) => conn === connection.id);
      this.checkedConnections.splice(connIndex, 1);
    }
    connection.selected = (<HTMLInputElement>event.target).checked;
  }

  submit(): void {
    this.loading = true;
    const selectedConnections = filter(this.connections, { selected: true });
    const collaborations = selectedConnections.map(connection => {
      const collaborationForm = this.getConnectionFormGroup(connection.id);
      let collaboration = {
        ...connection.collaboration,
        ...collaborationForm.value
      };
      if (!collaboration.customFieldData) { collaboration.customFieldData = {}; }
      if (this.brokerRateKey) {
        collaboration.customFieldData[this.brokerRateKey] = collaborationForm.get('brokerRateCode').value;
      }
      collaboration.numTrucks = (collaborationForm.get('requestedUnit').value.name === 'Trucks')
        ? collaborationForm.get('requestedAmount').value : 0;
      collaboration.requestedUnit = collaboration.requestedUnit.value;
      collaboration.invoiceType = collaboration.invoiceType.value;
      collaboration.invoiceWeightUnit = collaboration.invoiceWeightUnit.value;
      return collaboration;
    });
    if (this.jobEvent && this.jobEvent.job) {
      this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(res => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  getConnections(query = {}): void {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
    this.loading = true;
    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      leased_org: 'False',
      is_carrier: 'True',
      customer_only: 'False',
      status: 'active',
      search: this.search,
      page_size: 100,
      ...query
    }).subscribe(connections => {
      this.connections = this.connections.filter(c => (c.selected)).concat(this.setCollaborationDefaults(connections));
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  preselectConnections(connections: string[]) {
    let connectionReqs: Observable<any>[] = [];
    connections.forEach(id => {
      let request = this.connectionService.get(id);
      connectionReqs.push(request);
    });
    forkJoin(connectionReqs).subscribe(res => {
      this.connections = this.setCollaborationDefaults(
        res.map(c => (Object.assign(c, { selected: true })))
      );
    });
  }

  onScroll(event): void {
    if (!this.loading && event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight * 3) {
      let request = this.connectionService.listNext();
      if (request) {
        this.loading = true;
        this.connectionsReq = request.subscribe(connections => {
          this.connections = this.connections.concat(this.setCollaborationDefaults(connections));
          this.loading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
        });
      }
    }
  }

  changeConnectionSearch(term: string): void {
    this.search = term;
    this.getConnections();
  }

  connectionToggle(event: Event): void {
    this.connections.map(_connection => {
      _connection.selected = this.allConnectionsSelected;
    });
  }

  loadDropdownSelectionChanged(event: any, load: JobLoad, connection: Connection): void {
    const loadSchedule: JobLoad[] = connection.collaboration.loadSchedule;
    const existingLoad = loadSchedule.findIndex(l => l.loadNumber === load.loadNumber);
    if (event.checked) {
      if (existingLoad === -1) {
        loadSchedule.push(load);
      }
    } else {
      if (existingLoad > -1) {
        loadSchedule.splice(existingLoad, 1);
      }
    }
    loadSchedule.sort((a, b) => a.loadNumber - b.loadNumber);
  }

  setCollaborationDefaults(connections: Connection[]): Connection[] {
    return connections.map(connection => {
      if (!connection.collaboration) { connection.collaboration = {}; }
      connection.collaboration['invoiceWeightUnit'] = this.jobEvent.haulWeightUnit || this.jobEvent.invoiceWeightUnit;
      connection.collaboration['invoiceType'] = this.jobEvent.haulType || this.jobEvent.invoiceType;
      connection.collaboration['invoiceRate'] = this.jobEvent.haulRate || this.jobEvent.rate;
      connection.collaboration['numTrucks'] = null;
      connection.collaboration['jobevents'] = [this.jobEvent.id];
      connection.collaboration['brokerRateCodeKey'] = this.brokerRateKey;
      connection.collaboration['loadSchedule'] = [];

      if (this.unitsOfMeasure && this.unitsOfMeasure.length) {
        connection.collaboration['requestedUnit'] = this.unitsOfMeasure.find(u => u.name === 'Trucks');
      }

      return connection;
    });
  }

  setFormControlValue(connection: Connection, control: string, option): void {
    const targetConnection = this.getConnectionFormGroup(connection.id);
    targetConnection.controls[control].setValue(option);

    if (control === 'invoiceType' && option.value === 'weight') {
      targetConnection.controls.invoiceWeightUnit.setValue(this.weightOption);
    } else if (control === 'invoiceType') {
      targetConnection.controls.invoiceWeightUnit.setValue(option);
    }
  }

  expandSearch(): void {
    this.search = null;
    this.getConnections();
  }

  applyTemplate(template: SimpleCollaborationTemplate): void {
    let selectedConnections = this.connections.filter((connection) => connection.selected);
    selectedConnections.forEach((connection) => {
      const targetConnection = this.getConnectionFormGroup(connection.id);
      targetConnection.patchValue(template);
      targetConnection.markAsDirty();
    });
  }

  getUnitsOfMeasure() {
    this.unitsOfMeasureService.list().subscribe((units) => {
      const unitsOfMeasure = units.map(unit => ({
        value: unit.id,
        label: unit.name,
        name: unit.name,
        selected: false,
      }));
      this.unitsOfMeasure = [...unitsOfMeasure];
    });
  }
}
