import {
  Component, OnInit, Input, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, find as _find, pickBy } from 'lodash';

import { CollaboratorService } from '../collaborator.service';
import { JobEvent } from '../../job-events/job-event';
import { parseErrors } from '../../shared/api.service';
import { DropdownComponent } from '../../shared';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { JobEventShareService } from '../../job-event-shares/job-event-share.service';
import { CollaborationTemplate } from '../collaboration-template';
import { JOBWEIGHTOPTIONS } from '../../app.constants';
import { UnitsOfMeasureService } from '../../units/units-of-measure.service';
import { JobLoad } from '../../dispatch/dispatch-by-job/job-load';
import { collaboratorsAnimation } from '../../shared/animations/collaborators.animation';
import { SimpleCollaborationTemplate } from '../collaboration-template/collaboration-template';

@Component({
  selector: 'edit-collaborators-dialog',
  templateUrl: './edit-collaborators-dialog.component.html',
  styleUrls: ['./edit-collaborators-dialog.component.scss'],
  animations: [collaboratorsAnimation]
})
export class EditCollaboratorsDialogComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() allSelected = false;
  @Input() selectedShareIds: string[] = [];
  @Input() excludedShareIds: string[] = [];
  @Input() brokerRateKey: string;
  @Input() hasLoadListsEnabled = false;
  @Input() loadList: JobLoad[] = [];
  collaborationTemplate: CollaborationTemplate = {
    jobEventsLoading: false,
    jobEventOptions: [],
    jobevents: [],
    numTrucks: null,
    invoiceWeightUnit: null,
    invoiceType: null,
    invoiceRate: null,
    notes: '',
    cancelMissing: false,
    requestedAmount: null,
    requestedUnit: null,
  };
  loading = false;
  errors = [];
  callback: Function;
  jobEventShares: JobEventShare[] = [];
  jobEventSharesReq: Subscription;
  rateTrackingOptions = [
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  weightOptions = [...JOBWEIGHTOPTIONS];
  displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
  weightOption;
  haulTypeOption;

  @ViewChildren('jobEventsDropdown') jobEventDropdowns: QueryList<DropdownComponent>;
  unitsOfMeasure: any[] = [];
  editCollaborators: FormGroup;

  get formControls() {
    return this.editCollaborators.controls;
  }

  constructor(
    public dialogRef: MatDialogRef<EditCollaboratorsDialogComponent>,
    private collaboratorService: CollaboratorService,
    private jobEventShareService: JobEventShareService,
    private unitsOfMeasureService: UnitsOfMeasureService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getJobEventShares();
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });

    if (this.jobEvent.job.allowWeight) {
      this.rateTrackingOptions = this.rateTrackingOptions.concat([
        { value: 'weight', label: 'Weight', name: 'Weight' }
      ]);
    }
    this.weightOption = _find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
    this.getUnitsOfMeasure();
    this.editCollaborators = this.fb.group({});
  }

  ngOnDestroy() {
    if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
      this.jobEventSharesReq.unsubscribe();
    }
  }

  submit(): void {
    this.loading = true;
    const collaborations = this.jobEventShares.map(jobEventShare => {
      const shareFormGroup = this.getFormGroup(jobEventShare.id);
      let collaboration = {
        ...jobEventShare.collaboration,
        ...shareFormGroup.value
      };
      if (!collaboration.customFieldData) { collaboration.customFieldData = {}; }
      if (this.brokerRateKey) {
        collaboration.customFieldData[this.brokerRateKey] = shareFormGroup.get('brokerRateCode').value;
      }
      collaboration.numTrucks = shareFormGroup.get('requestedUnit').value.name === 'Trucks'
        ? shareFormGroup.get('requestedAmount').value : 0;
      collaboration.requestedUnit = collaboration.requestedUnit.value;
      collaboration.invoiceType = collaboration.invoiceType.value;
      collaboration.invoiceWeightUnit = collaboration.invoiceWeightUnit.value;
      collaboration.loadSchedule = jobEventShare.loadSchedule;
      return collaboration;
    });
    if (this.jobEvent && this.jobEvent.job) {
      this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(res => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  getJobEventShares(query = {}): void {
    if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
      this.jobEventSharesReq.unsubscribe();
    }
    this.loading = true;
    this.jobEventSharesReq = this.jobEventShareService.list({
      ordering: 'created_at',
      jobevent: this.jobEvent && this.jobEvent.id,
      id__in: this.selectedShareIds,
      ...query
    }).subscribe(jobEventShares => {
      this.jobEventShares = filter(jobEventShares, (jobEventShare) => {
        if (
          (this.allSelected || this.selectedShareIds.includes(jobEventShare.id)) &&
          !this.excludedShareIds.includes(jobEventShare.id)
        ) {
          return jobEventShare;
        }
      });
      this.jobEventShares.forEach(jobEventShare => {
        jobEventShare.brokerRateCodeKey = this.brokerRateKey;
        jobEventShare.collaboration.jobevents = [this.jobEvent.id];
        const shareFormGroup = this.createCollaborationFormGroup(jobEventShare);
        this.editCollaborators.addControl(`share_${jobEventShare.id}`, shareFormGroup);
      });
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  getFormGroup(shareId: string): FormGroup {
    return this.editCollaborators.get(`share_${shareId}`) as FormGroup;
  }

  createCollaborationFormGroup(share: JobEventShare): FormGroup {
    const invoiceType = this.rateTrackingOptions.find((opt) => opt.value === share.collaboration.invoiceType);
    const invoiceWeightUnit = this.weightOptions.find((opt) => opt.value === share.collaboration.invoiceWeightUnit);
    return this.fb.group({
      requestedAmount: [share.collaboration.requestedAmount, [Validators.required, Validators.min(1)]],
      requestedUnit: [share.collaboration.requestedUnit],
      invoiceType: [invoiceType || this.haulTypeOption],
      invoiceRate: [{
        value: share.collaboration.invoiceRate,
        disabled: share.readonly
      }, Validators.min(0)],
      invoiceWeightUnit: [invoiceWeightUnit || this.weightOption],
      brokerRateCode: [share.collaboration.brokerRateCode || ''],
      notes: [{
        value: share.collaboration.notes || '',
        disabled: share.readonly
      }]
    });
  }

  setFormControlValue(share: JobEventShare, control: string, option: any) {
    const targetShare = this.getFormGroup(share.id);
    targetShare.controls[control].setValue(option);
    if (option.value === 'weight') {
      targetShare.controls.invoiceWeightUnit.setValue(this.weightOption);
    }
    targetShare.markAsDirty();
  }

  applyTemplate(template: SimpleCollaborationTemplate): void {
    this.jobEventShares.forEach((share) => {
      if (!share.readonly) {
        const targetConnection = this.getFormGroup(share.id);
        targetConnection.patchValue(template);
        targetConnection.markAsDirty();
      }
    });
  }

  loadDropdownSelectionChanged(event: any, load: JobLoad, share: JobEventShare): void {
    const loadSchedule: JobLoad[] = share.loadSchedule;
    const existingLoad = loadSchedule.findIndex(l => l.loadNumber === load.loadNumber);
    if (event.checked) {
      if (existingLoad === -1) {
        loadSchedule.push(load);
      }
    } else {
      if (existingLoad > -1) {
        loadSchedule.splice(existingLoad, 1);
      }
    }
    loadSchedule.sort((a, b) => a.loadNumber - b.loadNumber);
    this.editCollaborators.markAsDirty();
  }

  getUnitsOfMeasure() {
    this.unitsOfMeasureService.list().subscribe((units) => {
      this.unitsOfMeasure = units.map(unit => ({
        value: unit.id,
        label: unit.name,
        name: unit.name,
        selected: false,
      }));
    });
  }
}
