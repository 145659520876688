import { Directive, forwardRef, ElementRef, Renderer2 } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[phoneInputMask]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneInputMaskDirective), multi: true }
  ]
})
export class PhoneInputMaskDirective implements Validator {

  mask(e) {

    if (e.key && e.key.length !== 1) {
      return;
    }
    if (!e.key || e.key && !e.key.match(/\d/) && !e.metaKey) {
      e.preventDefault();
    }
    setTimeout(() => {
      if (e.target && e.target.value) {
        let value = e.target.value.match(/\d/g);
        if (value) {
          if (value.length >= 3) {
            value.splice(3, 0, '-');
          }
          if (value.length >= 7) {
            value.splice(7, 0, '-');
          }
          value = value.slice(0, 12);
          e.target.value = value.join('');
        } else {
          e.target.value = '';
        }
      }
    }, 10);
  }

  constructor(
    el: ElementRef,
    renderer: Renderer2
  ) {
    renderer.listen(el.nativeElement, 'keydown', ($event) => {
      this.mask($event);
    });
  }

  validate(c: AbstractControl): { [key: string]: any } {
    // self value
    let v = c.value;


    if (v && v.match) {
      let numbers = v.match(/\d/g);
      if (numbers && numbers.length >= 10) {
      } else {
        return {phone: false};
      }
    }
    return null;
  }

}
