import { MatDialog } from '@angular/material/dialog';
import {
  Component, OnInit, Input, ViewChild, TemplateRef, OnChanges, SimpleChanges
} from '@angular/core';
import { clone } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../../shared/index';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { TableConfig } from '../../shared/fancy-table/table.types';

@Component({
  selector: 'ruckit-job-stats',
  templateUrl: './job-stats.component.html',
  styleUrls: ['./job-stats.component.scss']
})

export class JobStatsComponent implements OnInit, OnChanges {
  @Input() availableColumns = [
    { key: 'requested-trucks', title: this.translationService.instant('Requested Trucks') },
    { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
    { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks') },
    { key: 'ordered-loads', title: this.translationService.instant('Ordered Loads') },
    { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads') },
    { key: 'ordered-tons', title: this.translationService.instant('Ordered Tons') },
    { key: 'delivered-tons', title: this.translationService.instant('Delivered Tons') },
    { key: 'connex-actual-tons', title: this.translationService.instant('Connex Actual Tons') },
  ];
  @Input() displayedColumns = [
    'requested-trucks', 'confirmed-trucks', 'dispatched-trucks',
    'ordered-loads', 'delivered-loads', 'ordered-tons', 'delivered-tons', 'connex-actual-tons'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() jobEventDate: Date;
  @Input() hideCancelled = false;
  loading = true;
  tableConfig: TableConfig = {
    hasHeader: true,
    ignorePreferences: true,
    pageSize: 1,
    service: JobEventStatService,
    serviceFunction: 'tableList',
    query: {},
    collectionTitle: this.translationService.instant('Stats'),
    noResultsText: this.translationService.instant('a job'),
    customHeight: true,
    condensedLayout: true,
    automaticallyHidePagination: true,
  };

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates') columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('jobStatsTable') jobStatsTable: FancyTableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let jobEventDateStart = new Date();
    let jobEventDateEnd = clone(jobEventDateStart);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateEnd = clone(jobEventDateStart);
      }
    } else if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateEnd = clone(jobEventDateStart);
    }

    jobEventDateStart.setHours(0, 0, 0, 0);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    this.tableConfig['query'] = {
      exclude_pending: 'True',
      cancelled: this.hideCancelled ? 'False' : undefined,
      shift1_start__gte: jobEventDateStart.toISOString(),
      shift1_start__lte: jobEventDateEnd.toISOString()
    };
    if (this.jobStatsTable) {
      this.jobStatsTable.config = <TableConfig>this.tableConfig;
      this.jobStatsTable.getRecords();
    }
  }

  filtersModified(appliedFilters): void {
    // let filter = _find(this.appliedFilters, { key: '' });
    // if (!appliedFilters || !appliedFilters.length ) {
    //   this.filterChanges(_find(this.priceListsReq, { id: null }));
    // }
  }

  refreshTable(): void {
    if (this.jobStatsTable) {
      let query = {};
      if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
      this.jobStatsTable.getRecords({ ...this.tableConfig['query'], ...query });
    }
  }
}
