import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// libraries
import * as moment from 'moment';

// services
import { parseErrors } from '../../shared/api.service';
import { DriverService } from '../../drivers/driver.service';
import { TruckService } from '../../trucks/truck.service';
import { ShiftService } from '../shift.service';

// models
import { Driver } from '../../drivers/driver';
import { Shift } from '../shift';

// angular material
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'new-shift-dialog',
  templateUrl: './new-shift-dialog.component.html',
  styleUrls: ['./new-shift-dialog.component.scss'],
})
export class NewShiftDialogComponent implements OnInit, OnDestroy {
  @Input() driverForShift: Driver = null;
  @Output() shiftCreated = new EventEmitter<Shift>();
  loading = false;
  errors = [];

  driverDropdownConfig = {
    service: DriverService,
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    sortBy: 'profile__first_name,profile__last_name',
  };
  truckDropdownConfig = {
    service: TruckService,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'displayName',
    sortBy: 'name',
  };

  newShiftForm: FormGroup;
  startShiftDate: moment.Moment;
  endShiftDate: moment.Moment;

  // TODO CALLBACK

  constructor(
    private shiftService: ShiftService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewShiftDialogComponent>
  ) {}

  ngOnInit() {
    this.initForm(this.driverForShift);
  }

  ngOnDestroy() {}

  initForm(driver: Driver) {
    const currentDate = new Date();

    this.newShiftForm = this.fb.group(
      {
        driver: [driver, [Validators.required]],
        truck: [
          driver && driver.truck ? driver.truck : null,
          [Validators.required],
        ],
        startDate: [currentDate, [Validators.required]],
        startTime: [null, [Validators.required]],
        endTime: [null],
        endDate: [null],
        adjustment: [null, [Validators.pattern('[^.]+')]],
      },
      {
        validator: this.dateLessThan(
          'startDate',
          'endDate',
          'startTime',
          'endTime'
        ),
      }
    );
  }

  get startDate() {
    return this.newShiftForm.get('startDate').value;
  }

  get endDate() {
    return this.newShiftForm.get('endDate').value;
  }

  get driver() {
    return this.newShiftForm.get('driver');
  }

  get truck() {
    return this.newShiftForm.get('truck');
  }

  get adjustment() {
    return this.newShiftForm.get('adjustment');
  }

  onDateChanged(date: string, dates: any[]) {
    this.newShiftForm.patchValue({
      [date]: dates[0],
    });
  }

  onSelect(type: string, value: any) {
    this.newShiftForm.patchValue({
      [type]: value,
    });
  }

  dateLessThan(dFrom: string, dTo: string, tFrom: string, tTo: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const dateFrom = group.controls[dFrom];
      const dateTo = group.controls[dTo];
      const timeFrom = group.controls[tFrom];
      const timeTo = group.controls[tTo];

      const fullDateFrom = moment(
        moment(dateFrom.value).format('MM/DD/YYYY') + ' ' + timeFrom.value,
        'MM/DD/YYYY HH:mm'
      );
      const fullDateTo = moment(
        moment(dateTo.value).format('MM/DD/YYYY') + ' ' + timeTo.value,
        'MM/DD/YYYY HH:mm'
      );

      this.startShiftDate = fullDateFrom;
      this.endShiftDate = fullDateTo;

      if (!fullDateTo.isValid()) {
        return {};
      }

      if (!fullDateTo.isSameOrAfter(fullDateFrom)) {
        return {
          dates: 'Date from should be less than start date',
        };
      }
      return {};
    };
  }

  submit(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.errors = [];

    const model = {
      driver: this.driver.value.id,
      truck: this.truck.value.id,
      startTime: this.startShiftDate.toISOString(),
      endTime: this.endShiftDate.toISOString(),
      adjustment: this.adjustment.value,
    };
    this.shiftService.save(model).subscribe(
      (newShift: Shift) => {
        this.loading = false;
        this.shiftCreated.emit(newShift);
        this.dialogRef.close();
      },
      (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
      }
    );
  }
}
